/**
 * Teamscale REST API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit the class manually.
 */

import type { QueryOperation } from './Query';
import type { MutationOperation } from './Mutation';
import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { ABAPLintConfigurationFileImportSummary } from 'typedefs/ABAPLintConfigurationFileImportSummary';
import type { AnalysisProfile } from 'typedefs/AnalysisProfile';
import type { AnalysisProfileEditVersion } from 'typedefs/AnalysisProfileEditVersion';
import type { AnalysisProfileHistoryVersionComparison } from 'typedefs/AnalysisProfileHistoryVersionComparison';
import type { AnalysisProfileUsageInfoWithProjects } from 'typedefs/AnalysisProfileUsageInfoWithProjects';
import type { AnalysisProfileWithProjects } from 'typedefs/AnalysisProfileWithProjects';
import type { AnalysisProgressDescriptor } from 'typedefs/AnalysisProgressDescriptor';
import type { AppInstallationInfo } from 'typedefs/AppInstallationInfo';
import type { AvatarData } from 'typedefs/AvatarData';
import type { Backup } from 'typedefs/Backup';
import type { BackupExportStatus } from 'typedefs/BackupExportStatus';
import type { BackupImportStatus } from 'typedefs/BackupImportStatus';
import type { BackupJobSummary } from 'typedefs/BackupJobSummary';
import type { BaselineInfo } from 'typedefs/BaselineInfo';
import type { BranchesInfo } from 'typedefs/BranchesInfo';
import type { CodeSearchResultsWrapper } from 'typedefs/CodeSearchResultsWrapper';
import type { CommitAlerts } from 'typedefs/CommitAlerts';
import type { CommitDescriptor } from 'typedefs/CommitDescriptor';
import type { CommitTreeNodeData } from 'typedefs/CommitTreeNodeData';
import type { CommitterDisplayName } from 'typedefs/CommitterDisplayName';
import type { ConnectorConfiguration } from 'typedefs/ConnectorConfiguration';
import type { CoverageSourceInfo } from 'typedefs/CoverageSourceInfo';
import type { Credentials } from 'typedefs/Credentials';
import type { DashboardDescriptor } from 'typedefs/DashboardDescriptor';
import type { DashboardTemplateDescriptor } from 'typedefs/DashboardTemplateDescriptor';
import type { DeclarationOfAccessibilityContext } from 'typedefs/DeclarationOfAccessibilityContext';
import type { DependencyEntry } from 'typedefs/DependencyEntry';
import type { DetailedInstanceComparisonValue } from 'typedefs/DetailedInstanceComparisonValue';
import type { EAnalysisStateEntry } from 'typedefs/EAnalysisState';
import type { EAuthenticationToolEntry } from 'typedefs/EAuthenticationTool';
import type { EBasicPermissionScopeEntry } from 'typedefs/EBasicPermissionScope';
import type { EBlacklistingOptionEntry } from 'typedefs/EBlacklistingOption';
import type { ECommitAttributeEntry } from 'typedefs/ECommitAttribute';
import type { ECommitTypeEntry } from 'typedefs/ECommitType';
import type { EDeleteModeEntry } from 'typedefs/EDeleteMode';
import type { EExternalDataExportGranularityEntry } from 'typedefs/EExternalDataExportGranularity';
import type { EExternalDataExportTargetEntry } from 'typedefs/EExternalDataExportTarget';
import type { EFindingBlacklistOperationEntry } from 'typedefs/EFindingBlacklistOperation';
import type { EFindingBlacklistTypeEntry } from 'typedefs/EFindingBlacklistType';
import type { EFindingsExportFormatEntry } from 'typedefs/EFindingsExportFormat';
import type { EInstanceComparisonStatusEntry } from 'typedefs/EInstanceComparisonStatus';
import type { EInstanceComparisonTypeEntry } from 'typedefs/EInstanceComparisonType';
import type { EMergeRequestStatusEntry } from 'typedefs/EMergeRequestStatus';
import type { EReportFormatEntry } from 'typedefs/EReportFormat';
import type { ERepresentationEntry } from 'typedefs/ERepresentation';
import type { ESchedulerCommandEntry } from 'typedefs/ESchedulerCommand';
import type { ESonarLintIndependentAnalysisStatusEntry } from 'typedefs/ESonarLintIndependentAnalysisStatus';
import type { ESortOrderEntry } from 'typedefs/ESortOrder';
import type { ETestPrioritizationStrategyEntry } from 'typedefs/ETestPrioritizationStrategy';
import type { ETokenClassEntry } from 'typedefs/ETokenClass';
import type { ETrafficLightColorEntry } from 'typedefs/ETrafficLightColor';
import type { ETypeEntry } from 'typedefs/EType';
import type { EventAnnouncement } from 'typedefs/EventAnnouncement';
import type { ExceptionsTree } from 'typedefs/ExceptionsTree';
import type { ExtendedFindingsWithCount } from 'typedefs/ExtendedFindingsWithCount';
import type { ExtendedMergeRequestsInfo } from 'typedefs/ExtendedMergeRequestsInfo';
import type { ExternalAnalysisGroup } from 'typedefs/ExternalAnalysisGroup';
import type { ExternalAnalysisPartitionInfo } from 'typedefs/ExternalAnalysisPartitionInfo';
import type { ExternalCredentialsData } from 'typedefs/ExternalCredentialsData';
import type { ExternalFindingsDescription } from 'typedefs/ExternalFindingsDescription';
import type { ExternalStorageBackendData } from 'typedefs/ExternalStorageBackendData';
import type { FileGroup } from 'typedefs/FileGroup';
import type { FilteredTreeMapWrapper } from 'typedefs/FilteredTreeMapWrapper';
import type { FindingBlacklistInfo } from 'typedefs/FindingBlacklistInfo';
import type { FindingBlacklistRequestBody } from 'typedefs/FindingBlacklistRequestBody';
import type { FindingChurnCount } from 'typedefs/FindingChurnCount';
import type { FindingDelta } from 'typedefs/FindingDelta';
import type { FindingTypeDescription } from 'typedefs/FindingTypeDescription';
import type { FindingsSummaryInfo } from 'typedefs/FindingsSummaryInfo';
import type { FindingsTreemapWrapper } from 'typedefs/FindingsTreemapWrapper';
import type { FormattedTokenElementInfo } from 'typedefs/FormattedTokenElementInfo';
import type { GetLinkRolesResponse } from 'typedefs/GetLinkRolesResponse';
import type { GitTag } from 'typedefs/GitTag';
import type { InstanceComparisonSnapshotDto } from 'typedefs/InstanceComparisonSnapshotDto';
import type { JobDescriptor } from 'typedefs/JobDescriptor';
import type { JobQueueCountWithDelayedJobs } from 'typedefs/JobQueueCountWithDelayedJobs';
import type { LateExternalUploadProcessingInfo } from 'typedefs/LateExternalUploadProcessingInfo';
import type { LicenseInfo } from 'typedefs/LicenseInfo';
import type { LineCoverageInfo } from 'typedefs/LineCoverageInfo';
import type { LoadProfile } from 'typedefs/LoadProfile';
import type { LoginContext } from 'typedefs/LoginContext';
import type { MethodHistoryEntriesWrapper } from 'typedefs/MethodHistoryEntriesWrapper';
import type { MethodLocation } from 'typedefs/MethodLocation';
import type { MetricDirectorySchema } from 'typedefs/MetricDirectorySchema';
import type { MetricTablePage } from 'typedefs/MetricTablePage';
import type { MetricThresholdConfiguration } from 'typedefs/MetricThresholdConfiguration';
import type { MetricTrendEntry } from 'typedefs/MetricTrendEntry';
import type { MetricsForThresholdProfile } from 'typedefs/MetricsForThresholdProfile';
import type { ParseLogEntry } from 'typedefs/ParseLogEntry';
import type { ParseLogOverviewEntry } from 'typedefs/ParseLogOverviewEntry';
import type { PartitionInfo } from 'typedefs/PartitionInfo';
import type { PermissionLookupEBasicPermission } from 'typedefs/PermissionLookupEBasicPermission';
import type { PreviousNextSiblings } from 'typedefs/PreviousNextSiblings';
import type { PrioritizableTest } from 'typedefs/PrioritizableTest';
import type { ProjectBranchingConfiguration } from 'typedefs/ProjectBranchingConfiguration';
import type { ProjectComparisonResult } from 'typedefs/ProjectComparisonResult';
import type { ProjectConfiguration } from 'typedefs/ProjectConfiguration';
import type { ProjectInfo } from 'typedefs/ProjectInfo';
import type { ProjectNotificationRules } from 'typedefs/ProjectNotificationRules';
import type { QualityReport } from 'typedefs/QualityReport';
import type { QueryParserFailure } from 'typedefs/QueryParserFailure';
import type { ReducedInstanceComparisonComputation } from 'typedefs/ReducedInstanceComparisonComputation';
import type { RedundantLiteralInfo } from 'typedefs/RedundantLiteralInfo';
import type { RepositoryChurn } from 'typedefs/RepositoryChurn';
import type { RepositoryLogEntryAggregate } from 'typedefs/RepositoryLogEntryAggregate';
import type { RepositoryLogFileHistoryEntry } from 'typedefs/RepositoryLogFileHistoryEntry';
import type { RepositorySummary } from 'typedefs/RepositorySummary';
import type { RiskMethods } from 'typedefs/RiskMethods';
import type { SonarLintFindingTransport } from 'typedefs/SonarLintFindingTransport';
import type { SonarLintIndependentAnalysisRequestParameters } from 'typedefs/SonarLintIndependentAnalysisRequestParameters';
import type { SpecItemReferenceMapping } from 'typedefs/SpecItemReferenceMapping';
import type { Stacktrace } from 'typedefs/Stacktrace';
import type { SufficientRamResponse } from 'typedefs/SufficientRamResponse';
import type { SwiftLintConfigurationImportSummary } from 'typedefs/SwiftLintConfigurationImportSummary';
import type { SystemProcessInfo } from 'typedefs/SystemProcessInfo';
import type { TeamscaleVersionContainer } from 'typedefs/TeamscaleVersionContainer';
import type { TestCoverageOverlayData } from 'typedefs/TestCoverageOverlayData';
import type { TestGapTreeMapWrapper } from 'typedefs/TestGapTreeMapWrapper';
import type { TestListDescriptor } from 'typedefs/TestListDescriptor';
import type { TestMinimizationJobRun } from 'typedefs/TestMinimizationJobRun';
import type { TestMinimizationRequestOptions } from 'typedefs/TestMinimizationRequestOptions';
import type { TestMinimizationResult } from 'typedefs/TestMinimizationResult';
import type { TestQueryResult } from 'typedefs/TestQueryResult';
import type { TgaTableEntry } from 'typedefs/TgaTableEntry';
import type { TokenElementChurnInfo } from 'typedefs/TokenElementChurnInfo';
import type { TrackedFinding } from 'typedefs/TrackedFinding';
import type { TreeMapNode } from 'typedefs/TreeMapNode';
import type { TriggerGraphNode } from 'typedefs/TriggerGraphNode';
import type { UserActivity } from 'typedefs/UserActivity';
import type { UserBatchOperation } from 'typedefs/UserBatchOperation';
import type { UserGroup } from 'typedefs/UserGroup';
import type { UserResolvedDashboardDescriptor } from 'typedefs/UserResolvedDashboardDescriptor';
import type { UserResolvedFindingBlacklistInfo } from 'typedefs/UserResolvedFindingBlacklistInfo';
import type { UserResolvedQualityReport } from 'typedefs/UserResolvedQualityReport';
import type { UserResolvedRepositoryLogEntry } from 'typedefs/UserResolvedRepositoryLogEntry';
import type { UserResolvedSpecItem } from 'typedefs/UserResolvedSpecItem';
import type { UserResolvedTeamscaleIssue } from 'typedefs/UserResolvedTeamscaleIssue';
import type { UserWithActivity } from 'typedefs/UserWithActivity';

export type AutocompleteIssueIdQueryParams = {
	'search'?: string
};

export type AutocompleteUserGroupQueryParams = {
	'token'?: string, 
	'regex'?: boolean, 
	'max_matches'?: number
};

export type AutocompleteUserNameQueryParams = {
	'token'?: string, 
	'regex'?: boolean, 
	'max_matches'?: number
};

export type ComputeMetricTableQueryParams = {
	/**
	 * The index of the first entry to return starting at 0
	 */
	'start'?: number, 
	/**
	 * Maximum number of entries to return. If set to 0 all entries will be omitted and only relevant metadata is returned. If set to a negative value, all relevant entries are returned.
	 */
	'max'?: number, 
	/**
	 * Name of the field to sort entries by
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Uniform path to compute the metric table for
	 */
	'uniform-path'?: string, 
	/**
	 * The name of the metric threshold configuration to use
	 */
	'configuration-name': string, 
	/**
	 * Allows to return only the metrics that are in the threshold profile.
	 */
	'limit-to-profile'?: boolean, 
	/**
	 * Allows to limit the considered metrics to the given list.
	 */
	'metrics'?: Array<string>, 
	/**
	 * Allows to specify which partitions should be considered. This only has an effect for test and test execution metrics.
	 */
	'partition'?: Array<string>, 
	/**
	 * Includes data from all partitions.
	 */
	'all-partitions'?: boolean
};

export type CreateBackupBody = {
	/**
	 * Include global data in the backup.
	 */
	'backup-global'?: boolean, 
	/**
	 * The backup path. If this is not set, a new internal path will be generated.
	 */
	'backup-path'?: string, 
	/**
	 * The branch (pattern) for which rules are applied. May be present multiple times.
	 */
	'export-option-branch'?: Array<string>, 
	/**
	 * The start date for export of external data. May be present multiple times.
	 */
	'export-option-date'?: Array<string>, 
	/**
	 * The granularity to apply for export of external data (DISCARD, DAILY, WEEKLY, PRESERVE). May be present multiple times.
	 */
	'export-option-granularity'?: Array<EExternalDataExportGranularityEntry>, 
	/**
	 * The partition (pattern) for which rules are applied. May be present multiple times.
	 */
	'export-option-partition'?: Array<string>, 
	/**
	 * The target to apply for export of external data. May be present multiple times.
	 */
	'export-option-target'?: Array<EExternalDataExportTargetEntry>, 
	/**
	 * Includes all user visible projects in the backup.
	 */
	'include-all-visible-projects'?: boolean, 
	/**
	 * Include information about branch labels associated with Git commits. Default: false.
	 */
	'include-git-labeling-info'?: boolean, 
	/**
	 * Include information about pending sessions for external uploads. Default: false.
	 */
	'include-pending-external-upload-sessions'?: boolean, 
	/**
	 * Include project data in the backup. May be present multiple times.
	 */
	'include-project'?: Array<string>
};

export type CreateComparisonBody = {
	/**
	 * Access token for the remote instance. May be empty, in this case access token of locally logged in user is assumed.
	 */
	'remote-access-token'?: string, 
	/**
	 * The ID of the remote snapshot. May be empty or an incomplete prefix, as long as exactly one remote snapshot is uniquely identified.
	 */
	'remote-snapshot-id'?: string, 
	/**
	 * URL of the remote instance
	 */
	'remote-url'?: string, 
	/**
	 * User name for the remote instance. May be empty, in this case locally logged in user is assumed.
	 */
	'remote-user'?: string
};

export type CreateDashboardFromTemplateQueryParams = {
	/**
	 * ID of the template to use as base for the new dashboard.
	 */
	'template': string, 
	/**
	 * ID of the project to use as datasource for the new dashboard.
	 */
	'project': string, 
	/**
	 * Name of the new dashboard.
	 */
	'dashboard-name': string, 
	/**
	 * Path within the project to use as datasource for the new dashboard.
	 */
	'path'?: string
};

export type CreateOrUpdateBaselineQueryParams = {
	/**
	 * Old baseline name, needed if case of baseline renaming
	 */
	'old-name'?: string
};

export type CreateProjectQueryParams = {
	/**
	 * Indicates whether to copy all data (ext. uploads, finding exclusions, etc.) from an existing project. The value is the project id.
	 */
	'copy-data-from-project'?: string, 
	/**
	 * Indicates whether to skip validation of the project. Can be used to force project creation despite validation errors.
	 */
	'skip-project-validation'?: boolean
};

export type CreateQualityReportQueryParams = {
	/**
	 * The id of the report to be copied/overwritten
	 */
	'from-template'?: string, 
	/**
	 * Whether to overwrite the existing report
	 */
	'overwrite'?: boolean
};

export type CreateSessionQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * This parameter allows to pass a revision instead of a timestamp.
	 */
	'revision'?: string, 
	/**
	 * When using the revision parameter, this parameter allows to pass a repository name which is used to identify the correct commit in situations where the same revision exists in multiple repositories.
	 */
	'repository'?: string, 
	/**
	 * A message that describes the external analysis, similar to a commit message.
	 */
	'message'?: string, 
	/**
	 * The name of the logical partition to store the results into. All existing data in this partition will be invalidated. A partition typically corresponds to one analysis run, i.e. if there are two independent builds/runs, they must use different partitions.The partition parameter is only required with an auto-create session.
	 */
	'partition'?: string, 
	/**
	 * Whether to move the upload timestamp to right after the last commit.
	 */
	'movetolastcommit'?: boolean
};

export type CreateSnapshotBody = {
	/**
	 * Whether a more detailed snapshot should be created (i.e. includes which findings are present). May slow down the snapshot creation and comparison computation depending on instance size.
	 */
	'detailed-snapshot'?: boolean, 
	/**
	 * The timestamp used to determine when to cut off comparison. Useful when one instance has an ongoing analysis, and the other does not.
	 */
	'end-timestamp'?: string, 
	/**
	 * Optional regex pattern to specify which projects should be excluded from the snapshot.
	 */
	'project-exclusion-pattern'?: string, 
	/**
	 * Optional regex pattern to specify which projects should be included in the snapshot.
	 */
	'project-inclusion-pattern'?: string, 
	/**
	 * Access token for the remote instance. May be empty, in this case access token of locally logged in user is assumed.
	 */
	'remote-access-token'?: string, 
	/**
	 * URL of the remote instance
	 */
	'remote-url'?: string, 
	/**
	 * User name for the remote instance. May be empty, in this case locally logged in user is assumed.
	 */
	'remote-user'?: string, 
	/**
	 * Whether to schedule a comparison with a remote instance that uses the same parameters.
	 */
	'schedule-remote-comparison'?: string
};

export type DeleteAllUnprocessedArchitectureCommitsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type DeleteExternalAccountsQueryParams = {
	/**
	 * Skip connector validation for connectors using the account.
	 */
	'skip-connector-validation'?: boolean
};

export type DeleteExternalStorageBackendQueryParams = {
	/**
	 * Force deletion of the external storage backend.
	 */
	'force-deletion'?: boolean
};

export type DeleteJobTestsQueryParams = {
	'deleteMode'?: EDeleteModeEntry
};

export type DownloadHeapDumpBody = {
	/**
	 * Whether dead (i.e., unreachable) objects are included in the heap dump.
	 */
	'include-dead-objects'?: boolean
};

export type DownloadTestSelectionCsvQueryParams = {
	'timeBudgetMillis'?: number
};

export type EditServerOptionQueryParams = {
	/**
	 * If the option should be saved despite failing validation
	 */
	'save-if-validation-fails'?: boolean
};

export type ExecuteSchedulerCommandBody = {
	'command'?: ESchedulerCommandEntry
};

export type FindInitialCommitQueryParams = {
	/**
	 * The path of the file
	 */
	'uniformPath'?: string
};

export type FindLogEntriesInRangeQueryParams = {
	/**
	 * Start timestamp to return repository log entries after.
	 */
	'start'?: number, 
	/**
	 * End timestamp to return repository log entries before.
	 */
	'end'?: number, 
	/**
	 * Whether or not commits for the timestamps from the start and/or end commit are included.
	 */
	'include-bounds'?: boolean, 
	/**
	 * The amount of repository logs returned when at least one of the commit parameters is omitted.
	 */
	'entry-count'?: number, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon. Only log entries from commits merged into the given branch up to the given point will be considered when the start and end timestamps are evaluated.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Controls whether only repository log entries are returned where the current user was the committer.
	 */
	'privacy-aware'?: boolean, 
	/**
	 * The commit types to show or hide. If omitted, commits for all types are returned.
	 */
	'commit-types'?: Array<ECommitTypeEntry>, 
	/**
	 * The commit attribute to filter by.
	 */
	'commit-attribute'?: ECommitAttributeEntry, 
	/**
	 * Option whether to filter out commits, that belong to other branches.
	 */
	'exclude-other-branches'?: boolean, 
	/**
	 * User names to filter for.
	 */
	'users'?: Array<string>, 
	/**
	 * Group names to filter for.
	 */
	'groups'?: Array<string>, 
	/**
	 * Whether we want to filter for spec items or code.
	 */
	'specItemEntriesOnly'?: boolean, 
	/**
	 * Commit messages to filter for.
	 */
	'commit-messages'?: Array<string>
};

export type FlagFindingsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Request operation to perform (e.g. add or remove blacklisting information).
	 */
	'operation': EFindingBlacklistOperationEntry, 
	/**
	 * The type of flagging (optional for an unflag operation, findings of both types will be unflagged)
	 */
	'type'?: EFindingBlacklistTypeEntry
};

export type GetAllDashboardsQueryParams = {
	/**
	 * If the dashboards should be retrieved for specific project only
	 */
	'project'?: string
};

export type GetAllGitTagsQueryParams = {
	/**
	 * Only return tags from the given repository
	 */
	'repository'?: string
};

export type GetAllMetricThresholdConfigurationNamesQueryParams = {
	/**
	 * Parameter whether to include default configurations.
	 */
	'include-default-configurations'?: boolean
};

export type GetAllMetricsForThresholdProfilesQueryParams = {
	/**
	 * Project for which to return the metrics for threshold profiles.
	 */
	'project'?: string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetAllPartitionDetailsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetAllProjectsQueryParams = {
	/**
	 * Whether to include projects marked as deleted or not.
	 */
	'include-deleting'?: boolean, 
	/**
	 * Whether to include reanalyzing projects or not.
	 */
	'include-reanalyzing'?: boolean
};

export type GetAllSnapshotIdsQueryParams = {
	/**
	 * Whether only the IDs of the snapshots created by the currently logged in user should be returned
	 */
	'only-current-user'?: boolean
};

export type GetAnalysisEditHistoryVersionDiffQueryParams = {
	/**
	 * The version number of the newer version
	 */
	'newVersion': number, 
	/**
	 * The version number of the older version
	 */
	'oldVersion': number
};

export type GetAnalysisResultsByPathQueryParams = {
	'sessionKey'?: string
};

export type GetAnalysisStatusQueryParams = {
	'sessionKey'?: string
};

export type GetBranchForSpecItemQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetBranchesGetRequestQueryParams = {
	/**
	 * The offset from which to start loading branches.
	 */
	'start-offset'?: number, 
	/**
	 * The number of branches that should be returned.
	 */
	'limit'?: number, 
	/**
	 * Whether only currently live branches should be returned.
	 */
	'only-live-branches'?: boolean, 
	/**
	 * If this is set the service uses the regex filter to return branches matching the filter.
	 */
	'regex-filter'?: string, 
	/**
	 * If this is set the service checks whether the passed branches are contained in the currently live branches of the project and returns the one that are.
	 */
	'filter'?: Array<string>
};

export type GetBranchesPostRequestQueryParams = {
	/**
	 * The offset from which to start loading branches.
	 */
	'start-offset'?: number, 
	/**
	 * The number of branches that should be returned.
	 */
	'limit'?: number, 
	/**
	 * Whether only currently live branches should be returned.
	 */
	'only-live-branches'?: boolean, 
	/**
	 * If this is set the service uses the regex filter to return branches matching the filter.
	 */
	'regex-filter'?: string, 
	/**
	 * If this is set the service checks whether the passed branches are contained in the currently live branches of the project and returns the one that are.
	 */
	'filter'?: Array<string>
};

export type GetChangeTreemapQueryParams = {
	/**
	 * The index for the metric used to determine the area of the displayed nodes.
	 */
	'area-metric'?: number, 
	/**
	 * The width for the treemap to layout.
	 */
	'width'?: number, 
	/**
	 * The height for the treemap to layout.
	 */
	'height'?: number, 
	/**
	 * Range start timestamp
	 */
	't1'?: UnresolvedCommitDescriptor, 
	/**
	 * Range end timestamp
	 */
	't2'?: UnresolvedCommitDescriptor, 
	/**
	 * Range duration in milliseconds
	 */
	'max-milliseconds'?: number, 
	/**
	 * Uniform path to retrieve data for
	 */
	'uniform-path'?: string
};

export type GetCodeComponentsTreemapWrapperQueryParams = {
	'uniform-path': string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * The index for the metric used to determine the area of the displayed nodes.
	 */
	'area-metric'?: number, 
	/**
	 * The index for the metric used to determine the color of the displayed nodes.
	 */
	'color-metric'?: number, 
	'min-value-for-color'?: number, 
	'max-value-for-color'?: number, 
	'color'?: number, 
	/**
	 * Regular expressions the file paths have to match to be displayed.
	 */
	'included-files-regexes'?: Array<string>, 
	/**
	 * Regular expressions the file paths may not match to be displayed.
	 */
	'excluded-files-regexes'?: Array<string>, 
	/**
	 * The partitions to consider for metric retrieval
	 */
	'partition'?: Array<string>, 
	/**
	 * Whether all partitions should be included
	 */
	'all-partitions'?: boolean, 
	/**
	 * Whether to enable the color-blind mode for the Treemap
	 */
	'color-blind-mode'?: boolean, 
	/**
	 * The width for the treemap to layout.
	 */
	'width'?: number, 
	/**
	 * The height for the treemap to layout.
	 */
	'height'?: number, 
	/**
	 * Color (as 6-digit hex value without preceding #) for highlighted components.
	 */
	'highlight-color'?: string, 
	/**
	 * Pattern to color only matching components.
	 */
	'highlight-term'?: string, 
	/**
	 * Specifies whether top-level components should be colored or whether languages should be colored
	 */
	'is-show-components'?: boolean
};

export type GetCodeReferencesAndTestExecutionMappingQueryParams = {
	/**
	 * Spec item query
	 */
	'query': string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetCodeSearchMatchResultAndTreemapQueryParams = {
	/**
	 * Uniform path to retrieve files for
	 */
	'uniform-path': string, 
	/**
	 * The index for the metric used to determine the area of the displayed nodes.
	 */
	'area-metric'?: number, 
	/**
	 * The index for the metric used to determine the color of the displayed nodes.
	 */
	'color-metric'?: number, 
	'min-value-for-color'?: number, 
	'max-value-for-color'?: number, 
	'color'?: number, 
	/**
	 * Regular expressions the file paths have to match to be displayed.
	 */
	'included-files-regexes'?: Array<string>, 
	/**
	 * Regular expressions the file paths may not match to be displayed.
	 */
	'excluded-files-regexes'?: Array<string>, 
	/**
	 * The partitions to consider for metric retrieval
	 */
	'partition'?: Array<string>, 
	/**
	 * Whether all partitions should be included
	 */
	'all-partitions'?: boolean, 
	/**
	 * Whether to enable the color-blind mode for the Treemap
	 */
	'color-blind-mode'?: boolean, 
	/**
	 * The width for the treemap to layout.
	 */
	'width'?: number, 
	/**
	 * The height for the treemap to layout.
	 */
	'height'?: number, 
	'search-term': string, 
	'token-classes'?: Array<ETokenClassEntry>, 
	'is-color-gradation-active'?: boolean, 
	/**
	 * Result list size limit (or 0 for no limit)
	 */
	'preview-size'?: number, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetCommitAlertsQueryParams = {
	'commit'?: Array<UnresolvedCommitDescriptor>
};

export type GetCompleteExampleListQueryParams = {
	/**
	 * Whether the \"missingLocal\" or \"missingRemote\" value is requested.
	 */
	'missingLocal'?: boolean
};

export type GetConcurrencyPerformanceDetailsQueryParams = {
	/**
	 * The Analysis States to include, must value from EAnalysisState enum.
	 */
	'state'?: Array<EAnalysisStateEntry>
};

export type GetCoverageSourcesQueryParams = {
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution/coverage of methods. The result will not contain information about test gaps, but only about which methods were executed.
	 */
	'execution-only'?: boolean, 
	/**
	 * Whether we want to view only the churn. This means coverage is ignored for the assessment and methods are only classified as unchanged, added or modified.
	 */
	'churn'?: boolean, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor
};

export type GetDebugContentQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	'representation'?: ERepresentationEntry, 
	'pre-commit-id'?: string
};

export type GetDeclarationOfAccessibilityQueryParams = {
	/**
	 * Whether a reload should be forced.
	 */
	'force-reload'?: boolean
};

export type GetDependencyGraphQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetDumpCommitDescriptorQueryParams = {
	/**
	 * If the xdot parameter is true, the service returns commit descriptors as an xdot graph. Otherwise it returns a plain text.
	 */
	'xdot'?: boolean
};

export type GetDumpCommitTreeQueryParams = {
	/**
	 * If the xdot parameter is true, the service returns the commit trees as an xdot graph. Otherwise it returns a plain text.
	 */
	'xdot'?: boolean, 
	/**
	 * When provided will only dump the commit trees for the given repository, instead of all repository trees.
	 */
	'repository-id'?: string
};

export type GetDumpCommitTreeNodesQueryParams = {
	/**
	 * When provided will only dump the commit tree for the given repository, instead of all repository trees.
	 */
	'repository-id'?: string
};

export type GetExceptionsHierarchyTreeQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetExceptionsHierarchyTreeSVGQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Whether the DOT format is expected.
	 */
	'isdot'?: boolean
};

export type GetFilesWithIdenticalContentQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	'regex'?: string, 
	'ignoreWhitespaces'?: boolean
};

export type GetFilesWithIdenticalNamesQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	'regex'?: string
};

export type GetFindingChurnCountQueryParams = {
	'commit'?: Array<UnresolvedCommitDescriptor>
};

export type GetFindingDeltaQueryParams = {
	/**
	 * Range start timestamp
	 */
	't1'?: UnresolvedCommitDescriptor, 
	/**
	 * Range end timestamp
	 */
	't2'?: UnresolvedCommitDescriptor, 
	/**
	 * Range duration in milliseconds
	 */
	'max-milliseconds'?: number, 
	/**
	 * Uniform path to retrieve data for
	 */
	'uniform-path'?: string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * The regex filter. Filters findings by this regex. Considered Fields are Message and Location. Matches will be included in the result.
	 */
	'regex'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline-rules'?: Array<string>, 
	/**
	 * Whether regex excludes or includes findings.
	 */
	'exclude-regex'?: boolean, 
	/**
	 * The added to task filter. Filter findings that already have a task assigned.
	 */
	'added-to-task'?: boolean, 
	/**
	 * The finding category, group, and type filters. Every string must be either a single category, a combination category/group, or a type ID. If a category or group is given, all matching findings will be filtered out and not included in the result.
	 */
	'filter'?: Array<string>, 
	/**
	 * Whether to invert the category, group, type filters, i.e. including the elements given in the filters instead of excluding them.
	 */
	'invert'?: boolean, 
	/**
	 * The assessment filter. All mentioned assessment colors will be filtered out and not included in the result.
	 */
	'assessment-filters'?: Array<ETrafficLightColorEntry>, 
	/**
	 * The blacklist filtering option.
	 */
	'blacklisted'?: EBlacklistingOptionEntry, 
	/**
	 * If this parameter is given, only findings whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only findings whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean, 
	/**
	 * If this parameter is given, only findings for spec items matching the query are returned.
	 */
	'spec-item-query'?: string, 
	/**
	 * Indicates whether the result should contain the actual delta findings.
	 */
	'numeric-delta-only'?: boolean, 
	/**
	 * Filter findings changed by commits of this user group. The group needs at least 3 members.
	 */
	'group'?: string
};

export type GetFindingsQueryParams = {
	/**
	 * The uniform path for which findings should be retrieved. This can be either a concrete file or a container.
	 */
	'uniform-path'?: string, 
	/**
	 * If this is true, the findings are adjusted to match a pretty printed version of the code. This may not be used together with recursive queries to directories.
	 */
	'pretty'?: boolean, 
	/**
	 * If this is true, the uniform path will be treated case-insensitive. This may be used if the file casing may be different to the one on the Teamscale server.
	 */
	'case-insensitive-path'?: boolean, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * The regex filter. Filters findings by this regex. Considered Fields are Message and Location. Matches will be included in the result.
	 */
	'regex'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline-rules'?: Array<string>, 
	/**
	 * Whether regex excludes or includes findings.
	 */
	'exclude-regex'?: boolean, 
	/**
	 * The added to task filter. Filter findings that already have a task assigned.
	 */
	'added-to-task'?: boolean, 
	/**
	 * The finding category, group, and type filters. Every string must be either a single category, a combination category/group, or a type ID. If a category or group is given, all matching findings will be filtered out and not included in the result.
	 */
	'filter'?: Array<string>, 
	/**
	 * Whether to invert the category, group, type filters, i.e. including the elements given in the filters instead of excluding them.
	 */
	'invert'?: boolean, 
	/**
	 * The assessment filter. All mentioned assessment colors will be filtered out and not included in the result.
	 */
	'assessment-filters'?: Array<ETrafficLightColorEntry>, 
	/**
	 * The blacklist filtering option.
	 */
	'blacklisted'?: EBlacklistingOptionEntry, 
	/**
	 * If this parameter is given, only findings whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only findings whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean, 
	/**
	 * If this parameter is given, only findings for spec items matching the query are returned.
	 */
	'spec-item-query'?: string, 
	/**
	 * The baseline name or baseline timestamp, with regards to which the findings shall be filtered.
	 */
	'baseline'?: string, 
	/**
	 * If this parameter is given, general findings and such covering the specified qualified location are returned.
	 */
	'qualified-name'?: string, 
	/**
	 * If this is true, findings in changed code as included as well. Only used if a baseline is provided as well.
	 */
	'include-changed-code-findings'?: boolean, 
	/**
	 * If this is true, only findings in changed code are to be shown. Only used if a baseline is provided as well.
	 */
	'only-changed-code-findings'?: boolean, 
	/**
	 * A pattern to be matched against the rationale for which the findings was tolerated or marked as false positive.
	 */
	'blacklistRationale'?: string, 
	/**
	 * The finding property by which the result is sorted. If none is given the result is not sorted. One of group, uniformpath, message, or a finding property, or random
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * If this parameter is given, the findings returned will start from this index (0 based), i.e. the first start findings in the list (for current sorting) will be skipped.
	 */
	'start'?: number, 
	/**
	 * Limits the number of findings returned. If also the parameter 'all' is used, the limit is ignored. Providing no limit will use the default limit of 300.
	 */
	'max'?: number, 
	/**
	 * If this is true, the finding list is not truncated to 300 elements.
	 */
	'all'?: boolean
};

export type GetFindingsExportQueryParams = {
	/**
	 * The uniform path for which findings should be retrieved. This can be either a concrete file or a container. In the latter case the recursive parameter can be used to specify whether sub-trees should be considered.
	 */
	'uniform-path'?: string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * The regex filter. Filters findings by this regex. Considered Fields are Message and Location. Matches will be included in the result.
	 */
	'regex'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline-rules'?: Array<string>, 
	/**
	 * Whether regex excludes or includes findings.
	 */
	'exclude-regex'?: boolean, 
	/**
	 * The added to task filter. Filter findings that already have a task assigned.
	 */
	'added-to-task'?: boolean, 
	/**
	 * The finding category, group, and type filters. Every string must be either a single category, a combination category/group, or a type ID. If a category or group is given, all matching findings will be filtered out and not included in the result.
	 */
	'filter'?: Array<string>, 
	/**
	 * Whether to invert the category, group, type filters, i.e. including the elements given in the filters instead of excluding them.
	 */
	'invert'?: boolean, 
	/**
	 * The assessment filter. All mentioned assessment colors will be filtered out and not included in the result.
	 */
	'assessment-filters'?: Array<ETrafficLightColorEntry>, 
	/**
	 * The blacklist filtering option.
	 */
	'blacklisted'?: EBlacklistingOptionEntry, 
	/**
	 * If this parameter is given, only findings whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only findings whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean, 
	/**
	 * If this parameter is given, only findings for spec items matching the query are returned.
	 */
	'spec-item-query'?: string, 
	/**
	 * The baseline name or baseline timestamp, with regards to which the findings shall be filtered.
	 */
	'baseline'?: string, 
	/**
	 * If this parameter is given, general findings and such covering the specified qualified location are returned.
	 */
	'qualified-name'?: string, 
	/**
	 * If this is true, findings in changed code as included as well. Only used if a baseline is provided as well.
	 */
	'include-changed-code-findings'?: boolean, 
	/**
	 * If this is true, only findings in changed code are to be shown. Only used if a baseline is provided as well.
	 */
	'only-changed-code-findings'?: boolean, 
	/**
	 * A pattern to be matched against the rationale for which the findings was tolerated or marked as false positive.
	 */
	'blacklistRationale'?: string, 
	/**
	 * The finding property by which the result is sorted. If none is given the result is not sorted. One of group, uniformpath, message, or a finding property, or random
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry
};

export type GetFindingsSiblingsQueryParams = {
	/**
	 * The uniform path for which findings should be retrieved. This can be either a concrete file or a container.
	 */
	'uniform-path'?: string, 
	/**
	 * If this is true, the findings are adjusted to match a pretty printed version of the code. This may not be used together with recursive queries to directories.
	 */
	'pretty'?: boolean, 
	/**
	 * If this is true, the uniform path will be treated case-insensitive. This may be used if the file casing may be different to the one on the Teamscale server.
	 */
	'case-insensitive-path'?: boolean, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * The regex filter. Filters findings by this regex. Considered Fields are Message and Location. Matches will be included in the result.
	 */
	'regex'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline-rules'?: Array<string>, 
	/**
	 * Whether regex excludes or includes findings.
	 */
	'exclude-regex'?: boolean, 
	/**
	 * The added to task filter. Filter findings that already have a task assigned.
	 */
	'added-to-task'?: boolean, 
	/**
	 * The finding category, group, and type filters. Every string must be either a single category, a combination category/group, or a type ID. If a category or group is given, all matching findings will be filtered out and not included in the result.
	 */
	'filter'?: Array<string>, 
	/**
	 * Whether to invert the category, group, type filters, i.e. including the elements given in the filters instead of excluding them.
	 */
	'invert'?: boolean, 
	/**
	 * The assessment filter. All mentioned assessment colors will be filtered out and not included in the result.
	 */
	'assessment-filters'?: Array<ETrafficLightColorEntry>, 
	/**
	 * The blacklist filtering option.
	 */
	'blacklisted'?: EBlacklistingOptionEntry, 
	/**
	 * If this parameter is given, only findings whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only findings whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean, 
	/**
	 * If this parameter is given, only findings for spec items matching the query are returned.
	 */
	'spec-item-query'?: string, 
	/**
	 * The baseline name or baseline timestamp, with regards to which the findings shall be filtered.
	 */
	'baseline'?: string, 
	/**
	 * If this parameter is given, general findings and such covering the specified qualified location are returned.
	 */
	'qualified-name'?: string, 
	/**
	 * If this is true, findings in changed code as included as well. Only used if a baseline is provided as well.
	 */
	'include-changed-code-findings'?: boolean, 
	/**
	 * If this is true, only findings in changed code are to be shown. Only used if a baseline is provided as well.
	 */
	'only-changed-code-findings'?: boolean, 
	/**
	 * A pattern to be matched against the rationale for which the findings was tolerated or marked as false positive.
	 */
	'blacklistRationale'?: string, 
	/**
	 * The finding property by which the result is sorted. If none is given the result is not sorted. One of group, uniformpath, message, or a finding property, or random
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * If this parameter is given, the findings returned will start from this index (0 based), i.e. the first start findings in the list (for current sorting) will be skipped.
	 */
	'start'?: number, 
	/**
	 * Limits the number of findings returned. If also the parameter 'all' is used, the limit is ignored. Providing no limit will use the default limit of 300.
	 */
	'max'?: number, 
	/**
	 * If this is true, the finding list is not truncated to 300 elements.
	 */
	'all'?: boolean
};

export type GetFindingsSummaryQueryParams = {
	'uniform-path'?: string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * The regex filter. Filters findings by this regex. Considered Fields are Message and Location. Matches will be included in the result.
	 */
	'regex'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline-rules'?: Array<string>, 
	/**
	 * Whether regex excludes or includes findings.
	 */
	'exclude-regex'?: boolean, 
	/**
	 * The added to task filter. Filter findings that already have a task assigned.
	 */
	'added-to-task'?: boolean, 
	/**
	 * The finding category, group, and type filters. Every string must be either a single category, a combination category/group, or a type ID. If a category or group is given, all matching findings will be filtered out and not included in the result.
	 */
	'filter'?: Array<string>, 
	/**
	 * Whether to invert the category, group, type filters, i.e. including the elements given in the filters instead of excluding them.
	 */
	'invert'?: boolean, 
	/**
	 * The assessment filter. All mentioned assessment colors will be filtered out and not included in the result.
	 */
	'assessment-filters'?: Array<ETrafficLightColorEntry>, 
	/**
	 * The blacklist filtering option.
	 */
	'blacklisted'?: EBlacklistingOptionEntry, 
	/**
	 * If this parameter is given, only findings whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only findings whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean, 
	/**
	 * If this parameter is given, only findings for spec items matching the query are returned.
	 */
	'spec-item-query'?: string, 
	/**
	 * The baseline name or baseline timestamp, with regards to which the findings shall be filtered.
	 */
	'baseline'?: string, 
	/**
	 * If this parameter is given, general findings and such covering the specified qualified location are returned.
	 */
	'qualified-name'?: string, 
	/**
	 * If this is true, findings in changed code as included as well. Only used if a baseline is provided as well.
	 */
	'include-changed-code-findings'?: boolean, 
	/**
	 * If this is true, only findings in changed code are to be shown. Only used if a baseline is provided as well.
	 */
	'only-changed-code-findings'?: boolean, 
	/**
	 * A pattern to be matched against the rationale for which the findings was tolerated or marked as false positive.
	 */
	'blacklistRationale'?: string, 
	/**
	 * Whether finding categories without findings will be included.
	 */
	'report-categories-without-findings'?: boolean
};

export type GetFindingsTreemapQueryParams = {
	'uniform-path'?: string, 
	/**
	 * The width for the treemap to layout.
	 */
	'width'?: number, 
	/**
	 * The height for the treemap to layout.
	 */
	'height'?: number, 
	/**
	 * Optional: The main color for the treemap.
	 */
	'color'?: string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * The regex filter. Filters findings by this regex. Considered Fields are Message and Location. Matches will be included in the result.
	 */
	'regex'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline-rules'?: Array<string>, 
	/**
	 * Whether regex excludes or includes findings.
	 */
	'exclude-regex'?: boolean, 
	/**
	 * The added to task filter. Filter findings that already have a task assigned.
	 */
	'added-to-task'?: boolean, 
	/**
	 * The finding category, group, and type filters. Every string must be either a single category, a combination category/group, or a type ID. If a category or group is given, all matching findings will be filtered out and not included in the result.
	 */
	'filter'?: Array<string>, 
	/**
	 * Whether to invert the category, group, type filters, i.e. including the elements given in the filters instead of excluding them.
	 */
	'invert'?: boolean, 
	/**
	 * The assessment filter. All mentioned assessment colors will be filtered out and not included in the result.
	 */
	'assessment-filters'?: Array<ETrafficLightColorEntry>, 
	/**
	 * The blacklist filtering option.
	 */
	'blacklisted'?: EBlacklistingOptionEntry, 
	/**
	 * If this parameter is given, only findings whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only findings whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean, 
	/**
	 * If this parameter is given, only findings for spec items matching the query are returned.
	 */
	'spec-item-query'?: string, 
	/**
	 * The baseline name or baseline timestamp, with regards to which the findings shall be filtered.
	 */
	'baseline'?: string, 
	/**
	 * If this parameter is given, general findings and such covering the specified qualified location are returned.
	 */
	'qualified-name'?: string, 
	/**
	 * If this is true, findings in changed code as included as well. Only used if a baseline is provided as well.
	 */
	'include-changed-code-findings'?: boolean, 
	/**
	 * If this is true, only findings in changed code are to be shown. Only used if a baseline is provided as well.
	 */
	'only-changed-code-findings'?: boolean, 
	/**
	 * A pattern to be matched against the rationale for which the findings was tolerated or marked as false positive.
	 */
	'blacklistRationale'?: string
};

export type GetFindingsWithCountQueryParams = {
	/**
	 * The uniform path for which findings should be retrieved. This can be either a concrete file or a container.
	 */
	'uniform-path'?: string, 
	/**
	 * If this is true, the findings are adjusted to match a pretty printed version of the code. This may not be used together with recursive queries to directories.
	 */
	'pretty'?: boolean, 
	/**
	 * If this is true, the uniform path will be treated case-insensitive. This may be used if the file casing may be different to the one on the Teamscale server.
	 */
	'case-insensitive-path'?: boolean, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * The regex filter. Filters findings by this regex. Considered Fields are Message and Location. Matches will be included in the result.
	 */
	'regex'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline-rules'?: Array<string>, 
	/**
	 * Whether regex excludes or includes findings.
	 */
	'exclude-regex'?: boolean, 
	/**
	 * The added to task filter. Filter findings that already have a task assigned.
	 */
	'added-to-task'?: boolean, 
	/**
	 * The finding category, group, and type filters. Every string must be either a single category, a combination category/group, or a type ID. If a category or group is given, all matching findings will be filtered out and not included in the result.
	 */
	'filter'?: Array<string>, 
	/**
	 * Whether to invert the category, group, type filters, i.e. including the elements given in the filters instead of excluding them.
	 */
	'invert'?: boolean, 
	/**
	 * The assessment filter. All mentioned assessment colors will be filtered out and not included in the result.
	 */
	'assessment-filters'?: Array<ETrafficLightColorEntry>, 
	/**
	 * The blacklist filtering option.
	 */
	'blacklisted'?: EBlacklistingOptionEntry, 
	/**
	 * If this parameter is given, only findings whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only findings whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean, 
	/**
	 * If this parameter is given, only findings for spec items matching the query are returned.
	 */
	'spec-item-query'?: string, 
	/**
	 * The baseline name or baseline timestamp, with regards to which the findings shall be filtered.
	 */
	'baseline'?: string, 
	/**
	 * If this parameter is given, general findings and such covering the specified qualified location are returned.
	 */
	'qualified-name'?: string, 
	/**
	 * If this is true, findings in changed code as included as well. Only used if a baseline is provided as well.
	 */
	'include-changed-code-findings'?: boolean, 
	/**
	 * If this is true, only findings in changed code are to be shown. Only used if a baseline is provided as well.
	 */
	'only-changed-code-findings'?: boolean, 
	/**
	 * A pattern to be matched against the rationale for which the findings was tolerated or marked as false positive.
	 */
	'blacklistRationale'?: string, 
	/**
	 * The finding property by which the result is sorted. If none is given the result is not sorted. One of group, uniformpath, message, or a finding property, or random
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * If this parameter is given, the findings returned will start from this index (0 based), i.e. the first start findings in the list (for current sorting) will be skipped.
	 */
	'start'?: number, 
	/**
	 * Limits the number of findings returned. If also the parameter 'all' is used, the limit is ignored. Providing no limit will use the default limit of 300.
	 */
	'max'?: number, 
	/**
	 * If this is true, the finding list is not truncated to 300 elements.
	 */
	'all'?: boolean
};

export type GetFlaggedFindingIdsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetFlaggedFindingInfoQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetFlaggedFindingsQueryParams = {
	/**
	 * Key of a precomputed (using the \"merge-requests/parent-info\" endpoint) merge-base info. Should be provided in case the finding delta is requested for a (possible) merge request. 
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * Commit denoting the start of the flagged finding interval, or the source commit in case of a (possible) merge. Must be present when \"merge-base-cache-key\" is provided.
	 */
	'from'?: UnresolvedCommitDescriptor, 
	/**
	 * Commit denoting the end of the flagged finding interval, or the target commit in case of a (possible) merge.
	 */
	'to': UnresolvedCommitDescriptor
};

export type GetFlaggedFindingsInfosQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetGitHubAppInstallationRepositoriesQueryParams = {
	'appId'?: string
};

export type GetGitTagByNameQueryParams = {
	/**
	 * Only return tags from the given repository
	 */
	'repository'?: string
};

export type GetGlobalBranchesGetRequestQueryParams = {
	/**
	 * A list of project IDs. When not specified it defaults to \"all projects\"
	 */
	'projects'?: Array<string>, 
	/**
	 * The offset from which to start loading branches.
	 */
	'start-offset'?: number, 
	/**
	 * The number of branches that should be returned.
	 */
	'limit'?: number, 
	/**
	 * Whether only currently live branches should be returned.
	 */
	'only-live-branches'?: boolean, 
	/**
	 * If this is set the service uses the regex filter to return branches matching the filter.
	 */
	'regex-filter'?: string, 
	/**
	 * If this is set the service checks whether the passed branches are contained in the currently live branches of the project and returns the one that are.
	 */
	'filter'?: Array<string>
};

export type GetGlobalDefaultBranchGetRequestQueryParams = {
	/**
	 * A list of project IDs. When not specified it defaults to \"all projects\"
	 */
	'projects'?: Array<string>
};

export type GetGlobalRecentBranchesGetRequestQueryParams = {
	/**
	 * A list of project IDs. When not specified it defaults to \"all projects\"
	 */
	'projects'?: Array<string>
};

export type GetGroupAutoCompletionSuggestionsQueryParams = {
	/**
	 * Maximum number of results to return.
	 */
	'limit'?: number, 
	/**
	 * The name of the remote server.
	 */
	'server-name': string, 
	/**
	 * The type of the remote server (e.g. ldap or crowd).
	 */
	'server-type': EAuthenticationToolEntry
};

export type GetIssuesByRepositoryCommitsBody = {
	'commit'?: Array<UnresolvedCommitDescriptor>
};

export type GetIssuesDetailsQueryParams = {
	/**
	 * IDs of the issues to retrieve. The format is the connector ID of the originating issue tracker/requirements management tool (as specified in the connector settings in the project configuration), followed by the separator '|', followed by the ID of the issue, as it is provided by the external issue tracker/requirements management tool. For example, an issue with the ID \"TS-123\" is imported using an issue tracker connector with the connector ID \"issues1\". The expected ID is then \"issues1|TS-123\".
	 */
	'issue-ids'?: Array<string>
};

export type GetLastCommitOfTypeQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	'commit-type'?: ECommitTypeEntry
};

export type GetLicenseInfoQueryParams = {
	/**
	 * Reload license information
	 */
	'reload'?: boolean
};

export type GetMergeRequestFindingChurnQueryParams = {
	/**
	 * The source commit descriptor.
	 */
	'source': UnresolvedCommitDescriptor, 
	/**
	 * The target commit descriptor.
	 */
	'target': UnresolvedCommitDescriptor, 
	/**
	 * Key of a precomputed merge-base info object.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * The regex filter. Filters findings by this regex. Considered Fields are Message and Location. Matches will be included in the result.
	 */
	'regex'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline-rules'?: Array<string>, 
	/**
	 * Whether regex excludes or includes findings.
	 */
	'exclude-regex'?: boolean, 
	/**
	 * The added to task filter. Filter findings that already have a task assigned.
	 */
	'added-to-task'?: boolean, 
	/**
	 * The finding category, group, and type filters. Every string must be either a single category, a combination category/group, or a type ID. If a category or group is given, all matching findings will be filtered out and not included in the result.
	 */
	'filter'?: Array<string>, 
	/**
	 * Whether to invert the category, group, type filters, i.e. including the elements given in the filters instead of excluding them.
	 */
	'invert'?: boolean, 
	/**
	 * The assessment filter. All mentioned assessment colors will be filtered out and not included in the result.
	 */
	'assessment-filters'?: Array<ETrafficLightColorEntry>, 
	/**
	 * The blacklist filtering option.
	 */
	'blacklisted'?: EBlacklistingOptionEntry, 
	/**
	 * If this parameter is given, only findings whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only findings whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean, 
	/**
	 * If this parameter is given, only findings for spec items matching the query are returned.
	 */
	'spec-item-query'?: string
};

export type GetMergeRequestRepositoryChurnQueryParams = {
	/**
	 * The source commit descriptor.
	 */
	'source': UnresolvedCommitDescriptor, 
	/**
	 * The target commit descriptor.
	 */
	'target': UnresolvedCommitDescriptor, 
	/**
	 * Key of a precomputed merge-base info object.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * The commit types to show or hide. If omitted, commits for all types are returned.
	 */
	'commit-types'?: Array<ECommitTypeEntry>, 
	/**
	 * The commit attribute to filter by.
	 */
	'commit-attribute'?: ECommitAttributeEntry, 
	/**
	 * Option whether to filter out commits, that belong to other branches.
	 */
	'exclude-other-branches'?: boolean, 
	/**
	 * User names to filter for.
	 */
	'users'?: Array<string>, 
	/**
	 * Group names to filter for.
	 */
	'groups'?: Array<string>, 
	/**
	 * Whether we want to filter for spec items or code.
	 */
	'specItemEntriesOnly'?: boolean, 
	/**
	 * Commit messages to filter for.
	 */
	'commit-messages'?: Array<string>
};

export type GetMethodHistoryQueryParams = {
	/**
	 * The character-based offset in file on which the method starts (at the specified time).
	 */
	'start-offset': number, 
	/**
	 * The character-based offset in file on which the method ends (at the specified time).
	 */
	'end-offset': number, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * The partitions from which coverage uploads should be retrieved. If not set, all partitions in the project are used.
	 */
	'partitions'?: Array<string>, 
	/**
	 * The cross-annotation projects to retrieve execution information from.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * The baseline until to compute detailed history.
	 */
	'baseline-timestamp'?: number, 
	/**
	 * The commit types to show or hide. If omitted, commits for all types are returned.
	 */
	'commit-types'?: Array<ECommitTypeEntry>, 
	/**
	 * The commit attribute to filter by.
	 */
	'commit-attribute'?: ECommitAttributeEntry, 
	/**
	 * Option whether to filter out commits, that belong to other branches.
	 */
	'exclude-other-branches'?: boolean, 
	/**
	 * User names to filter for.
	 */
	'users'?: Array<string>, 
	/**
	 * Group names to filter for.
	 */
	'groups'?: Array<string>, 
	/**
	 * Whether we want to filter for spec items or code.
	 */
	'specItemEntriesOnly'?: boolean, 
	/**
	 * Commit messages to filter for.
	 */
	'commit-messages'?: Array<string>
};

export type GetMetricDirectorySchemasQueryParams = {
	/**
	 * List of uniform paths to retrieve metric directory schemas for
	 */
	'uniform-path'?: Array<string>
};

export type GetMetricThresholdConfigurationQueryParams = {
	/**
	 * Project name to load built-in configurations for
	 */
	'project-name'?: string, 
	/**
	 * Parameter indicating if configuration should be loaded together with default configurations.
	 */
	'load-with-base-configurations'?: boolean
};

export type GetMinimizedTestsQueryParams = {
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end': UnresolvedCommitDescriptor, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * If set to true the request will fail with '412 PRECONDITION FAILED' if the exact given end commit has not been processed yet and therefore data may not be up-to-date.
	 */
	'ensure-processed'?: boolean, 
	/**
	 * Do the minimization only for the tests specified by the query.
	 */
	'query'?: string, 
	/**
	 * The maximum duration of the minimized test suite in milliseconds (only relevant for test suite minimization).
	 */
	'max-exec-time'?: number, 
	/**
	 * The regex that is used to determine the clustering of tests. If left empty, one cluster containing all tests is created.
	 */
	'clustering-regex'?: string, 
	/**
	 * Consider coverage within this given uniform path only.
	 */
	'covering-path'?: string
};

export type GetPermissionLookupQueryParams = {
	/**
	 * Determines the basic permission scope for the instances. Represented by the enum values of EBasicPermissionScope.
	 */
	'permission-scope': EBasicPermissionScopeEntry
};

export type GetPreCommitContentQueryParams = {
	/**
	 * Uniform path to obtain the token element info for
	 */
	'uniform-path': string, 
	/**
	 * The commit id for which the pre-commit analysis has been triggered
	 */
	'preCommitId': string, 
	/**
	 * If the output should be pretty-printed
	 */
	'pretty'?: boolean
};

export type GetProjectComparisonResultsForContributorQueryParams = {
	/**
	 * The contributor of the comparison.
	 */
	'contributor'?: string
};

export type GetProjectUserActivityTrendQueryParams = {
	/**
	 * List of all ids of the projects.
	 */
	'project-ids'?: Array<string>, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline': number, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\" If this is not given, only one trend entry will be returned, which is for the day on which the given baseline timestamp lies.
	 */
	'end'?: number, 
	/**
	 * Users in these groups are ignored in the user count. Group names can contain spaces.
	 */
	'exclude-group'?: Array<string>
};

export type GetRedundantLiteralTreemapQueryParams = {
	/**
	 * The sub path
	 */
	'uniform-path'?: string, 
	/**
	 * The minimum size of the literal
	 */
	'min-literal'?: number, 
	/**
	 * The minimum occurrences of the literal
	 */
	'min-occurrences'?: number, 
	/**
	 * Constraint metric to local scope.
	 */
	'local-scope-metric'?: boolean, 
	/**
	 * Regex to include literals.
	 */
	'include-regex'?: string, 
	/**
	 * Regex to exclude literals.
	 */
	'exclude-regex'?: string, 
	/**
	 * The index for the metric used to determine the area of the displayed nodes.
	 */
	'area-metric'?: number, 
	/**
	 * The index for the metric used to determine the color of the displayed nodes.
	 */
	'color-metric'?: number, 
	'min-value-for-color'?: number, 
	'max-value-for-color'?: number, 
	'color'?: number, 
	/**
	 * Regular expressions the file paths have to match to be displayed.
	 */
	'included-files-regexes'?: Array<string>, 
	/**
	 * Regular expressions the file paths may not match to be displayed.
	 */
	'excluded-files-regexes'?: Array<string>, 
	/**
	 * The partitions to consider for metric retrieval
	 */
	'partition'?: Array<string>, 
	/**
	 * Whether all partitions should be included
	 */
	'all-partitions'?: boolean, 
	/**
	 * Whether to enable the color-blind mode for the Treemap
	 */
	'color-blind-mode'?: boolean, 
	/**
	 * The width for the treemap to layout.
	 */
	'width'?: number, 
	/**
	 * The height for the treemap to layout.
	 */
	'height'?: number, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetRedundantLiteralsQueryQueryParams = {
	/**
	 * The sub path
	 */
	'uniform-path'?: string, 
	/**
	 * The minimum size of the literal
	 */
	'min-literal'?: number, 
	/**
	 * The minimum occurrences of the literal
	 */
	'min-occurrences'?: number, 
	/**
	 * Constraint metric to local scope.
	 */
	'local-scope-metric'?: boolean, 
	/**
	 * Regex to include literals.
	 */
	'include-regex'?: string, 
	/**
	 * Regex to exclude literals.
	 */
	'exclude-regex'?: string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetRepositoryChurnQueryParams = {
	/**
	 * Range start timestamp
	 */
	't1'?: UnresolvedCommitDescriptor, 
	/**
	 * Range end timestamp
	 */
	't2'?: UnresolvedCommitDescriptor, 
	/**
	 * Range duration in milliseconds
	 */
	'max-milliseconds'?: number, 
	/**
	 * Uniform path to retrieve data for
	 */
	'uniform-path'?: string, 
	/**
	 * The commit types to show or hide. If omitted, commits for all types are returned.
	 */
	'commit-types'?: Array<ECommitTypeEntry>, 
	/**
	 * The commit attribute to filter by.
	 */
	'commit-attribute'?: ECommitAttributeEntry, 
	/**
	 * Option whether to filter out commits, that belong to other branches.
	 */
	'exclude-other-branches'?: boolean, 
	/**
	 * User names to filter for.
	 */
	'users'?: Array<string>, 
	/**
	 * Group names to filter for.
	 */
	'groups'?: Array<string>, 
	/**
	 * Whether we want to filter for spec items or code.
	 */
	'specItemEntriesOnly'?: boolean, 
	/**
	 * Commit messages to filter for.
	 */
	'commit-messages'?: Array<string>
};

export type GetRepositoryLogQueryParams = {
	/**
	 * Optional parameter that controls whether only repository log  entries are returned where the current user was the committer. This defaults to false.
	 */
	'privacy-aware'?: boolean, 
	'commit'?: Array<UnresolvedCommitDescriptor>
};

export type GetRepositoryLogFileHistoryForCommitsQueryParams = {
	'commit'?: Array<UnresolvedCommitDescriptor>
};

export type GetRepositorySummaryQueryParams = {
	/**
	 * Optional parameter, may be used to specify the last point in time to consider in the given query.
	 */
	'end-timestamp'?: number, 
	/**
	 * Optional parameter, if set a simpler RepositorySummary is returned, which is much faster to calculate.
	 */
	'only-first-and-last'?: boolean, 
	/**
	 * Optional parameter, if set only RepositoryLogEntries that include at least one code commit are considered
	 */
	'code-commits-only'?: boolean
};

export type GetResourceHistoryQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * The commit types to show or hide. If omitted, commits for all types are returned.
	 */
	'commit-types'?: Array<ECommitTypeEntry>, 
	/**
	 * The commit attribute to filter by.
	 */
	'commit-attribute'?: ECommitAttributeEntry, 
	/**
	 * Option whether to filter out commits, that belong to other branches.
	 */
	'exclude-other-branches'?: boolean, 
	/**
	 * User names to filter for.
	 */
	'users'?: Array<string>, 
	/**
	 * Group names to filter for.
	 */
	'groups'?: Array<string>, 
	/**
	 * Whether we want to filter for spec items or code.
	 */
	'specItemEntriesOnly'?: boolean, 
	/**
	 * Commit messages to filter for.
	 */
	'commit-messages'?: Array<string>, 
	/**
	 * This parameter limits the number of returned entries to the newest ones. If all parameters should be returned, either omit the parameter or use 0 instead.
	 */
	'number-of-entries'?: number
};

export type GetRisksQueryParams = {
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor
};

export type GetSelectedTestsQueryParams = {
	/**
	 * The index of the first entry to return starting at 0
	 */
	'start'?: number, 
	/**
	 * Maximum number of entries to return. If set to 0 all entries will be omitted and only relevant metadata is returned. If set to a negative value, all relevant entries are returned.
	 */
	'max'?: number
};

export type GetSnapshotQueryParams = {
	/**
	 * A flag whether or not the detailed comparison inputs should be included in the reply
	 */
	'reduced'?: boolean
};

export type GetSpecItemChurnQueryParams = {
	/**
	 * Range start timestamp
	 */
	't1'?: UnresolvedCommitDescriptor, 
	/**
	 * Range end timestamp
	 */
	't2'?: UnresolvedCommitDescriptor, 
	/**
	 * Range duration in milliseconds
	 */
	'max-milliseconds'?: number, 
	/**
	 * Uniform path to retrieve data for
	 */
	'uniform-path'?: string
};

export type GetSpecItemDetailsQueryParams = {
	'spec-item-ids'?: Array<string>, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetStacktraceQueryParams = {
	/**
	 * This parameter must be set to the partition in which the test was uploaded.
	 */
	'partition': string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetTechnologyDependenciesQueryParams = {
	/**
	 * Uniform path to retrieve files for
	 */
	'uniform-path': string, 
	/**
	 * Package depth for the given project.
	 */
	'package-depth': number, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetTechnologyTreeMapQueryParams = {
	/**
	 * Uniform path to retrieve files for
	 */
	'uniform-path': string, 
	/**
	 * The width for the treemap to layout.
	 */
	'width'?: number, 
	/**
	 * The height for the treemap to layout.
	 */
	'height'?: number, 
	/**
	 * The index for the metric used to determine the area of the displayed nodes.
	 */
	'area-metric'?: number, 
	/**
	 * The index for the metric used to determine the color of the displayed nodes.
	 */
	'color-metric'?: number, 
	'min-value-for-color'?: number, 
	'max-value-for-color'?: number, 
	'color'?: number, 
	/**
	 * Regular expressions the file paths have to match to be displayed.
	 */
	'included-files-regexes'?: Array<string>, 
	/**
	 * Regular expressions the file paths may not match to be displayed.
	 */
	'excluded-files-regexes'?: Array<string>, 
	/**
	 * The partitions to consider for metric retrieval
	 */
	'partition'?: Array<string>, 
	/**
	 * Whether all partitions should be included
	 */
	'all-partitions'?: boolean, 
	/**
	 * Whether to enable the color-blind mode for the Treemap
	 */
	'color-blind-mode'?: boolean, 
	'dependency'?: string, 
	'package-depth'?: number, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetTestCoverageQueryParams = {
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider. If this parameter is omitted every partition will be taken.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * If true, the coverage is adjusted to match a pretty printed version of the code.
	 */
	'pretty'?: boolean, 
	/**
	 * If true, the coverage will include method-accurate coverage.
	 */
	'include-method-coverage'?: boolean
};

export type GetTestCoverageOverlayQueryParams = {
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution/coverage of methods. The result will not contain information about test gaps, but only about which methods were executed.
	 */
	'execution-only'?: boolean, 
	/**
	 * Whether we want to view only the churn. This means coverage is ignored for the assessment and methods are only classified as unchanged, added or modified.
	 */
	'churn'?: boolean, 
	/**
	 * Will auto-select the issue branch if set to true. Otherwise, if given, the branchName will be used. As fallback the default branch will be used.
	 */
	'auto-select-branch'?: boolean, 
	/**
	 * If this is given, all issues are shown on this branch. Otherwise, the branch is auto-determined for each issue separately.
	 */
	'branch-name'?: string, 
	/**
	 * Will include changes introduced by child issues.
	 */
	'include-child-issues'?: boolean, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution of methods.
	 */
	'only-executed-methods'?: boolean, 
	/**
	 * If this parameter is given and is a valid issue ID, information about all methods changed in the context of this issue ID will be returned. In this case, the baseline parameter is ignored (the baseline is determined automatically for the issue instead). If the special value \"###no-connector###|[no-issue]\" is given, instead all methods that were changed in commits without an issue link between the baseline and end date are returned.
	 */
	'issue-id'?: string, 
	/**
	 * If this is true, we will compute TGA information based on the methods changed in the history of commit1 but not in the history of commit2. This simulates a merge from commit1 to commit2.
	 */
	'merge-request-mode'?: boolean, 
	/**
	 * Uniform path at which the request should focus on
	 */
	'uniform-path'?: string, 
	/**
	 * The test uniform path for which coverage should be shown in the treemap. This is an exclusive alternative to test-query i.e. if given, test-query is ignored.
	 */
	'test-uniform-path'?: string, 
	/**
	 * Test query that determines which coverage should be shown in the treemap. This is an exclusive alternative to test-uniform-path i.e. if test-uniform-path is given, this parameter is ignored.
	 */
	'test-query'?: string, 
	/**
	 * Optional key into the cache index for merge base calculation. If this is known it can be used to speed up calculation of the merge base.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * Optional merge request identifier to retrieve the merge base info. If this is provided it can be used to directly fetch the merge base info from the merge request delta index before trying to calculate the merge base. This is especially required when creating the TgaBranchMergeRequest for an already merged  merge request.
	 */
	'merge-request-identifier'?: string
};

export type GetTestCoveragePostBody = {
	/**
	 * If true, the coverage will include method-accurate coverage.
	 */
	'include-method-coverage'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider. If this parameter is omitted every partition will be taken.
	 */
	'partitions'?: Array<string>, 
	/**
	 * If true, the coverage is adjusted to match a pretty printed version of the code.
	 */
	'pretty'?: boolean, 
	/**
	 *  This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \\\"HEAD\\\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \\\"p1\\\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \\\"master:1601637680000p1\\\"
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetTestGapDataAsCsvQueryParams = {
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution/coverage of methods. The result will not contain information about test gaps, but only about which methods were executed.
	 */
	'execution-only'?: boolean, 
	/**
	 * Whether we want to view only the churn. This means coverage is ignored for the assessment and methods are only classified as unchanged, added or modified.
	 */
	'churn'?: boolean, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution of methods.
	 */
	'only-executed-methods'?: boolean, 
	/**
	 * If this parameter is given and is a valid issue ID, information about all methods changed in the context of this issue ID will be returned. In this case, the baseline parameter is ignored (the baseline is determined automatically for the issue instead). If the special value \"###no-connector###|[no-issue]\" is given, instead all methods that were changed in commits without an issue link between the baseline and end date are returned.
	 */
	'issue-id'?: string, 
	/**
	 * If this is true, we will compute TGA information based on the methods changed in the history of commit1 but not in the history of commit2. This simulates a merge from commit1 to commit2.
	 */
	'merge-request-mode'?: boolean, 
	/**
	 * Uniform path at which the request should focus on
	 */
	'uniform-path'?: string, 
	/**
	 * The test uniform path for which coverage should be shown in the treemap. This is an exclusive alternative to test-query i.e. if given, test-query is ignored.
	 */
	'test-uniform-path'?: string, 
	/**
	 * Test query that determines which coverage should be shown in the treemap. This is an exclusive alternative to test-uniform-path i.e. if test-uniform-path is given, this parameter is ignored.
	 */
	'test-query'?: string, 
	/**
	 * Optional key into the cache index for merge base calculation. If this is known it can be used to speed up calculation of the merge base.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * Optional merge request identifier to retrieve the merge base info. If this is provided it can be used to directly fetch the merge base info from the merge request delta index before trying to calculate the merge base. This is especially required when creating the TgaBranchMergeRequest for an already merged  merge request.
	 */
	'merge-request-identifier'?: string, 
	/**
	 * Will auto-select the issue branch if set to true. Otherwise, if given, the branchName will be used. As fallback the default branch will be used.
	 */
	'auto-select-branch'?: boolean, 
	/**
	 * If this is given, all issues are shown on this branch. Otherwise, the branch is auto-determined for each issue separately.
	 */
	'branch-name'?: string, 
	/**
	 * Will include changes introduced by child issues.
	 */
	'include-child-issues'?: boolean, 
	/**
	 * Parameter determining if only changed methods should be returned
	 */
	'exclude-unchanged-methods'?: boolean
};

export type GetTestGapTreeMapQueryParams = {
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution/coverage of methods. The result will not contain information about test gaps, but only about which methods were executed.
	 */
	'execution-only'?: boolean, 
	/**
	 * Whether we want to view only the churn. This means coverage is ignored for the assessment and methods are only classified as unchanged, added or modified.
	 */
	'churn'?: boolean, 
	/**
	 * Will auto-select the issue branch if set to true. Otherwise, if given, the branchName will be used. As fallback the default branch will be used.
	 */
	'auto-select-branch'?: boolean, 
	/**
	 * If this is given, all issues are shown on this branch. Otherwise, the branch is auto-determined for each issue separately.
	 */
	'branch-name'?: string, 
	/**
	 * Will include changes introduced by child issues.
	 */
	'include-child-issues'?: boolean, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution of methods.
	 */
	'only-executed-methods'?: boolean, 
	/**
	 * If this parameter is given and is a valid issue ID, information about all methods changed in the context of this issue ID will be returned. In this case, the baseline parameter is ignored (the baseline is determined automatically for the issue instead). If the special value \"###no-connector###|[no-issue]\" is given, instead all methods that were changed in commits without an issue link between the baseline and end date are returned.
	 */
	'issue-id'?: string, 
	/**
	 * If this is true, we will compute TGA information based on the methods changed in the history of commit1 but not in the history of commit2. This simulates a merge from commit1 to commit2.
	 */
	'merge-request-mode'?: boolean, 
	/**
	 * Uniform path at which the request should focus on
	 */
	'uniform-path'?: string, 
	/**
	 * The test uniform path for which coverage should be shown in the treemap. This is an exclusive alternative to test-query i.e. if given, test-query is ignored.
	 */
	'test-uniform-path'?: string, 
	/**
	 * Test query that determines which coverage should be shown in the treemap. This is an exclusive alternative to test-uniform-path i.e. if test-uniform-path is given, this parameter is ignored.
	 */
	'test-query'?: string, 
	/**
	 * Optional key into the cache index for merge base calculation. If this is known it can be used to speed up calculation of the merge base.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * Optional merge request identifier to retrieve the merge base info. If this is provided it can be used to directly fetch the merge base info from the merge request delta index before trying to calculate the merge base. This is especially required when creating the TgaBranchMergeRequest for an already merged  merge request.
	 */
	'merge-request-identifier'?: string, 
	/**
	 * The width for the treemap to layout.
	 */
	'width'?: number, 
	/**
	 * The height for the treemap to layout.
	 */
	'height'?: number, 
	/**
	 * Parameter determining if only changed methods should be returned
	 */
	'exclude-unchanged-methods'?: boolean
};

export type GetTgaPercentageQueryParams = {
	/**
	 * Will auto-select the issue branch if set to true. Otherwise, if given, the branchName will be used. As fallback the default branch will be used.
	 */
	'auto-select-branch'?: boolean, 
	/**
	 * If this is given, all issues are shown on this branch. Otherwise, the branch is auto-determined for each issue separately.
	 */
	'branch-name'?: string, 
	/**
	 * Will include changes introduced by child issues.
	 */
	'include-child-issues'?: boolean, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution of methods.
	 */
	'only-executed-methods'?: boolean, 
	/**
	 * If this parameter is given and is a valid issue ID, information about all methods changed in the context of this issue ID will be returned. In this case, the baseline parameter is ignored (the baseline is determined automatically for the issue instead). If the special value \"###no-connector###|[no-issue]\" is given, instead all methods that were changed in commits without an issue link between the baseline and end date are returned.
	 */
	'issue-id'?: string, 
	/**
	 * If this is true, we will compute TGA information based on the methods changed in the history of commit1 but not in the history of commit2. This simulates a merge from commit1 to commit2.
	 */
	'merge-request-mode'?: boolean, 
	/**
	 * Uniform path at which the request should focus on
	 */
	'uniform-path'?: string, 
	/**
	 * The test uniform path for which coverage should be shown in the treemap. This is an exclusive alternative to test-query i.e. if given, test-query is ignored.
	 */
	'test-uniform-path'?: string, 
	/**
	 * Test query that determines which coverage should be shown in the treemap. This is an exclusive alternative to test-uniform-path i.e. if test-uniform-path is given, this parameter is ignored.
	 */
	'test-query'?: string, 
	/**
	 * Optional key into the cache index for merge base calculation. If this is known it can be used to speed up calculation of the merge base.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * Optional merge request identifier to retrieve the merge base info. If this is provided it can be used to directly fetch the merge base info from the merge request delta index before trying to calculate the merge base. This is especially required when creating the TgaBranchMergeRequest for an already merged  merge request.
	 */
	'merge-request-identifier'?: string, 
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution/coverage of methods. The result will not contain information about test gaps, but only about which methods were executed.
	 */
	'execution-only'?: boolean, 
	/**
	 * Whether we want to view only the churn. This means coverage is ignored for the assessment and methods are only classified as unchanged, added or modified.
	 */
	'churn'?: boolean
};

export type GetTgaTableQueryParams = {
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution/coverage of methods. The result will not contain information about test gaps, but only about which methods were executed.
	 */
	'execution-only'?: boolean, 
	/**
	 * Whether we want to view only the churn. This means coverage is ignored for the assessment and methods are only classified as unchanged, added or modified.
	 */
	'churn'?: boolean, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution of methods.
	 */
	'only-executed-methods'?: boolean, 
	/**
	 * If this parameter is given and is a valid issue ID, information about all methods changed in the context of this issue ID will be returned. In this case, the baseline parameter is ignored (the baseline is determined automatically for the issue instead). If the special value \"###no-connector###|[no-issue]\" is given, instead all methods that were changed in commits without an issue link between the baseline and end date are returned.
	 */
	'issue-id'?: string, 
	/**
	 * If this is true, we will compute TGA information based on the methods changed in the history of commit1 but not in the history of commit2. This simulates a merge from commit1 to commit2.
	 */
	'merge-request-mode'?: boolean, 
	/**
	 * Uniform path at which the request should focus on
	 */
	'uniform-path'?: string, 
	/**
	 * The test uniform path for which coverage should be shown in the treemap. This is an exclusive alternative to test-query i.e. if given, test-query is ignored.
	 */
	'test-uniform-path'?: string, 
	/**
	 * Test query that determines which coverage should be shown in the treemap. This is an exclusive alternative to test-uniform-path i.e. if test-uniform-path is given, this parameter is ignored.
	 */
	'test-query'?: string, 
	/**
	 * Optional key into the cache index for merge base calculation. If this is known it can be used to speed up calculation of the merge base.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * Optional merge request identifier to retrieve the merge base info. If this is provided it can be used to directly fetch the merge base info from the merge request delta index before trying to calculate the merge base. This is especially required when creating the TgaBranchMergeRequest for an already merged  merge request.
	 */
	'merge-request-identifier'?: string
};

export type GetTgaTestCoveragePartitionsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetTgaTrendQueryParams = {
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution of methods.
	 */
	'only-executed-methods'?: boolean, 
	/**
	 * If this parameter is given and is a valid issue ID, information about all methods changed in the context of this issue ID will be returned. In this case, the baseline parameter is ignored (the baseline is determined automatically for the issue instead). If the special value \"###no-connector###|[no-issue]\" is given, instead all methods that were changed in commits without an issue link between the baseline and end date are returned.
	 */
	'issue-id'?: string, 
	/**
	 * If this is true, we will compute TGA information based on the methods changed in the history of commit1 but not in the history of commit2. This simulates a merge from commit1 to commit2.
	 */
	'merge-request-mode'?: boolean, 
	/**
	 * Uniform path at which the request should focus on
	 */
	'uniform-path'?: string, 
	/**
	 * The test uniform path for which coverage should be shown in the treemap. This is an exclusive alternative to test-query i.e. if given, test-query is ignored.
	 */
	'test-uniform-path'?: string, 
	/**
	 * Test query that determines which coverage should be shown in the treemap. This is an exclusive alternative to test-uniform-path i.e. if test-uniform-path is given, this parameter is ignored.
	 */
	'test-query'?: string, 
	/**
	 * Optional key into the cache index for merge base calculation. If this is known it can be used to speed up calculation of the merge base.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * Optional merge request identifier to retrieve the merge base info. If this is provided it can be used to directly fetch the merge base info from the merge request delta index before trying to calculate the merge base. This is especially required when creating the TgaBranchMergeRequest for an already merged  merge request.
	 */
	'merge-request-identifier'?: string, 
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution/coverage of methods. The result will not contain information about test gaps, but only about which methods were executed.
	 */
	'execution-only'?: boolean, 
	/**
	 * Whether we want to view only the churn. This means coverage is ignored for the assessment and methods are only classified as unchanged, added or modified.
	 */
	'churn'?: boolean
};

export type GetTriggerGraphDotQueryParams = {
	/**
	 * Whether read/written indexes are included as nodes in the graph.
	 */
	'include-indexes'?: boolean, 
	/**
	 * Names of nodes in the graph that should be used to start slicing. This can be trigger names or index names. To slice by index, use the index name, not the index-class name (e.g., check-phase-dependencies, not CheckPhaseResultDependencyIndex). All nodes that are not forward/backward reachable from these nodes are not displayed.Nodes are matched exact, so provide the exact names of triggers/indexes that you want to be included.If the list is empty, the complete graph is returned.
	 */
	'slice-nodes'?: Array<string>, 
	/**
	 * If true (forward slicing), only nodes influenced by the slice nodes are returned (i.e., nodes that are executed \"after\" the slice nodes). If false (backward slicing), we return only nodes that influence one of the slice nodes.
	 */
	'forward-slice'?: boolean
};

export type GetUserAutoCompletionSuggestionsQueryParams = {
	/**
	 * Maximum number of results to return.
	 */
	'limit'?: number, 
	/**
	 * The name of the remote server.
	 */
	'server-name': string, 
	/**
	 * The type of the remote server (e.g. ldap or crowd).
	 */
	'server-type': EAuthenticationToolEntry
};

export type GetUserAvatarDataQueryParams = {
	/**
	 * Controls the least size of the image.
	 */
	'size'?: string
};

export type ImportAbapLintConfigurationFileBody = {
	'configuration-files': Array<File>, 
	'enable-teamscale-defaults'?: boolean
};

export type ImportAnalysisProfileBody = {
	'analysis-profile': Array<File>
};

export type ImportAndReplaceDashboardsBody = {
	'dashboard': Array<File>
};

export type ImportBackupBody = {
	/**
	 * The backups to import.
	 */
	'backup'?: Array<File>, 
	/**
	 * Path to the backup
	 */
	'backup-path'?: string, 
	/**
	 * Remote user access token
	 */
	'remote-access-token'?: string, 
	/**
	 * Path to the instance
	 */
	'remote-url'?: string, 
	/**
	 * Remote user
	 */
	'remote-user'?: string, 
	/**
	 * Whether to enable shadow mode right after import.
	 */
	'shadow-mode'?: boolean, 
	/**
	 * Whether to skip importing global data
	 */
	'skip-global-data-import'?: boolean, 
	/**
	 * Whether to skip new projects and only import backup data into existing projects.
	 */
	'skip-new-projects'?: boolean, 
	/**
	 * Whether to skip the project validation on import
	 */
	'skip-project-validation'?: boolean
};

export type ImportConfigurationBody = {
	'configuration': File, 
	'enable-teamscale-defaults'?: boolean
};

export type ImportDashboardBody = {
	'dashboard': Array<File>
};

export type ImportDashboardTemplateBody = {
	'dashboard-template': Array<File>
};

export type ImportGroupQueryParams = {
	/**
	 * The name of the remote server.
	 */
	'server-name': string, 
	/**
	 * The type of the remote server (e.g. ldap or crowd).
	 */
	'server-type': EAuthenticationToolEntry
};

export type ImportUserQueryParams = {
	/**
	 * The name of the remote server.
	 */
	'server-name': string, 
	/**
	 * The type of the remote server (e.g. ldap or crowd).
	 */
	'server-type': EAuthenticationToolEntry
};

export type ListAllParseLogEntriesQueryParams = {
	/**
	 * The logs will start at this given index.
	 */
	'start'?: number, 
	/**
	 * Limits the number of returned logs to the given number.
	 */
	'max'?: number
};

export type ListMergeRequestsQueryParams = {
	/**
	 * The index of the first entry to return starting at 0
	 */
	'start'?: number, 
	/**
	 * Maximum number of entries to return. If set to 0 all entries will be omitted and only relevant metadata is returned. If set to a negative value, all relevant entries are returned.
	 */
	'max'?: number, 
	/**
	 * Name of the field to sort entries by
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * The value to filter by in the merge request list. If the value is empty do not filter.
	 */
	'filter'?: string, 
	/**
	 * The status of the merge request to filter by.
	 */
	'status'?: EMergeRequestStatusEntry
};

export type LoadFromNamedTestListQueryParams = {
	'testListName'?: string, 
	'replaceTests'?: boolean
};

export type MarkDashboardAsFavoriteQueryParams = {
	/**
	 * Whether to mark or unmark a dashboard as favorite
	 */
	'setFavorite': boolean
};

export type PerformTestQueryQueryParams = {
	/**
	 * Test query
	 */
	'query': string, 
	/**
	 * The index of the first entry to return starting at 0
	 */
	'start'?: number, 
	/**
	 * Maximum number of entries to return. If set to 0 all entries will be omitted and only relevant metadata is returned. If set to a negative value, all relevant entries are returned.
	 */
	'max'?: number, 
	/**
	 * Name of the field to sort entries by
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * If true only tests with testwise coverage will be returned
	 */
	'only-with-coverage'?: boolean
};

export type PostCommitAlertsBody = {
	'commit'?: Array<UnresolvedCommitDescriptor>
};

export type PostFindingChurnCountBody = {
	'commit'?: Array<UnresolvedCommitDescriptor>
};

export type PostRepositoryLogQueryParams = {
	/**
	 * Optional parameter that controls whether only repository log  entries are returned where the current user was the committer. This defaults to false.
	 */
	'privacy-aware'?: boolean
};

export type PostRepositoryLogBody = {
	'commit'?: Array<UnresolvedCommitDescriptor>
};

export type PostResolveUserAliasesBody = {
	/**
	 * User aliases to resolve
	 */
	'alias'?: Array<string>
};

export type PostTgaPercentageQueryParams = {
	/**
	 * Will auto-select the issue branch if set to true. Otherwise, if given, the branchName will be used. As fallback the default branch will be used.
	 */
	'auto-select-branch'?: boolean, 
	/**
	 * If this is given, all issues are shown on this branch. Otherwise, the branch is auto-determined for each issue separately.
	 */
	'branch-name'?: string, 
	/**
	 * Will include changes introduced by child issues.
	 */
	'include-child-issues'?: boolean, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution of methods.
	 */
	'only-executed-methods'?: boolean, 
	/**
	 * If this parameter is given and is a valid issue ID, information about all methods changed in the context of this issue ID will be returned. In this case, the baseline parameter is ignored (the baseline is determined automatically for the issue instead). If the special value \"###no-connector###|[no-issue]\" is given, instead all methods that were changed in commits without an issue link between the baseline and end date are returned.
	 */
	'issue-id'?: string, 
	/**
	 * If this is true, we will compute TGA information based on the methods changed in the history of commit1 but not in the history of commit2. This simulates a merge from commit1 to commit2.
	 */
	'merge-request-mode'?: boolean, 
	/**
	 * Uniform path at which the request should focus on
	 */
	'uniform-path'?: string, 
	/**
	 * The test uniform path for which coverage should be shown in the treemap. This is an exclusive alternative to test-query i.e. if given, test-query is ignored.
	 */
	'test-uniform-path'?: string, 
	/**
	 * Test query that determines which coverage should be shown in the treemap. This is an exclusive alternative to test-uniform-path i.e. if test-uniform-path is given, this parameter is ignored.
	 */
	'test-query'?: string, 
	/**
	 * Optional key into the cache index for merge base calculation. If this is known it can be used to speed up calculation of the merge base.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * Optional merge request identifier to retrieve the merge base info. If this is provided it can be used to directly fetch the merge base info from the merge request delta index before trying to calculate the merge base. This is especially required when creating the TgaBranchMergeRequest for an already merged  merge request.
	 */
	'merge-request-identifier'?: string
};

export type PostTgaPercentageBody = {
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * Whether we want to view only the churn. This means coverage is ignored for the assessment and methods are only classified as unchanged, added or modified.
	 */
	'churn'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution/coverage of methods. The result will not contain information about test gaps, but only about which methods were executed.
	 */
	'execution-only'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>
};

export type PostUserAvatarDataBody = {
	/**
	 * User avatar image
	 */
	'avatar'?: object
};

export type PutAvatarDataQueryParams = {
	/**
	 * Controls the size of the crop that is being cut out of the avatar image.
	 */
	'avatarSize'?: number, 
	/**
	 * Controls the x offset of the crop that is being cut out of the avatar image.
	 */
	'avatarOffsetX'?: number, 
	/**
	 * Controls the y offset of the crop that is being cut out of the avatar image.
	 */
	'avatarOffsetY'?: number
};

export type PutJobTestsFromImpactedQueryParams = {
	/**
	 * The baseline commit (exclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end': UnresolvedCommitDescriptor, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * The name of the test prioritization strategy
	 */
	'prioritization-strategy'?: ETestPrioritizationStrategyEntry, 
	/**
	 * If set to true the request will fail with '412 PRECONDITION FAILED' if the exact given end commit has not been processed yet and therefore data may not be up-to-date.
	 */
	'ensure-processed'?: boolean, 
	/**
	 * Append and prioritize non-impacted tests after impacted tests.
	 */
	'include-non-impacted'?: boolean, 
	/**
	 * Append and prioritize previously failed and skipped tests before impacted tests.
	 */
	'include-failed-and-skipped'?: boolean, 
	/**
	 * Append and prioritize tests for which there is no coverage yet.
	 */
	'include-added-tests'?: boolean, 
	/**
	 * The upper bound for the predicted test execution duration in milliseconds that the resulting test list should exhibit. All impacted tests that do not fit into this budget will be cut off.
	 */
	'max-exec-time'?: number, 
	/**
	 * Consider coverage within this given uniform path only.
	 */
	'covering-path'?: string
};

export type PutJobTestsFromQueryQueryParams = {
	'query'?: string, 
	/**
	 * If true only tests with testwise coverage will be considered
	 */
	'only-with-coverage'?: boolean
};

export type RedirectToCorrectHostQueryParams = {
	/**
	 * Id of the snapshot the comparison belongs to
	 */
	'snapshot-id'?: string, 
	/**
	 * Id of the comparison the example belongs to
	 */
	'comparison-id'?: string, 
	/**
	 * Project ID of the example
	 */
	'project'?: string, 
	/**
	 * ID of the example
	 */
	'example-id'?: string, 
	/**
	 * Type of the example
	 */
	'example-type'?: EInstanceComparisonTypeEntry, 
	/**
	 * Whether the example is on the remote instance
	 */
	'is-remote-example'?: boolean
};

export type RegenerateStoredServiceProviderCertificateQueryParams = {
	'name'?: string
};

export type RequestAnalysisAndOpenSessionQueryParams = {
	'projectId'?: string, 
	'commitDescriptor'?: UnresolvedCommitDescriptor, 
	'returnAddress'?: string
};

export type SetupJobQueryParams = {
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end': UnresolvedCommitDescriptor, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * If set to true the request will fail with '412 PRECONDITION FAILED' if the exact given end commit has not been processed yet and therefore data may not be up-to-date.
	 */
	'ensure-processed'?: boolean, 
	/**
	 * Do the minimization only for the tests specified by the query.
	 */
	'query'?: string, 
	/**
	 * The maximum duration of the minimized test suite in milliseconds (only relevant for test suite minimization).
	 */
	'max-exec-time'?: number, 
	/**
	 * The regex that is used to determine the clustering of tests. If left empty, one cluster containing all tests is created.
	 */
	'clustering-regex'?: string, 
	/**
	 * Consider coverage within this given uniform path only.
	 */
	'covering-path'?: string
};

export type ShutdownBody = {
	/**
	 * The exit code of the process. By convention, a nonzero status code indicates abnormal termination.
	 */
	'exitCode'?: number
};

export type TriggerSnapshotBackupBody = {
	'target'?: string
};

export type UnmarkToleratedAndFalsePositiveFindingsBeforeCommitQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Whether false positive findings should be unmarked as well. If not specified, only tolerated findings will be unmarked.
	 */
	'unmark-false-positives'?: boolean
};

export type UpdateAnalysisProfileQueryParams = {
	/**
	 * A comment similar to git commit messages associated with the changes made in the analysis profile
	 */
	'analysisProfileEditComment'?: string
};

export type UpdateExternalAccountsQueryParams = {
	/**
	 * Skip connector validation for connectors using the account.
	 */
	'skip-connector-validation'?: boolean
};

export type UpdateJobOptionsQueryParams = {
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end': UnresolvedCommitDescriptor, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * If set to true the request will fail with '412 PRECONDITION FAILED' if the exact given end commit has not been processed yet and therefore data may not be up-to-date.
	 */
	'ensure-processed'?: boolean, 
	/**
	 * Do the minimization only for the tests specified by the query.
	 */
	'query'?: string, 
	/**
	 * The maximum duration of the minimized test suite in milliseconds (only relevant for test suite minimization).
	 */
	'max-exec-time'?: number, 
	/**
	 * The regex that is used to determine the clustering of tests. If left empty, one cluster containing all tests is created.
	 */
	'clustering-regex'?: string, 
	/**
	 * Consider coverage within this given uniform path only.
	 */
	'covering-path'?: string
};

export type UploadReportQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * This parameter allows to pass a revision instead of a timestamp.
	 */
	'revision'?: string, 
	/**
	 * When using the revision parameter, this parameter allows to pass a repository name which is used to identify the correct commit in situations where the same revision exists in multiple repositories.
	 */
	'repository'?: string, 
	/**
	 * A message that describes the external analysis, similar to a commit message.
	 */
	'message'?: string, 
	/**
	 * The name of the logical partition to store the results into. All existing data in this partition will be invalidated. A partition typically corresponds to one analysis run, i.e. if there are two independent builds/runs, they must use different partitions.The partition parameter is only required with an auto-create session.
	 */
	'partition'?: string, 
	/**
	 * Whether to move the upload timestamp to right after the last commit.
	 */
	'movetolastcommit'?: boolean, 
	/**
	 * The format of the uploaded report.
	 */
	'format': EReportFormatEntry, 
	/**
	 * The path prefix for the uploaded test artifacts. For coverage reports the covered paths are only matched against those files that have the specified prefix within the Teamscale project. This can be used if the same package structures and classes appear in multiple subfolders of a project. For test execution reports the prefix is prepended to the test execution names, which allows to make otherwise ambiguous test paths unique.
	 */
	'path-prefix'?: string
};

export type UploadReportBody = {
	/**
	 * The external analysis report(s) to upload to Teamscale. The reports are expected to be UTF-8 encoded, other encodings need to be specified.  Note: Files that are *not* encoded in UTF-8, or without a BOM, and are not specified as such, cannot be read reliably, which might lead to parsing errors. For more information on uploading external analysis reports, see https://docs.teamscale.com/howto/uploading-external-results.
	 */
	'report': Array<File>
};


/** Type definition of the QUERIES interface. */
export type Queries = {

	/**
	 * Get autocompletion suggestions
	 *
	 * Gets autocompletion suggestions based on the input The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	autocompleteIssueId(project: string, queryParams: AutocompleteIssueIdQueryParams): QueryOperation<Array<string>>

	/**
	 * Autocomplete user group name
	 *
	 * null The API requires no permissions
	 */
	autocompleteUserGroup(queryParams: AutocompleteUserGroupQueryParams): QueryOperation<Array<string>>

	/**
	 * Get user autocompletion suggestions
	 *
	 * Gets autocompletion suggestions based on the input The API requires no permissions
	 */
	autocompleteUserName(queryParams: AutocompleteUserNameQueryParams): QueryOperation<Array<string>>

	/**
	 * Returns user activity statistics.
	 *
	 * Returns the number of users who have been active during different time durations. The API requires the user to have View System Status permissions.
	 */
	calculateActiveUsersStatistics(): QueryOperation<Array<UserActivity>>

	/**
	 * Returns committer activity statistics.
	 *
	 * Returns the number of committers who have been contributing during different time durations. The API requires the user to have Access Administrative Services permissions.
	 */
	calculateCommitterStatistics(): QueryOperation<Array<UserActivity>>

	/**
	 * Commit session
	 *
	 * Commits and closes the given session. This service is public API since Teamscale 5.9. The API requires the user to have Perform External Uploads permissions on the project.
     * @param project The project ID.
     * @param sessionId If session ID is provided, the results will be appended to the given session instead of creating a new session. Use \&quot;auto-create\&quot; in place of session ID to create a new session, perform upload and commit session in one step.
	 */
	commitAnalysisResults(project: string, sessionId: string): QueryOperation<void>

	/**
	 * Get metric table
	 *
	 * Retrieves a list of metric table entries for the given uniform path and its children. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	computeMetricTable(project: string, queryParams: ComputeMetricTableQueryParams): QueryOperation<MetricTablePage>

	/**
	 * Create analysis profile
	 *
	 * Creates a new analysis profile. The API requires the user to have Create Analysis Profiles permissions.
	 */
	createAnalysisProfile(body: AnalysisProfile): QueryOperation<void>

	/**
	 * Export backup.
	 *
	 * Triggers the creation of a backup and returns its ID. This service is public API since Teamscale 6.1. The user needs to have the permission to export global data provided the backup contains any and the permission to backup project data for all projects contained in the backup.
	 */
	createBackup(body: CreateBackupBody): QueryOperation<string>

	/**
	 * Trigger comparison computation
	 *
	 * Triggers computation of the comparison between two snapshots and returns the ID of the new comparison. One of the snapshots is stored locally, the other fetched from a remote instance. The API requires the user to have Access Administrative Services permissions.
     * @param snapshotId The ID of the local snapshot.
	 */
	createComparison(snapshotId: string, body: CreateComparisonBody): QueryOperation<string>

	/**
	 * Create dashboard
	 *
	 * Creates a new dashboard. The API requires the user to have Create Dashboards permissions.
	 */
	createDashboard(body: DashboardDescriptor): QueryOperation<string>

	/**
	 * Create dashboard from template
	 *
	 * Creates a dashboard from a template. Returns the ID of the created dashboard. The API requires the user to have Create Dashboards permissions.
	 */
	createDashboardFromTemplate(queryParams: CreateDashboardFromTemplateQueryParams): QueryOperation<string>

	/**
	 * Create dashboard template
	 *
	 * Creates a new dashboard template The API requires the user to have Create Dashboard Templates permissions.
	 */
	createDashboardTemplate(body: DashboardTemplateDescriptor): QueryOperation<string>

	/**
	 * Create external accounts
	 *
	 * Creates new external accounts. The API requires the user to have Create External Accounts permissions.
	 */
	createExternalAccounts(body: ExternalCredentialsData): QueryOperation<void>

	/**
	 * Create external analysis group
	 *
	 * Creates a new external analysis group. This service is public API since Teamscale 6.0. The API requires the user to have Edit External Findings Schema permissions.
	 */
	createExternalAnalysisGroup(body: ExternalAnalysisGroup): QueryOperation<void>

	/**
	 * Create external finding description
	 *
	 * Create a new external finding description in the system. This service is public API since Teamscale 6.0. The API requires the user to have Edit External Findings Schema permissions.
	 */
	createExternalFindingDescription(body: ExternalFindingsDescription): QueryOperation<void>

	/**
	 * Create external storage backend
	 *
	 * Creates new external storage backend. The API requires the user to have Configure External Storage Backends permissions.
	 */
	createExternalStorageBackend(body: ExternalStorageBackendData): QueryOperation<void>

	/**
	 * Create metric threshold configuration
	 *
	 * Creates a new metric threshold configuration. The API requires the user to have Create Metric Threshold Configurations permissions.
	 */
	createMetricThresholdConfiguration(body: MetricThresholdConfiguration): QueryOperation<void>

	/**
	 * Generate Access Key
	 *
	 * Generates a new access key and revokes any already existing access keys. The API requires the user to have Edit permissions on the user.
     * @param user 
	 */
	createNewRandomAccessKey(user: string): QueryOperation<string>

	/**
	 * Update baseline
	 *
	 * Creates a new or updates an existing baseline. This service is public API since Teamscale 5.2. The API requires the user to have Edit Baselines permissions on the project.
     * @param project The project ID.
     * @param baseline 
	 */
	createOrUpdateBaseline(project: string, baseline: string, queryParams: CreateOrUpdateBaselineQueryParams, body: BaselineInfo): QueryOperation<void>

	/**
	 * Create project
	 *
	 * Creates project based on provided project configuration. This service is public API since Teamscale 7.1. The API requires the user to have Create Projects permissions.
	 */
	createProject(queryParams: CreateProjectQueryParams, body: ProjectConfiguration): QueryOperation<void>

	/**
	 * Create quality report
	 *
	 * Creates a new quality report either from scratch or by copying/overwriting an existing report. The API requires the user to have Create Quality Reports permissions.
	 */
	createQualityReport(queryParams: CreateQualityReportQueryParams, body: QualityReport): QueryOperation<string>

	/**
	 * Get session ID
	 *
	 * Obtains a new session ID. Using session ID allows to perform external uploads in multiple calls that all belong to the same session. This service is public API since Teamscale 5.9. The API requires the user to have Perform External Uploads permissions on the project.
     * @param project The project ID.
	 */
	createSession(project: string, queryParams: CreateSessionQueryParams): QueryOperation<string>

	/**
	 * Create snapshot
	 *
	 * Triggers creation of a snapshot of this instance and returns the ID of the created snapshot. Additionally, a remote snapshot with the same parameters can be created and a comparison between the two scheduled. The API requires the user to have Access Administrative Services permissions.
	 */
	createSnapshot(body: CreateSnapshotBody): QueryOperation<string>

	/**
	 * Create user group
	 *
	 * Creates new user group. The API requires the user to have Create Groups permissions.
	 */
	createUserGroup(body: UserGroup): QueryOperation<void>

	/**
	 * Delete all unprocessed architecture uploads
	 *
	 * Deletes all commits of the architecture identified by the provided architecture path. The API requires the user to have Edit Architectures permissions on the project.
     * @param project 
	 */
	deleteAllUnprocessedArchitectureCommits(project: string, queryParams: DeleteAllUnprocessedArchitectureCommitsQueryParams): QueryOperation<void>

	/**
	 * Delete analysis profile
	 *
	 * Deletes the analysis profile identified by the given name from the system The API requires the user to have Delete permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName Name of the analysis profile to delete
	 */
	deleteAnalysisProfile(analysisProfileName: string): QueryOperation<void>

	/**
	 * Delete session
	 *
	 * Deletes a session in case of abortion. This service is public API since Teamscale 5.9. The API requires the user to have Perform External Uploads permissions on the project.
     * @param project The project ID.
     * @param sessionId If session ID is provided, the results will be appended to the given session instead of creating a new session. Use \&quot;auto-create\&quot; in place of session ID to create a new session, perform upload and commit session in one step.
	 */
	deleteAnalysisResults(project: string, sessionId: string): QueryOperation<void>

	/**
	 * Delete baseline
	 *
	 * Deletes a baseline. This service is public API since Teamscale 5.2. The API requires the user to have Edit Baselines permissions on the project.
     * @param project The project ID.
     * @param baseline 
	 */
	deleteBaseline(project: string, baseline: string): QueryOperation<void>

	/**
	 * Delete comparison
	 *
	 * Permanently deletes the given comparison. The API requires the user to have Access Administrative Services permissions.
     * @param snapshotId The ID of the local snapshot the comparison belongs to.
     * @param comparisonId The ID of the comparison to delete.
	 */
	deleteComparison(snapshotId: string, comparisonId: string): QueryOperation<void>

	/**
	 * Remove dashboard
	 *
	 * Removes the dashboard descriptor identified by given ID. The API requires the user to have Edit permissions on the Dashboard dashboardId.
     * @param dashboardId ID of the dashboard to retrieve.
	 */
	deleteDashboard(dashboardId: string): QueryOperation<void>

	/**
	 * Delete dashboard template
	 *
	 * Deletes the dashboard template identified by the given ID. The API requires the user to have Edit permissions on the Dashboard Templates templateId.
     * @param templateId ID of the template to delete
	 */
	deleteDashboardTemplate(templateId: string): QueryOperation<void>

	/**
	 * Delete event announcement
	 *
	 * Deletes the given announcement so it will not be displayed to anyone anymore. The API requires the user to have Access Administrative Services permissions.
     * @param eventId 
	 */
	deleteEventAnnouncement(eventId: string): QueryOperation<void>

	/**
	 * Delete external accounts
	 *
	 * Deletes the external accounts identified by the given name from the system The API requires the user to have Delete permissions on the External Accounts externalCredentialsName.
     * @param externalCredentialsName Name of the external accounts to delete
	 */
	deleteExternalAccounts(externalCredentialsName: string, queryParams: DeleteExternalAccountsQueryParams): QueryOperation<void>

	/**
	 * Remove external analysis group
	 *
	 * Removes the external analysis group identified by the given name. The API requires the user to have Edit External Findings Schema permissions.
     * @param groupName Name of the external analysis group to remove
	 */
	deleteExternalAnalysisGroup(groupName: string): QueryOperation<void>

	/**
	 * Remove external finding description
	 *
	 * Removes the external finding description identified by the given name The API requires the user to have Edit External Findings Schema permissions.
     * @param externalFindingDescriptionName Name of the finding description to remove
	 */
	deleteExternalFindingDescription(externalFindingDescriptionName: string): QueryOperation<void>

	/**
	 * Delete external storage backends
	 *
	 * Deletes the external storage backends identified by the given name from the system The API requires the user to have Delete permissions on the External Storage Backends externalStorageBackendName.
     * @param externalStorageBackendName Name of the external storage backend to delete
	 */
	deleteExternalStorageBackend(externalStorageBackendName: string, queryParams: DeleteExternalStorageBackendQueryParams): QueryOperation<void>

	/**
	 * Remove the given list of tests (identified by their current ranking) from the list of tests to minimize in the job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	deleteJobTests(project: string, jobId: string, queryParams: DeleteJobTestsQueryParams, body: Array<number>): QueryOperation<number>

	/**
	 * Delete metric threshold configuration
	 *
	 * Deletes the metric threshold configuration identified by the given name from the system The API requires the user to have Delete permissions on the Metric Threshold Configuration metricThresholdConfigurationName.
     * @param metricThresholdConfigurationName Name of the metric threshold configuration to delete
	 */
	deleteMetricThresholdConfiguration(metricThresholdConfigurationName: string): QueryOperation<void>

	/**
	 * Delete option
	 *
	 * Delete the project option identified by the given name. The API requires the user to have Edit Project Options permissions on the project.
     * @param project The project ID.
     * @param optionId Name of the option to delete
	 */
	deleteProjectOption(project: string, optionId: string): QueryOperation<void>

	/**
	 * Delete option
	 *
	 * Deletes the specified server option The API requires the user to have Edit Server Options permissions.
     * @param optionId Name of the option to delete
	 */
	deleteProjectOption1(optionId: string): QueryOperation<void>

	/**
	 * Delete report
	 *
	 * Deletes the report with the given id. The API requires the user to have Delete permissions on the Quality Report id.
     * @param id The report id
	 */
	deleteQualityReport(id: string): QueryOperation<void>

	/**
	 * Delete snapshot
	 *
	 * Permanently deletes the given snapshot and all associated comparisons. The API requires the user to have Access Administrative Services permissions.
     * @param id The ID of the snapshot to delete.
	 */
	deleteSnapshot(id: string): QueryOperation<void>

	/**
	 * Delete test list
	 *
	 * Deletes a test list descriptor from the system. The API requires the user to have Edit Test Queries permissions on the project.
     * @param project The project ID.
     * @param testListName Name of the test list to delete
	 */
	deleteTestListDescriptor(project: string, testListName: string): QueryOperation<void>

	/**
	 * Delete user group
	 *
	 * Deletes the user group identified by the given name from the system The API requires the user to have Delete permissions on the Group userGroupName.
     * @param userGroupName Name of the user group to delete
	 */
	deleteUserGroup(userGroupName: string): QueryOperation<void>

	/**
	 * Delete option
	 *
	 * Deletes the user option identified by the given name The API requires the user to have Edit permissions on the user.
     * @param user 
     * @param optionId 
	 */
	deleteUserOption(user: string, optionId: string): QueryOperation<void>

	/**
	 * Get analysis profile documentation
	 *
	 * Returns documentation of an analysis profile. The documentation is restricted to findings which are enabled in the profile and includes descriptions of the findings. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	downloadAnalysisProfileDocumentation(analysisProfileName: string): QueryOperation<void>

	/**
	 * Download heap dump
	 *
	 * Downloads a heap dump in HProf format. The API requires a HotSpot-based JVM. The API requires the user to have Access Administrative Services permissions.
	 */
	downloadHeapDump(body: DownloadHeapDumpBody): QueryOperation<void>

	/**
	 * Returns the (possibly ranked) list of tests that have been selected for this job as CSV.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	downloadTestSelectionCsv(project: string, jobId: string, queryParams: DownloadTestSelectionCsvQueryParams): QueryOperation<File>

	/**
	 * Update dashboard
	 *
	 * Updates an existing dashboard. The API requires the user to have Edit permissions on the Dashboard dashboardId.
     * @param dashboardId The ID of the dashboard to update.
	 */
	editDashboard(dashboardId: string, body: DashboardDescriptor): QueryOperation<void>

	/**
	 * Edit option
	 *
	 * Edits the project option identified by the given name. The API requires the user to have Edit Project Options permissions on the project.
     * @param project The project ID.
     * @param optionId Name of the option to edit
	 */
	editProjectOption(project: string, optionId: string, body: Array<string>): QueryOperation<void>

	/**
	 * Edit options
	 *
	 * Edits the specified server option The API requires the user to have Edit Server Options permissions.
     * @param optionId Name of the option to edit
	 */
	editServerOption(optionId: string, queryParams: EditServerOptionQueryParams, body: Array<string>): QueryOperation<void>

	/**
	 * Edit option
	 *
	 * Edits the user option identified by the given name The API requires the user to have Edit permissions on the user.
     * @param user Name of the user to edit the option for
     * @param optionId Name of the option to edit
	 */
	editUserOption(user: string, optionId: string, body: object): QueryOperation<void>

	/**
	 * Edit users
	 *
	 * Performs given user operation. This service is public API since Teamscale 5.5.1. Needs delete permission.
	 */
	editUsers(body: UserBatchOperation): QueryOperation<string>

	/**
	 * Execute scheduler command
	 *
	 * Allows to pass a command to the scheduler. The command is appended to the URL and should be one of the values from ESchedulerCommand. Note that using this service may harm the stability and data integrity of your instance. The API requires the user to have Access Administrative Services permissions.
	 */
	executeSchedulerCommand(body: ExecuteSchedulerCommandBody): QueryOperation<void>

	/**
	 * Exports the analysis profile
	 *
	 * Retrieves the analysis profile identified by given name. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	exportAnalysisProfile(analysisProfileName: string): QueryOperation<void>

	/**
	 * Get a dashboard file
	 *
	 * Performs an export of a dashboard. Returns the descriptor of the dashboard as a .tsdashboard file. The dashboard must be submitted within an XML Teamscale Version Container. The API requires the user to have View permissions on the Dashboard dashboardId.
     * @param dashboardId The ID of the dashboard
	 */
	exportDashboard(dashboardId: string): QueryOperation<TeamscaleVersionContainer>

	/**
	 * Get a dashboard template
	 *
	 * Performs an export of a dashboard template. Returns the template as a .tstemplate file. The API requires no permissions
     * @param templateId ID of the dashboard template
	 */
	exportDashboardTemplate(templateId: string): QueryOperation<TeamscaleVersionContainer>

	/**
	 * Check if external accounts exist
	 *
	 * Checks if the external accounts identified by the given name exist. No permissions needed, as the service should check whether the credentials already exists for every user to prohibit overwriting credentials not visible for the current user
     * @param externalCredentialsName Name of the external accounts to check
	 */
	externalAccountsExist(externalCredentialsName: string): QueryOperation<ExternalCredentialsData>

	/**
	 * Check if external storage backends exist
	 *
	 * Checks if the external storage backend identified by the given name exist. No permissions needed, as the service checks whether the backend already exists for every user to prohibit overwriting backends not visible for the current user
     * @param externalStorageBackendName Name of the external storage backend to check
	 */
	externalStorageBackendExists(externalStorageBackendName: string): QueryOperation<ExternalStorageBackendData>

	/**
	 * Get job queue
	 *
	 * Retrieves the entire job queue from all projects. The API requires the user to have View System Status permissions.
	 */
	extractJobQueue(): QueryOperation<Array<JobDescriptor>>

	/**
	 * Returns the initial commit which added the file at the given path. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param unresolvedCommit This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	findInitialCommit(project: string, unresolvedCommit: UnresolvedCommitDescriptor, queryParams: FindInitialCommitQueryParams): QueryOperation<CommitDescriptor | null>

	/**
	 * Get repository log entries
	 *
	 * Retrieves a specific count of repository log entries after a start timestamp or before an end timestamp. If both start and end timestamp are missing, the newest log entries are returned. If both a start and end timestamp are provided all log entries between the two are returned. The resulting log entries include all log entries from merged commits into the current branch. The current branch and branch state at a point in time are determined by the commit parameter. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	findLogEntriesInRange(project: string, queryParams: FindLogEntriesInRangeQueryParams): QueryOperation<Array<UserResolvedRepositoryLogEntry>>

	/**
	 * Flags/unflags findings
	 *
	 * Flags/unflags the given findings with the given flagging type. This service is public API since Teamscale 8.5. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	flagFindings(project: string, queryParams: FlagFindingsQueryParams, body: FindingBlacklistRequestBody): QueryOperation<void>

	/**
	 * Get Access Key
	 *
	 * Get the user's access key or an empty string if no access key exists yet. The API requires the user to have Edit permissions on the user.
     * @param user 
	 */
	getAccessKey(user: string): QueryOperation<string>

	/**
	 * Returns active users (anonymized) for the given primary project IDs
	 *
	 * For the given primary project IDs, returns all users (anonymized) of the last 3 months that contribute to the license volume. If an empty list is provided, all current projects will be included. The results might differ from the GET query in case the primary project id of a project changed in the last three months or if a user has logged into Teamscale but has not interacted with any project. The API requires the user to have Access Administrative Services permissions.
	 */
	getActiveUsersForProjects(body: Array<string>): QueryOperation<Array<string>>

	/**
	 * Returns a list of sets of additionally covered methods. 
	 *
	 * The set on position i denotes that executing the test corresponding to position i covers additionally (relative to the methods covered by tests 0 to i-1) the methods described in the set. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	getAdditionallyCoveredMethods(project: string, jobId: string): QueryOperation<Array<Array<MethodLocation>>>

	/**
	 * Get aggregated trigger performance metrics
	 *
	 * Allows downloading the performance aggregate data report as CSV. The API requires the user to have Access Administrative Services permissions.
	 */
	getAggregatedTriggerPerformanceMetrics(): QueryOperation<void>

	/**
	 * Get all dashboard templates
	 *
	 * Retrieves all the dashboard templates available in the system. Any user may see dashboard templates.
	 */
	getAllDashboardTemplates(): QueryOperation<Array<DashboardTemplateDescriptor>>

	/**
	 * Get all dashboard descriptors
	 *
	 * Retrieves all the dashboard descriptors available in the system No permissions needed, as the service will only return the dashboard descriptors visible to current user.
	 */
	getAllDashboards(queryParams: GetAllDashboardsQueryParams): QueryOperation<Array<UserResolvedDashboardDescriptor>>

	/**
	 * Get all external accounts
	 *
	 * Retrieves all available external accounts in the system. No permissions needed, as the service will only return credentials visible to current user.
	 */
	getAllExternalAccounts(): QueryOperation<Array<ExternalCredentialsData>>

	/**
	 * Get external analysis groups
	 *
	 * Retrieves all the available analysis groups in the system. The API requires the user to have Edit External Findings Schema permissions.
	 */
	getAllExternalAnalysisGroups(): QueryOperation<Array<ExternalAnalysisGroup>>

	/**
	 * Get all external storage backends
	 *
	 * Retrieves all available external storage backends in the system. No permissions needed, as the service will only return backends visible to current user.
	 */
	getAllExternalStorageBackends(): QueryOperation<Array<ExternalStorageBackendData>>

	/**
	 * Get external finding descriptions
	 *
	 * Retrieves all external finding descriptions available in the system The API requires the user to have Edit External Findings Schema permissions.
	 */
	getAllFindingsDescriptions(): QueryOperation<Array<ExternalFindingsDescription>>

	/**
	 * Get all git tags
	 *
	 * Collects all git tags from all git connections in the given project. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	getAllGitTags(project: string, queryParams: GetAllGitTagsQueryParams): QueryOperation<Array<GitTag>>

	/**
	 * Get all metric threshold configuration names
	 *
	 * Retrieves the names of all available metric threshold configurations in the system. No permissions needed, as the service will only return the names of metric threshold configurations visible to the current user.
	 */
	getAllMetricThresholdConfigurationNames(queryParams: GetAllMetricThresholdConfigurationNamesQueryParams): QueryOperation<Array<string>>

	/**
	 * Get all metric threshold configurations
	 *
	 * Retrieves all available metric threshold configurations in the system. No permissions needed, as the service will only return metric threshold configurations visible to the current user.
	 */
	getAllMetricThresholdConfigurations(): QueryOperation<Array<MetricThresholdConfiguration>>

	/**
	 * Get all metrics for metric threshold configuration names
	 *
	 * Retrieves a list, the entries being the metric threshold configuration names and lists of the metrics included in the profile with that name. No permissions needed, as the service will only return the metrics and their profiles visible to the current user.
	 */
	getAllMetricsForThresholdProfiles(queryParams: GetAllMetricsForThresholdProfilesQueryParams): QueryOperation<Array<MetricsForThresholdProfile>>

	/**
	 * Get parse log overview
	 *
	 * Returns overview information about the parse log entries in all visible projects The API requires the user to have View System Status permissions.
	 */
	getAllParseLogOverviewEntries(): QueryOperation<Array<ParseLogOverviewEntry>>

	/**
	 * Get details (name, last updated) for all test execution partitions
	 *
	 * Returns all available test execution partitions and their last updated timestamp; of the project that have been uploaded and processed up to the given commit. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getAllPartitionDetails(project: string, queryParams: GetAllPartitionDetailsQueryParams): QueryOperation<Array<PartitionInfo>>

	/**
	 * Get projects
	 *
	 * Returns a list of all projects. This service is public API since Teamscale 7.1. Only projects visible to the user are returned in the get all queries.
	 */
	getAllProjects(queryParams: GetAllProjectsQueryParams): QueryOperation<Array<ProjectInfo>>

	/**
	 * Get snapshot IDs
	 *
	 * Returns the list of all available snapshot IDs.  The API requires the user to have Access Administrative Services permissions.
	 */
	getAllSnapshotIds(queryParams: GetAllSnapshotIdsQueryParams): QueryOperation<Array<string>>

	/**
	 * Dumps the stack traces for all currently running threads as HTML. The API requires the user to have Access Administrative Services permissions.
	 */
	getAllThreadsDumpAsHtml(): QueryOperation<string>

	/**
	 * Get all user group names
	 *
	 * Retrieves the names of all available user groups in the system. No permissions needed, as the service will only return group names visible to current user.
	 */
	getAllUserGroupNames(): QueryOperation<Array<string>>

	/**
	 * Get all user groups
	 *
	 * Retrieves all available user groups in the system. No permissions needed, as the service will only return groups visible to current user.
	 */
	getAllUserGroups(): QueryOperation<Array<UserGroup>>

	/**
	 * Get all users
	 *
	 * Returns a list of all users. This service is public API since Teamscale 5.2. Only users visible to the user are returned in the get all queries.
	 */
	getAllUsers(): QueryOperation<Array<UserWithActivity>>

	/**
	 * Analysis profile version difference
	 *
	 * Calculates and return the difference between an older edit version and a newer version of an analysis profile. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	getAnalysisEditHistoryVersionDiff(analysisProfileName: string, queryParams: GetAnalysisEditHistoryVersionDiffQueryParams): QueryOperation<AnalysisProfileHistoryVersionComparison>

	/**
	 * Get analysis profile
	 *
	 * Retrieves the analysis profile identified by given name. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	getAnalysisProfile(analysisProfileName: string): QueryOperation<AnalysisProfile>

	/**
	 * Analysis Profile Edit History
	 *
	 * Returns the different versions of an analysis profile containing a version corresponding to each time the analysis profile was edited. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	getAnalysisProfileEditVersionHistory(analysisProfileName: string): QueryOperation<Array<AnalysisProfileEditVersion>>

	/**
	 * Analysis Profile Edit History Creation Times
	 *
	 * Returns a list of timestamps, each corresponding to the creation of a new profile edit history entry. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	getAnalysisProfileEditVersionHistoryTimestamps(analysisProfileName: string): QueryOperation<Array<number>>

	/**
	 * Get all analysis profile names
	 *
	 * Retrieves the names of all analysis profiles visible to the current user. No permissions needed, as the service will only return names of analysis profiles visible to current user.
	 */
	getAnalysisProfileNames(): QueryOperation<Array<string>>

	/**
	 * Get analysis profile usage
	 *
	 * Returns a list of AnalysisProfileUsageInfoWithProjects indicating the list of referenced projects for each analysis profile No permissions needed, as the service will only return names of analysis profiles visible to current user.
	 */
	getAnalysisProfileUsageInfo(): QueryOperation<Array<AnalysisProfileUsageInfoWithProjects>>

	/**
	 * Get analysis profile with referencing projects
	 *
	 * Returns an analysis profile annotated with the list of projects using the profile The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	getAnalysisProfileWithProjects(analysisProfileName: string): QueryOperation<AnalysisProfileWithProjects>

	/**
	 * Get SonarLint analysis results
	 *
	 * Gets the SonarLint analysis results. Results may be incomplete if the session is not in the completed status. The API requires the user to have Create Projects permissions.
	 */
	getAnalysisResultsByPath(queryParams: GetAnalysisResultsByPathQueryParams): QueryOperation<Record<string, Array<SonarLintFindingTransport>>>

	/**
	 * Get timings of analysis state changes
	 *
	 * Allows downloading timing of analysis state changes as CSV. The API requires the user to have Access Administrative Services permissions.
	 */
	getAnalysisStateChangeTiming(): QueryOperation<void>

	/**
	 * Get the status of a SonarLint Analysis session
	 *
	 * Gets the status of the given session. May return the 'unknown' status, if no session was found for the given key. The API requires the user to have Create Projects permissions.
	 */
	getAnalysisStatus(queryParams: GetAnalysisStatusQueryParams): QueryOperation<ESonarLintIndependentAnalysisStatusEntry>

	/**
	 * Get associated comparison IDs
	 *
	 * Returns the IDs of the associated comparisons of a single snapshot identified by the given ID. The API requires the user to have Access Administrative Services permissions.
     * @param id The ID of the snapshot.
	 */
	getAssociatedComparisonIds(id: string): QueryOperation<Array<string>>

	/**
	 * Get authentication servers
	 *
	 * List configured authentication servers. User must have the permission to edit or create groups or users to view authentication servers.
	 */
	getAvailableServers(): QueryOperation<Record<string, Array<string>>>

	/**
	 * Get user avatar data
	 *
	 * Returns avatar data for the user in the first path parameter. The API requires the user to have View permissions on the user.
     * @param user 
	 */
	getAvatarData(user: string): QueryOperation<AvatarData>

	/**
	 * Get the backup status
	 *
	 * Get the current backup import/export status This service is public API since Teamscale 6.1. The user needs to be able to configure projects. In addition the user needs to have the permission to backup global data provided the backup contains any global data and the permission to backup project data for all projects contained in the backup.
     * @param backupId The backup ID.
	 */
	getBackupStatus(backupId: string): QueryOperation<BackupExportStatus>

	/**
	 * Get the backup status
	 *
	 * Get the current backup import/export status This service is public API since Teamscale 6.1. The user needs to be able to configure projects. In addition the user needs to have the permission to backup global data provided the backup contains any global data and the permission to backup project data for all projects contained in the backup.
     * @param backupId The backup ID.
	 */
	getBackupStatus1(backupId: string): QueryOperation<BackupImportStatus>

	/**
	 * Get available sample projects
	 *
	 * Returns a list of sample projects that can be used as starting point. The API requires the user to have Backup Global Data permissions.
	 */
	getBackupsForImportWizard(): QueryOperation<Array<Backup>>

	/**
	 * Get baseline
	 *
	 * Returns details on a configured baseline. This service is public API since Teamscale 5.2. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param baseline 
	 */
	getBaseline(project: string, baseline: string): QueryOperation<BaselineInfo>

	/**
	 * Get spec item branch
	 *
	 * Get the branch where the spec item with the provided id is analysed on The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param id ID of the spec item to fetch the branch for
	 */
	getBranchForSpecItem(project: string, id: string, queryParams: GetBranchForSpecItemQueryParams): QueryOperation<string>

	/**
	 * Get branches
	 *
	 * Returns the specified range of sorted branches (or all if no range specified), including/excluding deleted/anonymous branches or the sorted filtered branches based on the defined branchesFilter/regexFilter as BranchesInfo. This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getBranchesGetRequest(project: string, queryParams: GetBranchesGetRequestQueryParams): QueryOperation<BranchesInfo>

	/**
	 * Get branches
	 *
	 * Returns the specified range of sorted branches (or all if no range specified), including/excluding deleted/anonymous branches or the sorted filtered branches based on the defined branchesFilter/regexFilter as BranchesInfo. This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getBranchesPostRequest(project: string, queryParams: GetBranchesPostRequestQueryParams): QueryOperation<BranchesInfo>

	/**
	 * Get change treemap
	 *
	 * Retrieves a treemap of change frequencies. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getChangeTreemap(project: string, queryParams: GetChangeTreemapQueryParams): QueryOperation<TreeMapNode>

	/**
	 * Get a treemap that colors components or languages
	 *
	 * Either top-level components or the used languages can be colored. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getCodeComponentsTreemapWrapper(project: string, queryParams: GetCodeComponentsTreemapWrapperQueryParams): QueryOperation<FilteredTreeMapWrapper>

	/**
	 * Get a mapping from spec items to code references and test executions
	 *
	 * Returns a spec item code and test result mapping for all spec items matching the given query. If the query is empty, all known spec items are considered. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getCodeReferencesAndTestExecutionMapping(project: string, queryParams: GetCodeReferencesAndTestExecutionMappingQueryParams): QueryOperation<SpecItemReferenceMapping>

	/**
	 * List the code search match results and returns a treemap.
	 *
	 * Lists all code search matches result including path and line numbers for a given search term and a max result size. Additionally, return a treemap. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getCodeSearchMatchResultAndTreemap(project: string, queryParams: GetCodeSearchMatchResultAndTreemapQueryParams): QueryOperation<CodeSearchResultsWrapper>

	/**
	 * Get commit alerts
	 *
	 * Provides access to the alerts for the given commits. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getCommitAlerts(project: string, queryParams: GetCommitAlertsQueryParams): QueryOperation<Array<CommitAlerts | null>>

	/**
	 * Get comparison summary
	 *
	 * Returns the global comparison data, the projects and the contributors for the projects for a single comparison identified by the given ID. The API requires the user to have Access Administrative Services permissions.
     * @param snapshotId The ID of the local snapshot the comparison belongs to.
     * @param comparisonId The ID of the snapshot to get. May be an incomplete prefix, as long as it is unambiguous.
	 */
	getComparison(snapshotId: string, comparisonId: string): QueryOperation<ReducedInstanceComparisonComputation>

	/**
	 * Get complete example list
	 *
	 * Get the full list of missing local or remote detail values The API requires the user to have Access Administrative Services permissions.
     * @param snapshotId The ID of the local snapshot the comparison belongs to.
     * @param comparisonId The ID of the comparison to get.
     * @param project The name of the project. May be \&quot;null\&quot; or empty if this is a global contribution
     * @param contributor The contributor of the comparison.
     * @param diffEntryName The name of the difference entry.
	 */
	getCompleteExampleList(snapshotId: string, comparisonId: string, project: string, contributor: string, diffEntryName: string, queryParams: GetCompleteExampleListQueryParams): QueryOperation<Array<DetailedInstanceComparisonValue>>

	/**
	 * Get a detailed concurrency analysis
	 *
	 * Allows downloading the detailed trigger performance analysis. The feature toggle -Dcom.teamscale.log_performance_details=true must be enabled. The API requires the user to have Access Administrative Services permissions.
	 */
	getConcurrencyPerformanceDetails(queryParams: GetConcurrencyPerformanceDetailsQueryParams): QueryOperation<string>

	/**
	 * Get coverage sources
	 *
	 * Retrieves information about coverage sources (partitions and cross-annotation projects),  respecting the given path. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getCoverageSources(project: string, queryParams: GetCoverageSourcesQueryParams): QueryOperation<Array<CoverageSourceInfo>>

	/**
	 * Get dashboard
	 *
	 * Retrieves the dashboard descriptor identified by given ID. The API requires the user to have View permissions on the Dashboard dashboardId.
     * @param dashboardId ID of the dashboard to retrieve.
	 */
	getDashboard(dashboardId: string): QueryOperation<UserResolvedDashboardDescriptor>

	/**
	 * Get dashboard template
	 *
	 * Retrieves the dashboard template identified by the given ID. Any user may see dashboard templates.
     * @param templateId ID of the template to retrieve
	 */
	getDashboardTemplateDescriptor(templateId: string): QueryOperation<DashboardTemplateDescriptor>

	/**
	 * Get token element internals
	 *
	 * Returns a string representation of a token element, supporting various representations. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getDebugContent(project: string, uniformPath: string, queryParams: GetDebugContentQueryParams): QueryOperation<string>

	/**
	 * Get the declaration of accessibility
	 *
	 * Returns the declaration of accessibility as found in the configuration directory (file name: 'declaration_of_accessibility.md'). To avoid unnecessary IO lookups, the declaration (or non-existent declaration) is locally cached until a reload is forced. The API requires no login
	 */
	getDeclarationOfAccessibility(queryParams: GetDeclarationOfAccessibilityQueryParams): QueryOperation<string | null>

	/**
	 * null The API requires no login
	 */
	getDeclarationOfAccessibilityContext(): QueryOperation<DeclarationOfAccessibilityContext>

	/**
	 * Get the default branch name
	 *
	 * Get the default branch name This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getDefaultBranchNameGetRequest(project: string): QueryOperation<string>

	/**
	 * Get the entire dependency graph of a system
	 *
	 * Extracts the dependency graph of a project in the \"Trivial Graph Format\" The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getDependencyGraph(project: string, uniformPath: string, queryParams: GetDependencyGraphQueryParams): QueryOperation<string>

	/**
	 * Get commit descriptors
	 *
	 * Returns commit descriptors for a project. The API requires the user to have Access Administrative Services permissions.
     * @param project The project ID.
	 */
	getDumpCommitDescriptor(project: string, queryParams: GetDumpCommitDescriptorQueryParams): QueryOperation<string>

	/**
	 * Get commit trees
	 *
	 * Returns the commit trees for a project. The API requires the user to have Access Administrative Services permissions.
     * @param project The project ID.
	 */
	getDumpCommitTree(project: string, queryParams: GetDumpCommitTreeQueryParams): QueryOperation<string>

	/**
	 * Get commit trees
	 *
	 * Returns the commit tree data for a project. It excludes the nodes from the detailed commit tree. Use the debug/commit-tree service to get the full detailed information. The API requires the user to have Access Administrative Services permissions.
     * @param project The project ID.
	 */
	getDumpCommitTreeNodes(project: string, queryParams: GetDumpCommitTreeNodesQueryParams): QueryOperation<Array<CommitTreeNodeData>>

	/**
	 * Get all events
	 *
	 * Returns the list of all available event announcements. The API requires no permissions
	 */
	getEventAnnouncements(): QueryOperation<Array<EventAnnouncement>>

	/**
	 * Get exception hierarchies
	 *
	 * Retrieves hierarchies of custom exceptions represented as objects. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getExceptionsHierarchyTree(project: string, queryParams: GetExceptionsHierarchyTreeQueryParams): QueryOperation<Array<Array<ExceptionsTree>>>

	/**
	 * Get hierarchies of custom exceptions
	 *
	 * Retrieves hierarchies of custom exceptions represented graphically. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getExceptionsHierarchyTreeSVG(project: string, queryParams: GetExceptionsHierarchyTreeSVGQueryParams): QueryOperation<Array<string>>

	/**
	 * Get files with an identical content
	 *
	 * Retrieves files. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getFilesWithIdenticalContent(project: string, uniformPath: string, queryParams: GetFilesWithIdenticalContentQueryParams): QueryOperation<Array<FileGroup> | null>

	/**
	 * Get files with an identical names
	 *
	 * Retrieves files. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getFilesWithIdenticalNames(project: string, uniformPath: string, queryParams: GetFilesWithIdenticalNamesQueryParams): QueryOperation<Array<FileGroup> | null>

	/**
	 * This service provides access to the finding churn count. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFindingChurnCount(project: string, queryParams: GetFindingChurnCountQueryParams): QueryOperation<Array<FindingChurnCount>>

	/**
	 * Get finding delta
	 *
	 * Retrieves the finding delta (i.e., the list of newly added and deleted findings) for the given uniform path and time range. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFindingDelta(project: string, queryParams: GetFindingDeltaQueryParams): QueryOperation<FindingDelta>

	/**
	 * Get finding descriptions
	 *
	 * Returns user readable finding names and descriptions for given finding type IDs This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFindingTypeDescriptions(project: string, body: Array<string>): QueryOperation<Array<FindingTypeDescription>>

	/**
	 * Get findings
	 *
	 * Gets a list of all findings. Findings can be filtered by path prefix. For recursive queries, these are all findings found in the sub-tree. Filter parameters allow to only retrieve findings in a specific category and/or group. There is an upper limit for the number of returned findings. This service is public API since Teamscale 5.6. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFindings(project: string, queryParams: GetFindingsQueryParams): QueryOperation<Array<TrackedFinding>>

	/**
	 * Export findings
	 *
	 * Exports findings for the element with the provided uniform path in the given format. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param format The export format used.
	 */
	getFindingsExport(project: string, format: EFindingsExportFormatEntry, queryParams: GetFindingsExportQueryParams): QueryOperation<void>

	/**
	 * Get finding siblings
	 *
	 * Gets next and prev finding respecting filter and sort options.  The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param finding 
	 */
	getFindingsSiblings(project: string, finding: string, queryParams: GetFindingsSiblingsQueryParams): QueryOperation<PreviousNextSiblings>

	/**
	 * Get summaries for all findings
	 *
	 * Gets the list of finding summaries for an element or a resource sub-tree. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFindingsSummary(project: string, queryParams: GetFindingsSummaryQueryParams): QueryOperation<FindingsSummaryInfo>

	/**
	 * Get findings treemap
	 *
	 * Produces a treemap of finding frequencies. The intensity of the rectangle colors determine the finding frequency in the corresponding file. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFindingsTreemap(project: string, queryParams: GetFindingsTreemapQueryParams): QueryOperation<FindingsTreemapWrapper>

	/**
	 * Get findings with false positive counts
	 *
	 * Gets a list of all findings with additional information on . For recursive queries, these are all findings found in the sub-tree. Filter parameters allow to only retrieve findings in a specific category and/or group. There is an upper limit for the number of returned findings. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFindingsWithCount(project: string, queryParams: GetFindingsWithCountQueryParams): QueryOperation<ExtendedFindingsWithCount>

	/**
	 * Get identifiers of flagged findings
	 *
	 * Returns identifiers of all flagged findings for a given project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFlaggedFindingIds(project: string, queryParams: GetFlaggedFindingIdsQueryParams): QueryOperation<Array<string>>

	/**
	 * Gets flagging information for a finding.
	 *
	 * Returns the flagging information for the finding with given identifier. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param finding Id of the finding the information is requested for.
	 */
	getFlaggedFindingInfo(project: string, finding: string, queryParams: GetFlaggedFindingInfoQueryParams): QueryOperation<UserResolvedFindingBlacklistInfo | null>

	/**
	 * Get flagged findings
	 *
	 * Returns all flagged findings for a given project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFlaggedFindings(project: string, queryParams: GetFlaggedFindingsQueryParams): QueryOperation<Array<FindingBlacklistInfo>>

	/**
	 * Get flagging information for findings.
	 *
	 * Returns flagging information for the given findings. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFlaggedFindingsInfos(project: string, queryParams: GetFlaggedFindingsInfosQueryParams, body: Array<string>): QueryOperation<Array<FindingBlacklistInfo>>

	/**
	 * Get connector ids of all Git-based connectors
	 *
	 * Returns all connector identifiers in a project that use Git. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	getGitConnectorIds(project: string): QueryOperation<Array<string>>

	/**
	 * Get Repositories the GitHub App has Access to
	 *
	 * Returns list of repositories of the installed GitHub App. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	getGitHubAppInstallationRepositories(queryParams: GetGitHubAppInstallationRepositoriesQueryParams): QueryOperation<Array<string>>

	/**
	 * Get GitHub App Infos
	 *
	 * Returns information about the installed GitHub Apps the current user has access to. User must be provisioned from e.g. GitHub.
	 */
	getGitHubAppInstallations(): QueryOperation<AppInstallationInfo>

	/**
	 * Collect URLs of all installed GitHub Apps.
	 *
	 * Returns URLs by setup GitHub Apps. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	getGitHubAppUrls(): QueryOperation<Array<string>>

	/**
	 * Gets a git tag by its ref name
	 *
	 * Returns the git tag with the given ref name or null if it could not be found. The API requires the user to have View Project permissions on the project.
     * @param project 
     * @param tagName 
	 */
	getGitTagByName(project: string, tagName: string, queryParams: GetGitTagByNameQueryParams): QueryOperation<GitTag | null>

	/**
	 * Get multi project branches
	 *
	 * Gets the branches for multiple projects as BranchesInfo The API requires no permissions
	 */
	getGlobalBranchesGetRequest(queryParams: GetGlobalBranchesGetRequestQueryParams): QueryOperation<BranchesInfo>

	/**
	 * Get multi project default branch
	 *
	 * Gets the default branch of one the specified projects. The API requires no permissions
	 */
	getGlobalDefaultBranchGetRequest(queryParams: GetGlobalDefaultBranchGetRequestQueryParams): QueryOperation<string | null>

	/**
	 * Get multi project recent branches
	 *
	 * Gets the recent branches for multiple projects. The API requires no permissions
	 */
	getGlobalRecentBranchesGetRequest(queryParams: GetGlobalRecentBranchesGetRequestQueryParams): QueryOperation<Array<string>>

	/**
	 * Get auto-completion suggestions
	 *
	 * Returns a list of suggested group names for auto-completion. The API requires the user to have Create Users permissions.
     * @param input The current input that should be auto-completed.
	 */
	getGroupAutoCompletionSuggestions(input: string, queryParams: GetGroupAutoCompletionSuggestionsQueryParams): QueryOperation<Array<string>>

	/**
	 * Retrieve heap dump form
	 *
	 * Provides a UI to download heap dump The API requires the user to have Access Administrative Services permissions.
	 */
	getHeapDumpFormPage(): QueryOperation<string>

	/**
	 * Retrieve commit issues
	 *
	 * Returns the issues associated with the given commits. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getIssuesByRepositoryCommits(project: string, body: GetIssuesByRepositoryCommitsBody): QueryOperation<Array<Array<UserResolvedTeamscaleIssue> | null>>

	/**
	 * Get issues details
	 *
	 * Retrieves issue details by their IDs. Unknown issue IDs will cause 'null' entries in the result The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getIssuesDetails(project: string, queryParams: GetIssuesDetailsQueryParams): QueryOperation<Array<UserResolvedTeamscaleIssue | null>>

	/**
	 * Returns the current computation status of a test minimization job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	getJob(project: string, jobId: string): QueryOperation<TestMinimizationJobRun>

	/**
	 * Returns the arguments of the test minimization job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	getJobOptions(project: string, jobId: string): QueryOperation<TestMinimizationRequestOptions>

	/**
	 * Get job queue size
	 *
	 * Retrieves size of the entire job queue from all projects. The API requires the user to have View System Status permissions.
	 */
	getJobQueueSize(): QueryOperation<JobQueueCountWithDelayedJobs>

	/**
	 * Returns the list of test minimization jobs of the current user for the project.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getJobs(project: string): QueryOperation<Array<TestMinimizationJobRun>>

	/**
	 * Return last change log entry with the given commit type
	 *
	 * Returns the log entry for the last change with the given commit type The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getLastCommitOfType(project: string, queryParams: GetLastCommitOfTypeQueryParams): QueryOperation<RepositoryLogEntryAggregate | null>

	/**
	 * Get the late external upload information
	 *
	 * Retrieves the timestamp of the next late processing of external uploads, the age threshold for external uploads being considered late and the maximum upload age for external uploads. The API requires the user to have Perform External Uploads permissions on the project.
     * @param project The project ID.
	 */
	getLateExternalUploadProcessingInfo(project: string): QueryOperation<LateExternalUploadProcessingInfo>

	/**
	 * Returns information about the license.
	 *
	 * Returns license information. If the reload parameter is set this reloads the license and logs any errors into the service log. The API requires the user to have Access Administrative Services permissions.
	 */
	getLicenseInfo(queryParams: GetLicenseInfoQueryParams): QueryOperation<LicenseInfo | null>

	/**
	 * Get link roles
	 *
	 * Returns the link roles for a specific connector. The API requires the user to have View permissions on the External Accounts accountName.
     * @param accountName The ID of the external accounts the user needs access to.
	 */
	getLinkRoles(accountName: string, body: ConnectorConfiguration): QueryOperation<GetLinkRolesResponse>

	/**
	 * Returns active committers across all projects
	 *
	 * Returns all committers of the last 90 days (across all projects) that contribute to the license volume The API requires the user to have Access Administrative Services permissions.
	 */
	getListOfActiveCommitters(): QueryOperation<Array<CommitterDisplayName>>

	/**
	 * Returns active committers for the given primary project IDs
	 *
	 * For the given list of primary project IDs, returns all committers of the last 90 days that contribute to the license volume. If an empty list is provided, all projects will be included (which is equivalent to performing a  GET query). The API requires the user to have Access Administrative Services permissions.
	 */
	getListOfActiveCommittersForProjects(body: Array<string>): QueryOperation<Array<CommitterDisplayName>>

	/**
	 * Provides context for the login screen
	 *
	 * null The API requires no login
	 */
	getLoginContext(): QueryOperation<LoginContext>

	/**
	 * Get merge request finding churn
	 *
	 * Creates a findings delta (newly added or deleted findings and unchanged findings in modified code) for a given proposed merge. Findings in modified code will only be reported if the modification happened since the last common commit with the target branch. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getMergeRequestFindingChurn(project: string, queryParams: GetMergeRequestFindingChurnQueryParams): QueryOperation<FindingDelta>

	/**
	 * Get merge request repository churn
	 *
	 * Retrieves the merge request repository churn. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getMergeRequestRepositoryChurn(project: string, queryParams: GetMergeRequestRepositoryChurnQueryParams): QueryOperation<RepositoryChurn>

	/**
	 * Get method history
	 *
	 * Returns the method history entries including changes, renamings or movements and points in time where method has been tested. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath Uniform path to retrieve the metric history for.
	 */
	getMethodHistory(project: string, uniformPath: string, queryParams: GetMethodHistoryQueryParams): QueryOperation<MethodHistoryEntriesWrapper>

	/**
	 * Get metric schema
	 *
	 * Returns the metric schema for a path within a project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPathType The path for which the metric schema should be fetched
	 */
	getMetricDirectorySchemaForPath(project: string, uniformPathType: ETypeEntry): QueryOperation<MetricDirectorySchema>

	/**
	 * Get global metric schema
	 *
	 * Retrieves all available metric schemas in the same order as the provided paths. Global metric directory schema is available to each authenticated user.
	 */
	getMetricDirectorySchemas(queryParams: GetMetricDirectorySchemasQueryParams): QueryOperation<Array<MetricDirectorySchema>>

	/**
	 * Get metric threshold configuration
	 *
	 * Retrieves the metric threshold configuration identified by given name. Built-in metric threshold configurations require no access permissions. For the rest, view permission for the respective configuration is required.
     * @param metricThresholdConfigurationName 
	 */
	getMetricThresholdConfiguration(metricThresholdConfigurationName: string, queryParams: GetMetricThresholdConfigurationQueryParams): QueryOperation<MetricThresholdConfiguration>

	/**
	 * Get minimized Test Set
	 *
	 * Returns a list of test clusters that have been determined to be the best tests to run in the amount of time that was specified in the request. The clusters are formed according tothe clustering-regex that can be specified in the request options. This service is public API since Teamscale 7.8. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getMinimizedTests(project: string, queryParams: GetMinimizedTestsQueryParams): QueryOperation<TestMinimizationResult>

	/**
	 * Number of Active User
	 *
	 * Calculates the number of active users in the past 90 days. This is either the number of active Teamscale users or the number of committers, depending on which value is larger.  The API requires no permissions
	 */
	getNumberOfActiveUsers(): QueryOperation<number>

	/**
	 * Get partitions
	 *
	 * Returns the list of external upload partitions in this project. The API requires the user to have Perform External Uploads, View Project permissions on the project.
     * @param project The project ID.
	 */
	getPartitions(project: string): QueryOperation<Array<ExternalAnalysisPartitionInfo>>

	/**
	 * Get permission lookup
	 *
	 * Returns a permission lookup for the whole permission scope The API requires no permissions
	 */
	getPermissionLookup(queryParams: GetPermissionLookupQueryParams): QueryOperation<PermissionLookupEBasicPermission>

	/**
	 * Get the content for a pre-commit element
	 *
	 * Retrieves the formatted content (i.e., incl. style information) for an element that is part of a pre-commit branch. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getPreCommitContent(project: string, queryParams: GetPreCommitContentQueryParams): QueryOperation<FormattedTokenElementInfo>

	/**
	 * Get project analysis progress
	 *
	 * Retrieves the project specific analysis progress. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getProjectAnalysisProgress(project: string): QueryOperation<Array<AnalysisProgressDescriptor>>

	/**
	 * Get project comparison results
	 *
	 * Returns the comparison data for the specified comparison, project and contributor. The comparison can be identified by the given ID. The API requires the user to have Access Administrative Services permissions.
     * @param snapshotId The ID of the local snapshot the comparison belongs to.
     * @param comparisonId The ID of the comparison to get.
     * @param project The name of the project.
	 */
	getProjectComparisonResultsForContributor(snapshotId: string, comparisonId: string, project: string, queryParams: GetProjectComparisonResultsForContributorQueryParams): QueryOperation<ProjectComparisonResult>

	/**
	 * Get project notification rules
	 *
	 * Retrieves the currently set project notification rules The API requires the user to have Edit Global Notification Settings permissions.
	 */
	getProjectNotificationRules(): QueryOperation<ProjectNotificationRules | null>

	/**
	 * Get options
	 *
	 * Retrieves all available project options. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getProjectOptions(project: string): QueryOperation<Record<string, object>>

	/**
	 * Get unique users activity trend
	 *
	 * Returns a list of lists, where each list contains a pair of the date timestamp and the number of unique users on that date. Requires VIEW permissions on all projects given via the project parameter
	 */
	getProjectUserActivityTrend(queryParams: GetProjectUserActivityTrendQueryParams): QueryOperation<Array<Array<number>>>

	/**
	 * Get recent branches
	 *
	 * Returns the recently visited branches. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRecentBranches(project: string): QueryOperation<Array<string>>

	/**
	 * Get redundant literal treemap
	 *
	 * Retrieves a treemap of all redundant literals. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRedundantLiteralTreemap(project: string, queryParams: GetRedundantLiteralTreemapQueryParams): QueryOperation<FilteredTreeMapWrapper>

	/**
	 * Get redundant literals
	 *
	 * Retrieves all redundant literals in the project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRedundantLiteralsQuery(project: string, queryParams: GetRedundantLiteralsQueryQueryParams): QueryOperation<Array<RedundantLiteralInfo>>

	/**
	 * Get report
	 *
	 * Returns the report. The API requires the user to have View permissions on the Quality Report id.
     * @param id The report id
	 */
	getReport(id: string): QueryOperation<QualityReport>

	/**
	 * Get repository churn
	 *
	 * Retrieves the repository churn for a time range. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRepositoryChurn(project: string, queryParams: GetRepositoryChurnQueryParams): QueryOperation<RepositoryChurn>

	/**
	 * Get all repository log entries
	 *
	 * Returns repository log entries for given commits.A commit is identified as branch1@timestamp1All timestamps are interpreted as milliseconds since 1970. If no commits are provided, all log entries are returned. Commits are separated by comma. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRepositoryLog(project: string, queryParams: GetRepositoryLogQueryParams): QueryOperation<Array<UserResolvedRepositoryLogEntry>>

	/**
	 * Get affected files by commits
	 *
	 * Returns repository log file entries for commits, i.e. the association between files and repository log entries. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRepositoryLogFileHistoryForCommits(project: string, queryParams: GetRepositoryLogFileHistoryForCommitsQueryParams): QueryOperation<Array<RepositoryLogFileHistoryEntry>>

	/**
	 * Get the repository summary
	 *
	 * Provides summary information about the commit activity in a project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRepositorySummary(project: string, queryParams: GetRepositorySummaryQueryParams): QueryOperation<RepositorySummary>

	/**
	 * Get resource history
	 *
	 * Returns repository log file entries for a specific path, i.e. the association between files and repository log entries. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getResourceHistory(project: string, uniformPath: string, queryParams: GetResourceHistoryQueryParams): QueryOperation<Array<RepositoryLogFileHistoryEntry>>

	/**
	 * Get review baseline
	 *
	 * Allows to retrieve the review baseline for a project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getReviewBaseline(project: string): QueryOperation<number | null>

	/**
	 * Get risk methods
	 *
	 * Returns all methods that are considered a risk and should therefore be covered with tests. For now this is all methods that have been added or changed in the given time range. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRisks(project: string, queryParams: GetRisksQueryParams): QueryOperation<RiskMethods>

	/**
	 * Open scheduler control page
	 *
	 * Provides a UI to send a command to the scheduler control. The API requires the user to have Access Administrative Services permissions.
	 */
	getSchedulerControlPage(): QueryOperation<string>

	/**
	 * Returns the currently selected list of test (possibly Pareto ranked).
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	getSelectedTests(project: string, jobId: string, queryParams: GetSelectedTestsQueryParams): QueryOperation<Array<PrioritizableTest>>

	/**
	 * Service-Load information
	 *
	 * Returns information about service load. The API requires the user to have View System Status permissions.
	 */
	getServiceLoad(): QueryOperation<Array<LoadProfile>>

	/**
	 * Get option
	 *
	 * Retrieves the project option identified by the given name. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param optionId Name of the option to retrieve.
	 */
	getSingleProjectOption(project: string, optionId: string): QueryOperation<object>

	/**
	 * Get option
	 *
	 * Retrieves the server option identified by the given name. EDIT_SERVER_OPTIONS permission needed for most options, but some options (PUBLIC_READ_OPTION_NAMES) can be read without permission
     * @param optionId Name of the option to retrieve.
	 */
	getSingleServerOption(optionId: string): QueryOperation<object>

	/**
	 * Get a snapshot
	 *
	 * Returns a single snapshot identified by the given ID or ID prefix. The ID (prefix) must not be empty, but can be as short as one character, as long as it unambiguously identifies the snapshot to be returned. The API requires the user to have Access Administrative Services permissions.
     * @param id The ID of the snapshot to get. May be an incomplete prefix, as long as it is unambiguous.
	 */
	getSnapshot(id: string, queryParams: GetSnapshotQueryParams): QueryOperation<InstanceComparisonSnapshotDto>

	/**
	 * Get a snapshot status
	 *
	 * Returns the status (IN_PROGRESS, SUCCESS or FAILURE) of a single status identified by the given ID or ID prefix. The ID (prefix) must not be empty, but can be as short as one character, as long as it unambiguously identifies a status. Only snapshots created by the logged-in user are considered for this operation. The API requires the user to have Access Administrative Services permissions.
     * @param id The ID of the snapshot. May be an incomplete prefix, as long as it is unambiguous.
	 */
	getSnapshotStatus(id: string): QueryOperation<EInstanceComparisonStatusEntry>

	/**
	 * Gets the spec item churn
	 *
	 * Provides a spec item churn (i.e., the list of changed spec items) for a given time range. This service is public API since Teamscale 8.5. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getSpecItemChurn(project: string, queryParams: GetSpecItemChurnQueryParams): QueryOperation<Array<TokenElementChurnInfo>>

	/**
	 * Get spec item details
	 *
	 * Retrieves spec item details by their IDs. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getSpecItemDetails(project: string, queryParams: GetSpecItemDetailsQueryParams): QueryOperation<Array<UserResolvedSpecItem | null>>

	/**
	 * Get stacktrace of failed test execution
	 *
	 * Retrieves the stacktrace of the failed test execution at a given uniform path. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param test Uniform path to retrieve the stacktrace for
	 */
	getStacktrace(project: string, test: string, queryParams: GetStacktraceQueryParams): QueryOperation<Stacktrace | null>

	/**
	 * Get state change timestamps
	 *
	 * Allows getting the timestamps for the different analysis states. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	getStateChangeTimestamps(project: string): QueryOperation<Record<string, number>>

	/**
	 * Get aggregated store performance metrics
	 *
	 * Allows downloading the store performance aggregate data as CSV. The API requires the user to have Access Administrative Services permissions.
	 */
	getStorePerformanceDetails(): QueryOperation<void>

	/**
	 * Get stored test lists
	 *
	 * Retrieves the list of available test list descriptors. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getStoredTestLists(project: string): QueryOperation<Array<TestListDescriptor>>

	/**
	 * Get stored test lists names
	 *
	 * Retrieves the list of available test list descriptors names. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getStoredTestListsNames(project: string): QueryOperation<Array<string>>

	/**
	 * Get the complete backup summary
	 *
	 * Get the summary of the 10 most recent backups. This service is public API since Teamscale 6.1. The API requires the user to have Backup Global Data permissions.
	 */
	getSummary1(): QueryOperation<Array<BackupJobSummary>>

	/**
	 * Get system information.
	 *
	 * Returns system information. The API requires the user to have View System Status permissions.
	 */
	getSystemInformation(): QueryOperation<Array<SystemProcessInfo>>

	/**
	 * Shutdown Teamscale
	 *
	 * Provides an UI to issue a Teamscale shutdown request. The API requires the user to have Access Administrative Services permissions.
	 */
	getTeamscaleShutdownPage(): QueryOperation<string>

	/**
	 * List all third party dependencies
	 *
	 * Lists all third party dependencies for the project with given package depth, along with the percentage of affected files for each dependency. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTechnologyDependencies(project: string, queryParams: GetTechnologyDependenciesQueryParams): QueryOperation<Array<DependencyEntry>>

	/**
	 * Get treemap with the third party dependencies
	 *
	 * Builds a treemap with the third party dependencies from the technology scan. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTechnologyTreeMap(project: string, queryParams: GetTechnologyTreeMapQueryParams): QueryOperation<FilteredTreeMapWrapper>

	/**
	 * Returns test coverage
	 *
	 * Returns the line coverage data for an element. This service is public API since Teamscale 5.3. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getTestCoverage(project: string, uniformPath: string, queryParams: GetTestCoverageQueryParams): QueryOperation<LineCoverageInfo | null>

	/**
	 * Get test coverage overlay
	 *
	 * Retrieves the methods that are expected to be covered by the impacted tests if they are rerun. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTestCoverageOverlay(project: string, queryParams: GetTestCoverageOverlayQueryParams): QueryOperation<TestCoverageOverlayData>

	/**
	 * Returns test coverage
	 *
	 * Returns the line coverage data for an element. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getTestCoveragePost(project: string, uniformPath: string, body: GetTestCoveragePostBody): QueryOperation<LineCoverageInfo | null>

	/**
	 * Download test gap data
	 *
	 * Downloads test gap information as a CSV file The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTestGapDataAsCsv(project: string, queryParams: GetTestGapDataAsCsvQueryParams): QueryOperation<File>

	/**
	 * Get test gap treemap
	 *
	 * Retrieves the test get treemap. For each method in the project, the treemap shows whether it was tested after its last change within the reference time frame. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTestGapTreeMap(project: string, queryParams: GetTestGapTreeMapQueryParams): QueryOperation<TestGapTreeMapWrapper>

	/**
	 * Get test gap percentage
	 *
	 * Calculates the TGA percentage for the given uniform path or the given issue ID. This service is public API since Teamscale 5.9.6. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTgaPercentage(project: string, queryParams: GetTgaPercentageQueryParams): QueryOperation<number>

	/**
	 * Get metric table entries
	 *
	 * Retrieves various test gap metrics (e.g., test gap state, number of test gaps, execution state and churn) and returns them in tabular form. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTgaTable(project: string, queryParams: GetTgaTableQueryParams): QueryOperation<Array<TgaTableEntry>>

	/**
	 * Get test coverage partitions
	 *
	 * Returns the available test coverage partitions of the project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTgaTestCoveragePartitions(project: string, queryParams: GetTgaTestCoveragePartitionsQueryParams): QueryOperation<Array<string>>

	/**
	 * Get test gap trend
	 *
	 * Calculates test gap trends. Does not support issue requests. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTgaTrend(project: string, queryParams: GetTgaTrendQueryParams): QueryOperation<Array<MetricTrendEntry>>

	/**
	 * Get trigger graph as JSON
	 *
	 * Returns a plain text GraphViz DOT description of the trigger graph The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTriggerGraphDot(project: string, queryParams: GetTriggerGraphDotQueryParams): QueryOperation<Array<TriggerGraphNode>>

	/**
	 * Get detailed trigger performance metrics
	 *
	 * Allows downloading the detailed trigger performance data as CSV. The feature toggle -Dcom.teamscale.log_performance_details=true must be enabled. The API requires the user to have Access Administrative Services permissions.
	 */
	getTriggerPerformanceDetails(): QueryOperation<void>

	/**
	 * Get unused ID
	 *
	 * Returns a valid event ID that is unused so far. The API requires no permissions
	 */
	getUnusedEventId(): QueryOperation<string>

	/**
	 * Get auto-completion suggestions
	 *
	 * Returns a list of suggested user names for auto-completion. The API requires the user to have Create Users permissions.
     * @param input The current input that should be auto-completed.
	 */
	getUserAutoCompletionSuggestions(input: string, queryParams: GetUserAutoCompletionSuggestionsQueryParams): QueryOperation<Array<string>>

	/**
	 * Get user avatar image
	 *
	 * Returns the image file that serves as the avatar for the user in the first path parameter. The API requires no permissions
     * @param user 
	 */
	getUserAvatarData(user: string, queryParams: GetUserAvatarDataQueryParams): QueryOperation<void>

	/**
	 * Get a user group
	 *
	 * Get the user group identified by the given name. The API requires the user to have View permissions on the Group userGroupName.
     * @param userGroupName Name of the requested user group.
	 */
	getUserGroup(userGroupName: string): QueryOperation<UserGroup>

	/**
	 * Sufficient Ram
	 *
	 * Checks whether Teamscale has enough RAM to operate, given the amount of workers. The API requires no permissions
	 */
	hasSufficientRam(): QueryOperation<SufficientRamResponse>

	/**
	 * Hide event announcement
	 *
	 * Hides the event announcement with the given ID for the logged-in user. The event will no longer be advertised to the user. The API requires no permissions
	 */
	hideEventAnnouncement(body: string): QueryOperation<void>

	/**
	 * Upload AbapLint configuration files
	 *
	 * Uploads AbapLint configuration files which are then converted to Teamscale analysis profiles. Returns a textual summary of the import process The API requires the user to have Create Analysis Profiles permissions.
	 */
	importAbapLintConfigurationFile(body: ImportAbapLintConfigurationFileBody): QueryOperation<Array<ABAPLintConfigurationFileImportSummary>>

	/**
	 * Import analysis profile
	 *
	 * Imports an analysis profile. Requires Create Analysis Profiles or the permission to edit analysis profiles when a profile with the same name already exists.
	 */
	importAnalysisProfile(body: ImportAnalysisProfileBody): QueryOperation<void>

	/**
	 * Update a dashboard from a dashboard export
	 *
	 * Performs an import of a dashboard. Adds the uploaded descriptor to the list of dashboards/templates. The descriptor must be packaged within an JSON Teamscale Version Container. In case the dashboard with the UUID already exists the existing one will be overridden. This service is public API since Teamscale 8.9.8. The API requires the user to have Create Dashboards permissions. For dashboards that already exist and should therefore be overwritten the user needs to have EDIT permissions on the dashboard.
	 */
	importAndReplaceDashboards(body: ImportAndReplaceDashboardsBody): QueryOperation<Array<string>>

	/**
	 * Import backup.
	 *
	 * Triggers the import of a backup and returns the job ID. This service is public API since Teamscale 6.1. The API requires the user to have Backup Global Data permissions.
	 */
	importBackup(body: ImportBackupBody): QueryOperation<string>

	/**
	 * Upload SwiftLint Configuration
	 *
	 * Uploads SwiftLint Configurations which are then converted to Teamscale analysis profiles. Returns a textual summary of the import process The API requires the user to have Create Analysis Profiles permissions.
	 */
	importConfiguration(body: ImportConfigurationBody): QueryOperation<SwiftLintConfigurationImportSummary>

	/**
	 * Create a dashboard from a dashboard export
	 *
	 * Performs an import of a dashboard. Adds the uploaded descriptor to the list of dashboards/templates. The descriptor must be packaged within an JSON Teamscale Version Container. This service is public API since Teamscale 8.7. The API requires the user to have Create Dashboards permissions.
	 */
	importDashboard(body: ImportDashboardBody): QueryOperation<Array<string>>

	/**
	 * Create a dashboard template
	 *
	 * Performs an import of a dashboard template. Adds the uploaded descriptor to the list of templates. The descriptor must be packaged within an JSON Teamscale Version Container. The API requires the user to have Create Dashboard Templates permissions.
	 */
	importDashboardTemplate(body: ImportDashboardTemplateBody): QueryOperation<Array<string>>

	/**
	 * Import group
	 *
	 * Imports all users of one group from a remote server. Returns the list of imported user names. The API requires the user to have Create Users, Create Groups permissions.
     * @param groupName The name of the group that should be imported.
	 */
	importGroup(groupName: string, queryParams: ImportGroupQueryParams): QueryOperation<Array<string>>

	/**
	 * Import user
	 *
	 * Imports one user from a remote server. Returns the name of the imported user. The API requires the user to have Create Users permissions.
     * @param userName The name of the user that should be imported.
	 */
	importUser(userName: string, queryParams: ImportUserQueryParams): QueryOperation<string>

	/**
	 * Availability of merge requests
	 *
	 * Returns true if merge requests are configured for the project The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	isMergeRequestsEnabled(project: string): QueryOperation<boolean>

	/**
	 * Reports if snapshot backups are supported
	 *
	 * Returns if snapshot backups are supported by the storage system. The API requires the user to have Access Administrative Services permissions.
	 */
	isSnapshotBackupSupported(): QueryOperation<boolean>

	/**
	 * Availability of spec-item analysis
	 *
	 * Determines whether spec-item analysis is enabled for the project The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	isSpecItemAnalysisEnabled(project: string): QueryOperation<boolean>

	/**
	 * List parse log of project
	 *
	 * Lists all parse log entries for the project. The API requires the user to have View System Status permissions.
     * @param project The project ID.
	 */
	listAllParseLogEntries(project: string, queryParams: ListAllParseLogEntriesQueryParams): QueryOperation<Array<ParseLogEntry>>

	/**
	 * List of merge requests, total number and existing status.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	listMergeRequests(project: string, queryParams: ListMergeRequestsQueryParams): QueryOperation<ExtendedMergeRequestsInfo>

	/**
	 * Get quality reports
	 *
	 * Returns all stored reports details, without slide contents. No permissions needed, as the service will only return reports visible to current user.
	 */
	listQualityReports(): QueryOperation<Array<UserResolvedQualityReport>>

	/**
	 * Load the list of tests of this job from a named test list.
	 *
	 * null The API requires the user to have Perform Test Ranking permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	loadFromNamedTestList(project: string, jobId: string, queryParams: LoadFromNamedTestListQueryParams): QueryOperation<number>

	/**
	 * Mark/Unmark a dashboard as user favorite
	 *
	 * Performs an operation on the dashboard favorite list of the current user, whether to add/remove a dashboard to/from the list. The API requires no permissions
     * @param dashboardId Contains the name of the dashboard
	 */
	markDashboardAsFavorite(dashboardId: string, queryParams: MarkDashboardAsFavoriteQueryParams): QueryOperation<void>

	/**
	 * Performs the login of a user.
	 *
	 * Authenticates the user based on given credentials and returns a session cookie in case of success. The API requires no login
	 */
	performLogin(body: Credentials): QueryOperation<void>

	/**
	 * Perform test query
	 *
	 * Retrieves the size and list of tests for the query. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	performTestQuery(project: string, queryParams: PerformTestQueryQueryParams): QueryOperation<TestQueryResult>

	/**
	 * Retrieve commit alerts
	 *
	 * Provides access to the alerts for the given commits. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	postCommitAlerts(project: string, body: PostCommitAlertsBody): QueryOperation<Array<CommitAlerts | null>>

	/**
	 * This service provides access to the finding churn count. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	postFindingChurnCount(project: string, body: PostFindingChurnCountBody): QueryOperation<Array<FindingChurnCount>>

	/**
	 * Sets the list of tests to minimize in the job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	postJobTests(project: string, jobId: string, body: Array<string>): QueryOperation<number>

	/**
	 * Get all repository log entries
	 *
	 * Returns all repository log entries for the commits given in the POST data.A commit is identified as branch1@timestamp1All timestamps are interpreted as milliseconds since 1970. If no commits are provided, all log entries are returned. Commits are separated by comma. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	postRepositoryLog(project: string, queryParams: PostRepositoryLogQueryParams, body: PostRepositoryLogBody): QueryOperation<Array<UserResolvedRepositoryLogEntry>>

	/**
	 * Resolve username or alias
	 *
	 * Retrieves the usernames for the supplied aliases, usernames, full names or e-mail addresses. For users which cannot be resolved, the supplied value is returned back. This API requires VIEW permission for every resolved user. If the permission is missing, the supplied value is returned back.
	 */
	postResolveUserAliases(body: PostResolveUserAliasesBody): QueryOperation<Array<string>>

	/**
	 * Get test gap percentage
	 *
	 * Calculates the TGA percentage for the given uniform path or the given issue ID. This service is public API since Teamscale 5.9.6. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	postTgaPercentage(project: string, queryParams: PostTgaPercentageQueryParams, body: PostTgaPercentageBody): QueryOperation<number>

	/**
	 * Posts user avatar image
	 *
	 * Uploads the image file given in the multipart called 'avatar' as the avatar of the user in the first path parameter. Users can change their own avatar only but admin users can change someone else's avatar
     * @param user 
	 */
	postUserAvatarData(user: string, body: PostUserAvatarDataBody): QueryOperation<void>

	/**
	 * Puts new crop dimensions for user avatar
	 *
	 * Tries to set new crop dimensions for the avatar of the user in the first path parameter. The API requires the user to have Edit permissions on the user.
     * @param user 
	 */
	putAvatarData(user: string, queryParams: PutAvatarDataQueryParams): QueryOperation<AvatarData>

	/**
	 * Append the list of impacted tests as tests to minimize to the job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	putJobTestsFromImpacted(project: string, jobId: string, queryParams: PutJobTestsFromImpactedQueryParams): QueryOperation<number>

	/**
	 * Append the list of tests to minimize to the job based on a query.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	putJobTestsFromQuery(project: string, jobId: string, queryParams: PutJobTestsFromQueryQueryParams): QueryOperation<number>

	/**
	 * Put quality report
	 *
	 * Updates/Renames an existing quality report. The API requires the user to have Edit permissions on the Quality Report id.
     * @param id The id of the report that gets an update.
	 */
	putQualityReport(id: string, body: QualityReport): QueryOperation<void>

	/**
	 * Redirect to the example hosting host
	 *
	 * Redirects to /example on the instance, which hosts the actual example. The API requires no permissions
	 */
	redirectToCorrectHost(queryParams: RedirectToCorrectHostQueryParams): QueryOperation<void>

	/**
	 * Generate Service Provider certificate
	 *
	 * Generates a new certificate for the service provider stored at the named option. The API requires the user to have Access Administrative Services permissions.
	 */
	regenerateStoredServiceProviderCertificate(queryParams: RegenerateStoredServiceProviderCertificateQueryParams): QueryOperation<boolean>

	/**
	 * Revoke Access Key
	 *
	 * Revokes the user's access key. The API requires the user to have Edit permissions on the user.
     * @param user 
	 */
	removeAccessKey(user: string): QueryOperation<void>

	/**
	 * Request a SonarLint analysis
	 *
	 * Opens and schedules a new SonarLint analysis session, returning the session key. The API requires the user to have Create Projects permissions.
	 */
	requestAnalysisAndOpenSession(queryParams: RequestAnalysisAndOpenSessionQueryParams, body: SonarLintIndependentAnalysisRequestParameters): QueryOperation<string>

	/**
	 * Get the target commit of a git tag
	 *
	 * Resolves the given git tag to the tagged commit. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	resolveTag(project: string, body: GitTag): QueryOperation<CommitDescriptor | null>

	/**
	 * Run database compaction
	 *
	 * Allows running a full compaction on the database. WARNING: This is likely a very expensive operation. The API requires the user to have Access Administrative Services permissions.
	 */
	runDatabaseCompaction(): QueryOperation<void>

	/**
	 * Saves the list of tests created along this job as a named test list.
	 *
	 * null The API requires the user to have Perform Test Ranking permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	saveAsNamedTestList(project: string, jobId: string, body: string): QueryOperation<number>

	/**
	 * Save a given test list
	 *
	 * Creates a test list descriptor in the system. The API requires the user to have Edit Test Queries permissions on the project.
     * @param project The project ID.
	 */
	saveTestListDescriptor(project: string, body: TestListDescriptor): QueryOperation<void>

	/**
	 * Set project notification rules
	 *
	 * Edits the currently set project notification rules The API requires the user to have Edit Global Notification Settings permissions.
	 */
	setProjectNotificationRules(body: ProjectNotificationRules): QueryOperation<void>

	/**
	 * Request the computation of a minimized test set.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	setupJob(project: string, queryParams: SetupJobQueryParams): QueryOperation<string>

	/**
	 * Default Admin Enabled
	 *
	 * Checks whether Teamscale has the default admin user enabled when it shouldn't. The API requires no permissions
	 */
	showDefaultAdminWarning(): QueryOperation<boolean>

	/**
	 * Default Backup Settings
	 *
	 * Checks whether Teamscale has default backup settings enabled, which were not yet acknowledged by an admin user.  The API requires no permissions
	 */
	showDefaultBackupSettingsWarning(): QueryOperation<boolean>

	/**
	 * In Memory Database
	 *
	 * Checks whether Teamscale operates on an in memory database when it shouldn't. The API requires no permissions
	 */
	showInMemoryWarning(): QueryOperation<boolean>

	/**
	 * Shadow Mode
	 *
	 * Checks whether Teamscale has the shadow mode enabled. The API requires no permissions
	 */
	showShadowModeWarning(): QueryOperation<boolean>

	/**
	 * Shutdown Teamscale
	 *
	 * Allows to shut down a running Teamscale instance The API requires the user to have Access Administrative Services permissions.
	 */
	shutdown(body: ShutdownBody): QueryOperation<void>

	/**
	 * Starts the given test minimization job.
	 *
	 * null The API requires the user to have Perform Test Ranking permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	startJob(project: string, jobId: string): QueryOperation<void>

	/**
	 * Stops and deletes the given test minimization job and its results.
	 *
	 * null The API requires the user to have Perform Test Ranking permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	stopAndDeleteJob(project: string, jobId: string): QueryOperation<void>

	/**
	 * Store event announcement
	 *
	 * Stores the given event announcement. The API requires the user to have Access Administrative Services permissions.
     * @param eventId 
	 */
	storeEventAnnouncement(eventId: string, body: EventAnnouncement): QueryOperation<void>

	/**
	 * Starts a snapshot backup.
	 *
	 * Schedules a snapshot backup to the given target URI. The API requires the user to have Access Administrative Services permissions.
	 */
	triggerSnapshotBackup(body: TriggerSnapshotBackupBody): QueryOperation<void>

	/**
	 * Unmark tolerated/false positive findings before given commit.
	 *
	 * Unmark all tolerated/false positive findings that were marked before the given commit. Also returns the ids of the unmarked findings. The API requires the user to have View Project, Flag Red Findings, Flag Yellow Findings permissions on the project.
     * @param project The project ID.
	 */
	unmarkToleratedAndFalsePositiveFindingsBeforeCommit(project: string, queryParams: UnmarkToleratedAndFalsePositiveFindingsBeforeCommitQueryParams): QueryOperation<Array<string>>

	/**
	 * Update analysis profile
	 *
	 * Updates the analysis profile identified by the given name with the value in the request body. Requires the edit permission on the respective analysis profile.
	 */
	updateAnalysisProfile(queryParams: UpdateAnalysisProfileQueryParams, body: AnalysisProfile): QueryOperation<void>

	/**
	 * Update dashboard template
	 *
	 * Updates the given dashboard template The API requires the user to have Edit permissions on the Dashboard Templates templateId.
     * @param templateId ID of the template to update
	 */
	updateDashboardTemplate(templateId: string, body: DashboardTemplateDescriptor): QueryOperation<void>

	/**
	 * Update external accounts
	 *
	 * Updates the external accounts identified by the given name with the value in the request body. The API requires the user to have Edit permissions on the External Accounts externalCredentialsName.
     * @param externalCredentialsName Name of the old external accounts.
	 */
	updateExternalAccounts(externalCredentialsName: string, queryParams: UpdateExternalAccountsQueryParams, body: ExternalCredentialsData): QueryOperation<void>

	/**
	 * Update external analysis group
	 *
	 * Updates the external analysis group identified by the given name. The API requires the user to have Edit External Findings Schema permissions.
     * @param groupName Name of the external analysis group to remove
	 */
	updateExternalAnalysisGroup(groupName: string, body: ExternalAnalysisGroup): QueryOperation<void>

	/**
	 * Update external finding description
	 *
	 * Update the external finding description identified by the given name. The API requires the user to have Edit External Findings Schema permissions.
     * @param externalFindingDescriptionTypeId ID of the finding description to update
	 */
	updateExternalFindingDescription(externalFindingDescriptionTypeId: string, body: ExternalFindingsDescription): QueryOperation<void>

	/**
	 * Update external storage backends
	 *
	 * Updates the external storage backend identified by the given name with the value in the request body. The API requires the user to have Edit permissions on the External Storage Backends externalStorageBackendName.
     * @param externalStorageBackendName Name of the external storage backend which should be updated.
	 */
	updateExternalStorageBackend(externalStorageBackendName: string, body: ExternalStorageBackendData): QueryOperation<void>

	/**
	 * Updates the minimization options for the given job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	updateJobOptions(project: string, jobId: string, queryParams: UpdateJobOptionsQueryParams): QueryOperation<void>

	/**
	 * Update metric threshold configuration
	 *
	 * Updates the metric threshold configuration identified by the given name with the value in the request body. Requires the edit permission for the respective metric threshold configuration.
	 */
	updateMetricThresholdConfiguration(body: MetricThresholdConfiguration): QueryOperation<void>

	/**
	 * Update user group
	 *
	 * Updates the user group identified by the given name with the value in the request body. The API requires the user to have Edit permissions on the Group oldUserGroupName.
     * @param oldUserGroupName Old name of the user group to change
	 */
	updateUserGroup(oldUserGroupName: string, body: UserGroup): QueryOperation<void>

	/**
	 * Upload external report(s)
	 *
	 * Adds external reports to the session. For performance reasons, it is recommended to batch calls to this service, i.e. not commit all files using single calls. This service is public API since Teamscale 5.9. The API requires the user to have Perform External Uploads permissions on the project.
     * @param project The project ID.
     * @param sessionId If session ID is provided, the results will be appended to the given session instead of creating a new session. Use \&quot;auto-create\&quot; in place of session ID to create a new session, perform upload and commit session in one step.
	 */
	uploadReport(project: string, sessionId: string, queryParams: UploadReportQueryParams, body: UploadReportBody): QueryOperation<void>

	/**
	 * Validates a branching configuration
	 *
	 * Validates a given branching configuration. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	validateBranchingConfiguration(body: ProjectBranchingConfiguration): QueryOperation<string | null>

	/**
	 * Verifies that there is a PowerShellScriptAnalyzer installed on the server, has the required  version, and can be called by Teamscale.
	 *
	 * Verifies the PowerShellScriptAnalyzer installation on the server. The API requires the user to have Create Analysis Profiles permissions.
	 */
	verifyPowershellScriptAnalyzerSetup(): QueryOperation<string>

}

/** Type definition of the MUTATIONS interface. */
export type Mutations = {

	/**
	 * Get autocompletion suggestions
	 *
	 * Gets autocompletion suggestions based on the input The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	autocompleteIssueId: MutationOperation<{ pathParams: { project: string }, queryParams: AutocompleteIssueIdQueryParams }, Array<string>>

	/**
	 * Autocomplete user group name
	 *
	 * null The API requires no permissions
	 */
	autocompleteUserGroup: MutationOperation<{ queryParams: AutocompleteUserGroupQueryParams }, Array<string>>

	/**
	 * Get user autocompletion suggestions
	 *
	 * Gets autocompletion suggestions based on the input The API requires no permissions
	 */
	autocompleteUserName: MutationOperation<{ queryParams: AutocompleteUserNameQueryParams }, Array<string>>

	/**
	 * Returns user activity statistics.
	 *
	 * Returns the number of users who have been active during different time durations. The API requires the user to have View System Status permissions.
	 */
	calculateActiveUsersStatistics: MutationOperation<void, Array<UserActivity>>

	/**
	 * Returns committer activity statistics.
	 *
	 * Returns the number of committers who have been contributing during different time durations. The API requires the user to have Access Administrative Services permissions.
	 */
	calculateCommitterStatistics: MutationOperation<void, Array<UserActivity>>

	/**
	 * Commit session
	 *
	 * Commits and closes the given session. This service is public API since Teamscale 5.9. The API requires the user to have Perform External Uploads permissions on the project.
	 * @param project The project ID.
	 * @param sessionId If session ID is provided, the results will be appended to the given session instead of creating a new session. Use \&quot;auto-create\&quot; in place of session ID to create a new session, perform upload and commit session in one step.
	 */
	commitAnalysisResults: MutationOperation<{ pathParams: { project: string, sessionId: string } }, void>

	/**
	 * Get metric table
	 *
	 * Retrieves a list of metric table entries for the given uniform path and its children. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	computeMetricTable: MutationOperation<{ pathParams: { project: string }, queryParams: ComputeMetricTableQueryParams }, MetricTablePage>

	/**
	 * Create analysis profile
	 *
	 * Creates a new analysis profile. The API requires the user to have Create Analysis Profiles permissions.
	 */
	createAnalysisProfile: MutationOperation<{ body: AnalysisProfile }, void>

	/**
	 * Export backup.
	 *
	 * Triggers the creation of a backup and returns its ID. This service is public API since Teamscale 6.1. The user needs to have the permission to export global data provided the backup contains any and the permission to backup project data for all projects contained in the backup.
	 */
	createBackup: MutationOperation<{ body: CreateBackupBody }, string>

	/**
	 * Trigger comparison computation
	 *
	 * Triggers computation of the comparison between two snapshots and returns the ID of the new comparison. One of the snapshots is stored locally, the other fetched from a remote instance. The API requires the user to have Access Administrative Services permissions.
	 * @param snapshotId The ID of the local snapshot.
	 */
	createComparison: MutationOperation<{ pathParams: { snapshotId: string }, body: CreateComparisonBody }, string>

	/**
	 * Create dashboard
	 *
	 * Creates a new dashboard. The API requires the user to have Create Dashboards permissions.
	 */
	createDashboard: MutationOperation<{ body: DashboardDescriptor }, string>

	/**
	 * Create dashboard from template
	 *
	 * Creates a dashboard from a template. Returns the ID of the created dashboard. The API requires the user to have Create Dashboards permissions.
	 */
	createDashboardFromTemplate: MutationOperation<{ queryParams: CreateDashboardFromTemplateQueryParams }, string>

	/**
	 * Create dashboard template
	 *
	 * Creates a new dashboard template The API requires the user to have Create Dashboard Templates permissions.
	 */
	createDashboardTemplate: MutationOperation<{ body: DashboardTemplateDescriptor }, string>

	/**
	 * Create external accounts
	 *
	 * Creates new external accounts. The API requires the user to have Create External Accounts permissions.
	 */
	createExternalAccounts: MutationOperation<{ body: ExternalCredentialsData }, void>

	/**
	 * Create external analysis group
	 *
	 * Creates a new external analysis group. This service is public API since Teamscale 6.0. The API requires the user to have Edit External Findings Schema permissions.
	 */
	createExternalAnalysisGroup: MutationOperation<{ body: ExternalAnalysisGroup }, void>

	/**
	 * Create external finding description
	 *
	 * Create a new external finding description in the system. This service is public API since Teamscale 6.0. The API requires the user to have Edit External Findings Schema permissions.
	 */
	createExternalFindingDescription: MutationOperation<{ body: ExternalFindingsDescription }, void>

	/**
	 * Create external storage backend
	 *
	 * Creates new external storage backend. The API requires the user to have Configure External Storage Backends permissions.
	 */
	createExternalStorageBackend: MutationOperation<{ body: ExternalStorageBackendData }, void>

	/**
	 * Create metric threshold configuration
	 *
	 * Creates a new metric threshold configuration. The API requires the user to have Create Metric Threshold Configurations permissions.
	 */
	createMetricThresholdConfiguration: MutationOperation<{ body: MetricThresholdConfiguration }, void>

	/**
	 * Generate Access Key
	 *
	 * Generates a new access key and revokes any already existing access keys. The API requires the user to have Edit permissions on the user.
	 * @param user 
	 */
	createNewRandomAccessKey: MutationOperation<{ pathParams: { user: string } }, string>

	/**
	 * Update baseline
	 *
	 * Creates a new or updates an existing baseline. This service is public API since Teamscale 5.2. The API requires the user to have Edit Baselines permissions on the project.
	 * @param project The project ID.
	 * @param baseline 
	 */
	createOrUpdateBaseline: MutationOperation<{ pathParams: { project: string, baseline: string }, queryParams: CreateOrUpdateBaselineQueryParams, body: BaselineInfo }, void>

	/**
	 * Create project
	 *
	 * Creates project based on provided project configuration. This service is public API since Teamscale 7.1. The API requires the user to have Create Projects permissions.
	 */
	createProject: MutationOperation<{ queryParams: CreateProjectQueryParams, body: ProjectConfiguration }, void>

	/**
	 * Create quality report
	 *
	 * Creates a new quality report either from scratch or by copying/overwriting an existing report. The API requires the user to have Create Quality Reports permissions.
	 */
	createQualityReport: MutationOperation<{ queryParams: CreateQualityReportQueryParams, body: QualityReport }, string>

	/**
	 * Get session ID
	 *
	 * Obtains a new session ID. Using session ID allows to perform external uploads in multiple calls that all belong to the same session. This service is public API since Teamscale 5.9. The API requires the user to have Perform External Uploads permissions on the project.
	 * @param project The project ID.
	 */
	createSession: MutationOperation<{ pathParams: { project: string }, queryParams: CreateSessionQueryParams }, string>

	/**
	 * Create snapshot
	 *
	 * Triggers creation of a snapshot of this instance and returns the ID of the created snapshot. Additionally, a remote snapshot with the same parameters can be created and a comparison between the two scheduled. The API requires the user to have Access Administrative Services permissions.
	 */
	createSnapshot: MutationOperation<{ body: CreateSnapshotBody }, string>

	/**
	 * Create user group
	 *
	 * Creates new user group. The API requires the user to have Create Groups permissions.
	 */
	createUserGroup: MutationOperation<{ body: UserGroup }, void>

	/**
	 * Delete all unprocessed architecture uploads
	 *
	 * Deletes all commits of the architecture identified by the provided architecture path. The API requires the user to have Edit Architectures permissions on the project.
	 * @param project 
	 */
	deleteAllUnprocessedArchitectureCommits: MutationOperation<{ pathParams: { project: string }, queryParams: DeleteAllUnprocessedArchitectureCommitsQueryParams }, void>

	/**
	 * Delete analysis profile
	 *
	 * Deletes the analysis profile identified by the given name from the system The API requires the user to have Delete permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName Name of the analysis profile to delete
	 */
	deleteAnalysisProfile: MutationOperation<{ pathParams: { analysisProfileName: string } }, void>

	/**
	 * Delete session
	 *
	 * Deletes a session in case of abortion. This service is public API since Teamscale 5.9. The API requires the user to have Perform External Uploads permissions on the project.
	 * @param project The project ID.
	 * @param sessionId If session ID is provided, the results will be appended to the given session instead of creating a new session. Use \&quot;auto-create\&quot; in place of session ID to create a new session, perform upload and commit session in one step.
	 */
	deleteAnalysisResults: MutationOperation<{ pathParams: { project: string, sessionId: string } }, void>

	/**
	 * Delete baseline
	 *
	 * Deletes a baseline. This service is public API since Teamscale 5.2. The API requires the user to have Edit Baselines permissions on the project.
	 * @param project The project ID.
	 * @param baseline 
	 */
	deleteBaseline: MutationOperation<{ pathParams: { project: string, baseline: string } }, void>

	/**
	 * Delete comparison
	 *
	 * Permanently deletes the given comparison. The API requires the user to have Access Administrative Services permissions.
	 * @param snapshotId The ID of the local snapshot the comparison belongs to.
	 * @param comparisonId The ID of the comparison to delete.
	 */
	deleteComparison: MutationOperation<{ pathParams: { snapshotId: string, comparisonId: string } }, void>

	/**
	 * Remove dashboard
	 *
	 * Removes the dashboard descriptor identified by given ID. The API requires the user to have Edit permissions on the Dashboard dashboardId.
	 * @param dashboardId ID of the dashboard to retrieve.
	 */
	deleteDashboard: MutationOperation<{ pathParams: { dashboardId: string } }, void>

	/**
	 * Delete dashboard template
	 *
	 * Deletes the dashboard template identified by the given ID. The API requires the user to have Edit permissions on the Dashboard Templates templateId.
	 * @param templateId ID of the template to delete
	 */
	deleteDashboardTemplate: MutationOperation<{ pathParams: { templateId: string } }, void>

	/**
	 * Delete event announcement
	 *
	 * Deletes the given announcement so it will not be displayed to anyone anymore. The API requires the user to have Access Administrative Services permissions.
	 * @param eventId 
	 */
	deleteEventAnnouncement: MutationOperation<{ pathParams: { eventId: string } }, void>

	/**
	 * Delete external accounts
	 *
	 * Deletes the external accounts identified by the given name from the system The API requires the user to have Delete permissions on the External Accounts externalCredentialsName.
	 * @param externalCredentialsName Name of the external accounts to delete
	 */
	deleteExternalAccounts: MutationOperation<{ pathParams: { externalCredentialsName: string }, queryParams: DeleteExternalAccountsQueryParams }, void>

	/**
	 * Remove external analysis group
	 *
	 * Removes the external analysis group identified by the given name. The API requires the user to have Edit External Findings Schema permissions.
	 * @param groupName Name of the external analysis group to remove
	 */
	deleteExternalAnalysisGroup: MutationOperation<{ pathParams: { groupName: string } }, void>

	/**
	 * Remove external finding description
	 *
	 * Removes the external finding description identified by the given name The API requires the user to have Edit External Findings Schema permissions.
	 * @param externalFindingDescriptionName Name of the finding description to remove
	 */
	deleteExternalFindingDescription: MutationOperation<{ pathParams: { externalFindingDescriptionName: string } }, void>

	/**
	 * Delete external storage backends
	 *
	 * Deletes the external storage backends identified by the given name from the system The API requires the user to have Delete permissions on the External Storage Backends externalStorageBackendName.
	 * @param externalStorageBackendName Name of the external storage backend to delete
	 */
	deleteExternalStorageBackend: MutationOperation<{ pathParams: { externalStorageBackendName: string }, queryParams: DeleteExternalStorageBackendQueryParams }, void>

	/**
	 * Remove the given list of tests (identified by their current ranking) from the list of tests to minimize in the job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	deleteJobTests: MutationOperation<{ pathParams: { project: string, jobId: string }, queryParams: DeleteJobTestsQueryParams, body: Array<number> }, number>

	/**
	 * Delete metric threshold configuration
	 *
	 * Deletes the metric threshold configuration identified by the given name from the system The API requires the user to have Delete permissions on the Metric Threshold Configuration metricThresholdConfigurationName.
	 * @param metricThresholdConfigurationName Name of the metric threshold configuration to delete
	 */
	deleteMetricThresholdConfiguration: MutationOperation<{ pathParams: { metricThresholdConfigurationName: string } }, void>

	/**
	 * Delete option
	 *
	 * Delete the project option identified by the given name. The API requires the user to have Edit Project Options permissions on the project.
	 * @param project The project ID.
	 * @param optionId Name of the option to delete
	 */
	deleteProjectOption: MutationOperation<{ pathParams: { project: string, optionId: string } }, void>

	/**
	 * Delete option
	 *
	 * Deletes the specified server option The API requires the user to have Edit Server Options permissions.
	 * @param optionId Name of the option to delete
	 */
	deleteProjectOption1: MutationOperation<{ pathParams: { optionId: string } }, void>

	/**
	 * Delete report
	 *
	 * Deletes the report with the given id. The API requires the user to have Delete permissions on the Quality Report id.
	 * @param id The report id
	 */
	deleteQualityReport: MutationOperation<{ pathParams: { id: string } }, void>

	/**
	 * Delete snapshot
	 *
	 * Permanently deletes the given snapshot and all associated comparisons. The API requires the user to have Access Administrative Services permissions.
	 * @param id The ID of the snapshot to delete.
	 */
	deleteSnapshot: MutationOperation<{ pathParams: { id: string } }, void>

	/**
	 * Delete test list
	 *
	 * Deletes a test list descriptor from the system. The API requires the user to have Edit Test Queries permissions on the project.
	 * @param project The project ID.
	 * @param testListName Name of the test list to delete
	 */
	deleteTestListDescriptor: MutationOperation<{ pathParams: { project: string, testListName: string } }, void>

	/**
	 * Delete user group
	 *
	 * Deletes the user group identified by the given name from the system The API requires the user to have Delete permissions on the Group userGroupName.
	 * @param userGroupName Name of the user group to delete
	 */
	deleteUserGroup: MutationOperation<{ pathParams: { userGroupName: string } }, void>

	/**
	 * Delete option
	 *
	 * Deletes the user option identified by the given name The API requires the user to have Edit permissions on the user.
	 * @param user 
	 * @param optionId 
	 */
	deleteUserOption: MutationOperation<{ pathParams: { user: string, optionId: string } }, void>

	/**
	 * Get analysis profile documentation
	 *
	 * Returns documentation of an analysis profile. The documentation is restricted to findings which are enabled in the profile and includes descriptions of the findings. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	downloadAnalysisProfileDocumentation: MutationOperation<{ pathParams: { analysisProfileName: string } }, void>

	/**
	 * Download heap dump
	 *
	 * Downloads a heap dump in HProf format. The API requires a HotSpot-based JVM. The API requires the user to have Access Administrative Services permissions.
	 */
	downloadHeapDump: MutationOperation<{ body: DownloadHeapDumpBody }, void>

	/**
	 * Returns the (possibly ranked) list of tests that have been selected for this job as CSV.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	downloadTestSelectionCsv: MutationOperation<{ pathParams: { project: string, jobId: string }, queryParams: DownloadTestSelectionCsvQueryParams }, File>

	/**
	 * Update dashboard
	 *
	 * Updates an existing dashboard. The API requires the user to have Edit permissions on the Dashboard dashboardId.
	 * @param dashboardId The ID of the dashboard to update.
	 */
	editDashboard: MutationOperation<{ pathParams: { dashboardId: string }, body: DashboardDescriptor }, void>

	/**
	 * Edit option
	 *
	 * Edits the project option identified by the given name. The API requires the user to have Edit Project Options permissions on the project.
	 * @param project The project ID.
	 * @param optionId Name of the option to edit
	 */
	editProjectOption: MutationOperation<{ pathParams: { project: string, optionId: string }, body: Array<string> }, void>

	/**
	 * Edit options
	 *
	 * Edits the specified server option The API requires the user to have Edit Server Options permissions.
	 * @param optionId Name of the option to edit
	 */
	editServerOption: MutationOperation<{ pathParams: { optionId: string }, queryParams: EditServerOptionQueryParams, body: Array<string> }, void>

	/**
	 * Edit option
	 *
	 * Edits the user option identified by the given name The API requires the user to have Edit permissions on the user.
	 * @param user Name of the user to edit the option for
	 * @param optionId Name of the option to edit
	 */
	editUserOption: MutationOperation<{ pathParams: { user: string, optionId: string }, body: object }, void>

	/**
	 * Edit users
	 *
	 * Performs given user operation. This service is public API since Teamscale 5.5.1. Needs delete permission.
	 */
	editUsers: MutationOperation<{ body: UserBatchOperation }, string>

	/**
	 * Execute scheduler command
	 *
	 * Allows to pass a command to the scheduler. The command is appended to the URL and should be one of the values from ESchedulerCommand. Note that using this service may harm the stability and data integrity of your instance. The API requires the user to have Access Administrative Services permissions.
	 */
	executeSchedulerCommand: MutationOperation<{ body: ExecuteSchedulerCommandBody }, void>

	/**
	 * Exports the analysis profile
	 *
	 * Retrieves the analysis profile identified by given name. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	exportAnalysisProfile: MutationOperation<{ pathParams: { analysisProfileName: string } }, void>

	/**
	 * Get a dashboard file
	 *
	 * Performs an export of a dashboard. Returns the descriptor of the dashboard as a .tsdashboard file. The dashboard must be submitted within an XML Teamscale Version Container. The API requires the user to have View permissions on the Dashboard dashboardId.
	 * @param dashboardId The ID of the dashboard
	 */
	exportDashboard: MutationOperation<{ pathParams: { dashboardId: string } }, TeamscaleVersionContainer>

	/**
	 * Get a dashboard template
	 *
	 * Performs an export of a dashboard template. Returns the template as a .tstemplate file. The API requires no permissions
	 * @param templateId ID of the dashboard template
	 */
	exportDashboardTemplate: MutationOperation<{ pathParams: { templateId: string } }, TeamscaleVersionContainer>

	/**
	 * Check if external accounts exist
	 *
	 * Checks if the external accounts identified by the given name exist. No permissions needed, as the service should check whether the credentials already exists for every user to prohibit overwriting credentials not visible for the current user
	 * @param externalCredentialsName Name of the external accounts to check
	 */
	externalAccountsExist: MutationOperation<{ pathParams: { externalCredentialsName: string } }, ExternalCredentialsData>

	/**
	 * Check if external storage backends exist
	 *
	 * Checks if the external storage backend identified by the given name exist. No permissions needed, as the service checks whether the backend already exists for every user to prohibit overwriting backends not visible for the current user
	 * @param externalStorageBackendName Name of the external storage backend to check
	 */
	externalStorageBackendExists: MutationOperation<{ pathParams: { externalStorageBackendName: string } }, ExternalStorageBackendData>

	/**
	 * Get job queue
	 *
	 * Retrieves the entire job queue from all projects. The API requires the user to have View System Status permissions.
	 */
	extractJobQueue: MutationOperation<void, Array<JobDescriptor>>

	/**
	 * Returns the initial commit which added the file at the given path. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param unresolvedCommit This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	findInitialCommit: MutationOperation<{ pathParams: { project: string, unresolvedCommit: UnresolvedCommitDescriptor }, queryParams: FindInitialCommitQueryParams }, CommitDescriptor | null>

	/**
	 * Get repository log entries
	 *
	 * Retrieves a specific count of repository log entries after a start timestamp or before an end timestamp. If both start and end timestamp are missing, the newest log entries are returned. If both a start and end timestamp are provided all log entries between the two are returned. The resulting log entries include all log entries from merged commits into the current branch. The current branch and branch state at a point in time are determined by the commit parameter. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	findLogEntriesInRange: MutationOperation<{ pathParams: { project: string }, queryParams: FindLogEntriesInRangeQueryParams }, Array<UserResolvedRepositoryLogEntry>>

	/**
	 * Flags/unflags findings
	 *
	 * Flags/unflags the given findings with the given flagging type. This service is public API since Teamscale 8.5. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	flagFindings: MutationOperation<{ pathParams: { project: string }, queryParams: FlagFindingsQueryParams, body: FindingBlacklistRequestBody }, void>

	/**
	 * Get Access Key
	 *
	 * Get the user's access key or an empty string if no access key exists yet. The API requires the user to have Edit permissions on the user.
	 * @param user 
	 */
	getAccessKey: MutationOperation<{ pathParams: { user: string } }, string>

	/**
	 * Returns active users (anonymized) for the given primary project IDs
	 *
	 * For the given primary project IDs, returns all users (anonymized) of the last 3 months that contribute to the license volume. If an empty list is provided, all current projects will be included. The results might differ from the GET query in case the primary project id of a project changed in the last three months or if a user has logged into Teamscale but has not interacted with any project. The API requires the user to have Access Administrative Services permissions.
	 */
	getActiveUsersForProjects: MutationOperation<{ body: Array<string> }, Array<string>>

	/**
	 * Returns a list of sets of additionally covered methods. 
	 *
	 * The set on position i denotes that executing the test corresponding to position i covers additionally (relative to the methods covered by tests 0 to i-1) the methods described in the set. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	getAdditionallyCoveredMethods: MutationOperation<{ pathParams: { project: string, jobId: string } }, Array<Array<MethodLocation>>>

	/**
	 * Get aggregated trigger performance metrics
	 *
	 * Allows downloading the performance aggregate data report as CSV. The API requires the user to have Access Administrative Services permissions.
	 */
	getAggregatedTriggerPerformanceMetrics: MutationOperation<void, void>

	/**
	 * Get all dashboard templates
	 *
	 * Retrieves all the dashboard templates available in the system. Any user may see dashboard templates.
	 */
	getAllDashboardTemplates: MutationOperation<void, Array<DashboardTemplateDescriptor>>

	/**
	 * Get all dashboard descriptors
	 *
	 * Retrieves all the dashboard descriptors available in the system No permissions needed, as the service will only return the dashboard descriptors visible to current user.
	 */
	getAllDashboards: MutationOperation<{ queryParams: GetAllDashboardsQueryParams }, Array<UserResolvedDashboardDescriptor>>

	/**
	 * Get all external accounts
	 *
	 * Retrieves all available external accounts in the system. No permissions needed, as the service will only return credentials visible to current user.
	 */
	getAllExternalAccounts: MutationOperation<void, Array<ExternalCredentialsData>>

	/**
	 * Get external analysis groups
	 *
	 * Retrieves all the available analysis groups in the system. The API requires the user to have Edit External Findings Schema permissions.
	 */
	getAllExternalAnalysisGroups: MutationOperation<void, Array<ExternalAnalysisGroup>>

	/**
	 * Get all external storage backends
	 *
	 * Retrieves all available external storage backends in the system. No permissions needed, as the service will only return backends visible to current user.
	 */
	getAllExternalStorageBackends: MutationOperation<void, Array<ExternalStorageBackendData>>

	/**
	 * Get external finding descriptions
	 *
	 * Retrieves all external finding descriptions available in the system The API requires the user to have Edit External Findings Schema permissions.
	 */
	getAllFindingsDescriptions: MutationOperation<void, Array<ExternalFindingsDescription>>

	/**
	 * Get all git tags
	 *
	 * Collects all git tags from all git connections in the given project. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	getAllGitTags: MutationOperation<{ pathParams: { project: string }, queryParams: GetAllGitTagsQueryParams }, Array<GitTag>>

	/**
	 * Get all metric threshold configuration names
	 *
	 * Retrieves the names of all available metric threshold configurations in the system. No permissions needed, as the service will only return the names of metric threshold configurations visible to the current user.
	 */
	getAllMetricThresholdConfigurationNames: MutationOperation<{ queryParams: GetAllMetricThresholdConfigurationNamesQueryParams }, Array<string>>

	/**
	 * Get all metric threshold configurations
	 *
	 * Retrieves all available metric threshold configurations in the system. No permissions needed, as the service will only return metric threshold configurations visible to the current user.
	 */
	getAllMetricThresholdConfigurations: MutationOperation<void, Array<MetricThresholdConfiguration>>

	/**
	 * Get all metrics for metric threshold configuration names
	 *
	 * Retrieves a list, the entries being the metric threshold configuration names and lists of the metrics included in the profile with that name. No permissions needed, as the service will only return the metrics and their profiles visible to the current user.
	 */
	getAllMetricsForThresholdProfiles: MutationOperation<{ queryParams: GetAllMetricsForThresholdProfilesQueryParams }, Array<MetricsForThresholdProfile>>

	/**
	 * Get parse log overview
	 *
	 * Returns overview information about the parse log entries in all visible projects The API requires the user to have View System Status permissions.
	 */
	getAllParseLogOverviewEntries: MutationOperation<void, Array<ParseLogOverviewEntry>>

	/**
	 * Get details (name, last updated) for all test execution partitions
	 *
	 * Returns all available test execution partitions and their last updated timestamp; of the project that have been uploaded and processed up to the given commit. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getAllPartitionDetails: MutationOperation<{ pathParams: { project: string }, queryParams: GetAllPartitionDetailsQueryParams }, Array<PartitionInfo>>

	/**
	 * Get projects
	 *
	 * Returns a list of all projects. This service is public API since Teamscale 7.1. Only projects visible to the user are returned in the get all queries.
	 */
	getAllProjects: MutationOperation<{ queryParams: GetAllProjectsQueryParams }, Array<ProjectInfo>>

	/**
	 * Get snapshot IDs
	 *
	 * Returns the list of all available snapshot IDs.  The API requires the user to have Access Administrative Services permissions.
	 */
	getAllSnapshotIds: MutationOperation<{ queryParams: GetAllSnapshotIdsQueryParams }, Array<string>>

	/**
	 * Dumps the stack traces for all currently running threads as HTML. The API requires the user to have Access Administrative Services permissions.
	 */
	getAllThreadsDumpAsHtml: MutationOperation<void, string>

	/**
	 * Get all user group names
	 *
	 * Retrieves the names of all available user groups in the system. No permissions needed, as the service will only return group names visible to current user.
	 */
	getAllUserGroupNames: MutationOperation<void, Array<string>>

	/**
	 * Get all user groups
	 *
	 * Retrieves all available user groups in the system. No permissions needed, as the service will only return groups visible to current user.
	 */
	getAllUserGroups: MutationOperation<void, Array<UserGroup>>

	/**
	 * Get all users
	 *
	 * Returns a list of all users. This service is public API since Teamscale 5.2. Only users visible to the user are returned in the get all queries.
	 */
	getAllUsers: MutationOperation<void, Array<UserWithActivity>>

	/**
	 * Analysis profile version difference
	 *
	 * Calculates and return the difference between an older edit version and a newer version of an analysis profile. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	getAnalysisEditHistoryVersionDiff: MutationOperation<{ pathParams: { analysisProfileName: string }, queryParams: GetAnalysisEditHistoryVersionDiffQueryParams }, AnalysisProfileHistoryVersionComparison>

	/**
	 * Get analysis profile
	 *
	 * Retrieves the analysis profile identified by given name. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	getAnalysisProfile: MutationOperation<{ pathParams: { analysisProfileName: string } }, AnalysisProfile>

	/**
	 * Analysis Profile Edit History
	 *
	 * Returns the different versions of an analysis profile containing a version corresponding to each time the analysis profile was edited. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	getAnalysisProfileEditVersionHistory: MutationOperation<{ pathParams: { analysisProfileName: string } }, Array<AnalysisProfileEditVersion>>

	/**
	 * Analysis Profile Edit History Creation Times
	 *
	 * Returns a list of timestamps, each corresponding to the creation of a new profile edit history entry. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	getAnalysisProfileEditVersionHistoryTimestamps: MutationOperation<{ pathParams: { analysisProfileName: string } }, Array<number>>

	/**
	 * Get all analysis profile names
	 *
	 * Retrieves the names of all analysis profiles visible to the current user. No permissions needed, as the service will only return names of analysis profiles visible to current user.
	 */
	getAnalysisProfileNames: MutationOperation<void, Array<string>>

	/**
	 * Get analysis profile usage
	 *
	 * Returns a list of AnalysisProfileUsageInfoWithProjects indicating the list of referenced projects for each analysis profile No permissions needed, as the service will only return names of analysis profiles visible to current user.
	 */
	getAnalysisProfileUsageInfo: MutationOperation<void, Array<AnalysisProfileUsageInfoWithProjects>>

	/**
	 * Get analysis profile with referencing projects
	 *
	 * Returns an analysis profile annotated with the list of projects using the profile The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	getAnalysisProfileWithProjects: MutationOperation<{ pathParams: { analysisProfileName: string } }, AnalysisProfileWithProjects>

	/**
	 * Get SonarLint analysis results
	 *
	 * Gets the SonarLint analysis results. Results may be incomplete if the session is not in the completed status. The API requires the user to have Create Projects permissions.
	 */
	getAnalysisResultsByPath: MutationOperation<{ queryParams: GetAnalysisResultsByPathQueryParams }, Record<string, Array<SonarLintFindingTransport>>>

	/**
	 * Get timings of analysis state changes
	 *
	 * Allows downloading timing of analysis state changes as CSV. The API requires the user to have Access Administrative Services permissions.
	 */
	getAnalysisStateChangeTiming: MutationOperation<void, void>

	/**
	 * Get the status of a SonarLint Analysis session
	 *
	 * Gets the status of the given session. May return the 'unknown' status, if no session was found for the given key. The API requires the user to have Create Projects permissions.
	 */
	getAnalysisStatus: MutationOperation<{ queryParams: GetAnalysisStatusQueryParams }, ESonarLintIndependentAnalysisStatusEntry>

	/**
	 * Get associated comparison IDs
	 *
	 * Returns the IDs of the associated comparisons of a single snapshot identified by the given ID. The API requires the user to have Access Administrative Services permissions.
	 * @param id The ID of the snapshot.
	 */
	getAssociatedComparisonIds: MutationOperation<{ pathParams: { id: string } }, Array<string>>

	/**
	 * Get authentication servers
	 *
	 * List configured authentication servers. User must have the permission to edit or create groups or users to view authentication servers.
	 */
	getAvailableServers: MutationOperation<void, Record<string, Array<string>>>

	/**
	 * Get user avatar data
	 *
	 * Returns avatar data for the user in the first path parameter. The API requires the user to have View permissions on the user.
	 * @param user 
	 */
	getAvatarData: MutationOperation<{ pathParams: { user: string } }, AvatarData>

	/**
	 * Get the backup status
	 *
	 * Get the current backup import/export status This service is public API since Teamscale 6.1. The user needs to be able to configure projects. In addition the user needs to have the permission to backup global data provided the backup contains any global data and the permission to backup project data for all projects contained in the backup.
	 * @param backupId The backup ID.
	 */
	getBackupStatus: MutationOperation<{ pathParams: { backupId: string } }, BackupExportStatus>

	/**
	 * Get the backup status
	 *
	 * Get the current backup import/export status This service is public API since Teamscale 6.1. The user needs to be able to configure projects. In addition the user needs to have the permission to backup global data provided the backup contains any global data and the permission to backup project data for all projects contained in the backup.
	 * @param backupId The backup ID.
	 */
	getBackupStatus1: MutationOperation<{ pathParams: { backupId: string } }, BackupImportStatus>

	/**
	 * Get available sample projects
	 *
	 * Returns a list of sample projects that can be used as starting point. The API requires the user to have Backup Global Data permissions.
	 */
	getBackupsForImportWizard: MutationOperation<void, Array<Backup>>

	/**
	 * Get baseline
	 *
	 * Returns details on a configured baseline. This service is public API since Teamscale 5.2. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param baseline 
	 */
	getBaseline: MutationOperation<{ pathParams: { project: string, baseline: string } }, BaselineInfo>

	/**
	 * Get spec item branch
	 *
	 * Get the branch where the spec item with the provided id is analysed on The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param id ID of the spec item to fetch the branch for
	 */
	getBranchForSpecItem: MutationOperation<{ pathParams: { project: string, id: string }, queryParams: GetBranchForSpecItemQueryParams }, string>

	/**
	 * Get branches
	 *
	 * Returns the specified range of sorted branches (or all if no range specified), including/excluding deleted/anonymous branches or the sorted filtered branches based on the defined branchesFilter/regexFilter as BranchesInfo. This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getBranchesGetRequest: MutationOperation<{ pathParams: { project: string }, queryParams: GetBranchesGetRequestQueryParams }, BranchesInfo>

	/**
	 * Get branches
	 *
	 * Returns the specified range of sorted branches (or all if no range specified), including/excluding deleted/anonymous branches or the sorted filtered branches based on the defined branchesFilter/regexFilter as BranchesInfo. This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getBranchesPostRequest: MutationOperation<{ pathParams: { project: string }, queryParams: GetBranchesPostRequestQueryParams }, BranchesInfo>

	/**
	 * Get change treemap
	 *
	 * Retrieves a treemap of change frequencies. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getChangeTreemap: MutationOperation<{ pathParams: { project: string }, queryParams: GetChangeTreemapQueryParams }, TreeMapNode>

	/**
	 * Get a treemap that colors components or languages
	 *
	 * Either top-level components or the used languages can be colored. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getCodeComponentsTreemapWrapper: MutationOperation<{ pathParams: { project: string }, queryParams: GetCodeComponentsTreemapWrapperQueryParams }, FilteredTreeMapWrapper>

	/**
	 * Get a mapping from spec items to code references and test executions
	 *
	 * Returns a spec item code and test result mapping for all spec items matching the given query. If the query is empty, all known spec items are considered. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getCodeReferencesAndTestExecutionMapping: MutationOperation<{ pathParams: { project: string }, queryParams: GetCodeReferencesAndTestExecutionMappingQueryParams }, SpecItemReferenceMapping>

	/**
	 * List the code search match results and returns a treemap.
	 *
	 * Lists all code search matches result including path and line numbers for a given search term and a max result size. Additionally, return a treemap. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getCodeSearchMatchResultAndTreemap: MutationOperation<{ pathParams: { project: string }, queryParams: GetCodeSearchMatchResultAndTreemapQueryParams }, CodeSearchResultsWrapper>

	/**
	 * Get commit alerts
	 *
	 * Provides access to the alerts for the given commits. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getCommitAlerts: MutationOperation<{ pathParams: { project: string }, queryParams: GetCommitAlertsQueryParams }, Array<CommitAlerts | null>>

	/**
	 * Get comparison summary
	 *
	 * Returns the global comparison data, the projects and the contributors for the projects for a single comparison identified by the given ID. The API requires the user to have Access Administrative Services permissions.
	 * @param snapshotId The ID of the local snapshot the comparison belongs to.
	 * @param comparisonId The ID of the snapshot to get. May be an incomplete prefix, as long as it is unambiguous.
	 */
	getComparison: MutationOperation<{ pathParams: { snapshotId: string, comparisonId: string } }, ReducedInstanceComparisonComputation>

	/**
	 * Get complete example list
	 *
	 * Get the full list of missing local or remote detail values The API requires the user to have Access Administrative Services permissions.
	 * @param snapshotId The ID of the local snapshot the comparison belongs to.
	 * @param comparisonId The ID of the comparison to get.
	 * @param project The name of the project. May be \&quot;null\&quot; or empty if this is a global contribution
	 * @param contributor The contributor of the comparison.
	 * @param diffEntryName The name of the difference entry.
	 */
	getCompleteExampleList: MutationOperation<{ pathParams: { snapshotId: string, comparisonId: string, project: string, contributor: string, diffEntryName: string }, queryParams: GetCompleteExampleListQueryParams }, Array<DetailedInstanceComparisonValue>>

	/**
	 * Get a detailed concurrency analysis
	 *
	 * Allows downloading the detailed trigger performance analysis. The feature toggle -Dcom.teamscale.log_performance_details=true must be enabled. The API requires the user to have Access Administrative Services permissions.
	 */
	getConcurrencyPerformanceDetails: MutationOperation<{ queryParams: GetConcurrencyPerformanceDetailsQueryParams }, string>

	/**
	 * Get coverage sources
	 *
	 * Retrieves information about coverage sources (partitions and cross-annotation projects),  respecting the given path. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getCoverageSources: MutationOperation<{ pathParams: { project: string }, queryParams: GetCoverageSourcesQueryParams }, Array<CoverageSourceInfo>>

	/**
	 * Get dashboard
	 *
	 * Retrieves the dashboard descriptor identified by given ID. The API requires the user to have View permissions on the Dashboard dashboardId.
	 * @param dashboardId ID of the dashboard to retrieve.
	 */
	getDashboard: MutationOperation<{ pathParams: { dashboardId: string } }, UserResolvedDashboardDescriptor>

	/**
	 * Get dashboard template
	 *
	 * Retrieves the dashboard template identified by the given ID. Any user may see dashboard templates.
	 * @param templateId ID of the template to retrieve
	 */
	getDashboardTemplateDescriptor: MutationOperation<{ pathParams: { templateId: string } }, DashboardTemplateDescriptor>

	/**
	 * Get token element internals
	 *
	 * Returns a string representation of a token element, supporting various representations. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getDebugContent: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetDebugContentQueryParams }, string>

	/**
	 * Get the declaration of accessibility
	 *
	 * Returns the declaration of accessibility as found in the configuration directory (file name: 'declaration_of_accessibility.md'). To avoid unnecessary IO lookups, the declaration (or non-existent declaration) is locally cached until a reload is forced. The API requires no login
	 */
	getDeclarationOfAccessibility: MutationOperation<{ queryParams: GetDeclarationOfAccessibilityQueryParams }, string | null>

	/**
	 * null The API requires no login
	 */
	getDeclarationOfAccessibilityContext: MutationOperation<void, DeclarationOfAccessibilityContext>

	/**
	 * Get the default branch name
	 *
	 * Get the default branch name This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getDefaultBranchNameGetRequest: MutationOperation<{ pathParams: { project: string } }, string>

	/**
	 * Get the entire dependency graph of a system
	 *
	 * Extracts the dependency graph of a project in the \"Trivial Graph Format\" The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getDependencyGraph: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetDependencyGraphQueryParams }, string>

	/**
	 * Get commit descriptors
	 *
	 * Returns commit descriptors for a project. The API requires the user to have Access Administrative Services permissions.
	 * @param project The project ID.
	 */
	getDumpCommitDescriptor: MutationOperation<{ pathParams: { project: string }, queryParams: GetDumpCommitDescriptorQueryParams }, string>

	/**
	 * Get commit trees
	 *
	 * Returns the commit trees for a project. The API requires the user to have Access Administrative Services permissions.
	 * @param project The project ID.
	 */
	getDumpCommitTree: MutationOperation<{ pathParams: { project: string }, queryParams: GetDumpCommitTreeQueryParams }, string>

	/**
	 * Get commit trees
	 *
	 * Returns the commit tree data for a project. It excludes the nodes from the detailed commit tree. Use the debug/commit-tree service to get the full detailed information. The API requires the user to have Access Administrative Services permissions.
	 * @param project The project ID.
	 */
	getDumpCommitTreeNodes: MutationOperation<{ pathParams: { project: string }, queryParams: GetDumpCommitTreeNodesQueryParams }, Array<CommitTreeNodeData>>

	/**
	 * Get all events
	 *
	 * Returns the list of all available event announcements. The API requires no permissions
	 */
	getEventAnnouncements: MutationOperation<void, Array<EventAnnouncement>>

	/**
	 * Get exception hierarchies
	 *
	 * Retrieves hierarchies of custom exceptions represented as objects. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getExceptionsHierarchyTree: MutationOperation<{ pathParams: { project: string }, queryParams: GetExceptionsHierarchyTreeQueryParams }, Array<Array<ExceptionsTree>>>

	/**
	 * Get hierarchies of custom exceptions
	 *
	 * Retrieves hierarchies of custom exceptions represented graphically. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getExceptionsHierarchyTreeSVG: MutationOperation<{ pathParams: { project: string }, queryParams: GetExceptionsHierarchyTreeSVGQueryParams }, Array<string>>

	/**
	 * Get files with an identical content
	 *
	 * Retrieves files. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getFilesWithIdenticalContent: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetFilesWithIdenticalContentQueryParams }, Array<FileGroup> | null>

	/**
	 * Get files with an identical names
	 *
	 * Retrieves files. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getFilesWithIdenticalNames: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetFilesWithIdenticalNamesQueryParams }, Array<FileGroup> | null>

	/**
	 * This service provides access to the finding churn count. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFindingChurnCount: MutationOperation<{ pathParams: { project: string }, queryParams: GetFindingChurnCountQueryParams }, Array<FindingChurnCount>>

	/**
	 * Get finding delta
	 *
	 * Retrieves the finding delta (i.e., the list of newly added and deleted findings) for the given uniform path and time range. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFindingDelta: MutationOperation<{ pathParams: { project: string }, queryParams: GetFindingDeltaQueryParams }, FindingDelta>

	/**
	 * Get finding descriptions
	 *
	 * Returns user readable finding names and descriptions for given finding type IDs This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFindingTypeDescriptions: MutationOperation<{ pathParams: { project: string }, body: Array<string> }, Array<FindingTypeDescription>>

	/**
	 * Get findings
	 *
	 * Gets a list of all findings. Findings can be filtered by path prefix. For recursive queries, these are all findings found in the sub-tree. Filter parameters allow to only retrieve findings in a specific category and/or group. There is an upper limit for the number of returned findings. This service is public API since Teamscale 5.6. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFindings: MutationOperation<{ pathParams: { project: string }, queryParams: GetFindingsQueryParams }, Array<TrackedFinding>>

	/**
	 * Export findings
	 *
	 * Exports findings for the element with the provided uniform path in the given format. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param format The export format used.
	 */
	getFindingsExport: MutationOperation<{ pathParams: { project: string, format: EFindingsExportFormatEntry }, queryParams: GetFindingsExportQueryParams }, void>

	/**
	 * Get finding siblings
	 *
	 * Gets next and prev finding respecting filter and sort options.  The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param finding 
	 */
	getFindingsSiblings: MutationOperation<{ pathParams: { project: string, finding: string }, queryParams: GetFindingsSiblingsQueryParams }, PreviousNextSiblings>

	/**
	 * Get summaries for all findings
	 *
	 * Gets the list of finding summaries for an element or a resource sub-tree. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFindingsSummary: MutationOperation<{ pathParams: { project: string }, queryParams: GetFindingsSummaryQueryParams }, FindingsSummaryInfo>

	/**
	 * Get findings treemap
	 *
	 * Produces a treemap of finding frequencies. The intensity of the rectangle colors determine the finding frequency in the corresponding file. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFindingsTreemap: MutationOperation<{ pathParams: { project: string }, queryParams: GetFindingsTreemapQueryParams }, FindingsTreemapWrapper>

	/**
	 * Get findings with false positive counts
	 *
	 * Gets a list of all findings with additional information on . For recursive queries, these are all findings found in the sub-tree. Filter parameters allow to only retrieve findings in a specific category and/or group. There is an upper limit for the number of returned findings. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFindingsWithCount: MutationOperation<{ pathParams: { project: string }, queryParams: GetFindingsWithCountQueryParams }, ExtendedFindingsWithCount>

	/**
	 * Get identifiers of flagged findings
	 *
	 * Returns identifiers of all flagged findings for a given project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFlaggedFindingIds: MutationOperation<{ pathParams: { project: string }, queryParams: GetFlaggedFindingIdsQueryParams }, Array<string>>

	/**
	 * Gets flagging information for a finding.
	 *
	 * Returns the flagging information for the finding with given identifier. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param finding Id of the finding the information is requested for.
	 */
	getFlaggedFindingInfo: MutationOperation<{ pathParams: { project: string, finding: string }, queryParams: GetFlaggedFindingInfoQueryParams }, UserResolvedFindingBlacklistInfo | null>

	/**
	 * Get flagged findings
	 *
	 * Returns all flagged findings for a given project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFlaggedFindings: MutationOperation<{ pathParams: { project: string }, queryParams: GetFlaggedFindingsQueryParams }, Array<FindingBlacklistInfo>>

	/**
	 * Get flagging information for findings.
	 *
	 * Returns flagging information for the given findings. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFlaggedFindingsInfos: MutationOperation<{ pathParams: { project: string }, queryParams: GetFlaggedFindingsInfosQueryParams, body: Array<string> }, Array<FindingBlacklistInfo>>

	/**
	 * Get connector ids of all Git-based connectors
	 *
	 * Returns all connector identifiers in a project that use Git. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	getGitConnectorIds: MutationOperation<{ pathParams: { project: string } }, Array<string>>

	/**
	 * Get Repositories the GitHub App has Access to
	 *
	 * Returns list of repositories of the installed GitHub App. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	getGitHubAppInstallationRepositories: MutationOperation<{ queryParams: GetGitHubAppInstallationRepositoriesQueryParams }, Array<string>>

	/**
	 * Get GitHub App Infos
	 *
	 * Returns information about the installed GitHub Apps the current user has access to. User must be provisioned from e.g. GitHub.
	 */
	getGitHubAppInstallations: MutationOperation<void, AppInstallationInfo>

	/**
	 * Collect URLs of all installed GitHub Apps.
	 *
	 * Returns URLs by setup GitHub Apps. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	getGitHubAppUrls: MutationOperation<void, Array<string>>

	/**
	 * Gets a git tag by its ref name
	 *
	 * Returns the git tag with the given ref name or null if it could not be found. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 * @param tagName 
	 */
	getGitTagByName: MutationOperation<{ pathParams: { project: string, tagName: string }, queryParams: GetGitTagByNameQueryParams }, GitTag | null>

	/**
	 * Get multi project branches
	 *
	 * Gets the branches for multiple projects as BranchesInfo The API requires no permissions
	 */
	getGlobalBranchesGetRequest: MutationOperation<{ queryParams: GetGlobalBranchesGetRequestQueryParams }, BranchesInfo>

	/**
	 * Get multi project default branch
	 *
	 * Gets the default branch of one the specified projects. The API requires no permissions
	 */
	getGlobalDefaultBranchGetRequest: MutationOperation<{ queryParams: GetGlobalDefaultBranchGetRequestQueryParams }, string | null>

	/**
	 * Get multi project recent branches
	 *
	 * Gets the recent branches for multiple projects. The API requires no permissions
	 */
	getGlobalRecentBranchesGetRequest: MutationOperation<{ queryParams: GetGlobalRecentBranchesGetRequestQueryParams }, Array<string>>

	/**
	 * Get auto-completion suggestions
	 *
	 * Returns a list of suggested group names for auto-completion. The API requires the user to have Create Users permissions.
	 * @param input The current input that should be auto-completed.
	 */
	getGroupAutoCompletionSuggestions: MutationOperation<{ pathParams: { input: string }, queryParams: GetGroupAutoCompletionSuggestionsQueryParams }, Array<string>>

	/**
	 * Retrieve heap dump form
	 *
	 * Provides a UI to download heap dump The API requires the user to have Access Administrative Services permissions.
	 */
	getHeapDumpFormPage: MutationOperation<void, string>

	/**
	 * Retrieve commit issues
	 *
	 * Returns the issues associated with the given commits. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getIssuesByRepositoryCommits: MutationOperation<{ pathParams: { project: string }, body: GetIssuesByRepositoryCommitsBody }, Array<Array<UserResolvedTeamscaleIssue> | null>>

	/**
	 * Get issues details
	 *
	 * Retrieves issue details by their IDs. Unknown issue IDs will cause 'null' entries in the result The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getIssuesDetails: MutationOperation<{ pathParams: { project: string }, queryParams: GetIssuesDetailsQueryParams }, Array<UserResolvedTeamscaleIssue | null>>

	/**
	 * Returns the current computation status of a test minimization job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	getJob: MutationOperation<{ pathParams: { project: string, jobId: string } }, TestMinimizationJobRun>

	/**
	 * Returns the arguments of the test minimization job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	getJobOptions: MutationOperation<{ pathParams: { project: string, jobId: string } }, TestMinimizationRequestOptions>

	/**
	 * Get job queue size
	 *
	 * Retrieves size of the entire job queue from all projects. The API requires the user to have View System Status permissions.
	 */
	getJobQueueSize: MutationOperation<void, JobQueueCountWithDelayedJobs>

	/**
	 * Returns the list of test minimization jobs of the current user for the project.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getJobs: MutationOperation<{ pathParams: { project: string } }, Array<TestMinimizationJobRun>>

	/**
	 * Return last change log entry with the given commit type
	 *
	 * Returns the log entry for the last change with the given commit type The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getLastCommitOfType: MutationOperation<{ pathParams: { project: string }, queryParams: GetLastCommitOfTypeQueryParams }, RepositoryLogEntryAggregate | null>

	/**
	 * Get the late external upload information
	 *
	 * Retrieves the timestamp of the next late processing of external uploads, the age threshold for external uploads being considered late and the maximum upload age for external uploads. The API requires the user to have Perform External Uploads permissions on the project.
	 * @param project The project ID.
	 */
	getLateExternalUploadProcessingInfo: MutationOperation<{ pathParams: { project: string } }, LateExternalUploadProcessingInfo>

	/**
	 * Returns information about the license.
	 *
	 * Returns license information. If the reload parameter is set this reloads the license and logs any errors into the service log. The API requires the user to have Access Administrative Services permissions.
	 */
	getLicenseInfo: MutationOperation<{ queryParams: GetLicenseInfoQueryParams }, LicenseInfo | null>

	/**
	 * Get link roles
	 *
	 * Returns the link roles for a specific connector. The API requires the user to have View permissions on the External Accounts accountName.
	 * @param accountName The ID of the external accounts the user needs access to.
	 */
	getLinkRoles: MutationOperation<{ pathParams: { accountName: string }, body: ConnectorConfiguration }, GetLinkRolesResponse>

	/**
	 * Returns active committers across all projects
	 *
	 * Returns all committers of the last 90 days (across all projects) that contribute to the license volume The API requires the user to have Access Administrative Services permissions.
	 */
	getListOfActiveCommitters: MutationOperation<void, Array<CommitterDisplayName>>

	/**
	 * Returns active committers for the given primary project IDs
	 *
	 * For the given list of primary project IDs, returns all committers of the last 90 days that contribute to the license volume. If an empty list is provided, all projects will be included (which is equivalent to performing a  GET query). The API requires the user to have Access Administrative Services permissions.
	 */
	getListOfActiveCommittersForProjects: MutationOperation<{ body: Array<string> }, Array<CommitterDisplayName>>

	/**
	 * Provides context for the login screen
	 *
	 * null The API requires no login
	 */
	getLoginContext: MutationOperation<void, LoginContext>

	/**
	 * Get merge request finding churn
	 *
	 * Creates a findings delta (newly added or deleted findings and unchanged findings in modified code) for a given proposed merge. Findings in modified code will only be reported if the modification happened since the last common commit with the target branch. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getMergeRequestFindingChurn: MutationOperation<{ pathParams: { project: string }, queryParams: GetMergeRequestFindingChurnQueryParams }, FindingDelta>

	/**
	 * Get merge request repository churn
	 *
	 * Retrieves the merge request repository churn. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getMergeRequestRepositoryChurn: MutationOperation<{ pathParams: { project: string }, queryParams: GetMergeRequestRepositoryChurnQueryParams }, RepositoryChurn>

	/**
	 * Get method history
	 *
	 * Returns the method history entries including changes, renamings or movements and points in time where method has been tested. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath Uniform path to retrieve the metric history for.
	 */
	getMethodHistory: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetMethodHistoryQueryParams }, MethodHistoryEntriesWrapper>

	/**
	 * Get metric schema
	 *
	 * Returns the metric schema for a path within a project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPathType The path for which the metric schema should be fetched
	 */
	getMetricDirectorySchemaForPath: MutationOperation<{ pathParams: { project: string, uniformPathType: ETypeEntry } }, MetricDirectorySchema>

	/**
	 * Get global metric schema
	 *
	 * Retrieves all available metric schemas in the same order as the provided paths. Global metric directory schema is available to each authenticated user.
	 */
	getMetricDirectorySchemas: MutationOperation<{ queryParams: GetMetricDirectorySchemasQueryParams }, Array<MetricDirectorySchema>>

	/**
	 * Get metric threshold configuration
	 *
	 * Retrieves the metric threshold configuration identified by given name. Built-in metric threshold configurations require no access permissions. For the rest, view permission for the respective configuration is required.
	 * @param metricThresholdConfigurationName 
	 */
	getMetricThresholdConfiguration: MutationOperation<{ pathParams: { metricThresholdConfigurationName: string }, queryParams: GetMetricThresholdConfigurationQueryParams }, MetricThresholdConfiguration>

	/**
	 * Get minimized Test Set
	 *
	 * Returns a list of test clusters that have been determined to be the best tests to run in the amount of time that was specified in the request. The clusters are formed according tothe clustering-regex that can be specified in the request options. This service is public API since Teamscale 7.8. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getMinimizedTests: MutationOperation<{ pathParams: { project: string }, queryParams: GetMinimizedTestsQueryParams }, TestMinimizationResult>

	/**
	 * Number of Active User
	 *
	 * Calculates the number of active users in the past 90 days. This is either the number of active Teamscale users or the number of committers, depending on which value is larger.  The API requires no permissions
	 */
	getNumberOfActiveUsers: MutationOperation<void, number>

	/**
	 * Get partitions
	 *
	 * Returns the list of external upload partitions in this project. The API requires the user to have Perform External Uploads, View Project permissions on the project.
	 * @param project The project ID.
	 */
	getPartitions: MutationOperation<{ pathParams: { project: string } }, Array<ExternalAnalysisPartitionInfo>>

	/**
	 * Get permission lookup
	 *
	 * Returns a permission lookup for the whole permission scope The API requires no permissions
	 */
	getPermissionLookup: MutationOperation<{ queryParams: GetPermissionLookupQueryParams }, PermissionLookupEBasicPermission>

	/**
	 * Get the content for a pre-commit element
	 *
	 * Retrieves the formatted content (i.e., incl. style information) for an element that is part of a pre-commit branch. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getPreCommitContent: MutationOperation<{ pathParams: { project: string }, queryParams: GetPreCommitContentQueryParams }, FormattedTokenElementInfo>

	/**
	 * Get project analysis progress
	 *
	 * Retrieves the project specific analysis progress. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getProjectAnalysisProgress: MutationOperation<{ pathParams: { project: string } }, Array<AnalysisProgressDescriptor>>

	/**
	 * Get project comparison results
	 *
	 * Returns the comparison data for the specified comparison, project and contributor. The comparison can be identified by the given ID. The API requires the user to have Access Administrative Services permissions.
	 * @param snapshotId The ID of the local snapshot the comparison belongs to.
	 * @param comparisonId The ID of the comparison to get.
	 * @param project The name of the project.
	 */
	getProjectComparisonResultsForContributor: MutationOperation<{ pathParams: { snapshotId: string, comparisonId: string, project: string }, queryParams: GetProjectComparisonResultsForContributorQueryParams }, ProjectComparisonResult>

	/**
	 * Get project notification rules
	 *
	 * Retrieves the currently set project notification rules The API requires the user to have Edit Global Notification Settings permissions.
	 */
	getProjectNotificationRules: MutationOperation<void, ProjectNotificationRules | null>

	/**
	 * Get options
	 *
	 * Retrieves all available project options. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getProjectOptions: MutationOperation<{ pathParams: { project: string } }, Record<string, object>>

	/**
	 * Get unique users activity trend
	 *
	 * Returns a list of lists, where each list contains a pair of the date timestamp and the number of unique users on that date. Requires VIEW permissions on all projects given via the project parameter
	 */
	getProjectUserActivityTrend: MutationOperation<{ queryParams: GetProjectUserActivityTrendQueryParams }, Array<Array<number>>>

	/**
	 * Get recent branches
	 *
	 * Returns the recently visited branches. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRecentBranches: MutationOperation<{ pathParams: { project: string } }, Array<string>>

	/**
	 * Get redundant literal treemap
	 *
	 * Retrieves a treemap of all redundant literals. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRedundantLiteralTreemap: MutationOperation<{ pathParams: { project: string }, queryParams: GetRedundantLiteralTreemapQueryParams }, FilteredTreeMapWrapper>

	/**
	 * Get redundant literals
	 *
	 * Retrieves all redundant literals in the project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRedundantLiteralsQuery: MutationOperation<{ pathParams: { project: string }, queryParams: GetRedundantLiteralsQueryQueryParams }, Array<RedundantLiteralInfo>>

	/**
	 * Get report
	 *
	 * Returns the report. The API requires the user to have View permissions on the Quality Report id.
	 * @param id The report id
	 */
	getReport: MutationOperation<{ pathParams: { id: string } }, QualityReport>

	/**
	 * Get repository churn
	 *
	 * Retrieves the repository churn for a time range. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRepositoryChurn: MutationOperation<{ pathParams: { project: string }, queryParams: GetRepositoryChurnQueryParams }, RepositoryChurn>

	/**
	 * Get all repository log entries
	 *
	 * Returns repository log entries for given commits.A commit is identified as branch1@timestamp1All timestamps are interpreted as milliseconds since 1970. If no commits are provided, all log entries are returned. Commits are separated by comma. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRepositoryLog: MutationOperation<{ pathParams: { project: string }, queryParams: GetRepositoryLogQueryParams }, Array<UserResolvedRepositoryLogEntry>>

	/**
	 * Get affected files by commits
	 *
	 * Returns repository log file entries for commits, i.e. the association between files and repository log entries. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRepositoryLogFileHistoryForCommits: MutationOperation<{ pathParams: { project: string }, queryParams: GetRepositoryLogFileHistoryForCommitsQueryParams }, Array<RepositoryLogFileHistoryEntry>>

	/**
	 * Get the repository summary
	 *
	 * Provides summary information about the commit activity in a project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRepositorySummary: MutationOperation<{ pathParams: { project: string }, queryParams: GetRepositorySummaryQueryParams }, RepositorySummary>

	/**
	 * Get resource history
	 *
	 * Returns repository log file entries for a specific path, i.e. the association between files and repository log entries. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getResourceHistory: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetResourceHistoryQueryParams }, Array<RepositoryLogFileHistoryEntry>>

	/**
	 * Get review baseline
	 *
	 * Allows to retrieve the review baseline for a project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getReviewBaseline: MutationOperation<{ pathParams: { project: string } }, number | null>

	/**
	 * Get risk methods
	 *
	 * Returns all methods that are considered a risk and should therefore be covered with tests. For now this is all methods that have been added or changed in the given time range. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRisks: MutationOperation<{ pathParams: { project: string }, queryParams: GetRisksQueryParams }, RiskMethods>

	/**
	 * Open scheduler control page
	 *
	 * Provides a UI to send a command to the scheduler control. The API requires the user to have Access Administrative Services permissions.
	 */
	getSchedulerControlPage: MutationOperation<void, string>

	/**
	 * Returns the currently selected list of test (possibly Pareto ranked).
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	getSelectedTests: MutationOperation<{ pathParams: { project: string, jobId: string }, queryParams: GetSelectedTestsQueryParams }, Array<PrioritizableTest>>

	/**
	 * Service-Load information
	 *
	 * Returns information about service load. The API requires the user to have View System Status permissions.
	 */
	getServiceLoad: MutationOperation<void, Array<LoadProfile>>

	/**
	 * Get option
	 *
	 * Retrieves the project option identified by the given name. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param optionId Name of the option to retrieve.
	 */
	getSingleProjectOption: MutationOperation<{ pathParams: { project: string, optionId: string } }, object>

	/**
	 * Get option
	 *
	 * Retrieves the server option identified by the given name. EDIT_SERVER_OPTIONS permission needed for most options, but some options (PUBLIC_READ_OPTION_NAMES) can be read without permission
	 * @param optionId Name of the option to retrieve.
	 */
	getSingleServerOption: MutationOperation<{ pathParams: { optionId: string } }, object>

	/**
	 * Get a snapshot
	 *
	 * Returns a single snapshot identified by the given ID or ID prefix. The ID (prefix) must not be empty, but can be as short as one character, as long as it unambiguously identifies the snapshot to be returned. The API requires the user to have Access Administrative Services permissions.
	 * @param id The ID of the snapshot to get. May be an incomplete prefix, as long as it is unambiguous.
	 */
	getSnapshot: MutationOperation<{ pathParams: { id: string }, queryParams: GetSnapshotQueryParams }, InstanceComparisonSnapshotDto>

	/**
	 * Get a snapshot status
	 *
	 * Returns the status (IN_PROGRESS, SUCCESS or FAILURE) of a single status identified by the given ID or ID prefix. The ID (prefix) must not be empty, but can be as short as one character, as long as it unambiguously identifies a status. Only snapshots created by the logged-in user are considered for this operation. The API requires the user to have Access Administrative Services permissions.
	 * @param id The ID of the snapshot. May be an incomplete prefix, as long as it is unambiguous.
	 */
	getSnapshotStatus: MutationOperation<{ pathParams: { id: string } }, EInstanceComparisonStatusEntry>

	/**
	 * Gets the spec item churn
	 *
	 * Provides a spec item churn (i.e., the list of changed spec items) for a given time range. This service is public API since Teamscale 8.5. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getSpecItemChurn: MutationOperation<{ pathParams: { project: string }, queryParams: GetSpecItemChurnQueryParams }, Array<TokenElementChurnInfo>>

	/**
	 * Get spec item details
	 *
	 * Retrieves spec item details by their IDs. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getSpecItemDetails: MutationOperation<{ pathParams: { project: string }, queryParams: GetSpecItemDetailsQueryParams }, Array<UserResolvedSpecItem | null>>

	/**
	 * Get stacktrace of failed test execution
	 *
	 * Retrieves the stacktrace of the failed test execution at a given uniform path. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param test Uniform path to retrieve the stacktrace for
	 */
	getStacktrace: MutationOperation<{ pathParams: { project: string, test: string }, queryParams: GetStacktraceQueryParams }, Stacktrace | null>

	/**
	 * Get state change timestamps
	 *
	 * Allows getting the timestamps for the different analysis states. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	getStateChangeTimestamps: MutationOperation<{ pathParams: { project: string } }, Record<string, number>>

	/**
	 * Get aggregated store performance metrics
	 *
	 * Allows downloading the store performance aggregate data as CSV. The API requires the user to have Access Administrative Services permissions.
	 */
	getStorePerformanceDetails: MutationOperation<void, void>

	/**
	 * Get stored test lists
	 *
	 * Retrieves the list of available test list descriptors. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getStoredTestLists: MutationOperation<{ pathParams: { project: string } }, Array<TestListDescriptor>>

	/**
	 * Get stored test lists names
	 *
	 * Retrieves the list of available test list descriptors names. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getStoredTestListsNames: MutationOperation<{ pathParams: { project: string } }, Array<string>>

	/**
	 * Get the complete backup summary
	 *
	 * Get the summary of the 10 most recent backups. This service is public API since Teamscale 6.1. The API requires the user to have Backup Global Data permissions.
	 */
	getSummary1: MutationOperation<void, Array<BackupJobSummary>>

	/**
	 * Get system information.
	 *
	 * Returns system information. The API requires the user to have View System Status permissions.
	 */
	getSystemInformation: MutationOperation<void, Array<SystemProcessInfo>>

	/**
	 * Shutdown Teamscale
	 *
	 * Provides an UI to issue a Teamscale shutdown request. The API requires the user to have Access Administrative Services permissions.
	 */
	getTeamscaleShutdownPage: MutationOperation<void, string>

	/**
	 * List all third party dependencies
	 *
	 * Lists all third party dependencies for the project with given package depth, along with the percentage of affected files for each dependency. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTechnologyDependencies: MutationOperation<{ pathParams: { project: string }, queryParams: GetTechnologyDependenciesQueryParams }, Array<DependencyEntry>>

	/**
	 * Get treemap with the third party dependencies
	 *
	 * Builds a treemap with the third party dependencies from the technology scan. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTechnologyTreeMap: MutationOperation<{ pathParams: { project: string }, queryParams: GetTechnologyTreeMapQueryParams }, FilteredTreeMapWrapper>

	/**
	 * Returns test coverage
	 *
	 * Returns the line coverage data for an element. This service is public API since Teamscale 5.3. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getTestCoverage: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetTestCoverageQueryParams }, LineCoverageInfo | null>

	/**
	 * Get test coverage overlay
	 *
	 * Retrieves the methods that are expected to be covered by the impacted tests if they are rerun. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTestCoverageOverlay: MutationOperation<{ pathParams: { project: string }, queryParams: GetTestCoverageOverlayQueryParams }, TestCoverageOverlayData>

	/**
	 * Returns test coverage
	 *
	 * Returns the line coverage data for an element. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getTestCoveragePost: MutationOperation<{ pathParams: { project: string, uniformPath: string }, body: GetTestCoveragePostBody }, LineCoverageInfo | null>

	/**
	 * Download test gap data
	 *
	 * Downloads test gap information as a CSV file The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTestGapDataAsCsv: MutationOperation<{ pathParams: { project: string }, queryParams: GetTestGapDataAsCsvQueryParams }, File>

	/**
	 * Get test gap treemap
	 *
	 * Retrieves the test get treemap. For each method in the project, the treemap shows whether it was tested after its last change within the reference time frame. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTestGapTreeMap: MutationOperation<{ pathParams: { project: string }, queryParams: GetTestGapTreeMapQueryParams }, TestGapTreeMapWrapper>

	/**
	 * Get test gap percentage
	 *
	 * Calculates the TGA percentage for the given uniform path or the given issue ID. This service is public API since Teamscale 5.9.6. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTgaPercentage: MutationOperation<{ pathParams: { project: string }, queryParams: GetTgaPercentageQueryParams }, number>

	/**
	 * Get metric table entries
	 *
	 * Retrieves various test gap metrics (e.g., test gap state, number of test gaps, execution state and churn) and returns them in tabular form. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTgaTable: MutationOperation<{ pathParams: { project: string }, queryParams: GetTgaTableQueryParams }, Array<TgaTableEntry>>

	/**
	 * Get test coverage partitions
	 *
	 * Returns the available test coverage partitions of the project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTgaTestCoveragePartitions: MutationOperation<{ pathParams: { project: string }, queryParams: GetTgaTestCoveragePartitionsQueryParams }, Array<string>>

	/**
	 * Get test gap trend
	 *
	 * Calculates test gap trends. Does not support issue requests. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTgaTrend: MutationOperation<{ pathParams: { project: string }, queryParams: GetTgaTrendQueryParams }, Array<MetricTrendEntry>>

	/**
	 * Get trigger graph as JSON
	 *
	 * Returns a plain text GraphViz DOT description of the trigger graph The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTriggerGraphDot: MutationOperation<{ pathParams: { project: string }, queryParams: GetTriggerGraphDotQueryParams }, Array<TriggerGraphNode>>

	/**
	 * Get detailed trigger performance metrics
	 *
	 * Allows downloading the detailed trigger performance data as CSV. The feature toggle -Dcom.teamscale.log_performance_details=true must be enabled. The API requires the user to have Access Administrative Services permissions.
	 */
	getTriggerPerformanceDetails: MutationOperation<void, void>

	/**
	 * Get unused ID
	 *
	 * Returns a valid event ID that is unused so far. The API requires no permissions
	 */
	getUnusedEventId: MutationOperation<void, string>

	/**
	 * Get auto-completion suggestions
	 *
	 * Returns a list of suggested user names for auto-completion. The API requires the user to have Create Users permissions.
	 * @param input The current input that should be auto-completed.
	 */
	getUserAutoCompletionSuggestions: MutationOperation<{ pathParams: { input: string }, queryParams: GetUserAutoCompletionSuggestionsQueryParams }, Array<string>>

	/**
	 * Get user avatar image
	 *
	 * Returns the image file that serves as the avatar for the user in the first path parameter. The API requires no permissions
	 * @param user 
	 */
	getUserAvatarData: MutationOperation<{ pathParams: { user: string }, queryParams: GetUserAvatarDataQueryParams }, void>

	/**
	 * Get a user group
	 *
	 * Get the user group identified by the given name. The API requires the user to have View permissions on the Group userGroupName.
	 * @param userGroupName Name of the requested user group.
	 */
	getUserGroup: MutationOperation<{ pathParams: { userGroupName: string } }, UserGroup>

	/**
	 * Sufficient Ram
	 *
	 * Checks whether Teamscale has enough RAM to operate, given the amount of workers. The API requires no permissions
	 */
	hasSufficientRam: MutationOperation<void, SufficientRamResponse>

	/**
	 * Hide event announcement
	 *
	 * Hides the event announcement with the given ID for the logged-in user. The event will no longer be advertised to the user. The API requires no permissions
	 */
	hideEventAnnouncement: MutationOperation<{ body: string }, void>

	/**
	 * Upload AbapLint configuration files
	 *
	 * Uploads AbapLint configuration files which are then converted to Teamscale analysis profiles. Returns a textual summary of the import process The API requires the user to have Create Analysis Profiles permissions.
	 */
	importAbapLintConfigurationFile: MutationOperation<{ body: ImportAbapLintConfigurationFileBody }, Array<ABAPLintConfigurationFileImportSummary>>

	/**
	 * Import analysis profile
	 *
	 * Imports an analysis profile. Requires Create Analysis Profiles or the permission to edit analysis profiles when a profile with the same name already exists.
	 */
	importAnalysisProfile: MutationOperation<{ body: ImportAnalysisProfileBody }, void>

	/**
	 * Update a dashboard from a dashboard export
	 *
	 * Performs an import of a dashboard. Adds the uploaded descriptor to the list of dashboards/templates. The descriptor must be packaged within an JSON Teamscale Version Container. In case the dashboard with the UUID already exists the existing one will be overridden. This service is public API since Teamscale 8.9.8. The API requires the user to have Create Dashboards permissions. For dashboards that already exist and should therefore be overwritten the user needs to have EDIT permissions on the dashboard.
	 */
	importAndReplaceDashboards: MutationOperation<{ body: ImportAndReplaceDashboardsBody }, Array<string>>

	/**
	 * Import backup.
	 *
	 * Triggers the import of a backup and returns the job ID. This service is public API since Teamscale 6.1. The API requires the user to have Backup Global Data permissions.
	 */
	importBackup: MutationOperation<{ body: ImportBackupBody }, string>

	/**
	 * Upload SwiftLint Configuration
	 *
	 * Uploads SwiftLint Configurations which are then converted to Teamscale analysis profiles. Returns a textual summary of the import process The API requires the user to have Create Analysis Profiles permissions.
	 */
	importConfiguration: MutationOperation<{ body: ImportConfigurationBody }, SwiftLintConfigurationImportSummary>

	/**
	 * Create a dashboard from a dashboard export
	 *
	 * Performs an import of a dashboard. Adds the uploaded descriptor to the list of dashboards/templates. The descriptor must be packaged within an JSON Teamscale Version Container. This service is public API since Teamscale 8.7. The API requires the user to have Create Dashboards permissions.
	 */
	importDashboard: MutationOperation<{ body: ImportDashboardBody }, Array<string>>

	/**
	 * Create a dashboard template
	 *
	 * Performs an import of a dashboard template. Adds the uploaded descriptor to the list of templates. The descriptor must be packaged within an JSON Teamscale Version Container. The API requires the user to have Create Dashboard Templates permissions.
	 */
	importDashboardTemplate: MutationOperation<{ body: ImportDashboardTemplateBody }, Array<string>>

	/**
	 * Import group
	 *
	 * Imports all users of one group from a remote server. Returns the list of imported user names. The API requires the user to have Create Users, Create Groups permissions.
	 * @param groupName The name of the group that should be imported.
	 */
	importGroup: MutationOperation<{ pathParams: { groupName: string }, queryParams: ImportGroupQueryParams }, Array<string>>

	/**
	 * Import user
	 *
	 * Imports one user from a remote server. Returns the name of the imported user. The API requires the user to have Create Users permissions.
	 * @param userName The name of the user that should be imported.
	 */
	importUser: MutationOperation<{ pathParams: { userName: string }, queryParams: ImportUserQueryParams }, string>

	/**
	 * Availability of merge requests
	 *
	 * Returns true if merge requests are configured for the project The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	isMergeRequestsEnabled: MutationOperation<{ pathParams: { project: string } }, boolean>

	/**
	 * Reports if snapshot backups are supported
	 *
	 * Returns if snapshot backups are supported by the storage system. The API requires the user to have Access Administrative Services permissions.
	 */
	isSnapshotBackupSupported: MutationOperation<void, boolean>

	/**
	 * Availability of spec-item analysis
	 *
	 * Determines whether spec-item analysis is enabled for the project The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	isSpecItemAnalysisEnabled: MutationOperation<{ pathParams: { project: string } }, boolean>

	/**
	 * List parse log of project
	 *
	 * Lists all parse log entries for the project. The API requires the user to have View System Status permissions.
	 * @param project The project ID.
	 */
	listAllParseLogEntries: MutationOperation<{ pathParams: { project: string }, queryParams: ListAllParseLogEntriesQueryParams }, Array<ParseLogEntry>>

	/**
	 * List of merge requests, total number and existing status.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	listMergeRequests: MutationOperation<{ pathParams: { project: string }, queryParams: ListMergeRequestsQueryParams }, ExtendedMergeRequestsInfo>

	/**
	 * Get quality reports
	 *
	 * Returns all stored reports details, without slide contents. No permissions needed, as the service will only return reports visible to current user.
	 */
	listQualityReports: MutationOperation<void, Array<UserResolvedQualityReport>>

	/**
	 * Load the list of tests of this job from a named test list.
	 *
	 * null The API requires the user to have Perform Test Ranking permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	loadFromNamedTestList: MutationOperation<{ pathParams: { project: string, jobId: string }, queryParams: LoadFromNamedTestListQueryParams }, number>

	/**
	 * Mark/Unmark a dashboard as user favorite
	 *
	 * Performs an operation on the dashboard favorite list of the current user, whether to add/remove a dashboard to/from the list. The API requires no permissions
	 * @param dashboardId Contains the name of the dashboard
	 */
	markDashboardAsFavorite: MutationOperation<{ pathParams: { dashboardId: string }, queryParams: MarkDashboardAsFavoriteQueryParams }, void>

	/**
	 * Performs the login of a user.
	 *
	 * Authenticates the user based on given credentials and returns a session cookie in case of success. The API requires no login
	 */
	performLogin: MutationOperation<{ body: Credentials }, void>

	/**
	 * Perform test query
	 *
	 * Retrieves the size and list of tests for the query. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	performTestQuery: MutationOperation<{ pathParams: { project: string }, queryParams: PerformTestQueryQueryParams }, TestQueryResult>

	/**
	 * Retrieve commit alerts
	 *
	 * Provides access to the alerts for the given commits. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	postCommitAlerts: MutationOperation<{ pathParams: { project: string }, body: PostCommitAlertsBody }, Array<CommitAlerts | null>>

	/**
	 * This service provides access to the finding churn count. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	postFindingChurnCount: MutationOperation<{ pathParams: { project: string }, body: PostFindingChurnCountBody }, Array<FindingChurnCount>>

	/**
	 * Sets the list of tests to minimize in the job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	postJobTests: MutationOperation<{ pathParams: { project: string, jobId: string }, body: Array<string> }, number>

	/**
	 * Get all repository log entries
	 *
	 * Returns all repository log entries for the commits given in the POST data.A commit is identified as branch1@timestamp1All timestamps are interpreted as milliseconds since 1970. If no commits are provided, all log entries are returned. Commits are separated by comma. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	postRepositoryLog: MutationOperation<{ pathParams: { project: string }, queryParams: PostRepositoryLogQueryParams, body: PostRepositoryLogBody }, Array<UserResolvedRepositoryLogEntry>>

	/**
	 * Resolve username or alias
	 *
	 * Retrieves the usernames for the supplied aliases, usernames, full names or e-mail addresses. For users which cannot be resolved, the supplied value is returned back. This API requires VIEW permission for every resolved user. If the permission is missing, the supplied value is returned back.
	 */
	postResolveUserAliases: MutationOperation<{ body: PostResolveUserAliasesBody }, Array<string>>

	/**
	 * Get test gap percentage
	 *
	 * Calculates the TGA percentage for the given uniform path or the given issue ID. This service is public API since Teamscale 5.9.6. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	postTgaPercentage: MutationOperation<{ pathParams: { project: string }, queryParams: PostTgaPercentageQueryParams, body: PostTgaPercentageBody }, number>

	/**
	 * Posts user avatar image
	 *
	 * Uploads the image file given in the multipart called 'avatar' as the avatar of the user in the first path parameter. Users can change their own avatar only but admin users can change someone else's avatar
	 * @param user 
	 */
	postUserAvatarData: MutationOperation<{ pathParams: { user: string }, body: PostUserAvatarDataBody }, void>

	/**
	 * Puts new crop dimensions for user avatar
	 *
	 * Tries to set new crop dimensions for the avatar of the user in the first path parameter. The API requires the user to have Edit permissions on the user.
	 * @param user 
	 */
	putAvatarData: MutationOperation<{ pathParams: { user: string }, queryParams: PutAvatarDataQueryParams }, AvatarData>

	/**
	 * Append the list of impacted tests as tests to minimize to the job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	putJobTestsFromImpacted: MutationOperation<{ pathParams: { project: string, jobId: string }, queryParams: PutJobTestsFromImpactedQueryParams }, number>

	/**
	 * Append the list of tests to minimize to the job based on a query.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	putJobTestsFromQuery: MutationOperation<{ pathParams: { project: string, jobId: string }, queryParams: PutJobTestsFromQueryQueryParams }, number>

	/**
	 * Put quality report
	 *
	 * Updates/Renames an existing quality report. The API requires the user to have Edit permissions on the Quality Report id.
	 * @param id The id of the report that gets an update.
	 */
	putQualityReport: MutationOperation<{ pathParams: { id: string }, body: QualityReport }, void>

	/**
	 * Redirect to the example hosting host
	 *
	 * Redirects to /example on the instance, which hosts the actual example. The API requires no permissions
	 */
	redirectToCorrectHost: MutationOperation<{ queryParams: RedirectToCorrectHostQueryParams }, void>

	/**
	 * Generate Service Provider certificate
	 *
	 * Generates a new certificate for the service provider stored at the named option. The API requires the user to have Access Administrative Services permissions.
	 */
	regenerateStoredServiceProviderCertificate: MutationOperation<{ queryParams: RegenerateStoredServiceProviderCertificateQueryParams }, boolean>

	/**
	 * Revoke Access Key
	 *
	 * Revokes the user's access key. The API requires the user to have Edit permissions on the user.
	 * @param user 
	 */
	removeAccessKey: MutationOperation<{ pathParams: { user: string } }, void>

	/**
	 * Request a SonarLint analysis
	 *
	 * Opens and schedules a new SonarLint analysis session, returning the session key. The API requires the user to have Create Projects permissions.
	 */
	requestAnalysisAndOpenSession: MutationOperation<{ queryParams: RequestAnalysisAndOpenSessionQueryParams, body: SonarLintIndependentAnalysisRequestParameters }, string>

	/**
	 * Get the target commit of a git tag
	 *
	 * Resolves the given git tag to the tagged commit. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	resolveTag: MutationOperation<{ pathParams: { project: string }, body: GitTag }, CommitDescriptor | null>

	/**
	 * Run database compaction
	 *
	 * Allows running a full compaction on the database. WARNING: This is likely a very expensive operation. The API requires the user to have Access Administrative Services permissions.
	 */
	runDatabaseCompaction: MutationOperation<void, void>

	/**
	 * Saves the list of tests created along this job as a named test list.
	 *
	 * null The API requires the user to have Perform Test Ranking permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	saveAsNamedTestList: MutationOperation<{ pathParams: { project: string, jobId: string }, body: string }, number>

	/**
	 * Save a given test list
	 *
	 * Creates a test list descriptor in the system. The API requires the user to have Edit Test Queries permissions on the project.
	 * @param project The project ID.
	 */
	saveTestListDescriptor: MutationOperation<{ pathParams: { project: string }, body: TestListDescriptor }, void>

	/**
	 * Set project notification rules
	 *
	 * Edits the currently set project notification rules The API requires the user to have Edit Global Notification Settings permissions.
	 */
	setProjectNotificationRules: MutationOperation<{ body: ProjectNotificationRules }, void>

	/**
	 * Request the computation of a minimized test set.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	setupJob: MutationOperation<{ pathParams: { project: string }, queryParams: SetupJobQueryParams }, string>

	/**
	 * Default Admin Enabled
	 *
	 * Checks whether Teamscale has the default admin user enabled when it shouldn't. The API requires no permissions
	 */
	showDefaultAdminWarning: MutationOperation<void, boolean>

	/**
	 * Default Backup Settings
	 *
	 * Checks whether Teamscale has default backup settings enabled, which were not yet acknowledged by an admin user.  The API requires no permissions
	 */
	showDefaultBackupSettingsWarning: MutationOperation<void, boolean>

	/**
	 * In Memory Database
	 *
	 * Checks whether Teamscale operates on an in memory database when it shouldn't. The API requires no permissions
	 */
	showInMemoryWarning: MutationOperation<void, boolean>

	/**
	 * Shadow Mode
	 *
	 * Checks whether Teamscale has the shadow mode enabled. The API requires no permissions
	 */
	showShadowModeWarning: MutationOperation<void, boolean>

	/**
	 * Shutdown Teamscale
	 *
	 * Allows to shut down a running Teamscale instance The API requires the user to have Access Administrative Services permissions.
	 */
	shutdown: MutationOperation<{ body: ShutdownBody }, void>

	/**
	 * Starts the given test minimization job.
	 *
	 * null The API requires the user to have Perform Test Ranking permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	startJob: MutationOperation<{ pathParams: { project: string, jobId: string } }, void>

	/**
	 * Stops and deletes the given test minimization job and its results.
	 *
	 * null The API requires the user to have Perform Test Ranking permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	stopAndDeleteJob: MutationOperation<{ pathParams: { project: string, jobId: string } }, void>

	/**
	 * Store event announcement
	 *
	 * Stores the given event announcement. The API requires the user to have Access Administrative Services permissions.
	 * @param eventId 
	 */
	storeEventAnnouncement: MutationOperation<{ pathParams: { eventId: string }, body: EventAnnouncement }, void>

	/**
	 * Starts a snapshot backup.
	 *
	 * Schedules a snapshot backup to the given target URI. The API requires the user to have Access Administrative Services permissions.
	 */
	triggerSnapshotBackup: MutationOperation<{ body: TriggerSnapshotBackupBody }, void>

	/**
	 * Unmark tolerated/false positive findings before given commit.
	 *
	 * Unmark all tolerated/false positive findings that were marked before the given commit. Also returns the ids of the unmarked findings. The API requires the user to have View Project, Flag Red Findings, Flag Yellow Findings permissions on the project.
	 * @param project The project ID.
	 */
	unmarkToleratedAndFalsePositiveFindingsBeforeCommit: MutationOperation<{ pathParams: { project: string }, queryParams: UnmarkToleratedAndFalsePositiveFindingsBeforeCommitQueryParams }, Array<string>>

	/**
	 * Update analysis profile
	 *
	 * Updates the analysis profile identified by the given name with the value in the request body. Requires the edit permission on the respective analysis profile.
	 */
	updateAnalysisProfile: MutationOperation<{ queryParams: UpdateAnalysisProfileQueryParams, body: AnalysisProfile }, void>

	/**
	 * Update dashboard template
	 *
	 * Updates the given dashboard template The API requires the user to have Edit permissions on the Dashboard Templates templateId.
	 * @param templateId ID of the template to update
	 */
	updateDashboardTemplate: MutationOperation<{ pathParams: { templateId: string }, body: DashboardTemplateDescriptor }, void>

	/**
	 * Update external accounts
	 *
	 * Updates the external accounts identified by the given name with the value in the request body. The API requires the user to have Edit permissions on the External Accounts externalCredentialsName.
	 * @param externalCredentialsName Name of the old external accounts.
	 */
	updateExternalAccounts: MutationOperation<{ pathParams: { externalCredentialsName: string }, queryParams: UpdateExternalAccountsQueryParams, body: ExternalCredentialsData }, void>

	/**
	 * Update external analysis group
	 *
	 * Updates the external analysis group identified by the given name. The API requires the user to have Edit External Findings Schema permissions.
	 * @param groupName Name of the external analysis group to remove
	 */
	updateExternalAnalysisGroup: MutationOperation<{ pathParams: { groupName: string }, body: ExternalAnalysisGroup }, void>

	/**
	 * Update external finding description
	 *
	 * Update the external finding description identified by the given name. The API requires the user to have Edit External Findings Schema permissions.
	 * @param externalFindingDescriptionTypeId ID of the finding description to update
	 */
	updateExternalFindingDescription: MutationOperation<{ pathParams: { externalFindingDescriptionTypeId: string }, body: ExternalFindingsDescription }, void>

	/**
	 * Update external storage backends
	 *
	 * Updates the external storage backend identified by the given name with the value in the request body. The API requires the user to have Edit permissions on the External Storage Backends externalStorageBackendName.
	 * @param externalStorageBackendName Name of the external storage backend which should be updated.
	 */
	updateExternalStorageBackend: MutationOperation<{ pathParams: { externalStorageBackendName: string }, body: ExternalStorageBackendData }, void>

	/**
	 * Updates the minimization options for the given job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	updateJobOptions: MutationOperation<{ pathParams: { project: string, jobId: string }, queryParams: UpdateJobOptionsQueryParams }, void>

	/**
	 * Update metric threshold configuration
	 *
	 * Updates the metric threshold configuration identified by the given name with the value in the request body. Requires the edit permission for the respective metric threshold configuration.
	 */
	updateMetricThresholdConfiguration: MutationOperation<{ body: MetricThresholdConfiguration }, void>

	/**
	 * Update user group
	 *
	 * Updates the user group identified by the given name with the value in the request body. The API requires the user to have Edit permissions on the Group oldUserGroupName.
	 * @param oldUserGroupName Old name of the user group to change
	 */
	updateUserGroup: MutationOperation<{ pathParams: { oldUserGroupName: string }, body: UserGroup }, void>

	/**
	 * Upload external report(s)
	 *
	 * Adds external reports to the session. For performance reasons, it is recommended to batch calls to this service, i.e. not commit all files using single calls. This service is public API since Teamscale 5.9. The API requires the user to have Perform External Uploads permissions on the project.
	 * @param project The project ID.
	 * @param sessionId If session ID is provided, the results will be appended to the given session instead of creating a new session. Use \&quot;auto-create\&quot; in place of session ID to create a new session, perform upload and commit session in one step.
	 */
	uploadReport: MutationOperation<{ pathParams: { project: string, sessionId: string }, queryParams: UploadReportQueryParams, body: UploadReportBody }, void>

	/**
	 * Validates a branching configuration
	 *
	 * Validates a given branching configuration. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	validateBranchingConfiguration: MutationOperation<{ body: ProjectBranchingConfiguration }, string | null>

	/**
	 * Verifies that there is a PowerShellScriptAnalyzer installed on the server, has the required  version, and can be called by Teamscale.
	 *
	 * Verifies the PowerShellScriptAnalyzer installation on the server. The API requires the user to have Create Analysis Profiles permissions.
	 */
	verifyPowershellScriptAnalyzerSetup: MutationOperation<void, string>

}

/** Maps operation IDs to the actual path, method and expected Content-Type. */
export const urlMapping = { 
	autocompleteIssueId: {
		method: 'GET',
		path: '/api/projects/{project}/issues/find'
	},
	autocompleteUserGroup: {
		method: 'GET',
		path: '/api/user-groups/find'
	},
	autocompleteUserName: {
		method: 'GET',
		path: '/api/users/find'
	},
	calculateActiveUsersStatistics: {
		method: 'GET',
		path: '/api/user-activity-history'
	},
	calculateCommitterStatistics: {
		method: 'GET',
		path: '/api/committer-activity-history'
	},
	commitAnalysisResults: {
		method: 'POST',
		path: '/api/projects/{project}/external-analysis/session/{sessionId}'
	},
	computeMetricTable: {
		method: 'GET',
		path: '/api/projects/{project}/metrics/table'
	},
	createAnalysisProfile: {
		method: 'POST',
		path: '/api/analysis-profiles',
		contentType: 'application/json'
	},
	createBackup: {
		method: 'POST',
		path: '/api/backups/export',
		contentType: 'application/x-www-form-urlencoded'
	},
	createComparison: {
		method: 'POST',
		path: '/api/instance-comparison/snapshots/{snapshotId}/comparisons',
		contentType: 'application/x-www-form-urlencoded'
	},
	createDashboard: {
		method: 'POST',
		path: '/api/dashboards',
		contentType: 'application/json'
	},
	createDashboardFromTemplate: {
		method: 'POST',
		path: '/api/dashboards/from-template'
	},
	createDashboardTemplate: {
		method: 'POST',
		path: '/api/dashboard-templates',
		contentType: 'application/json'
	},
	createExternalAccounts: {
		method: 'POST',
		path: '/api/external-accounts',
		contentType: 'application/json'
	},
	createExternalAnalysisGroup: {
		method: 'POST',
		path: '/api/external-findings/groups',
		contentType: 'application/json'
	},
	createExternalFindingDescription: {
		method: 'POST',
		path: '/api/external-findings/descriptions',
		contentType: 'application/json'
	},
	createExternalStorageBackend: {
		method: 'POST',
		path: '/api/external-storage-backends',
		contentType: 'application/json'
	},
	createMetricThresholdConfiguration: {
		method: 'POST',
		path: '/api/metric-threshold-configurations',
		contentType: 'application/json'
	},
	createNewRandomAccessKey: {
		method: 'POST',
		path: '/api/users/{user}/access-key'
	},
	createOrUpdateBaseline: {
		method: 'PUT',
		path: '/api/projects/{project}/baselines/{baseline}',
		contentType: 'application/json'
	},
	createProject: {
		method: 'POST',
		path: '/api/projects',
		contentType: 'application/json'
	},
	createQualityReport: {
		method: 'POST',
		path: '/api/quality-reports',
		contentType: 'application/json'
	},
	createSession: {
		method: 'POST',
		path: '/api/projects/{project}/external-analysis/session'
	},
	createSnapshot: {
		method: 'POST',
		path: '/api/instance-comparison/snapshots',
		contentType: 'application/x-www-form-urlencoded'
	},
	createUserGroup: {
		method: 'POST',
		path: '/api/user-groups',
		contentType: 'application/json'
	},
	deleteAllUnprocessedArchitectureCommits: {
		method: 'DELETE',
		path: '/api/projects/{project}/architectures/all-unprocessed-uploads'
	},
	deleteAnalysisProfile: {
		method: 'DELETE',
		path: '/api/analysis-profiles/{analysisProfileName}'
	},
	deleteAnalysisResults: {
		method: 'DELETE',
		path: '/api/projects/{project}/external-analysis/session/{sessionId}'
	},
	deleteBaseline: {
		method: 'DELETE',
		path: '/api/projects/{project}/baselines/{baseline}'
	},
	deleteComparison: {
		method: 'DELETE',
		path: '/api/instance-comparison/snapshots/{snapshotId}/comparisons/{comparisonId}'
	},
	deleteDashboard: {
		method: 'DELETE',
		path: '/api/dashboards/{dashboardId}'
	},
	deleteDashboardTemplate: {
		method: 'DELETE',
		path: '/api/dashboard-templates/{templateId}'
	},
	deleteEventAnnouncement: {
		method: 'DELETE',
		path: '/api/event-announcement/delete/{eventId}'
	},
	deleteExternalAccounts: {
		method: 'DELETE',
		path: '/api/external-accounts/{externalCredentialsName}'
	},
	deleteExternalAnalysisGroup: {
		method: 'DELETE',
		path: '/api/external-findings/groups/{groupName}'
	},
	deleteExternalFindingDescription: {
		method: 'DELETE',
		path: '/api/external-findings/descriptions/{externalFindingDescriptionName}'
	},
	deleteExternalStorageBackend: {
		method: 'DELETE',
		path: '/api/external-storage-backends/{externalStorageBackendName}'
	},
	deleteJobTests: {
		method: 'DELETE',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests',
		contentType: 'application/json'
	},
	deleteMetricThresholdConfiguration: {
		method: 'DELETE',
		path: '/api/metric-threshold-configurations/{metricThresholdConfigurationName}'
	},
	deleteProjectOption: {
		method: 'DELETE',
		path: '/api/projects/{project}/options/{optionId}'
	},
	deleteProjectOption1: {
		method: 'DELETE',
		path: '/api/options/server/{optionId}'
	},
	deleteQualityReport: {
		method: 'DELETE',
		path: '/api/quality-reports/{id}'
	},
	deleteSnapshot: {
		method: 'DELETE',
		path: '/api/instance-comparison/snapshots/{id}'
	},
	deleteTestListDescriptor: {
		method: 'DELETE',
		path: '/api/projects/{project}/test-lists/{testListName}'
	},
	deleteUserGroup: {
		method: 'DELETE',
		path: '/api/user-groups/{userGroupName}'
	},
	deleteUserOption: {
		method: 'DELETE',
		path: '/api/users/{user}/options/{optionId}'
	},
	downloadAnalysisProfileDocumentation: {
		method: 'GET',
		path: '/api/analysis-profiles/{analysisProfileName}/documentation'
	},
	downloadHeapDump: {
		method: 'POST',
		path: '/api/debug/heap-dump',
		contentType: 'application/x-www-form-urlencoded'
	},
	downloadTestSelectionCsv: {
		method: 'GET',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests/csv',
		acceptType: 'text/csv'
	},
	editDashboard: {
		method: 'PUT',
		path: '/api/dashboards/{dashboardId}',
		contentType: 'application/json'
	},
	editProjectOption: {
		method: 'PUT',
		path: '/api/projects/{project}/options/{optionId}',
		contentType: 'application/json'
	},
	editServerOption: {
		method: 'PUT',
		path: '/api/options/server/{optionId}',
		contentType: 'application/json'
	},
	editUserOption: {
		method: 'PUT',
		path: '/api/users/{user}/options/{optionId}',
		contentType: 'application/json'
	},
	editUsers: {
		method: 'POST',
		path: '/api/users',
		contentType: 'application/json'
	},
	executeSchedulerCommand: {
		method: 'POST',
		path: '/api/scheduler',
		contentType: 'application/x-www-form-urlencoded'
	},
	exportAnalysisProfile: {
		method: 'GET',
		path: '/api/analysis-profiles/{analysisProfileName}/export'
	},
	exportDashboard: {
		method: 'GET',
		path: '/api/dashboards/{dashboardId}/export'
	},
	exportDashboardTemplate: {
		method: 'GET',
		path: '/api/dashboard-templates/{templateId}/export'
	},
	externalAccountsExist: {
		method: 'GET',
		path: '/api/external-accounts/{externalCredentialsName}'
	},
	externalStorageBackendExists: {
		method: 'GET',
		path: '/api/external-storage-backends/{externalStorageBackendName}'
	},
	extractJobQueue: {
		method: 'GET',
		path: '/api/execution-status/queue'
	},
	findInitialCommit: {
		method: 'GET',
		path: '/api/projects/{project}/initial-commit-finder/{unresolvedCommit}'
	},
	findLogEntriesInRange: {
		method: 'GET',
		path: '/api/projects/{project}/repository-log-range'
	},
	flagFindings: {
		method: 'PUT',
		path: '/api/projects/{project}/findings/flagged',
		contentType: 'application/json'
	},
	getAccessKey: {
		method: 'GET',
		path: '/api/users/{user}/access-key'
	},
	getActiveUsersForProjects: {
		method: 'POST',
		path: '/api/user-activity-history',
		contentType: 'application/json'
	},
	getAdditionallyCoveredMethods: {
		method: 'GET',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests/additional-coverage'
	},
	getAggregatedTriggerPerformanceMetrics: {
		method: 'GET',
		path: '/api/debug/performance/trigger/aggregate.csv'
	},
	getAllDashboardTemplates: {
		method: 'GET',
		path: '/api/dashboard-templates'
	},
	getAllDashboards: {
		method: 'GET',
		path: '/api/dashboards'
	},
	getAllExternalAccounts: {
		method: 'GET',
		path: '/api/external-accounts'
	},
	getAllExternalAnalysisGroups: {
		method: 'GET',
		path: '/api/external-findings/groups'
	},
	getAllExternalStorageBackends: {
		method: 'GET',
		path: '/api/external-storage-backends'
	},
	getAllFindingsDescriptions: {
		method: 'GET',
		path: '/api/external-findings/descriptions'
	},
	getAllGitTags: {
		method: 'GET',
		path: '/api/projects/{project}/git-tags'
	},
	getAllMetricThresholdConfigurationNames: {
		method: 'GET',
		path: '/api/metric-threshold-configurations/names'
	},
	getAllMetricThresholdConfigurations: {
		method: 'GET',
		path: '/api/metric-threshold-configurations'
	},
	getAllMetricsForThresholdProfiles: {
		method: 'GET',
		path: '/api/metric-threshold-configurations/metrics'
	},
	getAllParseLogOverviewEntries: {
		method: 'GET',
		path: '/api/system/parse-log/overview'
	},
	getAllPartitionDetails: {
		method: 'GET',
		path: '/api/projects/{project}/test-executions/partitions/details'
	},
	getAllProjects: {
		method: 'GET',
		path: '/api/projects'
	},
	getAllSnapshotIds: {
		method: 'GET',
		path: '/api/instance-comparison/snapshots/ids'
	},
	getAllThreadsDumpAsHtml: {
		method: 'GET',
		path: '/api/debug/thread-dump',
		acceptType: 'text/html'
	},
	getAllUserGroupNames: {
		method: 'GET',
		path: '/api/user-groups/names'
	},
	getAllUserGroups: {
		method: 'GET',
		path: '/api/user-groups'
	},
	getAllUsers: {
		method: 'GET',
		path: '/api/users'
	},
	getAnalysisEditHistoryVersionDiff: {
		method: 'GET',
		path: '/api/analysis-profiles/compare-versions/{analysisProfileName}'
	},
	getAnalysisProfile: {
		method: 'GET',
		path: '/api/analysis-profiles/{analysisProfileName}'
	},
	getAnalysisProfileEditVersionHistory: {
		method: 'GET',
		path: '/api/analysis-profiles/history/{analysisProfileName}'
	},
	getAnalysisProfileEditVersionHistoryTimestamps: {
		method: 'GET',
		path: '/api/analysis-profiles/history-timestamps/{analysisProfileName}'
	},
	getAnalysisProfileNames: {
		method: 'GET',
		path: '/api/analysis-profiles'
	},
	getAnalysisProfileUsageInfo: {
		method: 'GET',
		path: '/api/analysis-profiles/with-referenced-projects'
	},
	getAnalysisProfileWithProjects: {
		method: 'GET',
		path: '/api/analysis-profiles/{analysisProfileName}/with-referenced-projects'
	},
	getAnalysisResultsByPath: {
		method: 'GET',
		path: '/api/analysis/sonarlint'
	},
	getAnalysisStateChangeTiming: {
		method: 'GET',
		path: '/api/debug/performance/state-changes.csv'
	},
	getAnalysisStatus: {
		method: 'GET',
		path: '/api/analysis/sonarlint/status'
	},
	getAssociatedComparisonIds: {
		method: 'GET',
		path: '/api/instance-comparison/snapshots/{id}/comparisons/ids'
	},
	getAvailableServers: {
		method: 'GET',
		path: '/api/auth/servers'
	},
	getAvatarData: {
		method: 'GET',
		path: '/api/avatars/{user}'
	},
	getBackupStatus: {
		method: 'GET',
		path: '/api/backups/export/{backupId}/status'
	},
	getBackupStatus1: {
		method: 'GET',
		path: '/api/backups/import/{backupId}/status'
	},
	getBackupsForImportWizard: {
		method: 'GET',
		path: '/api/import-wizard'
	},
	getBaseline: {
		method: 'GET',
		path: '/api/projects/{project}/baselines/{baseline}'
	},
	getBranchForSpecItem: {
		method: 'GET',
		path: '/api/projects/{project}/spec-items/{id}/branch'
	},
	getBranchesGetRequest: {
		method: 'GET',
		path: '/api/projects/{project}/branches'
	},
	getBranchesPostRequest: {
		method: 'POST',
		path: '/api/projects/{project}/branches'
	},
	getChangeTreemap: {
		method: 'GET',
		path: '/api/projects/{project}/change-treemap'
	},
	getCodeComponentsTreemapWrapper: {
		method: 'GET',
		path: '/api/projects/{project}/audit/code-components-treemap'
	},
	getCodeReferencesAndTestExecutionMapping: {
		method: 'GET',
		path: '/api/projects/{project}/spec-items/references'
	},
	getCodeSearchMatchResultAndTreemap: {
		method: 'GET',
		path: '/api/projects/{project}/audit/code-search/match-list-and-treemap'
	},
	getCommitAlerts: {
		method: 'GET',
		path: '/api/projects/{project}/commit-alerts'
	},
	getComparison: {
		method: 'GET',
		path: '/api/instance-comparison/snapshots/{snapshotId}/comparisons/{comparisonId}'
	},
	getCompleteExampleList: {
		method: 'GET',
		path: '/api/instance-comparison/snapshots/{snapshotId}/comparisons/{comparisonId}/{project}/{contributor}/{diffEntryName}'
	},
	getConcurrencyPerformanceDetails: {
		method: 'GET',
		path: '/api/debug/performance/trigger/concurrency'
	},
	getCoverageSources: {
		method: 'GET',
		path: '/api/projects/{project}/test-gaps/overview'
	},
	getDashboard: {
		method: 'GET',
		path: '/api/dashboards/{dashboardId}'
	},
	getDashboardTemplateDescriptor: {
		method: 'GET',
		path: '/api/dashboard-templates/{templateId}'
	},
	getDebugContent: {
		method: 'GET',
		path: '/api/projects/{project}/debug/content/{uniformPath}'
	},
	getDeclarationOfAccessibility: {
		method: 'GET',
		path: '/api/accessibility/declaration',
		acceptType: 'text/markdown'
	},
	getDeclarationOfAccessibilityContext: {
		method: 'GET',
		path: '/api/context/accessibility'
	},
	getDefaultBranchNameGetRequest: {
		method: 'GET',
		path: '/api/projects/{project}/default-branch'
	},
	getDependencyGraph: {
		method: 'GET',
		path: '/api/projects/{project}/audit/dependency-graph-extractor/{uniformPath}'
	},
	getDumpCommitDescriptor: {
		method: 'GET',
		path: '/api/projects/{project}/debug/commit-descriptors',
		acceptType: 'text/plain'
	},
	getDumpCommitTree: {
		method: 'GET',
		path: '/api/projects/{project}/debug/commit-tree',
		acceptType: 'text/plain'
	},
	getDumpCommitTreeNodes: {
		method: 'GET',
		path: '/api/projects/{project}/debug/commit-tree-nodes'
	},
	getEventAnnouncements: {
		method: 'GET',
		path: '/api/event-announcement/events'
	},
	getExceptionsHierarchyTree: {
		method: 'GET',
		path: '/api/projects/{project}/exceptions-hierarchy/object'
	},
	getExceptionsHierarchyTreeSVG: {
		method: 'GET',
		path: '/api/projects/{project}/exceptions-hierarchy/graph'
	},
	getFilesWithIdenticalContent: {
		method: 'GET',
		path: '/api/projects/{project}/audit/files-with-identical-content/{uniformPath}'
	},
	getFilesWithIdenticalNames: {
		method: 'GET',
		path: '/api/projects/{project}/audit/files-with-identical-name/{uniformPath}'
	},
	getFindingChurnCount: {
		method: 'GET',
		path: '/api/projects/{project}/finding-churn/count'
	},
	getFindingDelta: {
		method: 'GET',
		path: '/api/projects/{project}/findings/delta'
	},
	getFindingTypeDescriptions: {
		method: 'POST',
		path: '/api/projects/{project}/finding-type-descriptors',
		contentType: 'application/json'
	},
	getFindings: {
		method: 'GET',
		path: '/api/projects/{project}/findings/list'
	},
	getFindingsExport: {
		method: 'GET',
		path: '/api/projects/{project}/findings/list/export/{format}'
	},
	getFindingsSiblings: {
		method: 'GET',
		path: '/api/projects/{project}/findings/previousNextSiblings/{finding}'
	},
	getFindingsSummary: {
		method: 'GET',
		path: '/api/projects/{project}/findings/summary'
	},
	getFindingsTreemap: {
		method: 'GET',
		path: '/api/projects/{project}/findings/treemap'
	},
	getFindingsWithCount: {
		method: 'GET',
		path: '/api/projects/{project}/findings/list/with-count'
	},
	getFlaggedFindingIds: {
		method: 'GET',
		path: '/api/projects/{project}/findings/flagged/ids'
	},
	getFlaggedFindingInfo: {
		method: 'GET',
		path: '/api/projects/{project}/findings/flagged/{finding}'
	},
	getFlaggedFindings: {
		method: 'GET',
		path: '/api/projects/{project}/findings/flagged'
	},
	getFlaggedFindingsInfos: {
		method: 'POST',
		path: '/api/projects/{project}/findings/flagged/with-ids',
		contentType: 'application/json'
	},
	getGitConnectorIds: {
		method: 'GET',
		path: '/api/projects/{project}/connectors/git/connector-ids'
	},
	getGitHubAppInstallationRepositories: {
		method: 'GET',
		path: '/api/github/repositories'
	},
	getGitHubAppInstallations: {
		method: 'GET',
		path: '/api/github/app-installations'
	},
	getGitHubAppUrls: {
		method: 'GET',
		path: '/api/github/urls'
	},
	getGitTagByName: {
		method: 'GET',
		path: '/api/projects/{project}/git-tags/{tagName}'
	},
	getGlobalBranchesGetRequest: {
		method: 'GET',
		path: '/api/branches'
	},
	getGlobalDefaultBranchGetRequest: {
		method: 'GET',
		path: '/api/branches/default-branch'
	},
	getGlobalRecentBranchesGetRequest: {
		method: 'GET',
		path: '/api/branches/recent-branches'
	},
	getGroupAutoCompletionSuggestions: {
		method: 'GET',
		path: '/api/auth/import/groups/auto-completion-suggestions/{input}'
	},
	getHeapDumpFormPage: {
		method: 'GET',
		path: '/api/debug/heap-dump',
		acceptType: 'text/html'
	},
	getIssuesByRepositoryCommits: {
		method: 'POST',
		path: '/api/projects/{project}/commit-issues',
		contentType: 'application/x-www-form-urlencoded'
	},
	getIssuesDetails: {
		method: 'GET',
		path: '/api/projects/{project}/issues/details'
	},
	getJob: {
		method: 'GET',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}'
	},
	getJobOptions: {
		method: 'GET',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/options'
	},
	getJobQueueSize: {
		method: 'GET',
		path: '/api/execution-status/queue-size'
	},
	getJobs: {
		method: 'GET',
		path: '/api/projects/{project}/minimized-tests/jobs'
	},
	getLastCommitOfType: {
		method: 'GET',
		path: '/api/projects/{project}/repository/last-commit'
	},
	getLateExternalUploadProcessingInfo: {
		method: 'GET',
		path: '/api/projects/{project}/late-external-upload'
	},
	getLicenseInfo: {
		method: 'GET',
		path: '/api/license-info'
	},
	getLinkRoles: {
		method: 'PUT',
		path: '/api/spec-items/default/{accountName}/schema/link-roles',
		contentType: 'application/json'
	},
	getListOfActiveCommitters: {
		method: 'GET',
		path: '/api/license/active-committer-names'
	},
	getListOfActiveCommittersForProjects: {
		method: 'POST',
		path: '/api/license/active-committer-names',
		contentType: 'application/json'
	},
	getLoginContext: {
		method: 'GET',
		path: '/api/context/login'
	},
	getMergeRequestFindingChurn: {
		method: 'GET',
		path: '/api/projects/{project}/merge-requests/finding-churn'
	},
	getMergeRequestRepositoryChurn: {
		method: 'GET',
		path: '/api/projects/{project}/merge-requests/repository-churn'
	},
	getMethodHistory: {
		method: 'GET',
		path: '/api/projects/{project}/methods/history/{uniformPath}'
	},
	getMetricDirectorySchemaForPath: {
		method: 'GET',
		path: '/api/projects/{project}/metric-schema/{uniformPathType}'
	},
	getMetricDirectorySchemas: {
		method: 'GET',
		path: '/api/metric-schema'
	},
	getMetricThresholdConfiguration: {
		method: 'GET',
		path: '/api/metric-threshold-configurations/{metricThresholdConfigurationName}'
	},
	getMinimizedTests: {
		method: 'GET',
		path: '/api/projects/{project}/minimized-tests'
	},
	getNumberOfActiveUsers: {
		method: 'GET',
		path: '/api/global-warnings/activeUsers'
	},
	getPartitions: {
		method: 'GET',
		path: '/api/projects/{project}/external-analysis/status/partitions'
	},
	getPermissionLookup: {
		method: 'GET',
		path: '/api/basic-permissions'
	},
	getPreCommitContent: {
		method: 'GET',
		path: '/api/projects/{project}/content/formatted/pre-commit'
	},
	getProjectAnalysisProgress: {
		method: 'GET',
		path: '/api/projects/{project}/analysis-progress'
	},
	getProjectComparisonResultsForContributor: {
		method: 'GET',
		path: '/api/instance-comparison/snapshots/{snapshotId}/comparisons/{comparisonId}/{project}'
	},
	getProjectNotificationRules: {
		method: 'GET',
		path: '/api/notification-rules/project'
	},
	getProjectOptions: {
		method: 'GET',
		path: '/api/projects/{project}/options'
	},
	getProjectUserActivityTrend: {
		method: 'GET',
		path: '/api/unique-project-users/trend'
	},
	getRecentBranches: {
		method: 'GET',
		path: '/api/projects/{project}/recent-branches'
	},
	getRedundantLiteralTreemap: {
		method: 'GET',
		path: '/api/projects/{project}/redundant-literals/treemap'
	},
	getRedundantLiteralsQuery: {
		method: 'GET',
		path: '/api/projects/{project}/redundant-literals'
	},
	getReport: {
		method: 'GET',
		path: '/api/quality-reports/{id}'
	},
	getRepositoryChurn: {
		method: 'GET',
		path: '/api/projects/{project}/repository-churn'
	},
	getRepositoryLog: {
		method: 'GET',
		path: '/api/projects/{project}/repository-logs'
	},
	getRepositoryLogFileHistoryForCommits: {
		method: 'GET',
		path: '/api/projects/{project}/commits/affected-files'
	},
	getRepositorySummary: {
		method: 'GET',
		path: '/api/projects/{project}/repository-summary'
	},
	getResourceHistory: {
		method: 'GET',
		path: '/api/projects/{project}/{uniformPath}/resource-history'
	},
	getReviewBaseline: {
		method: 'GET',
		path: '/api/projects/{project}/baseline'
	},
	getRisks: {
		method: 'GET',
		path: '/api/projects/{project}/minimized-tests/risks'
	},
	getSchedulerControlPage: {
		method: 'GET',
		path: '/api/scheduler',
		acceptType: 'text/html'
	},
	getSelectedTests: {
		method: 'GET',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests'
	},
	getServiceLoad: {
		method: 'GET',
		path: '/api/service-load'
	},
	getSingleProjectOption: {
		method: 'GET',
		path: '/api/projects/{project}/options/{optionId}'
	},
	getSingleServerOption: {
		method: 'GET',
		path: '/api/options/server/{optionId}'
	},
	getSnapshot: {
		method: 'GET',
		path: '/api/instance-comparison/snapshots/{id}'
	},
	getSnapshotStatus: {
		method: 'GET',
		path: '/api/instance-comparison/snapshots/{id}/status'
	},
	getSpecItemChurn: {
		method: 'GET',
		path: '/api/projects/{project}/delta/affected-spec-items'
	},
	getSpecItemDetails: {
		method: 'GET',
		path: '/api/projects/{project}/spec-items/details'
	},
	getStacktrace: {
		method: 'GET',
		path: '/api/projects/{project}/tests/{test}/stacktrace'
	},
	getStateChangeTimestamps: {
		method: 'GET',
		path: '/api/debug/performance/state/timestamps/{project}'
	},
	getStorePerformanceDetails: {
		method: 'GET',
		path: '/api/debug/performance/store-details.csv'
	},
	getStoredTestLists: {
		method: 'GET',
		path: '/api/projects/{project}/test-lists'
	},
	getStoredTestListsNames: {
		method: 'GET',
		path: '/api/projects/{project}/test-lists/names'
	},
	getSummary1: {
		method: 'GET',
		path: '/api/backups/import/summary'
	},
	getSystemInformation: {
		method: 'GET',
		path: '/api/system-info'
	},
	getTeamscaleShutdownPage: {
		method: 'GET',
		path: '/api/shutdown',
		acceptType: 'text/html'
	},
	getTechnologyDependencies: {
		method: 'GET',
		path: '/api/projects/{project}/audit/technology/dependencies'
	},
	getTechnologyTreeMap: {
		method: 'GET',
		path: '/api/projects/{project}/audit/technology/treemap'
	},
	getTestCoverage: {
		method: 'GET',
		path: '/api/projects/{project}/test-coverage/{uniformPath}'
	},
	getTestCoverageOverlay: {
		method: 'GET',
		path: '/api/projects/{project}/overlay-tests'
	},
	getTestCoveragePost: {
		method: 'POST',
		path: '/api/projects/{project}/test-coverage/{uniformPath}',
		contentType: 'application/x-www-form-urlencoded'
	},
	getTestGapDataAsCsv: {
		method: 'GET',
		path: '/api/projects/{project}/test-gaps.csv',
		acceptType: 'text/csv'
	},
	getTestGapTreeMap: {
		method: 'GET',
		path: '/api/projects/{project}/test-gaps/treemap'
	},
	getTgaPercentage: {
		method: 'GET',
		path: '/api/projects/{project}/test-gaps/percentage'
	},
	getTgaTable: {
		method: 'GET',
		path: '/api/projects/{project}/test-gaps/metrics'
	},
	getTgaTestCoveragePartitions: {
		method: 'GET',
		path: '/api/projects/{project}/test-coverage/line-based/partitions'
	},
	getTgaTrend: {
		method: 'GET',
		path: '/api/projects/{project}/test-gaps/trend'
	},
	getTriggerGraphDot: {
		method: 'GET',
		path: '/api/projects/{project}/debug/triggers/graph-json'
	},
	getTriggerPerformanceDetails: {
		method: 'GET',
		path: '/api/debug/performance/trigger/details.csv'
	},
	getUnusedEventId: {
		method: 'GET',
		path: '/api/event-announcement/unused-id'
	},
	getUserAutoCompletionSuggestions: {
		method: 'GET',
		path: '/api/auth/import/users/auto-completion-suggestions/{input}'
	},
	getUserAvatarData: {
		method: 'GET',
		path: '/api/avatars/{user}/image',
		acceptType: 'image/*'
	},
	getUserGroup: {
		method: 'GET',
		path: '/api/user-groups/{userGroupName}'
	},
	hasSufficientRam: {
		method: 'GET',
		path: '/api/global-warnings/sufficientRam'
	},
	hideEventAnnouncement: {
		method: 'POST',
		path: '/api/event-announcement/hide',
		contentType: 'application/json'
	},
	importAbapLintConfigurationFile: {
		method: 'POST',
		path: '/api/import-abaplint-configuration',
		contentType: 'multipart/form-data'
	},
	importAnalysisProfile: {
		method: 'POST',
		path: '/api/analysis-profiles/import',
		contentType: 'multipart/form-data'
	},
	importAndReplaceDashboards: {
		method: 'PUT',
		path: '/api/dashboards/import',
		contentType: 'multipart/form-data'
	},
	importBackup: {
		method: 'POST',
		path: '/api/backups/import',
		contentType: 'multipart/form-data'
	},
	importConfiguration: {
		method: 'POST',
		path: '/api/import-swiftlint-configuration',
		contentType: 'multipart/form-data'
	},
	importDashboard: {
		method: 'POST',
		path: '/api/dashboards/import',
		contentType: 'multipart/form-data'
	},
	importDashboardTemplate: {
		method: 'POST',
		path: '/api/dashboard-templates/import',
		contentType: 'multipart/form-data'
	},
	importGroup: {
		method: 'POST',
		path: '/api/auth/import/groups/{groupName}'
	},
	importUser: {
		method: 'POST',
		path: '/api/auth/import/users/{userName}'
	},
	isMergeRequestsEnabled: {
		method: 'GET',
		path: '/api/projects/{project}/feature/merge-request'
	},
	isSnapshotBackupSupported: {
		method: 'GET',
		path: '/api/storage-snapshot'
	},
	isSpecItemAnalysisEnabled: {
		method: 'GET',
		path: '/api/projects/{project}/feature/spec-item'
	},
	listAllParseLogEntries: {
		method: 'GET',
		path: '/api/projects/{project}/parse-log/all'
	},
	listMergeRequests: {
		method: 'GET',
		path: '/api/projects/{project}/merge-requests'
	},
	listQualityReports: {
		method: 'GET',
		path: '/api/quality-reports'
	},
	loadFromNamedTestList: {
		method: 'POST',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests/load-from-named-list'
	},
	markDashboardAsFavorite: {
		method: 'POST',
		path: '/api/dashboards/{dashboardId}/favorite'
	},
	performLogin: {
		method: 'POST',
		path: '/api/auth/login',
		contentType: 'application/json'
	},
	performTestQuery: {
		method: 'GET',
		path: '/api/projects/{project}/test-query'
	},
	postCommitAlerts: {
		method: 'POST',
		path: '/api/projects/{project}/commit-alerts',
		contentType: 'application/x-www-form-urlencoded'
	},
	postFindingChurnCount: {
		method: 'POST',
		path: '/api/projects/{project}/finding-churn/count',
		contentType: 'application/x-www-form-urlencoded'
	},
	postJobTests: {
		method: 'POST',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests',
		contentType: 'application/json'
	},
	postRepositoryLog: {
		method: 'POST',
		path: '/api/projects/{project}/repository-logs',
		contentType: 'application/x-www-form-urlencoded'
	},
	postResolveUserAliases: {
		method: 'POST',
		path: '/api/users/names/resolution',
		contentType: 'application/x-www-form-urlencoded'
	},
	postTgaPercentage: {
		method: 'POST',
		path: '/api/projects/{project}/test-gaps/percentage',
		contentType: 'application/x-www-form-urlencoded'
	},
	postUserAvatarData: {
		method: 'POST',
		path: '/api/avatars/{user}/image',
		contentType: 'multipart/form-data'
	},
	putAvatarData: {
		method: 'PUT',
		path: '/api/avatars/{user}'
	},
	putJobTestsFromImpacted: {
		method: 'PUT',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests-from-impacted'
	},
	putJobTestsFromQuery: {
		method: 'PUT',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests'
	},
	putQualityReport: {
		method: 'PUT',
		path: '/api/quality-reports/{id}',
		contentType: 'application/json'
	},
	redirectToCorrectHost: {
		method: 'GET',
		path: '/api/instance-comparison/redirect'
	},
	regenerateStoredServiceProviderCertificate: {
		method: 'POST',
		path: '/api/auth/saml/sp-configuration/certificate/generate'
	},
	removeAccessKey: {
		method: 'DELETE',
		path: '/api/users/{user}/access-key'
	},
	requestAnalysisAndOpenSession: {
		method: 'POST',
		path: '/api/analysis/sonarlint',
		contentType: 'application/json'
	},
	resolveTag: {
		method: 'POST',
		path: '/api/projects/{project}/git-tags/resolve',
		contentType: 'application/json'
	},
	runDatabaseCompaction: {
		method: 'POST',
		path: '/api/database-compaction'
	},
	saveAsNamedTestList: {
		method: 'POST',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests/save-as-named-list',
		contentType: 'application/json'
	},
	saveTestListDescriptor: {
		method: 'POST',
		path: '/api/projects/{project}/test-lists',
		contentType: 'application/json'
	},
	setProjectNotificationRules: {
		method: 'PUT',
		path: '/api/notification-rules/project',
		contentType: 'application/json'
	},
	setupJob: {
		method: 'POST',
		path: '/api/projects/{project}/minimized-tests/jobs'
	},
	showDefaultAdminWarning: {
		method: 'GET',
		path: '/api/global-warnings/defaultAdmin'
	},
	showDefaultBackupSettingsWarning: {
		method: 'GET',
		path: '/api/global-warnings/defaultBackupSettings'
	},
	showInMemoryWarning: {
		method: 'GET',
		path: '/api/global-warnings/inMemory'
	},
	showShadowModeWarning: {
		method: 'GET',
		path: '/api/global-warnings/shadowMode'
	},
	shutdown: {
		method: 'POST',
		path: '/api/shutdown',
		contentType: 'application/x-www-form-urlencoded'
	},
	startJob: {
		method: 'POST',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/start'
	},
	stopAndDeleteJob: {
		method: 'DELETE',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}'
	},
	storeEventAnnouncement: {
		method: 'PUT',
		path: '/api/event-announcement/events/{eventId}',
		contentType: 'application/json'
	},
	triggerSnapshotBackup: {
		method: 'POST',
		path: '/api/storage-snapshot',
		contentType: 'multipart/form-data'
	},
	unmarkToleratedAndFalsePositiveFindingsBeforeCommit: {
		method: 'DELETE',
		path: '/api/projects/{project}/findings/unmark-before-commit'
	},
	updateAnalysisProfile: {
		method: 'PUT',
		path: '/api/analysis-profiles',
		contentType: 'application/json'
	},
	updateDashboardTemplate: {
		method: 'PUT',
		path: '/api/dashboard-templates/{templateId}',
		contentType: 'application/json'
	},
	updateExternalAccounts: {
		method: 'PUT',
		path: '/api/external-accounts/{externalCredentialsName}',
		contentType: 'application/json'
	},
	updateExternalAnalysisGroup: {
		method: 'PUT',
		path: '/api/external-findings/groups/{groupName}',
		contentType: 'application/json'
	},
	updateExternalFindingDescription: {
		method: 'PUT',
		path: '/api/external-findings/descriptions/{externalFindingDescriptionTypeId}',
		contentType: 'application/json'
	},
	updateExternalStorageBackend: {
		method: 'PUT',
		path: '/api/external-storage-backends/{externalStorageBackendName}',
		contentType: 'application/json'
	},
	updateJobOptions: {
		method: 'PUT',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}'
	},
	updateMetricThresholdConfiguration: {
		method: 'PUT',
		path: '/api/metric-threshold-configurations',
		contentType: 'application/json'
	},
	updateUserGroup: {
		method: 'PUT',
		path: '/api/user-groups/{oldUserGroupName}',
		contentType: 'application/json'
	},
	uploadReport: {
		method: 'POST',
		path: '/api/projects/{project}/external-analysis/session/{sessionId}/report',
		contentType: 'multipart/form-data'
	},
	validateBranchingConfiguration: {
		method: 'PUT',
		path: '/api/branching-configuration/validation',
		contentType: 'application/json'
	},
	verifyPowershellScriptAnalyzerSetup: {
		method: 'GET',
		path: '/api/verify-powershell-script-analyzer-setup'
	}
} as const;
