import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { ReactNode } from 'react';
import * as React from 'react';
import { useContext, useState, type JSX } from 'react';
import type { Callback } from 'ts/base/Callback';
import type { FileListQuery } from 'ts/commons/dialog/PathEntitySelectionModalContent';
import { useProjectIds } from 'ts/commons/dialog/PathEntitySelectionModalContent';
import { useDebouncedAndLiveState } from 'ts/commons/hooks/UseDebouncedAndLiveState';
import { StringUtils } from 'ts/commons/StringUtils';
import { UniformPath } from 'ts/commons/UniformPath';
import type { EType } from 'ts/typedefs/EType';

/** The "public" API of the path selector context */
export type ExposedPathEntitySelectionContext = PathEntitySelectionContextProviderProps & {
	/** Currently selected project (if you have given one or if one can be selected) */
	selectedProject?: string;

	/** The currently selected path which is affected by debouncing */
	selectedPath: UniformPath;

	/** This will always contain the most recent path. It is not affected by debouncing */
	livePath: UniformPath;

	/** Currently selected commit */
	selectedCommit: UnresolvedCommitDescriptor;

	/**
	 * Whether there are any errors. If true, then the currently selected values cannot be submitted and the input is
	 * marked red.
	 */
	containsError: boolean;

	setProject: Callback<string | undefined>;

	/** Sets the path (immediately) */
	setPath: Callback<UniformPath>;

	/**
	 * Sets the path, however, the selected path will only be updated 500ms after the last call to this method. Prevents
	 * unnecessary rendering in cases where there are constant changes but only the last change is relevant.
	 */
	setPathDebounced: Callback<UniformPath>;

	setError: Callback<boolean>;
	setCommit: Callback<UnresolvedCommitDescriptor>;

	/** Can be used to check the validity of a given path string */
	forbiddenUniformPathTypes: EType[];

	/** Whether the currently selected project is valid or not */
	validProject: boolean;

	/** Resets the project, path and commit back to the initial values */
	reset: Callback<void>;

	/** Checks whether the given path string is allowed */
	isAllowed: (path: string) => boolean;
};

/** Context that holds the current state of the modal based on the PathEntitySelectionModal */
const PathEntitySelectionContext = React.createContext<ExposedPathEntitySelectionContext | undefined>(undefined);

export type PathEntitySelectionContextProviderProps = {
	initialProject?: string;
	initialPath?: UniformPath;
	initialCommit?: UnresolvedCommitDescriptor;
	forbiddenUniformPathTypes?: EType[];

	/** If true, it is possible to navigate below the actual file paths and select a different project */
	projectsSelectable?: boolean;

	/**
	 * If false, it is not possible to navigate up the directory. This should only be used in conjunction with entries
	 * which prohibit navigating down, meaning they do not provide a changed path.
	 */
	disableDirNavigation?: boolean;

	/**
	 * Fetches the entries for this list using the currently selected project, path and commit. IMPORTANT: This method
	 * must be called unconditionally because it implements react hooks.
	 */
	useEntries: (
		project: string,
		path: UniformPath,
		commit: UnresolvedCommitDescriptor,
		enable: boolean
	) => FileListQuery;
};

export function PathEntitySelectionContextProvider({
	initialProject,
	initialPath = new UniformPath('/'),
	initialCommit = UnresolvedCommitDescriptor.createLatestOnDefaultBranch(),
	forbiddenUniformPathTypes = [],
	projectsSelectable,
	disableDirNavigation,
	useEntries,
	children
}: PathEntitySelectionContextProviderProps & { children: ReactNode }): JSX.Element {
	const {
		state: selectedPath,
		liveState: livePath,
		setState: setPath,
		setStateDebounced: setPathDebounced
	} = useDebouncedAndLiveState(initialPath, 500);
	const [selectedProject, setProject] = useState(initialProject);
	const [selectedCommit, setCommit] = useState(initialCommit);
	const [containsError, setError] = useState(false);

	const projects = useProjectIds();

	const reset = () => {
		setProject(initialProject);
		setPath(initialPath);
		setCommit(initialCommit);
	};

	let validProject = false;
	if (projects != null) {
		validProject = !StringUtils.isEmptyOrWhitespace(selectedProject) && projects.includes(selectedProject);
	}

	const isAllowed = (path: string): boolean => {
		return checkPath(path, forbiddenUniformPathTypes);
	};

	const value: ExposedPathEntitySelectionContext = {
		selectedProject,
		setProject,
		selectedPath,
		livePath,
		setPath,
		setPathDebounced,
		selectedCommit,
		setCommit,
		containsError,
		setError,
		forbiddenUniformPathTypes,
		projectsSelectable,
		disableDirNavigation,
		useEntries,
		validProject,
		reset,
		isAllowed
	};

	return <PathEntitySelectionContext.Provider value={value}>{children}</PathEntitySelectionContext.Provider>;
}

export function usePathEntitySelectionContext(): ExposedPathEntitySelectionContext {
	const context = useContext(PathEntitySelectionContext);
	if (context === undefined) {
		throw new Error('usePathEntitySelectionContext must be used within a PathEntitySelectionModal');
	}
	return context;
}

/**
 * Checks whether the current selected path is allowed for according to the blacklist {@link forbiddenUniformPathTypes}.
 *
 * @returns Whether the current selected path is an allowed value.
 */
function checkPath(path: string, forbiddenUniformPathTypes: EType[]): boolean {
	const entityPath = new UniformPath(StringUtils.stripSuffix(path, '/'));
	return !entityPath.isAnyOfTypes(forbiddenUniformPathTypes);
}
