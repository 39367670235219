import { ColorUtils } from 'ts/commons/ColorUtils';
import type { ETestGapStateEntry } from 'typedefs/ETestGapState';
import { ETestGapState } from 'typedefs/ETestGapState';
import { ETrafficLightColor } from 'typedefs/ETrafficLightColor';

/**
 * The description of the semantics of a color, i.e. mapping of a color to specific semantics. Note that the
 * color-mapping should be kept in sync with the back-end (ColorUtils.ColorBlindModeColors), until this redundancy is
 * resolved.
 */
export type SemanticColor = {
	color: string;
	colorBlindColor: string;
	readableName: string;
};

/** A semantic color with an additional description. */
export type SemanticColorWithDescription = SemanticColor & {
	description: string;
};

/** Returns a semantic color's representation depending on whether the color-blind mode is enabled or not. */
export function getColorRepresentationForSemanticColor(
	semanticColor: SemanticColor,
	enableColorBlindMode?: boolean
): string {
	if (enableColorBlindMode) {
		return semanticColor.colorBlindColor;
	}
	return semanticColor.color;
}

/** See {@link ColorUtils#TRAFFIC_LIGHT_RED}. */
export const TRAFFIC_LIGHT_RED: SemanticColor = {
	color: ColorUtils.TRAFFIC_LIGHT_RED,
	colorBlindColor: ColorUtils.VIRIDIS_COLOR_CATEGORY_3,
	readableName: 'RED'
};

/** See {@link ColorUtils#TRAFFIC_LIGHT_ORANGE}. */
export const TRAFFIC_LIGHT_ORANGE: SemanticColor = {
	color: ColorUtils.TRAFFIC_LIGHT_ORANGE,
	colorBlindColor: ColorUtils.VIRIDIS_COLOR_CATEGORY_2,
	readableName: 'ORANGE'
};

/**
 * See {@link ColorUtils#TRAFFIC_LIGHT_YELLOW}. Whereas orange is used in the context of TGA, yellow is only used in the
 * context of metrics (and findings). As yellow and orange do thus not occur in the same view, they can map to the same
 * color-blind color.
 */
export const TRAFFIC_LIGHT_YELLOW: SemanticColor = {
	color: ColorUtils.TRAFFIC_LIGHT_YELLOW,
	colorBlindColor: ColorUtils.VIRIDIS_COLOR_CATEGORY_2,
	readableName: 'YELLOW'
};

/**
 * An alternative version of the traffic light yellow which is preferred to be used for numeric values in Metric table
 * and system quality overview slide.
 */
export const TRAFFIC_LIGHT_YELLOW_ALTERNATIVE: SemanticColor = {
	color: ColorUtils.YELLOW_READABLE_ON_BRIGHT_BACKGROUND,
	colorBlindColor: ColorUtils.VIRIDIS_COLOR_CATEGORY_2,
	readableName: 'YELLOW'
};

/** See {@link ColorUtils#TRAFFIC_LIGHT_GREEN}. */
export const TRAFFIC_LIGHT_GREEN: SemanticColor = {
	color: ColorUtils.TRAFFIC_LIGHT_GREEN,
	colorBlindColor: ColorUtils.VIRIDIS_COLOR_CATEGORY_1,
	readableName: 'GREEN'
};

/** See {@link ETrafficLightColor#BASELINE}. */
export const TRAFFIC_LIGHT_BASELINE: SemanticColor = {
	color: ColorUtils.WHITE,
	colorBlindColor: ColorUtils.WHITE,
	readableName: 'BASELINE'
};

/** See {@link ETrafficLightColor#UNKNOWN}. */
export const TRAFFIC_LIGHT_UNKNOWN: SemanticColor = {
	color: ColorUtils.TRAFFIC_LIGHT_UNKNOWN,
	colorBlindColor: ColorUtils.TRAFFIC_LIGHT_UNKNOWN,
	readableName: 'UNKNOWN'
};

/** All Traffic Light colors as semantic colors. */
export const TRAFFIC_LIGHT_SEMANTIC_COLORS = [
	TRAFFIC_LIGHT_RED,
	TRAFFIC_LIGHT_ORANGE,
	TRAFFIC_LIGHT_YELLOW,
	TRAFFIC_LIGHT_GREEN,
	TRAFFIC_LIGHT_BASELINE,
	TRAFFIC_LIGHT_UNKNOWN
];

/** Color of entities to be covered by selected tests. */
export const PLANED_COVERAGE: SemanticColorWithDescription = {
	color: ColorUtils.GREEN_BLUE,
	colorBlindColor: ColorUtils.GREEN_BLUE,
	readableName: 'To be covered',
	description: 'Running the chosen tests will likely cover this code.'
};

/** Color of entities that can be covered by tests. */
export const COVERABLE: SemanticColorWithDescription = {
	color: ColorUtils.CYAN,
	colorBlindColor: ColorUtils.CYAN,
	readableName: 'Can be covered',
	description:
		'There are known tests that would cover the method, but the tests are not within the selected test budget.'
};

/** Color of entities that can be covered by tests. */
export const NO_COVERAGE_INFOS: SemanticColorWithDescription = {
	color: ColorUtils.LIGHT_GRAY,
	colorBlindColor: ColorUtils.LIGHT_GRAY,
	readableName: 'No coverage information available',
	description: 'The method has not been covered in any test.'
};

/** Color of entities that were added but are not covered by a test. */
export const ADDED_NOT_COVERED_ELEMENT: SemanticColorWithDescription = {
	color: ColorUtils.TEAMSCALE_RED,
	colorBlindColor: ColorUtils.TEAMSCALE_RED,
	readableName: 'Added but not covered by a test',
	description: 'Running the chosen tests does not cover this newly added code.'
};

/** Color of entities that were modified but are not covered by a test. */
export const MODIFIED_NOT_COVERED_ELEMENT: SemanticColorWithDescription = {
	color: ColorUtils.TEAMSCALE_YELLOW,
	colorBlindColor: ColorUtils.TEAMSCALE_YELLOW,
	readableName: 'Modified but not covered by a test',
	description: 'Running the chosen tests does not cover this modified code.'
};

/** Color of methods that were modified. */
export const CHANGED_METHOD: SemanticColorWithDescription = {
	color: ColorUtils.TEAMSCALE_ORANGE,
	colorBlindColor: ColorUtils.TEAMSCALE_ORANGE,
	readableName: 'Modified',
	description:
		'Orange methods are most likely to have caused the failure. These are code changes to methods that are covered by the test that happened after the last successful run of this test.'
};

/** Color of methods that were not modified, but the method coverage changed. */
export const UNCHANGED_METHOD_COVER_CHANGED: SemanticColorWithDescription = {
	color: ColorUtils.BLUE_TEAL,
	colorBlindColor: ColorUtils.BLUE_TEAL,
	readableName: 'Unchanged but coverage changed',
	description:
		'Turquoise methods are second most likely to have caused the failure. These have been covered in the last failed run, but not in the last successful run, potentially indicating a flickering or unstable test.'
};

/** Color of methods that were not changed. */
export const UNCHANGED_METHOD: SemanticColorWithDescription = {
	color: ColorUtils.TEAMSCALE_GREEN,
	colorBlindColor: ColorUtils.TEAMSCALE_GREEN,
	readableName: 'Unchanged',
	description:
		'Green methods are unlikely to be the cause of the test failure. Their code was not changed and they were covered in both the latest failure and successful run of the test.'
};

/** Color of {@link ETestGapState#EXECUTED}. */
export const TGA_EXECUTED: SemanticColorWithDescription = {
	color: ETrafficLightColor.GREEN.hexValue,
	colorBlindColor: ColorUtils.VIRIDIS_COLOR_CATEGORY_1,
	readableName: ETestGapState.EXECUTED.shortDescription,
	description: ETestGapState.EXECUTED.longDescription
};

/** Color of {@link ETestGapState#NOT_EXECUTED}. */
export const TGA_NOT_EXECUTED: SemanticColorWithDescription = {
	color: ColorUtils.LIGHT_GRAY,
	colorBlindColor: ColorUtils.VIRIDIS_COLOR_CATEGORY_NEUTRAL,
	readableName: ETestGapState.NOT_EXECUTED.shortDescription,
	description: ETestGapState.NOT_EXECUTED.longDescription
};

/** Color of {@link ETestGapState#TESTED_CHURN}. */
export const TGA_TESTED_CHURN: SemanticColorWithDescription = {
	color: ETrafficLightColor.GREEN.hexValue,
	colorBlindColor: ColorUtils.VIRIDIS_COLOR_CATEGORY_1,
	readableName: ETestGapState.TESTED_CHURN.shortDescription,
	description: ETestGapState.TESTED_CHURN.longDescription
};

/** Color of {@link ETestGapState#UNTESTED_CHANGE}. */
export const TGA_UNTESTED_CHANGE: SemanticColorWithDescription = {
	color: ETrafficLightColor.YELLOW.hexValue,
	colorBlindColor: ColorUtils.VIRIDIS_COLOR_CATEGORY_2,
	readableName: ETestGapState.UNTESTED_CHANGE.shortDescription,
	description: ETestGapState.UNTESTED_CHANGE.longDescription
};

/** Color of {@link ETestGapState#UNTESTED_ADDITION}. */
export const TGA_UNTESTED_ADDITION: SemanticColorWithDescription = {
	color: ETrafficLightColor.RED.hexValue,
	colorBlindColor: ColorUtils.VIRIDIS_COLOR_CATEGORY_3,
	readableName: ETestGapState.UNTESTED_ADDITION.shortDescription,
	description: ETestGapState.UNTESTED_ADDITION.longDescription
};

/** Color of {@link ETestGapState#UNCHANGED}. */
export const TGA_UNCHANGED: SemanticColorWithDescription = {
	color: ColorUtils.LIGHT_GRAY,
	colorBlindColor: ColorUtils.VIRIDIS_COLOR_CATEGORY_NEUTRAL,
	readableName: ETestGapState.UNCHANGED.shortDescription,
	description: ETestGapState.UNCHANGED.longDescription
};

/** Color of {@link ETestGapState#NA}. */
export const TGA_NA: SemanticColorWithDescription = {
	color: ColorUtils.LIGHT_GRAY,
	colorBlindColor: ColorUtils.VIRIDIS_COLOR_CATEGORY_NEUTRAL,
	readableName: ETestGapState.NA.shortDescription,
	description: ETestGapState.NA.longDescription
};

/**
 * A record mapping {@link ETestGapState} entities to their respective semantic color. The semantic color contains both
 * standard colors and color-blind mode colors.
 */
export const semanticColorMapping: Record<ETestGapStateEntry, SemanticColorWithDescription> = {
	EXECUTED: TGA_EXECUTED,
	NOT_EXECUTED: TGA_NOT_EXECUTED,
	TESTED_CHURN: TGA_TESTED_CHURN,
	UNTESTED_CHANGE: TGA_UNTESTED_CHANGE,
	UNTESTED_ADDITION: TGA_UNTESTED_ADDITION,
	UNCHANGED: TGA_UNCHANGED,
	NA: TGA_NA
};
