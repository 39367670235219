import type { DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import { Dropdown } from 'semantic-ui-react';
import type { Callback } from 'ts/base/Callback';

/** Props of a {@link GitTagPreFilterDropdown}. */
export type GitTagPreFilterDropdownProps<T> = DropdownProps & {
	testId: string;
	selectedValue: string | null;
	setSelectedValue: Callback<string | null>;
	displayObjects: T[] | undefined;
	optionMapper: (item: T) => DropdownItemProps;
};

/** Renders a dropdown for pre-filtering git tags. */
export function GitTagPreFilterDropdown<T>({
	testId,
	selectedValue,
	setSelectedValue,
	displayObjects,
	optionMapper
}: GitTagPreFilterDropdownProps<T>) {
	const options = displayObjects?.map(optionMapper) || [];
	return (
		<Dropdown
			fluid
			search
			selection
			data-testid={testId}
			options={options}
			disabled={options.length <= 1}
			onChange={(event, { value }) => setSelectedValue(value as string)}
			value={selectedValue || ''}
		/>
	);
}

/** Can be used as an option mapper for {@link GitTagPreFilterDropdown} if simple strings are used to identify options. */
export function createGitTagPreFilterDropdownOptions(item: string) {
	return { value: item, text: item };
}
