import type { ReactNode } from 'react';
import type { Root } from 'react-dom/client';
import type { WrapperStyles } from 'ts/base/ReactUtils';
import { ReactUtils } from 'ts/base/ReactUtils';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';

/**
 * Base class that provides helper methods similar to ReactUtils to render components, keeps track of them and
 * automatically unmounts them when dispose is called.
 */
export abstract class ReactDisposable {
	private readonly reactElements: Set<Root> = new Set<Root>();

	/**
	 * Renders the given react component into the container element. The component will be automatically unmounted when
	 * the view is disposed. The perspective context is made available inside the component via the following hooks:
	 *
	 * - UsePerspectiveContext
	 * - UseUserInfo
	 * - UseUserPermissionInfo
	 * - UseProjectInfos
	 * - UseTeamscaleInfo
	 */
	protected renderComponent(component: ReactNode, container: Element): Root {
		const root = ReactUtils.render(component, container, this.getPerspectiveContext());
		this.reactElements.add(root);
		return root;
	}

	/**
	 * Appends a new DOM element that will serve as a React root element and can be filled with #replace or
	 * #replaceStatic later on.
	 */
	protected appendRoot(container: Element | DocumentFragment, wrapperStyles?: WrapperStyles): Root {
		const wrapper = ReactUtils.appendRoot(container, wrapperStyles);
		this.reactElements.add(wrapper);
		return wrapper;
	}

	/**
	 * Convenience method that creates a separate child in the given container into which the given component is
	 * rendered. The component will be automatically unmounted when the view is disposed.
	 *
	 * @returns The auto-created wrapper element
	 */
	protected appendComponent(
		component: ReactNode,
		container: Element | DocumentFragment,
		wrapperStyles?: WrapperStyles
	): Root {
		const wrapper = ReactUtils.append(component, container, this.getPerspectiveContext(), wrapperStyles);
		this.reactElements.add(wrapper);
		return wrapper;
	}

	/**
	 * Convenience method that creates a separate child in the given container as first child into which the given
	 * component is rendered. The component will be automatically unmounted when the view is disposed.
	 *
	 * @returns The auto-created wrapper element
	 */
	protected prependComponent(component: ReactNode, container: Element | DocumentFragment): Root {
		const wrapper = ReactUtils.prepend(component, container, this.getPerspectiveContext());
		this.reactElements.add(wrapper);
		return wrapper;
	}

	/** Replaces the currently rendered content with the new component. */
	protected replaceComponent(component: ReactNode, container: Root): void {
		ReactUtils.replace(component, container, this.getPerspectiveContext());
		this.reactElements.add(container);
	}

	/** Provides access to the perspective context. */
	protected abstract getPerspectiveContext(): ExtendedPerspectiveContext;

	/** Allows to clean up resources or unregister listeners when the view/widget is no longer needed. */
	public dispose(): void {
		for (const element of this.reactElements) {
			ReactUtils.unmount(element);
		}
		this.reactElements.clear();
	}

	/**
	 * Unmounts the React component from the given DOM element. This needs to be called before the DOM node is removed
	 * from the DOM.
	 */
	protected unmountComponent(element: Root | undefined | null): void {
		if (element != null) {
			this.reactElements.delete(element);
		}
		ReactUtils.unmount(element);
	}
}
