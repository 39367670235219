import { GitTagFormatter } from 'ts/commons/time/GitTagFormatter';
import { DateTimeFormatter } from './DateTimeFormatter';
import { DefinedPointInTimeFormatter } from './DefinedPointInTimeFormatter';
import { EPointInTimeType } from './EPointInTimeType';
import { RevisionFormatter } from './RevisionFormatter';
import { TimespanFormatter } from './TimespanFormatter';
import type { TypedPointInTime } from './TypedPointInTime';

/** Provides formatting for TypedPointInTime objects. */
export class TimeFormatter {
	/** Lookup map for formatters for different types of points in time. */
	private static readonly FORMATTERS = {
		[EPointInTimeType.BASELINE]: new DefinedPointInTimeFormatter('Baseline'),
		[EPointInTimeType.SYSTEM_VERSION]: new DefinedPointInTimeFormatter('System Version'),
		[EPointInTimeType.TIMESTAMP]: new DateTimeFormatter(),
		[EPointInTimeType.REVISION]: new RevisionFormatter(),
		[EPointInTimeType.GIT_TAG]: new GitTagFormatter(),
		[EPointInTimeType.TIMESPAN]: new TimespanFormatter()
	} as const;

	/** Creates a readable string. */
	public static simple(pointInTime: TypedPointInTime): string {
		// @ts-ignore
		return TimeFormatter.FORMATTERS[pointInTime.type]!.format(pointInTime.value);
	}

	/** Creates a readable string when indicating a trend since the point in time to format. */
	public static asTrend(pointInTime: TypedPointInTime): string {
		// @ts-ignore
		return TimeFormatter.FORMATTERS[pointInTime.type]!.formatAsTrend(pointInTime.value);
	}

	/** Creates a readable string when used as a trend in the task summary widget. */
	public static asTaskTrend(pointInTime: TypedPointInTime): string {
		// @ts-ignore
		return TimeFormatter.FORMATTERS[pointInTime.type]!.formatAsTaskTrend(pointInTime.value);
	}

	/** Creates a readable string when used as a baseline in the findings perspective. */
	public static asBaseline(pointInTime: TypedPointInTime): string {
		// @ts-ignore
		return TimeFormatter.FORMATTERS[pointInTime.type]!.formatAsBaseline(pointInTime.value);
	}
}
