// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/commons/LinkTemplate.soy

/**
 * @fileoverview Templates in namespace ts.commons.LinkTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import * as google from 'ts-closure-library/lib/google';
import * as goog_soy_data from 'soyutils/soyutils_usegoog';
import * as soy from 'soyutils/soyutils_usegoog';


/**
 * @param {?$newDashboard.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $newDashboard = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.newDashboard']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.newDashboard'](opt_data, $ijData);
  }
  opt_data = opt_data || {};
  /** @type {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} */
  const commit = soy.assertParamType(opt_data.commit == null || google.isObject(opt_data.commit), 'commit', opt_data.commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  /** @type {null|string|undefined} */
  const project = soy.assertParamType(opt_data.project == null || typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'null|string|undefined');
  return $uniformLink(soy.$$assignDefaults({perspective: 'dashboard', viewName: 'new', commit: commit, project: project != null ? project : ''}, opt_data), $ijData);
};
export { $newDashboard as newDashboard };
/**
 * @typedef {{
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  project?: (null|string|undefined),
 *  uniformPath?: (null|string|undefined),
 *  parameters?: (?|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$newDashboard.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $newDashboard.soyTemplateName = 'ts.commons.LinkTemplate.newDashboard';
}


/**
 * @param {?$dashboard.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $dashboard = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.dashboard']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.dashboard'](opt_data, $ijData);
  }
  opt_data = opt_data || {};
  /** @type {null|string|undefined} */
  const name = soy.assertParamType(opt_data.name == null || typeof opt_data.name === 'string', 'name', opt_data.name, '@param', 'null|string|undefined');
  /** @type {boolean|null|undefined} */
  const kioskMode = soy.assertParamType(opt_data.kioskMode == null || typeof opt_data.kioskMode === 'boolean', 'kioskMode', opt_data.kioskMode, '@param', 'boolean|null|undefined');
  /** @type {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} */
  const commit = soy.assertParamType(opt_data.commit == null || google.isObject(opt_data.commit), 'commit', opt_data.commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  /** @type {null|string|undefined} */
  const project = soy.assertParamType(opt_data.project == null || typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'null|string|undefined');
  return $uniformLink(soy.$$assignDefaults({perspective: 'dashboard', viewName: '', parameters: {id: name, kioskViewMode: kioskMode}, commit: commit, project: project != null ? project : ''}, opt_data), $ijData);
};
export { $dashboard as dashboard };
/**
 * @typedef {{
 *  name?: (null|string|undefined),
 *  kioskMode?: (boolean|null|undefined),
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  project?: (null|string|undefined),
 *  uniformPath?: (null|string|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$dashboard.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $dashboard.soyTemplateName = 'ts.commons.LinkTemplate.dashboard';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $repositoryOverview = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.repositoryOverview']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.repositoryOverview'](opt_data, $ijData);
  }
  opt_data = opt_data || {};
  return $uniformLink(soy.$$assignDefaults({perspective: 'repository'}, opt_data), $ijData);
};
export { $repositoryOverview as repositoryOverview };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $repositoryOverview.soyTemplateName = 'ts.commons.LinkTemplate.repositoryOverview';
}


/**
 * @param {?$repositorySetup.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $repositorySetup = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.repositorySetup']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.repositorySetup'](opt_data, $ijData);
  }
  opt_data = opt_data || {};
  /** @type {null|string|undefined} */
  const step = soy.assertParamType(opt_data.step == null || typeof opt_data.step === 'string', 'step', opt_data.step, '@param', 'null|string|undefined');
  return $uniformLink(soy.$$assignDefaults({perspective: 'repository', viewName: 'setup', parameters: {step: step}}, opt_data), $ijData);
};
export { $repositorySetup as repositorySetup };
/**
 * @typedef {{
 *  step?: (null|string|undefined),
 *  project?: (null|string|undefined),
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  uniformPath?: (null|string|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$repositorySetup.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $repositorySetup.soyTemplateName = 'ts.commons.LinkTemplate.repositorySetup';
}


/**
 * @param {?$dashboardHistory.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $dashboardHistory = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.dashboardHistory']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.dashboardHistory'](opt_data, $ijData);
  }
  opt_data = opt_data || {};
  /** @type {null|string|undefined} */
  const name = soy.assertParamType(opt_data.name == null || typeof opt_data.name === 'string', 'name', opt_data.name, '@param', 'null|string|undefined');
  return $uniformLink(soy.$$assignDefaults({perspective: 'dashboard', viewName: 'history', parameters: {id: name}}, opt_data), $ijData);
};
export { $dashboardHistory as dashboardHistory };
/**
 * @typedef {{
 *  name?: (null|string|undefined),
 *  project?: (null|string|undefined),
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  uniformPath?: (null|string|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$dashboardHistory.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $dashboardHistory.soyTemplateName = 'ts.commons.LinkTemplate.dashboardHistory';
}


/**
 * @param {!$dashboardSharing.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $dashboardSharing = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.dashboardSharing']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.dashboardSharing'](opt_data, $ijData);
  }
  /** @type {string} */
  const id = soy.assertParamType(typeof opt_data.id === 'string', 'id', opt_data.id, '@param', 'string');
  return $uniformLink(soy.$$assignDefaults({perspective: 'dashboard', viewName: 'sharing', parameters: {id: id}}, opt_data), $ijData);
};
export { $dashboardSharing as dashboardSharing };
/**
 * @typedef {{
 *  id: string,
 *  project?: (null|string|undefined),
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  uniformPath?: (null|string|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$dashboardSharing.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $dashboardSharing.soyTemplateName = 'ts.commons.LinkTemplate.dashboardSharing';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $dashboardTemplates = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.dashboardTemplates']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.dashboardTemplates'](opt_data, $ijData);
  }
  opt_data = opt_data || {};
  return $uniformLink(soy.$$assignDefaults({perspective: 'dashboard', viewName: 'templates'}, opt_data), $ijData);
};
export { $dashboardTemplates as dashboardTemplates };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $dashboardTemplates.soyTemplateName = 'ts.commons.LinkTemplate.dashboardTemplates';
}


/**
 * @param {?$editDashboard.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $editDashboard = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.editDashboard']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.editDashboard'](opt_data, $ijData);
  }
  opt_data = opt_data || {};
  /** @type {null|string|undefined} */
  const name = soy.assertParamType(opt_data.name == null || typeof opt_data.name === 'string', 'name', opt_data.name, '@param', 'null|string|undefined');
  return $uniformLink(soy.$$assignDefaults({perspective: 'dashboard', viewName: 'edit', parameters: {id: name}}, opt_data), $ijData);
};
export { $editDashboard as editDashboard };
/**
 * @typedef {{
 *  name?: (null|string|undefined),
 *  project?: (null|string|undefined),
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  uniformPath?: (null|string|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$editDashboard.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editDashboard.soyTemplateName = 'ts.commons.LinkTemplate.editDashboard';
}


/**
 * @param {!$baselines.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $baselines = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.baselines']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.baselines'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  return $uniformLink(soy.$$assignDefaults({perspective: 'qualitycontrol', viewName: 'baselines'}, opt_data), $ijData);
};
export { $baselines as baselines };
/**
 * @typedef {{
 *  project: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  uniformPath?: (null|string|undefined),
 *  parameters?: (?|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$baselines.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $baselines.soyTemplateName = 'ts.commons.LinkTemplate.baselines';
}


/**
 * @param {!$findingDetails.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $findingDetails = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.findingDetails']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.findingDetails'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {string} */
  const id = soy.assertParamType(typeof opt_data.id === 'string', 'id', opt_data.id, '@param', 'string');
  /** @type {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} */
  const commit = soy.assertParamType(opt_data.commit == null || google.isObject(opt_data.commit), 'commit', opt_data.commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  /** @type {null|string|undefined} */
  const searchParamsId = soy.assertParamType(opt_data.searchParamsId == null || typeof opt_data.searchParamsId === 'string', 'searchParamsId', opt_data.searchParamsId, '@param', 'null|string|undefined');
  return $uniformLink(soy.$$assignDefaults({perspective: 'findings', viewName: 'details', parameters: {id: id, searchParamsId: searchParamsId}}, opt_data), $ijData);
};
export { $findingDetails as findingDetails };
/**
 * @typedef {{
 *  project: string,
 *  id: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  searchParamsId?: (null|string|undefined),
 *  uniformPath?: (null|string|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$findingDetails.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $findingDetails.soyTemplateName = 'ts.commons.LinkTemplate.findingDetails';
}


/**
 * @param {!$findingsList.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $findingsList = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.findingsList']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.findingsList'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {null|string|undefined} */
  const uniformPath = soy.assertParamType(opt_data.uniformPath == null || typeof opt_data.uniformPath === 'string', 'uniformPath', opt_data.uniformPath, '@param', 'null|string|undefined');
  /** @type {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} */
  const commit = soy.assertParamType(opt_data.commit == null || google.isObject(opt_data.commit), 'commit', opt_data.commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  /** @type {?} */
  const parameters = opt_data.parameters;
  return $uniformLink(soy.$$assignDefaults({perspective: 'findings', viewName: 'list', parameters: parameters}, opt_data), $ijData);
};
export { $findingsList as findingsList };
/**
 * @typedef {{
 *  project: string,
 *  uniformPath?: (null|string|undefined),
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  parameters?: (?|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$findingsList.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $findingsList.soyTemplateName = 'ts.commons.LinkTemplate.findingsList';
}


/**
 * @param {!$code.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $code = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.code']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.code'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {string} */
  const uniformPath = soy.assertParamType(typeof opt_data.uniformPath === 'string', 'uniformPath', opt_data.uniformPath, '@param', 'string');
  /** @type {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} */
  const commit = soy.assertParamType(opt_data.commit == null || google.isObject(opt_data.commit), 'commit', opt_data.commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  /** @type {!Array<string>|null|undefined} */
  const findingFilter = soy.assertParamType(opt_data.findingFilter == null || Array.isArray(opt_data.findingFilter), 'findingFilter', opt_data.findingFilter, '@param', '!Array<string>|null|undefined');
  /** @type {?} */
  const selection = opt_data.selection;
  /** @type {null|string|undefined} */
  const identifier = soy.assertParamType(opt_data.identifier == null || typeof opt_data.identifier === 'string', 'identifier', opt_data.identifier, '@param', 'null|string|undefined');
  /** @type {null|string|undefined} */
  const highlight = soy.assertParamType(opt_data.highlight == null || typeof opt_data.highlight === 'string', 'highlight', opt_data.highlight, '@param', 'null|string|undefined');
  /** @type {null|string|undefined} */
  const thresholdProfile = soy.assertParamType(opt_data.thresholdProfile == null || typeof opt_data.thresholdProfile === 'string', 'thresholdProfile', opt_data.thresholdProfile, '@param', 'null|string|undefined');
  /** @type {null|string|undefined} */
  const visibleMetric = soy.assertParamType(opt_data.visibleMetric == null || typeof opt_data.visibleMetric === 'string', 'visibleMetric', opt_data.visibleMetric, '@param', 'null|string|undefined');
  /** @type {boolean|null|undefined} */
  const highlightMetric = soy.assertParamType(opt_data.highlightMetric == null || typeof opt_data.highlightMetric === 'boolean', 'highlightMetric', opt_data.highlightMetric, '@param', 'boolean|null|undefined');
  /** @type {boolean|null|undefined} */
  const showCoverage = soy.assertParamType(opt_data.showCoverage == null || typeof opt_data.showCoverage === 'boolean', 'showCoverage', opt_data.showCoverage, '@param', 'boolean|null|undefined');
  /** @type {null|string|undefined} */
  const targetPerspectiveName = soy.assertParamType(opt_data.targetPerspectiveName == null || typeof opt_data.targetPerspectiveName === 'string', 'targetPerspectiveName', opt_data.targetPerspectiveName, '@param', 'null|string|undefined');
  /** @type {null|string|undefined} */
  const targetViewName = soy.assertParamType(opt_data.targetViewName == null || typeof opt_data.targetViewName === 'string', 'targetViewName', opt_data.targetViewName, '@param', 'null|string|undefined');
  let $output = '';
  let $tmp;
  if (!targetPerspectiveName && (soy.$$checkNotNull(uniformPath).startsWith('-test-implementation-/') || soy.$$checkNotNull(uniformPath).startsWith('-test-execution-/'))) {
    $tmp = 'tests';
  } else if (targetViewName) {
    $tmp = '' + targetViewName;
  } else {
    $tmp = '';
  }
  let targetView__soy28127 = '' + $tmp;
  let perspectiveName__soy28134 = '' + (targetPerspectiveName ? '' + targetPerspectiveName : uniformPath == '-architectures-' ? 'architecture' : 'metrics');
  let $tmp$$2;
  if (selection) {
    let $tmp$$1;
    if (selection.startOffset >= 0 && selection.endOffset >= 0) {
      $tmp$$1 = 'char-' + '' + selection.startOffset + '-' + '' + selection.endOffset;
    } else if (soy.$$coerceToBoolean(selection.startLine) && soy.$$coerceToBoolean(selection.endLine)) {
      $tmp$$1 = '' + selection.startLine + '-' + '' + selection.endLine;
    } else if (soy.$$coerceToBoolean(selection.firstLine) && soy.$$coerceToBoolean(selection.lastLine)) {
      $tmp$$1 = '' + selection.firstLine + '-' + '' + selection.lastLine;
    } else {
      $tmp$$1 = '';
    }
    $tmp$$2 = $tmp$$1;
  } else {
    $tmp$$2 = '';
  }
  let selectionParameter__soy28144 = '' + $tmp$$2;
  $output += $uniformLink(soy.$$assignDefaults({perspective: perspectiveName__soy28134, viewName: targetView__soy28127, parameters: {selection: selectionParameter__soy28144, filter: findingFilter, identifier: identifier, highlight: highlight != null ? highlight : '', profile: thresholdProfile != null ? thresholdProfile : '', visibleMetric: visibleMetric, highlightMetric: highlightMetric, showCoverage: showCoverage}}, opt_data), $ijData);
  return $output;
};
export { $code as code };
/**
 * @typedef {{
 *  project: string,
 *  uniformPath: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  findingFilter?: (!Array<string>|null|undefined),
 *  selection?: (?|undefined),
 *  identifier?: (null|string|undefined),
 *  highlight?: (null|string|undefined),
 *  thresholdProfile?: (null|string|undefined),
 *  visibleMetric?: (null|string|undefined),
 *  highlightMetric?: (boolean|null|undefined),
 *  showCoverage?: (boolean|null|undefined),
 *  targetPerspectiveName?: (null|string|undefined),
 *  targetViewName?: (null|string|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$code.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $code.soyTemplateName = 'ts.commons.LinkTemplate.code';
}


/**
 * @param {!$architectureMetrics.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $architectureMetrics = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.architectureMetrics']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.architectureMetrics'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {string} */
  const uniformPath = soy.assertParamType(typeof opt_data.uniformPath === 'string', 'uniformPath', opt_data.uniformPath, '@param', 'string');
  /** @type {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} */
  const commit = soy.assertParamType(opt_data.commit == null || google.isObject(opt_data.commit), 'commit', opt_data.commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  /** @type {null|string|undefined} */
  const thresholdProfile = soy.assertParamType(opt_data.thresholdProfile == null || typeof opt_data.thresholdProfile === 'string', 'thresholdProfile', opt_data.thresholdProfile, '@param', 'null|string|undefined');
  /** @type {null|string|undefined} */
  const visibleMetric = soy.assertParamType(opt_data.visibleMetric == null || typeof opt_data.visibleMetric === 'string', 'visibleMetric', opt_data.visibleMetric, '@param', 'null|string|undefined');
  /** @type {null|string|undefined} */
  const targetPerspectiveName = soy.assertParamType(opt_data.targetPerspectiveName == null || typeof opt_data.targetPerspectiveName === 'string', 'targetPerspectiveName', opt_data.targetPerspectiveName, '@param', 'null|string|undefined');
  return $uniformLink(soy.$$assignDefaults({perspective: targetPerspectiveName != null ? targetPerspectiveName : 'metrics', viewName: 'architecture', parameters: {thresholdProfile: thresholdProfile != null ? thresholdProfile : '', visibleMetric: visibleMetric}}, opt_data), $ijData);
};
export { $architectureMetrics as architectureMetrics };
/**
 * @typedef {{
 *  project: string,
 *  uniformPath: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  thresholdProfile?: (null|string|undefined),
 *  visibleMetric?: (null|string|undefined),
 *  targetPerspectiveName?: (null|string|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$architectureMetrics.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $architectureMetrics.soyTemplateName = 'ts.commons.LinkTemplate.architectureMetrics';
}


/**
 * @param {!$delta.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $delta = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.delta']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.delta'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {null|string|undefined} */
  const uniformPath = soy.assertParamType(opt_data.uniformPath == null || typeof opt_data.uniformPath === 'string', 'uniformPath', opt_data.uniformPath, '@param', 'null|string|undefined');
  /** @type {string} */
  const viewName = soy.assertParamType(typeof opt_data.viewName === 'string', 'viewName', opt_data.viewName, '@param', 'string');
  /** @type {{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} */
  const startTimestamp = soy.assertParamType(google.isObject(opt_data.startTimestamp), 'startTimestamp', opt_data.startTimestamp, '@param', '{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  /** @type {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} */
  const endTimestamp = soy.assertParamType(opt_data.endTimestamp == null || google.isObject(opt_data.endTimestamp), 'endTimestamp', opt_data.endTimestamp, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  /** @type {boolean|null|undefined} */
  const mergeMode = soy.assertParamType(opt_data.mergeMode == null || typeof opt_data.mergeMode === 'boolean', 'mergeMode', opt_data.mergeMode, '@param', 'boolean|null|undefined');
  /** @type {boolean|null|undefined} */
  const isSpecItemChurn = soy.assertParamType(opt_data.isSpecItemChurn == null || typeof opt_data.isSpecItemChurn === 'boolean', 'isSpecItemChurn', opt_data.isSpecItemChurn, '@param', 'boolean|null|undefined');
  /** @type {null|string|undefined} */
  const group = soy.assertParamType(opt_data.group == null || typeof opt_data.group === 'string', 'group', opt_data.group, '@param', 'null|string|undefined');
  /** @type {boolean|null|undefined} */
  const kioskMode = soy.assertParamType(opt_data.kioskMode == null || typeof opt_data.kioskMode === 'boolean', 'kioskMode', opt_data.kioskMode, '@param', 'boolean|null|undefined');
  let $output = '';
  let formattedStartCommit__soy28171 = '' + $commitForQueryValue$(soy.$$internalCallMarkerDoNotUse, $ijData, startTimestamp);
  let formattedEndCommit__soy28174 = '' + $commitForQueryValue$(soy.$$internalCallMarkerDoNotUse, $ijData, endTimestamp);
  $output += $uniformLink(soy.$$assignDefaults({perspective: 'delta', viewName: viewName, parameters: {from: formattedStartCommit__soy28171, to: formattedEndCommit__soy28174, showMergeFindings: mergeMode != null ? mergeMode : false, isSpecItemDelta: isSpecItemChurn != null ? isSpecItemChurn : false, kioskViewMode: kioskMode, group: group}}, opt_data), $ijData);
  return $output;
};
export { $delta as delta };
/**
 * @typedef {{
 *  project: string,
 *  uniformPath?: (null|string|undefined),
 *  viewName: string,
 *  startTimestamp: {timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),},
 *  endTimestamp?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  mergeMode?: (boolean|null|undefined),
 *  isSpecItemChurn?: (boolean|null|undefined),
 *  group?: (null|string|undefined),
 *  kioskMode?: (boolean|null|undefined),
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$delta.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $delta.soyTemplateName = 'ts.commons.LinkTemplate.delta';
}


/**
 * @param {!$searchResults.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $searchResults = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.searchResults']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.searchResults'](opt_data, $ijData);
  }
  /** @type {string} */
  const query = soy.assertParamType(typeof opt_data.query === 'string', 'query', opt_data.query, '@param', 'string');
  /** @type {null|string|undefined} */
  const project = soy.assertParamType(opt_data.project == null || typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'null|string|undefined');
  return $uniformLink(soy.$$assignDefaults({perspective: 'search', viewName: 'search', parameters: {query: query}, project: project}, opt_data), $ijData);
};
export { $searchResults as searchResults };
/**
 * @typedef {{
 *  query: string,
 *  project?: (null|string|undefined),
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  uniformPath?: (null|string|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$searchResults.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $searchResults.soyTemplateName = 'ts.commons.LinkTemplate.searchResults';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $searchView = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.searchView']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.searchView'](opt_data, $ijData);
  }
  opt_data = opt_data || {};
  return $uniformLink(soy.$$assignDefaults({perspective: 'search'}, opt_data), $ijData);
};
export { $searchView as searchView };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $searchView.soyTemplateName = 'ts.commons.LinkTemplate.searchView';
}


/**
 * @param {!$fileHistory.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $fileHistory = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.fileHistory']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.fileHistory'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {string} */
  const uniformPath = soy.assertParamType(typeof opt_data.uniformPath === 'string', 'uniformPath', opt_data.uniformPath, '@param', 'string');
  /** @type {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} */
  const commit = soy.assertParamType(opt_data.commit == null || google.isObject(opt_data.commit), 'commit', opt_data.commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  return $uniformLink(soy.$$assignDefaults({perspective: 'activity', viewName: 'history'}, opt_data), $ijData);
};
export { $fileHistory as fileHistory };
/**
 * @typedef {{
 *  project: string,
 *  uniformPath: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  parameters?: (?|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$fileHistory.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $fileHistory.soyTemplateName = 'ts.commons.LinkTemplate.fileHistory';
}


/**
 * @param {!$compareFileForTwoCommits.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $compareFileForTwoCommits = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $compareFileForTwoCommits$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.uniformPath, opt_data.leftCommit, opt_data.rightCommit);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {string} uniformPath
 * @param {{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} leftCommit
 * @param {{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} rightCommit
 * @return {string}
 * @suppress {checkTypes}
 */
const $compareFileForTwoCommits$ = function($$areYouAnInternalCaller, $ijData, project, uniformPath, leftCommit, rightCommit) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.compareFileForTwoCommits']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.compareFileForTwoCommits']({project: project, uniformPath: uniformPath, leftCommit: leftCommit, rightCommit: rightCommit}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(typeof uniformPath === 'string', 'uniformPath', uniformPath, '@param', 'string');
  soy.assertParamType(google.isObject(leftCommit), 'leftCommit', leftCommit, '@param', '{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  soy.assertParamType(google.isObject(rightCommit), 'rightCommit', rightCommit, '@param', '{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  return $compareFiles$(soy.$$internalCallMarkerDoNotUse, $ijData, project, uniformPath, leftCommit, uniformPath, rightCommit);
};
export { $compareFileForTwoCommits as compareFileForTwoCommits };
export { $compareFileForTwoCommits$ as compareFileForTwoCommits$ };
/**
 * @typedef {{
 *  project: string,
 *  uniformPath: string,
 *  leftCommit: {timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),},
 *  rightCommit: {timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),},
 * }}
 */
$compareFileForTwoCommits.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $compareFileForTwoCommits.soyTemplateName = 'ts.commons.LinkTemplate.compareFileForTwoCommits';
}


/**
 * @param {!$compareFiles.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $compareFiles = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $compareFiles$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.leftUniformPath, opt_data.leftCommit, opt_data.rightUniformPath, opt_data.rightCommit);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {string} leftUniformPath
 * @param {{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} leftCommit
 * @param {string} rightUniformPath
 * @param {{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} rightCommit
 * @return {string}
 * @suppress {checkTypes}
 */
const $compareFiles$ = function($$areYouAnInternalCaller, $ijData, project, leftUniformPath, leftCommit, rightUniformPath, rightCommit) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.compareFiles']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.compareFiles']({project: project, leftUniformPath: leftUniformPath, leftCommit: leftCommit, rightUniformPath: rightUniformPath, rightCommit: rightCommit}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(typeof leftUniformPath === 'string', 'leftUniformPath', leftUniformPath, '@param', 'string');
  soy.assertParamType(google.isObject(leftCommit), 'leftCommit', leftCommit, '@param', '{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  soy.assertParamType(typeof rightUniformPath === 'string', 'rightUniformPath', rightUniformPath, '@param', 'string');
  soy.assertParamType(google.isObject(rightCommit), 'rightCommit', rightCommit, '@param', '{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  return $comparePerspective$(soy.$$internalCallMarkerDoNotUse, $ijData, project, leftUniformPath, project, rightUniformPath, leftCommit, undefined, undefined, rightCommit);
};
export { $compareFiles as compareFiles };
export { $compareFiles$ as compareFiles$ };
/**
 * @typedef {{
 *  project: string,
 *  leftUniformPath: string,
 *  leftCommit: {timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),},
 *  rightUniformPath: string,
 *  rightCommit: {timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),},
 * }}
 */
$compareFiles.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $compareFiles.soyTemplateName = 'ts.commons.LinkTemplate.compareFiles';
}


/**
 * @param {!$compareLocationsInFiles.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $compareLocationsInFiles = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $compareLocationsInFiles$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.leftUniformPath, opt_data.leftStartLine, opt_data.leftEndLine, opt_data.rightUniformPath, opt_data.rightStartLine, opt_data.rightEndLine, opt_data.commit);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {string} leftUniformPath
 * @param {number} leftStartLine
 * @param {number} leftEndLine
 * @param {string} rightUniformPath
 * @param {number} rightStartLine
 * @param {number} rightEndLine
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @return {string}
 * @suppress {checkTypes}
 */
const $compareLocationsInFiles$ = function($$areYouAnInternalCaller, $ijData, project, leftUniformPath, leftStartLine, leftEndLine, rightUniformPath, rightStartLine, rightEndLine, commit) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.compareLocationsInFiles']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.compareLocationsInFiles']({project: project, leftUniformPath: leftUniformPath, leftStartLine: leftStartLine, leftEndLine: leftEndLine, rightUniformPath: rightUniformPath, rightStartLine: rightStartLine, rightEndLine: rightEndLine, commit: commit}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(typeof leftUniformPath === 'string', 'leftUniformPath', leftUniformPath, '@param', 'string');
  soy.assertParamType(typeof leftStartLine === 'number', 'leftStartLine', leftStartLine, '@param', 'number');
  soy.assertParamType(typeof leftEndLine === 'number', 'leftEndLine', leftEndLine, '@param', 'number');
  soy.assertParamType(typeof rightUniformPath === 'string', 'rightUniformPath', rightUniformPath, '@param', 'string');
  soy.assertParamType(typeof rightStartLine === 'number', 'rightStartLine', rightStartLine, '@param', 'number');
  soy.assertParamType(typeof rightEndLine === 'number', 'rightEndLine', rightEndLine, '@param', 'number');
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  return $comparePerspective$(soy.$$internalCallMarkerDoNotUse, $ijData, project, leftUniformPath, project, rightUniformPath, commit, leftStartLine, leftEndLine, commit, rightStartLine, rightEndLine);
};
export { $compareLocationsInFiles as compareLocationsInFiles };
export { $compareLocationsInFiles$ as compareLocationsInFiles$ };
/**
 * @typedef {{
 *  project: string,
 *  leftUniformPath: string,
 *  leftStartLine: number,
 *  leftEndLine: number,
 *  rightUniformPath: string,
 *  rightStartLine: number,
 *  rightEndLine: number,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 * }}
 */
$compareLocationsInFiles.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $compareLocationsInFiles.soyTemplateName = 'ts.commons.LinkTemplate.compareLocationsInFiles';
}


/**
 * @param {!$comparePerspective.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $comparePerspective = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $comparePerspective$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.leftProject, opt_data.leftUniformPath, opt_data.rightProject, opt_data.rightUniformPath, opt_data.leftCommit, opt_data.leftStartLine, opt_data.leftEndLine, opt_data.rightCommit, opt_data.rightStartLine, opt_data.rightEndLine, opt_data.isInconsistentClone);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} leftProject
 * @param {string} leftUniformPath
 * @param {string} rightProject
 * @param {string} rightUniformPath
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} leftCommit
 * @param {null|number|undefined=} leftStartLine
 * @param {null|number|undefined=} leftEndLine
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} rightCommit
 * @param {null|number|undefined=} rightStartLine
 * @param {null|number|undefined=} rightEndLine
 * @param {boolean|null|undefined=} isInconsistentClone
 * @return {string}
 * @suppress {checkTypes}
 */
const $comparePerspective$ = function($$areYouAnInternalCaller, $ijData, leftProject, leftUniformPath, rightProject, rightUniformPath, leftCommit, leftStartLine, leftEndLine, rightCommit, rightStartLine, rightEndLine, isInconsistentClone) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.comparePerspective']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.comparePerspective']({leftProject: leftProject, leftUniformPath: leftUniformPath, leftCommit: leftCommit, leftStartLine: leftStartLine, leftEndLine: leftEndLine, rightProject: rightProject, rightUniformPath: rightUniformPath, rightCommit: rightCommit, rightStartLine: rightStartLine, rightEndLine: rightEndLine, isInconsistentClone: isInconsistentClone}, $ijData);
  }
  soy.assertParamType(typeof leftProject === 'string', 'leftProject', leftProject, '@param', 'string');
  soy.assertParamType(typeof leftUniformPath === 'string', 'leftUniformPath', leftUniformPath, '@param', 'string');
  soy.assertParamType(leftCommit == null || google.isObject(leftCommit), 'leftCommit', leftCommit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  soy.assertParamType(leftStartLine == null || typeof leftStartLine === 'number', 'leftStartLine', leftStartLine, '@param', 'null|number|undefined');
  soy.assertParamType(leftEndLine == null || typeof leftEndLine === 'number', 'leftEndLine', leftEndLine, '@param', 'null|number|undefined');
  soy.assertParamType(typeof rightProject === 'string', 'rightProject', rightProject, '@param', 'string');
  soy.assertParamType(typeof rightUniformPath === 'string', 'rightUniformPath', rightUniformPath, '@param', 'string');
  soy.assertParamType(rightCommit == null || google.isObject(rightCommit), 'rightCommit', rightCommit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  soy.assertParamType(rightStartLine == null || typeof rightStartLine === 'number', 'rightStartLine', rightStartLine, '@param', 'null|number|undefined');
  soy.assertParamType(rightEndLine == null || typeof rightEndLine === 'number', 'rightEndLine', rightEndLine, '@param', 'null|number|undefined');
  soy.assertParamType(isInconsistentClone == null || typeof isInconsistentClone === 'boolean', 'isInconsistentClone', isInconsistentClone, '@param', 'boolean|null|undefined');
  let $output = '' + $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'compare') + $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'left-project', leftProject, true) + $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'right-project', rightProject) + $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'left-path', leftUniformPath) + $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'right-path', rightUniformPath);
  let leftFormattedCommit__soy28237 = '' + $commitForQueryValue$(soy.$$internalCallMarkerDoNotUse, $ijData, leftCommit);
  $output += $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'left-commit', leftFormattedCommit__soy28237);
  let rightFormattedCommit__soy28243 = '' + $commitForQueryValue$(soy.$$internalCallMarkerDoNotUse, $ijData, rightCommit);
  let $tmp;
  if (soy.$$coerceToBoolean(leftStartLine) && soy.$$coerceToBoolean(leftEndLine) && soy.$$coerceToBoolean(rightStartLine) && soy.$$coerceToBoolean(rightEndLine)) {
    $tmp = $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'region', '' + leftStartLine + '-' + leftEndLine + ':' + rightStartLine + '-' + rightEndLine);
  } else if (leftStartLine) {
    $tmp = $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'left-line', leftStartLine);
  } else if (rightStartLine) {
    $tmp = $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'right-line', rightStartLine);
  } else {
    $tmp = '';
  }
  $output += $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'right-commit', rightFormattedCommit__soy28243) + $tmp + (isInconsistentClone ? $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'inconsistent-clone', 'true') : '');
  return $output;
};
export { $comparePerspective as comparePerspective };
export { $comparePerspective$ as comparePerspective$ };
/**
 * @typedef {{
 *  leftProject: string,
 *  leftUniformPath: string,
 *  leftCommit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  leftStartLine?: (null|number|undefined),
 *  leftEndLine?: (null|number|undefined),
 *  rightProject: string,
 *  rightUniformPath: string,
 *  rightCommit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  rightStartLine?: (null|number|undefined),
 *  rightEndLine?: (null|number|undefined),
 *  isInconsistentClone?: (boolean|null|undefined),
 * }}
 */
$comparePerspective.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $comparePerspective.soyTemplateName = 'ts.commons.LinkTemplate.comparePerspective';
}


/**
 * @param {!$commitStream.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $commitStream = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.commitStream']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.commitStream'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {null|string|undefined} */
  const userFilter = soy.assertParamType(opt_data.userFilter == null || typeof opt_data.userFilter === 'string', 'userFilter', opt_data.userFilter, '@param', 'null|string|undefined');
  return $uniformLink(soy.$$assignDefaults({perspective: 'activity', viewName: 'commits', parameters: userFilter ? {user: userFilter} : []}, opt_data), $ijData);
};
export { $commitStream as commitStream };
/**
 * @typedef {{
 *  project: string,
 *  userFilter?: (null|string|undefined),
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  uniformPath?: (null|string|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$commitStream.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $commitStream.soyTemplateName = 'ts.commons.LinkTemplate.commitStream';
}


/**
 * @param {!$methodHistory.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $methodHistory = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.methodHistory']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.methodHistory'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {string} */
  const uniformPath = soy.assertParamType(typeof opt_data.uniformPath === 'string', 'uniformPath', opt_data.uniformPath, '@param', 'string');
  /** @type {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} */
  const commit = soy.assertParamType(opt_data.commit == null || google.isObject(opt_data.commit), 'commit', opt_data.commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  /** @type {number} */
  const startOffset = soy.assertParamType(typeof opt_data.startOffset === 'number', 'startOffset', opt_data.startOffset, '@param', 'number');
  /** @type {number} */
  const endOffset = soy.assertParamType(typeof opt_data.endOffset === 'number', 'endOffset', opt_data.endOffset, '@param', 'number');
  /** @type {!Array<string>} */
  const partitions = soy.assertParamType(Array.isArray(opt_data.partitions), 'partitions', opt_data.partitions, '@param', '!Array<string>');
  /** @type {!Array<string>} */
  const crossAnnotationProjects = soy.assertParamType(Array.isArray(opt_data.crossAnnotationProjects), 'crossAnnotationProjects', opt_data.crossAnnotationProjects, '@param', '!Array<string>');
  /** @type {null|string|undefined} */
  const baseline = soy.assertParamType(opt_data.baseline == null || typeof opt_data.baseline === 'string', 'baseline', opt_data.baseline, '@param', 'null|string|undefined');
  /** @type {null|number|undefined} */
  const lastChangedTimestamp = soy.assertParamType(opt_data.lastChangedTimestamp == null || typeof opt_data.lastChangedTimestamp === 'number', 'lastChangedTimestamp', opt_data.lastChangedTimestamp, '@param', 'null|number|undefined');
  /** @type {boolean|null|undefined} */
  const isTested = soy.assertParamType(opt_data.isTested == null || typeof opt_data.isTested === 'boolean', 'isTested', opt_data.isTested, '@param', 'boolean|null|undefined');
  /** @type {boolean|null|undefined} */
  const isViewWithoutBaselineOptionLink = soy.assertParamType(opt_data.isViewWithoutBaselineOptionLink == null || typeof opt_data.isViewWithoutBaselineOptionLink === 'boolean', 'isViewWithoutBaselineOptionLink', opt_data.isViewWithoutBaselineOptionLink, '@param', 'boolean|null|undefined');
  return $uniformLink(soy.$$assignDefaults({perspective: 'activity', viewName: 'method-history', parameters: {startOffset: startOffset, endOffset: endOffset, baseline: baseline, lastChangedTimestamp: lastChangedTimestamp, isTested: isTested, isViewWithoutBaselineOptionLink: isViewWithoutBaselineOptionLink, methodHistoryTab: 0}}, opt_data), $ijData) + $arrayQueryParameter$(soy.$$internalCallMarkerDoNotUse, $ijData, 'partitions', partitions) + $arrayQueryParameter$(soy.$$internalCallMarkerDoNotUse, $ijData, 'crossAnnotationProjects', crossAnnotationProjects);
};
export { $methodHistory as methodHistory };
/**
 * @typedef {{
 *  project: string,
 *  uniformPath: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  startOffset: number,
 *  endOffset: number,
 *  partitions: !Array<string>,
 *  crossAnnotationProjects: !Array<string>,
 *  baseline?: (null|string|undefined),
 *  lastChangedTimestamp?: (null|number|undefined),
 *  isTested?: (boolean|null|undefined),
 *  isViewWithoutBaselineOptionLink?: (boolean|null|undefined),
 * }}
 */
$methodHistory.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $methodHistory.soyTemplateName = 'ts.commons.LinkTemplate.methodHistory';
}


/**
 * @param {!$commitDetails.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $commitDetails = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.commitDetails']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.commitDetails'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} */
  const commit = soy.assertParamType(opt_data.commit == null || google.isObject(opt_data.commit), 'commit', opt_data.commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  /** @type {?} */
  const churn = opt_data.churn;
  return churn ? $uniformLink(soy.$$assignDefaults({perspective: 'activity', viewName: 'details', parameters: {churn: churn}}, opt_data), $ijData) : $uniformLink(soy.$$assignDefaults({perspective: 'activity', viewName: 'details'}, opt_data), $ijData);
};
export { $commitDetails as commitDetails };
/**
 * @typedef {{
 *  project: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  churn?: (?|undefined),
 *  uniformPath?: (null|string|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 *  parameters?: (?|undefined),
 * }}
 */
$commitDetails.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $commitDetails.soyTemplateName = 'ts.commons.LinkTemplate.commitDetails';
}


/**
 * @param {!$branchActivity.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $branchActivity = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.branchActivity']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.branchActivity'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} */
  const commit = soy.assertParamType(opt_data.commit == null || google.isObject(opt_data.commit), 'commit', opt_data.commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  return $uniformLink(soy.$$assignDefaults({perspective: 'activity', commit: {branchName: commit.branchName, timestamp: null, previous: 0}}, opt_data), $ijData);
};
export { $branchActivity as branchActivity };
/**
 * @typedef {{
 *  project: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  viewName?: (null|string|undefined),
 *  uniformPath?: (null|string|undefined),
 *  parameters?: (?|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$branchActivity.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $branchActivity.soyTemplateName = 'ts.commons.LinkTemplate.branchActivity';
}


/**
 * @param {!$showUser.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $showUser = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $showUser$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.username, opt_data.isAdmin);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} username
 * @param {boolean|null|undefined=} isAdmin
 * @return {string}
 * @suppress {checkTypes}
 */
const $showUser$ = function($$areYouAnInternalCaller, $ijData, username, isAdmin) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showUser']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showUser']({username: username, isAdmin: isAdmin}, $ijData);
  }
  soy.assertParamType(typeof username === 'string', 'username', username, '@param', 'string');
  soy.assertParamType(isAdmin == null || typeof isAdmin === 'boolean', 'isAdmin', isAdmin, '@param', 'boolean|null|undefined');
  return isAdmin ? $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'admin', 'users', undefined, undefined, undefined, {action: 'edit', username: username}) : $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'user', 'show', undefined, undefined, undefined, {username: username});
};
export { $showUser as showUser };
export { $showUser$ as showUser$ };
/**
 * @typedef {{
 *  username: string,
 *  isAdmin?: (boolean|null|undefined),
 * }}
 */
$showUser.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showUser.soyTemplateName = 'ts.commons.LinkTemplate.showUser';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $userGroups = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.userGroups']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.userGroups'](opt_data, $ijData);
  }
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'admin', 'groups');
};
export { $userGroups as userGroups };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $userGroups.soyTemplateName = 'ts.commons.LinkTemplate.userGroups';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $createUser = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.createUser']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.createUser'](opt_data, $ijData);
  }
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'admin', 'users', undefined, undefined, undefined, {action: 'create'});
};
export { $createUser as createUser };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $createUser.soyTemplateName = 'ts.commons.LinkTemplate.createUser';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $showUsers = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showUsers']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showUsers'](opt_data, $ijData);
  }
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'admin', 'users');
};
export { $showUsers as showUsers };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showUsers.soyTemplateName = 'ts.commons.LinkTemplate.showUsers';
}


/**
 * @param {!$editUserRoles.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $editUserRoles = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $editUserRoles$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.username);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} username
 * @return {string}
 * @suppress {checkTypes}
 */
const $editUserRoles$ = function($$areYouAnInternalCaller, $ijData, username) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.editUserRoles']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.editUserRoles']({username: username}, $ijData);
  }
  soy.assertParamType(typeof username === 'string', 'username', username, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'admin', 'users', undefined, undefined, undefined, {action: 'roles', username: username});
};
export { $editUserRoles as editUserRoles };
export { $editUserRoles$ as editUserRoles$ };
/**
 * @typedef {{
 *  username: string,
 * }}
 */
$editUserRoles.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editUserRoles.soyTemplateName = 'ts.commons.LinkTemplate.editUserRoles';
}


/**
 * @param {!$changeAvatar.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $changeAvatar = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $changeAvatar$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.username, opt_data.isAdmin);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} username
 * @param {boolean|null|undefined=} isAdmin
 * @return {string}
 * @suppress {checkTypes}
 */
const $changeAvatar$ = function($$areYouAnInternalCaller, $ijData, username, isAdmin) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.changeAvatar']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.changeAvatar']({username: username, isAdmin: isAdmin}, $ijData);
  }
  soy.assertParamType(typeof username === 'string', 'username', username, '@param', 'string');
  soy.assertParamType(isAdmin == null || typeof isAdmin === 'boolean', 'isAdmin', isAdmin, '@param', 'boolean|null|undefined');
  return isAdmin ? $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'admin', 'users', undefined, undefined, undefined, {action: 'avatar', username: username}) : $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'user', 'avatar', undefined, undefined, undefined, {username: username});
};
export { $changeAvatar as changeAvatar };
export { $changeAvatar$ as changeAvatar$ };
/**
 * @typedef {{
 *  username: string,
 *  isAdmin?: (boolean|null|undefined),
 * }}
 */
$changeAvatar.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $changeAvatar.soyTemplateName = 'ts.commons.LinkTemplate.changeAvatar';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $createGroup = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.createGroup']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.createGroup'](opt_data, $ijData);
  }
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'admin', 'groups', undefined, undefined, undefined, {action: 'create', groupId: ':new'});
};
export { $createGroup as createGroup };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $createGroup.soyTemplateName = 'ts.commons.LinkTemplate.createGroup';
}


/**
 * @param {!$editGroup.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $editGroup = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $editGroup$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.groupName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} groupName
 * @return {string}
 * @suppress {checkTypes}
 */
const $editGroup$ = function($$areYouAnInternalCaller, $ijData, groupName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.editGroup']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.editGroup']({groupName: groupName}, $ijData);
  }
  soy.assertParamType(typeof groupName === 'string', 'groupName', groupName, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'admin', 'groups', undefined, undefined, undefined, {action: 'edit', groupId: groupName});
};
export { $editGroup as editGroup };
export { $editGroup$ as editGroup$ };
/**
 * @typedef {{
 *  groupName: string,
 * }}
 */
$editGroup.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editGroup.soyTemplateName = 'ts.commons.LinkTemplate.editGroup';
}


/**
 * @param {!$editGroupRoles.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $editGroupRoles = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $editGroupRoles$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.groupName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} groupName
 * @return {string}
 * @suppress {checkTypes}
 */
const $editGroupRoles$ = function($$areYouAnInternalCaller, $ijData, groupName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.editGroupRoles']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.editGroupRoles']({groupName: groupName}, $ijData);
  }
  soy.assertParamType(typeof groupName === 'string', 'groupName', groupName, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'admin', 'groups', undefined, undefined, undefined, {action: 'roles', groupId: groupName});
};
export { $editGroupRoles as editGroupRoles };
export { $editGroupRoles$ as editGroupRoles$ };
/**
 * @typedef {{
 *  groupName: string,
 * }}
 */
$editGroupRoles.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editGroupRoles.soyTemplateName = 'ts.commons.LinkTemplate.editGroupRoles';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $globalSettings = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.globalSettings']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.globalSettings'](opt_data, $ijData);
  }
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'admin', 'settings');
};
export { $globalSettings as globalSettings };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $globalSettings.soyTemplateName = 'ts.commons.LinkTemplate.globalSettings';
}


/**
 * @param {!$backupImport.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $backupImport = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $backupImport$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.backupId);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} backupId
 * @return {string}
 * @suppress {checkTypes}
 */
const $backupImport$ = function($$areYouAnInternalCaller, $ijData, backupId) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.backupImport']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.backupImport']({backupId: backupId}, $ijData);
  }
  soy.assertParamType(typeof backupId === 'string', 'backupId', backupId, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'admin', 'backup') + $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'import-id', backupId, true);
};
export { $backupImport as backupImport };
export { $backupImport$ as backupImport$ };
/**
 * @typedef {{
 *  backupId: string,
 * }}
 */
$backupImport.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $backupImport.soyTemplateName = 'ts.commons.LinkTemplate.backupImport';
}


/**
 * @param {!$backupExport.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $backupExport = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $backupExport$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.backupId);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} backupId
 * @return {string}
 * @suppress {checkTypes}
 */
const $backupExport$ = function($$areYouAnInternalCaller, $ijData, backupId) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.backupExport']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.backupExport']({backupId: backupId}, $ijData);
  }
  soy.assertParamType(typeof backupId === 'string', 'backupId', backupId, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'admin', 'backup') + $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'export-id', backupId, true);
};
export { $backupExport as backupExport };
export { $backupExport$ as backupExport$ };
/**
 * @typedef {{
 *  backupId: string,
 * }}
 */
$backupExport.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $backupExport.soyTemplateName = 'ts.commons.LinkTemplate.backupExport';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $editUser = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.editUser']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.editUser'](opt_data, $ijData);
  }
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'user', 'edit');
};
export { $editUser as editUser };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editUser.soyTemplateName = 'ts.commons.LinkTemplate.editUser';
}


/**
 * @param {!$logView.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $logView = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $logView$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.viewName, opt_data.project, opt_data.level, opt_data.searchDetailedLogs, opt_data.filterRegex);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} viewName
 * @param {string} project
 * @param {null|string|undefined=} level
 * @param {null|string|undefined=} searchDetailedLogs
 * @param {null|string|undefined=} filterRegex
 * @return {string}
 * @suppress {checkTypes}
 */
const $logView$ = function($$areYouAnInternalCaller, $ijData, viewName, project, level, searchDetailedLogs, filterRegex) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.logView']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.logView']({viewName: viewName, project: project, level: level, searchDetailedLogs: searchDetailedLogs, filterRegex: filterRegex}, $ijData);
  }
  soy.assertParamType(typeof viewName === 'string', 'viewName', viewName, '@param', 'string');
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(level == null || typeof level === 'string', 'level', level, '@param', 'null|string|undefined');
  soy.assertParamType(searchDetailedLogs == null || typeof searchDetailedLogs === 'string', 'searchDetailedLogs', searchDetailedLogs, '@param', 'null|string|undefined');
  soy.assertParamType(filterRegex == null || typeof filterRegex === 'string', 'filterRegex', filterRegex, '@param', 'null|string|undefined');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'system', viewName, undefined, undefined, undefined, {project: project, level: level, searchDetailedLogs: searchDetailedLogs, filterRegex: filterRegex});
};
export { $logView as logView };
export { $logView$ as logView$ };
/**
 * @typedef {{
 *  viewName: string,
 *  project: string,
 *  level?: (null|string|undefined),
 *  searchDetailedLogs?: (null|string|undefined),
 *  filterRegex?: (null|string|undefined),
 * }}
 */
$logView.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $logView.soyTemplateName = 'ts.commons.LinkTemplate.logView';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $systemExectuionStatus = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.systemExectuionStatus']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.systemExectuionStatus'](opt_data, $ijData);
  }
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'system');
};
export { $systemExectuionStatus as systemExectuionStatus };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $systemExectuionStatus.soyTemplateName = 'ts.commons.LinkTemplate.systemExectuionStatus';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $showApiDocumentation = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showApiDocumentation']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showApiDocumentation'](opt_data, $ijData);
  }
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'api');
};
export { $showApiDocumentation as showApiDocumentation };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showApiDocumentation.soyTemplateName = 'ts.commons.LinkTemplate.showApiDocumentation';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $showCheckExplorer = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showCheckExplorer']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showCheckExplorer'](opt_data, $ijData);
  }
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'checks');
};
export { $showCheckExplorer as showCheckExplorer };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showCheckExplorer.soyTemplateName = 'ts.commons.LinkTemplate.showCheckExplorer';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $showProjects = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showProjects']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showProjects'](opt_data, $ijData);
  }
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'project');
};
export { $showProjects as showProjects };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showProjects.soyTemplateName = 'ts.commons.LinkTemplate.showProjects';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $showSampleProjects = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showSampleProjects']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showSampleProjects'](opt_data, $ijData);
  }
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'samples');
};
export { $showSampleProjects as showSampleProjects };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showSampleProjects.soyTemplateName = 'ts.commons.LinkTemplate.showSampleProjects';
}


/**
 * @param {!$showProjectConfiguration.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $showProjectConfiguration = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $showProjectConfiguration$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.projectId);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} projectId
 * @return {string}
 * @suppress {checkTypes}
 */
const $showProjectConfiguration$ = function($$areYouAnInternalCaller, $ijData, projectId) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showProjectConfiguration']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showProjectConfiguration']({projectId: projectId}, $ijData);
  }
  soy.assertParamType(typeof projectId === 'string', 'projectId', projectId, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'project', undefined, undefined, undefined, {name: projectId});
};
export { $showProjectConfiguration as showProjectConfiguration };
export { $showProjectConfiguration$ as showProjectConfiguration$ };
/**
 * @typedef {{
 *  projectId: string,
 * }}
 */
$showProjectConfiguration.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showProjectConfiguration.soyTemplateName = 'ts.commons.LinkTemplate.showProjectConfiguration';
}


/**
 * @param {!$showProjectOptions.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $showProjectOptions = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $showProjectOptions$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.projectId);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} projectId
 * @return {string}
 * @suppress {checkTypes}
 */
const $showProjectOptions$ = function($$areYouAnInternalCaller, $ijData, projectId) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showProjectOptions']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showProjectOptions']({projectId: projectId}, $ijData);
  }
  soy.assertParamType(typeof projectId === 'string', 'projectId', projectId, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'project', undefined, undefined, undefined, {name: projectId, action: 'options'});
};
export { $showProjectOptions as showProjectOptions };
export { $showProjectOptions$ as showProjectOptions$ };
/**
 * @typedef {{
 *  projectId: string,
 * }}
 */
$showProjectOptions.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showProjectOptions.soyTemplateName = 'ts.commons.LinkTemplate.showProjectOptions';
}


/**
 * @param {!$showProjectConnectorStatus.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $showProjectConnectorStatus = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $showProjectConnectorStatus$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.projectId);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} projectId
 * @return {string}
 * @suppress {checkTypes}
 */
const $showProjectConnectorStatus$ = function($$areYouAnInternalCaller, $ijData, projectId) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showProjectConnectorStatus']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showProjectConnectorStatus']({projectId: projectId}, $ijData);
  }
  soy.assertParamType(typeof projectId === 'string', 'projectId', projectId, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'project', undefined, undefined, undefined, {name: projectId, action: 'connectors'});
};
export { $showProjectConnectorStatus as showProjectConnectorStatus };
export { $showProjectConnectorStatus$ as showProjectConnectorStatus$ };
/**
 * @typedef {{
 *  projectId: string,
 * }}
 */
$showProjectConnectorStatus.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showProjectConnectorStatus.soyTemplateName = 'ts.commons.LinkTemplate.showProjectConnectorStatus';
}


/**
 * @param {!$showCommitTreeDetails.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $showCommitTreeDetails = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $showCommitTreeDetails$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.projectId, opt_data.repositoryId);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} projectId
 * @param {string} repositoryId
 * @return {string}
 * @suppress {checkTypes}
 */
const $showCommitTreeDetails$ = function($$areYouAnInternalCaller, $ijData, projectId, repositoryId) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showCommitTreeDetails']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showCommitTreeDetails']({projectId: projectId, repositoryId: repositoryId}, $ijData);
  }
  soy.assertParamType(typeof projectId === 'string', 'projectId', projectId, '@param', 'string');
  soy.assertParamType(typeof repositoryId === 'string', 'repositoryId', repositoryId, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'project', undefined, undefined, undefined, {name: projectId, action: 'showCommitTreeDetails'}) + $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'repository-id', repositoryId);
};
export { $showCommitTreeDetails as showCommitTreeDetails };
export { $showCommitTreeDetails$ as showCommitTreeDetails$ };
/**
 * @typedef {{
 *  projectId: string,
 *  repositoryId: string,
 * }}
 */
$showCommitTreeDetails.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showCommitTreeDetails.soyTemplateName = 'ts.commons.LinkTemplate.showCommitTreeDetails';
}


/**
 * @param {!$showProjectPostponedRollbacks.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $showProjectPostponedRollbacks = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $showProjectPostponedRollbacks$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.projectId);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} projectId
 * @return {string}
 * @suppress {checkTypes}
 */
const $showProjectPostponedRollbacks$ = function($$areYouAnInternalCaller, $ijData, projectId) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showProjectPostponedRollbacks']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showProjectPostponedRollbacks']({projectId: projectId}, $ijData);
  }
  soy.assertParamType(typeof projectId === 'string', 'projectId', projectId, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'project', undefined, undefined, undefined, {name: projectId, action: 'postponed-rollbacks'});
};
export { $showProjectPostponedRollbacks as showProjectPostponedRollbacks };
export { $showProjectPostponedRollbacks$ as showProjectPostponedRollbacks$ };
/**
 * @typedef {{
 *  projectId: string,
 * }}
 */
$showProjectPostponedRollbacks.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showProjectPostponedRollbacks.soyTemplateName = 'ts.commons.LinkTemplate.showProjectPostponedRollbacks';
}


/**
 * @param {!$editProjectConfiguration.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $editProjectConfiguration = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $editProjectConfiguration$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.projectId);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} projectId
 * @return {string}
 * @suppress {checkTypes}
 */
const $editProjectConfiguration$ = function($$areYouAnInternalCaller, $ijData, projectId) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.editProjectConfiguration']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.editProjectConfiguration']({projectId: projectId}, $ijData);
  }
  soy.assertParamType(typeof projectId === 'string', 'projectId', projectId, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'project', undefined, undefined, undefined, {name: projectId, action: 'edit'});
};
export { $editProjectConfiguration as editProjectConfiguration };
export { $editProjectConfiguration$ as editProjectConfiguration$ };
/**
 * @typedef {{
 *  projectId: string,
 * }}
 */
$editProjectConfiguration.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editProjectConfiguration.soyTemplateName = 'ts.commons.LinkTemplate.editProjectConfiguration';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $showAnalysisProfiles = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showAnalysisProfiles']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showAnalysisProfiles'](opt_data, $ijData);
  }
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'analysis-profiles');
};
export { $showAnalysisProfiles as showAnalysisProfiles };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showAnalysisProfiles.soyTemplateName = 'ts.commons.LinkTemplate.showAnalysisProfiles';
}


/**
 * @param {!$showAnalysisProfile.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $showAnalysisProfile = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $showAnalysisProfile$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.analysisProfileName, opt_data.showProjects);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} analysisProfileName
 * @param {?=} showProjects
 * @return {string}
 * @suppress {checkTypes}
 */
const $showAnalysisProfile$ = function($$areYouAnInternalCaller, $ijData, analysisProfileName, showProjects) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showAnalysisProfile']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showAnalysisProfile']({analysisProfileName: analysisProfileName, showProjects: showProjects}, $ijData);
  }
  soy.assertParamType(typeof analysisProfileName === 'string', 'analysisProfileName', analysisProfileName, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'analysis-profiles', undefined, undefined, undefined, {name: analysisProfileName, showProjects: showProjects});
};
export { $showAnalysisProfile as showAnalysisProfile };
export { $showAnalysisProfile$ as showAnalysisProfile$ };
/**
 * @typedef {{
 *  analysisProfileName: string,
 *  showProjects?: (?|undefined),
 * }}
 */
$showAnalysisProfile.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showAnalysisProfile.soyTemplateName = 'ts.commons.LinkTemplate.showAnalysisProfile';
}


/**
 * @param {!$editAnalysisProfile.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $editAnalysisProfile = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $editAnalysisProfile$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.analysisProfileName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} analysisProfileName
 * @return {string}
 * @suppress {checkTypes}
 */
const $editAnalysisProfile$ = function($$areYouAnInternalCaller, $ijData, analysisProfileName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.editAnalysisProfile']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.editAnalysisProfile']({analysisProfileName: analysisProfileName}, $ijData);
  }
  soy.assertParamType(typeof analysisProfileName === 'string', 'analysisProfileName', analysisProfileName, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'analysis-profiles', undefined, undefined, undefined, {name: analysisProfileName, action: 'edit'});
};
export { $editAnalysisProfile as editAnalysisProfile };
export { $editAnalysisProfile$ as editAnalysisProfile$ };
/**
 * @typedef {{
 *  analysisProfileName: string,
 * }}
 */
$editAnalysisProfile.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editAnalysisProfile.soyTemplateName = 'ts.commons.LinkTemplate.editAnalysisProfile';
}


/**
 * @param {!$showAnalysisProfileHistory.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $showAnalysisProfileHistory = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $showAnalysisProfileHistory$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.analysisProfileName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} analysisProfileName
 * @return {string}
 * @suppress {checkTypes}
 */
const $showAnalysisProfileHistory$ = function($$areYouAnInternalCaller, $ijData, analysisProfileName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showAnalysisProfileHistory']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showAnalysisProfileHistory']({analysisProfileName: analysisProfileName}, $ijData);
  }
  soy.assertParamType(typeof analysisProfileName === 'string', 'analysisProfileName', analysisProfileName, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'analysis-profiles', undefined, undefined, undefined, {name: analysisProfileName, action: 'history'});
};
export { $showAnalysisProfileHistory as showAnalysisProfileHistory };
export { $showAnalysisProfileHistory$ as showAnalysisProfileHistory$ };
/**
 * @typedef {{
 *  analysisProfileName: string,
 * }}
 */
$showAnalysisProfileHistory.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showAnalysisProfileHistory.soyTemplateName = 'ts.commons.LinkTemplate.showAnalysisProfileHistory';
}


/**
 * @param {!$editAnalysisProfileRolesLink.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $editAnalysisProfileRolesLink = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $editAnalysisProfileRolesLink$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.analysisProfileName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} analysisProfileName
 * @return {string}
 * @suppress {checkTypes}
 */
const $editAnalysisProfileRolesLink$ = function($$areYouAnInternalCaller, $ijData, analysisProfileName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.editAnalysisProfileRolesLink']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.editAnalysisProfileRolesLink']({analysisProfileName: analysisProfileName}, $ijData);
  }
  soy.assertParamType(typeof analysisProfileName === 'string', 'analysisProfileName', analysisProfileName, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'analysis-profiles', undefined, undefined, undefined, {name: analysisProfileName, action: 'roles'});
};
export { $editAnalysisProfileRolesLink as editAnalysisProfileRolesLink };
export { $editAnalysisProfileRolesLink$ as editAnalysisProfileRolesLink$ };
/**
 * @typedef {{
 *  analysisProfileName: string,
 * }}
 */
$editAnalysisProfileRolesLink.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editAnalysisProfileRolesLink.soyTemplateName = 'ts.commons.LinkTemplate.editAnalysisProfileRolesLink';
}


/**
 * @param {!$editQualityReportRolesLink.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $editQualityReportRolesLink = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $editQualityReportRolesLink$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.qualityReportId, opt_data.qualityReportName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} qualityReportId
 * @param {string} qualityReportName
 * @return {string}
 * @suppress {checkTypes}
 */
const $editQualityReportRolesLink$ = function($$areYouAnInternalCaller, $ijData, qualityReportId, qualityReportName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.editQualityReportRolesLink']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.editQualityReportRolesLink']({qualityReportId: qualityReportId, qualityReportName: qualityReportName}, $ijData);
  }
  soy.assertParamType(typeof qualityReportId === 'string', 'qualityReportId', qualityReportId, '@param', 'string');
  soy.assertParamType(typeof qualityReportName === 'string', 'qualityReportName', qualityReportName, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'qualitycontrol', 'reports', undefined, undefined, undefined, {id: qualityReportId, name: qualityReportName, action: 'report-roles'});
};
export { $editQualityReportRolesLink as editQualityReportRolesLink };
export { $editQualityReportRolesLink$ as editQualityReportRolesLink$ };
/**
 * @typedef {{
 *  qualityReportId: string,
 *  qualityReportName: string,
 * }}
 */
$editQualityReportRolesLink.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editQualityReportRolesLink.soyTemplateName = 'ts.commons.LinkTemplate.editQualityReportRolesLink';
}


/**
 * @param {!$editQualityReport.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $editQualityReport = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $editQualityReport$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.qualityReportId);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} qualityReportId
 * @return {string}
 * @suppress {checkTypes}
 */
const $editQualityReport$ = function($$areYouAnInternalCaller, $ijData, qualityReportId) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.editQualityReport']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.editQualityReport']({qualityReportId: qualityReportId}, $ijData);
  }
  soy.assertParamType(typeof qualityReportId === 'string', 'qualityReportId', qualityReportId, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'qualitycontrol', 'reports', undefined, undefined, undefined, {id: qualityReportId, action: 'edit'});
};
export { $editQualityReport as editQualityReport };
export { $editQualityReport$ as editQualityReport$ };
/**
 * @typedef {{
 *  qualityReportId: string,
 * }}
 */
$editQualityReport.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editQualityReport.soyTemplateName = 'ts.commons.LinkTemplate.editQualityReport';
}


/**
 * @param {!$viewQualityReport.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedUri}
 */
const $viewQualityReport = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $viewQualityReport$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.qualityReportId);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} qualityReportId
 * @return {!goog_soy_data.SanitizedUri}
 * @suppress {checkTypes}
 */
const $viewQualityReport$ = function($$areYouAnInternalCaller, $ijData, qualityReportId) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.viewQualityReport']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.viewQualityReport']({qualityReportId: qualityReportId}, $ijData);
  }
  soy.assertParamType(typeof qualityReportId === 'string', 'qualityReportId', qualityReportId, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedUri(soy.$$filterNormalizeUri($uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'qualitycontrol', 'reports', undefined, undefined, undefined, {id: qualityReportId, action: 'view'})));
};
export { $viewQualityReport as viewQualityReport };
export { $viewQualityReport$ as viewQualityReport$ };
/**
 * @typedef {{
 *  qualityReportId: string,
 * }}
 */
$viewQualityReport.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $viewQualityReport.soyTemplateName = 'ts.commons.LinkTemplate.viewQualityReport';
}


/**
 * @param {!$editQualityRetrospective.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedUri}
 */
const $editQualityRetrospective = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $editQualityRetrospective$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.qualityRetrospectiveId);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} qualityRetrospectiveId
 * @return {!goog_soy_data.SanitizedUri}
 * @suppress {checkTypes}
 */
const $editQualityRetrospective$ = function($$areYouAnInternalCaller, $ijData, qualityRetrospectiveId) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.editQualityRetrospective']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.editQualityRetrospective']({qualityRetrospectiveId: qualityRetrospectiveId}, $ijData);
  }
  soy.assertParamType(typeof qualityRetrospectiveId === 'string', 'qualityRetrospectiveId', qualityRetrospectiveId, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedUri(soy.$$filterNormalizeUri($uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'qualitycontrol', 'retrospectives', undefined, undefined, undefined, {id: qualityRetrospectiveId, action: 'edit'})));
};
export { $editQualityRetrospective as editQualityRetrospective };
export { $editQualityRetrospective$ as editQualityRetrospective$ };
/**
 * @typedef {{
 *  qualityRetrospectiveId: string,
 * }}
 */
$editQualityRetrospective.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editQualityRetrospective.soyTemplateName = 'ts.commons.LinkTemplate.editQualityRetrospective';
}


/**
 * @param {!$editQualityRetrospectiveRoles.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedUri}
 */
const $editQualityRetrospectiveRoles = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $editQualityRetrospectiveRoles$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.qualityRetrospectiveId);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} qualityRetrospectiveId
 * @return {!goog_soy_data.SanitizedUri}
 * @suppress {checkTypes}
 */
const $editQualityRetrospectiveRoles$ = function($$areYouAnInternalCaller, $ijData, qualityRetrospectiveId) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.editQualityRetrospectiveRoles']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.editQualityRetrospectiveRoles']({qualityRetrospectiveId: qualityRetrospectiveId}, $ijData);
  }
  soy.assertParamType(typeof qualityRetrospectiveId === 'string', 'qualityRetrospectiveId', qualityRetrospectiveId, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedUri(soy.$$filterNormalizeUri($uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'qualitycontrol', 'retrospectives', undefined, undefined, undefined, {id: qualityRetrospectiveId, action: 'roles'})));
};
export { $editQualityRetrospectiveRoles as editQualityRetrospectiveRoles };
export { $editQualityRetrospectiveRoles$ as editQualityRetrospectiveRoles$ };
/**
 * @typedef {{
 *  qualityRetrospectiveId: string,
 * }}
 */
$editQualityRetrospectiveRoles.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editQualityRetrospectiveRoles.soyTemplateName = 'ts.commons.LinkTemplate.editQualityRetrospectiveRoles';
}


/**
 * @param {!$printQualityReport.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedUri}
 */
const $printQualityReport = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $printQualityReport$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.qualityReportId);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} qualityReportId
 * @return {!goog_soy_data.SanitizedUri}
 * @suppress {checkTypes}
 */
const $printQualityReport$ = function($$areYouAnInternalCaller, $ijData, qualityReportId) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.printQualityReport']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.printQualityReport']({qualityReportId: qualityReportId}, $ijData);
  }
  soy.assertParamType(typeof qualityReportId === 'string', 'qualityReportId', qualityReportId, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedUri(soy.$$filterNormalizeUri($uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'qualitycontrol', 'reports', undefined, undefined, undefined, {id: qualityReportId, action: 'print'})));
};
export { $printQualityReport as printQualityReport };
export { $printQualityReport$ as printQualityReport$ };
/**
 * @typedef {{
 *  qualityReportId: string,
 * }}
 */
$printQualityReport.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $printQualityReport.soyTemplateName = 'ts.commons.LinkTemplate.printQualityReport';
}


/**
 * @param {!$copyAnalysisProfile.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $copyAnalysisProfile = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $copyAnalysisProfile$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.analysisProfileName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} analysisProfileName
 * @return {string}
 * @suppress {checkTypes}
 */
const $copyAnalysisProfile$ = function($$areYouAnInternalCaller, $ijData, analysisProfileName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.copyAnalysisProfile']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.copyAnalysisProfile']({analysisProfileName: analysisProfileName}, $ijData);
  }
  soy.assertParamType(typeof analysisProfileName === 'string', 'analysisProfileName', analysisProfileName, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'analysis-profiles', undefined, undefined, undefined, {name: analysisProfileName, action: 'copy'});
};
export { $copyAnalysisProfile as copyAnalysisProfile };
export { $copyAnalysisProfile$ as copyAnalysisProfile$ };
/**
 * @typedef {{
 *  analysisProfileName: string,
 * }}
 */
$copyAnalysisProfile.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $copyAnalysisProfile.soyTemplateName = 'ts.commons.LinkTemplate.copyAnalysisProfile';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $showExternalMetrics = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showExternalMetrics']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showExternalMetrics'](opt_data, $ijData);
  }
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'external-metric-descriptions');
};
export { $showExternalMetrics as showExternalMetrics };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showExternalMetrics.soyTemplateName = 'ts.commons.LinkTemplate.showExternalMetrics';
}


/**
 * @param {!$showExternalMetric.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $showExternalMetric = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $showExternalMetric$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.externalMetricName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} externalMetricName
 * @return {string}
 * @suppress {checkTypes}
 */
const $showExternalMetric$ = function($$areYouAnInternalCaller, $ijData, externalMetricName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showExternalMetric']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showExternalMetric']({externalMetricName: externalMetricName}, $ijData);
  }
  soy.assertParamType(typeof externalMetricName === 'string', 'externalMetricName', externalMetricName, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'external-metric-descriptions', undefined, undefined, undefined, {name: externalMetricName});
};
export { $showExternalMetric as showExternalMetric };
export { $showExternalMetric$ as showExternalMetric$ };
/**
 * @typedef {{
 *  externalMetricName: string,
 * }}
 */
$showExternalMetric.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showExternalMetric.soyTemplateName = 'ts.commons.LinkTemplate.showExternalMetric';
}


/**
 * @param {!$editExternalMetric.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $editExternalMetric = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $editExternalMetric$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.externalMetricName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} externalMetricName
 * @return {string}
 * @suppress {checkTypes}
 */
const $editExternalMetric$ = function($$areYouAnInternalCaller, $ijData, externalMetricName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.editExternalMetric']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.editExternalMetric']({externalMetricName: externalMetricName}, $ijData);
  }
  soy.assertParamType(typeof externalMetricName === 'string', 'externalMetricName', externalMetricName, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'external-metric-descriptions', undefined, undefined, undefined, {name: externalMetricName, action: 'edit'});
};
export { $editExternalMetric as editExternalMetric };
export { $editExternalMetric$ as editExternalMetric$ };
/**
 * @typedef {{
 *  externalMetricName: string,
 * }}
 */
$editExternalMetric.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editExternalMetric.soyTemplateName = 'ts.commons.LinkTemplate.editExternalMetric';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $showExternalFindings = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showExternalFindings']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showExternalFindings'](opt_data, $ijData);
  }
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'external-findings-descriptions');
};
export { $showExternalFindings as showExternalFindings };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showExternalFindings.soyTemplateName = 'ts.commons.LinkTemplate.showExternalFindings';
}


/**
 * @param {!$showExternalFinding.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $showExternalFinding = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $showExternalFinding$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.externalFindingName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} externalFindingName
 * @return {string}
 * @suppress {checkTypes}
 */
const $showExternalFinding$ = function($$areYouAnInternalCaller, $ijData, externalFindingName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showExternalFinding']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showExternalFinding']({externalFindingName: externalFindingName}, $ijData);
  }
  soy.assertParamType(typeof externalFindingName === 'string', 'externalFindingName', externalFindingName, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'external-findings-descriptions', undefined, undefined, undefined, {name: externalFindingName});
};
export { $showExternalFinding as showExternalFinding };
export { $showExternalFinding$ as showExternalFinding$ };
/**
 * @typedef {{
 *  externalFindingName: string,
 * }}
 */
$showExternalFinding.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showExternalFinding.soyTemplateName = 'ts.commons.LinkTemplate.showExternalFinding';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $listMetricThresholdConfiguration = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.listMetricThresholdConfiguration']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.listMetricThresholdConfiguration'](opt_data, $ijData);
  }
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'metric-threshold-configuration');
};
export { $listMetricThresholdConfiguration as listMetricThresholdConfiguration };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $listMetricThresholdConfiguration.soyTemplateName = 'ts.commons.LinkTemplate.listMetricThresholdConfiguration';
}


/**
 * @param {!$showMetricThresholdConfiguration.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $showMetricThresholdConfiguration = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $showMetricThresholdConfiguration$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.metricThresholdConfigurationName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} metricThresholdConfigurationName
 * @return {string}
 * @suppress {checkTypes}
 */
const $showMetricThresholdConfiguration$ = function($$areYouAnInternalCaller, $ijData, metricThresholdConfigurationName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showMetricThresholdConfiguration']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showMetricThresholdConfiguration']({metricThresholdConfigurationName: metricThresholdConfigurationName}, $ijData);
  }
  soy.assertParamType(typeof metricThresholdConfigurationName === 'string', 'metricThresholdConfigurationName', metricThresholdConfigurationName, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'metric-threshold-configuration', undefined, undefined, undefined, {name: metricThresholdConfigurationName});
};
export { $showMetricThresholdConfiguration as showMetricThresholdConfiguration };
export { $showMetricThresholdConfiguration$ as showMetricThresholdConfiguration$ };
/**
 * @typedef {{
 *  metricThresholdConfigurationName: string,
 * }}
 */
$showMetricThresholdConfiguration.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showMetricThresholdConfiguration.soyTemplateName = 'ts.commons.LinkTemplate.showMetricThresholdConfiguration';
}


/**
 * @param {!$editMetricThresholdConfiguration.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $editMetricThresholdConfiguration = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $editMetricThresholdConfiguration$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.metricThresholdConfigurationName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} metricThresholdConfigurationName
 * @return {string}
 * @suppress {checkTypes}
 */
const $editMetricThresholdConfiguration$ = function($$areYouAnInternalCaller, $ijData, metricThresholdConfigurationName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.editMetricThresholdConfiguration']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.editMetricThresholdConfiguration']({metricThresholdConfigurationName: metricThresholdConfigurationName}, $ijData);
  }
  soy.assertParamType(typeof metricThresholdConfigurationName === 'string', 'metricThresholdConfigurationName', metricThresholdConfigurationName, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'metric-threshold-configuration', undefined, undefined, undefined, {name: metricThresholdConfigurationName, action: 'edit'});
};
export { $editMetricThresholdConfiguration as editMetricThresholdConfiguration };
export { $editMetricThresholdConfiguration$ as editMetricThresholdConfiguration$ };
/**
 * @typedef {{
 *  metricThresholdConfigurationName: string,
 * }}
 */
$editMetricThresholdConfiguration.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editMetricThresholdConfiguration.soyTemplateName = 'ts.commons.LinkTemplate.editMetricThresholdConfiguration';
}


/**
 * @param {!$editMetricThresholdConfigurationRolesLink.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $editMetricThresholdConfigurationRolesLink = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $editMetricThresholdConfigurationRolesLink$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.metricThresholdConfigurationName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} metricThresholdConfigurationName
 * @return {string}
 * @suppress {checkTypes}
 */
const $editMetricThresholdConfigurationRolesLink$ = function($$areYouAnInternalCaller, $ijData, metricThresholdConfigurationName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.editMetricThresholdConfigurationRolesLink']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.editMetricThresholdConfigurationRolesLink']({metricThresholdConfigurationName: metricThresholdConfigurationName}, $ijData);
  }
  soy.assertParamType(typeof metricThresholdConfigurationName === 'string', 'metricThresholdConfigurationName', metricThresholdConfigurationName, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'metric-threshold-configuration', undefined, undefined, undefined, {name: metricThresholdConfigurationName, action: 'roles'});
};
export { $editMetricThresholdConfigurationRolesLink as editMetricThresholdConfigurationRolesLink };
export { $editMetricThresholdConfigurationRolesLink$ as editMetricThresholdConfigurationRolesLink$ };
/**
 * @typedef {{
 *  metricThresholdConfigurationName: string,
 * }}
 */
$editMetricThresholdConfigurationRolesLink.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editMetricThresholdConfigurationRolesLink.soyTemplateName = 'ts.commons.LinkTemplate.editMetricThresholdConfigurationRolesLink';
}


/**
 * @param {!$copyMetricThresholdConfiguration.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $copyMetricThresholdConfiguration = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $copyMetricThresholdConfiguration$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.metricThresholdConfigurationName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} metricThresholdConfigurationName
 * @return {string}
 * @suppress {checkTypes}
 */
const $copyMetricThresholdConfiguration$ = function($$areYouAnInternalCaller, $ijData, metricThresholdConfigurationName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.copyMetricThresholdConfiguration']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.copyMetricThresholdConfiguration']({metricThresholdConfigurationName: metricThresholdConfigurationName}, $ijData);
  }
  soy.assertParamType(typeof metricThresholdConfigurationName === 'string', 'metricThresholdConfigurationName', metricThresholdConfigurationName, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'metric-threshold-configuration', undefined, undefined, undefined, {name: metricThresholdConfigurationName, action: 'copy'});
};
export { $copyMetricThresholdConfiguration as copyMetricThresholdConfiguration };
export { $copyMetricThresholdConfiguration$ as copyMetricThresholdConfiguration$ };
/**
 * @typedef {{
 *  metricThresholdConfigurationName: string,
 * }}
 */
$copyMetricThresholdConfiguration.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $copyMetricThresholdConfiguration.soyTemplateName = 'ts.commons.LinkTemplate.copyMetricThresholdConfiguration';
}


/**
 * @param {!$editProjectOptions.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $editProjectOptions = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $editProjectOptions$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @return {string}
 * @suppress {checkTypes}
 */
const $editProjectOptions$ = function($$areYouAnInternalCaller, $ijData, project) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.editProjectOptions']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.editProjectOptions']({project: project}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', undefined, undefined, undefined, undefined, {name: project, action: 'options'});
};
export { $editProjectOptions as editProjectOptions };
export { $editProjectOptions$ as editProjectOptions$ };
/**
 * @typedef {{
 *  project: string,
 * }}
 */
$editProjectOptions.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editProjectOptions.soyTemplateName = 'ts.commons.LinkTemplate.editProjectOptions';
}


/**
 * @param {!$editProject.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $editProject = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $editProject$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @return {string}
 * @suppress {checkTypes}
 */
const $editProject$ = function($$areYouAnInternalCaller, $ijData, project) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.editProject']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.editProject']({project: project}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', undefined, undefined, undefined, undefined, {name: project, action: 'edit'});
};
export { $editProject as editProject };
export { $editProject$ as editProject$ };
/**
 * @typedef {{
 *  project: string,
 * }}
 */
$editProject.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editProject.soyTemplateName = 'ts.commons.LinkTemplate.editProject';
}


/**
 * @param {!$editRepository.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $editRepository = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $editRepository$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @return {string}
 * @suppress {checkTypes}
 */
const $editRepository$ = function($$areYouAnInternalCaller, $ijData, project) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.editRepository']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.editRepository']({project: project}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'repository', 'edit', undefined, undefined, undefined, {name: project});
};
export { $editRepository as editRepository };
export { $editRepository$ as editRepository$ };
/**
 * @typedef {{
 *  project: string,
 * }}
 */
$editRepository.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editRepository.soyTemplateName = 'ts.commons.LinkTemplate.editRepository';
}


/**
 * @param {!$copyProject.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $copyProject = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $copyProject$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @return {string}
 * @suppress {checkTypes}
 */
const $copyProject$ = function($$areYouAnInternalCaller, $ijData, project) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.copyProject']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.copyProject']({project: project}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', undefined, undefined, undefined, undefined, {name: project, action: 'copy'});
};
export { $copyProject as copyProject };
export { $copyProject$ as copyProject$ };
/**
 * @typedef {{
 *  project: string,
 * }}
 */
$copyProject.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $copyProject.soyTemplateName = 'ts.commons.LinkTemplate.copyProject';
}


/**
 * @param {!$editProjectRolesLink.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $editProjectRolesLink = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $editProjectRolesLink$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @return {string}
 * @suppress {checkTypes}
 */
const $editProjectRolesLink$ = function($$areYouAnInternalCaller, $ijData, project) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.editProjectRolesLink']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.editProjectRolesLink']({project: project}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', undefined, undefined, undefined, undefined, {name: project, action: 'roles'});
};
export { $editProjectRolesLink as editProjectRolesLink };
export { $editProjectRolesLink$ as editProjectRolesLink$ };
/**
 * @typedef {{
 *  project: string,
 * }}
 */
$editProjectRolesLink.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editProjectRolesLink.soyTemplateName = 'ts.commons.LinkTemplate.editProjectRolesLink';
}


/**
 * @param {!$editUserRolesLink.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $editUserRolesLink = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $editUserRolesLink$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.username);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} username
 * @return {string}
 * @suppress {checkTypes}
 */
const $editUserRolesLink$ = function($$areYouAnInternalCaller, $ijData, username) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.editUserRolesLink']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.editUserRolesLink']({username: username}, $ijData);
  }
  soy.assertParamType(typeof username === 'string', 'username', username, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'admin', 'users', undefined, undefined, undefined, {name: username, action: 'roles'});
};
export { $editUserRolesLink as editUserRolesLink };
export { $editUserRolesLink$ as editUserRolesLink$ };
/**
 * @typedef {{
 *  username: string,
 * }}
 */
$editUserRolesLink.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editUserRolesLink.soyTemplateName = 'ts.commons.LinkTemplate.editUserRolesLink';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $showAdminSettings = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showAdminSettings']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showAdminSettings'](opt_data, $ijData);
  }
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'admin', 'options');
};
export { $showAdminSettings as showAdminSettings };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showAdminSettings.soyTemplateName = 'ts.commons.LinkTemplate.showAdminSettings';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $showLateExternalProcessingAdminSettings = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showLateExternalProcessingAdminSettings']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showLateExternalProcessingAdminSettings'](opt_data, $ijData);
  }
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'admin', 'options', undefined, undefined, undefined, {action: 'Server Limits'});
};
export { $showLateExternalProcessingAdminSettings as showLateExternalProcessingAdminSettings };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showLateExternalProcessingAdminSettings.soyTemplateName = 'ts.commons.LinkTemplate.showLateExternalProcessingAdminSettings';
}


/**
 * @param {!$editExternalCredentialsRolesLink.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $editExternalCredentialsRolesLink = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $editExternalCredentialsRolesLink$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.credentialsName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} credentialsName
 * @return {string}
 * @suppress {checkTypes}
 */
const $editExternalCredentialsRolesLink$ = function($$areYouAnInternalCaller, $ijData, credentialsName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.editExternalCredentialsRolesLink']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.editExternalCredentialsRolesLink']({credentialsName: credentialsName}, $ijData);
  }
  soy.assertParamType(typeof credentialsName === 'string', 'credentialsName', credentialsName, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'admin', 'external-accounts', undefined, undefined, undefined, {name: credentialsName, action: 'roles'});
};
export { $editExternalCredentialsRolesLink as editExternalCredentialsRolesLink };
export { $editExternalCredentialsRolesLink$ as editExternalCredentialsRolesLink$ };
/**
 * @typedef {{
 *  credentialsName: string,
 * }}
 */
$editExternalCredentialsRolesLink.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editExternalCredentialsRolesLink.soyTemplateName = 'ts.commons.LinkTemplate.editExternalCredentialsRolesLink';
}


/**
 * @param {!$showInstanceComparison.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $showInstanceComparison = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $showInstanceComparison$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.snapshotId, opt_data.comparisonId);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} snapshotId
 * @param {string} comparisonId
 * @return {string}
 * @suppress {checkTypes}
 */
const $showInstanceComparison$ = function($$areYouAnInternalCaller, $ijData, snapshotId, comparisonId) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showInstanceComparison']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showInstanceComparison']({snapshotId: snapshotId, comparisonId: comparisonId}, $ijData);
  }
  soy.assertParamType(typeof snapshotId === 'string', 'snapshotId', snapshotId, '@param', 'string');
  soy.assertParamType(typeof comparisonId === 'string', 'comparisonId', comparisonId, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'admin', 'instance-compare', undefined, undefined, undefined, {subView: 'comparison', snapshotId: snapshotId, comparisonId: comparisonId});
};
export { $showInstanceComparison as showInstanceComparison };
export { $showInstanceComparison$ as showInstanceComparison$ };
/**
 * @typedef {{
 *  snapshotId: string,
 *  comparisonId: string,
 * }}
 */
$showInstanceComparison.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showInstanceComparison.soyTemplateName = 'ts.commons.LinkTemplate.showInstanceComparison';
}


/**
 * @param {!$showInstanceComparisonContribution.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $showInstanceComparisonContribution = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $showInstanceComparisonContribution$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.snapshotId, opt_data.comparisonId, opt_data.contributor, opt_data.project);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} snapshotId
 * @param {string} comparisonId
 * @param {string} contributor
 * @param {null|string|undefined=} project
 * @return {string}
 * @suppress {checkTypes}
 */
const $showInstanceComparisonContribution$ = function($$areYouAnInternalCaller, $ijData, snapshotId, comparisonId, contributor, project) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showInstanceComparisonContribution']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showInstanceComparisonContribution']({snapshotId: snapshotId, comparisonId: comparisonId, contributor: contributor, project: project}, $ijData);
  }
  soy.assertParamType(typeof snapshotId === 'string', 'snapshotId', snapshotId, '@param', 'string');
  soy.assertParamType(typeof comparisonId === 'string', 'comparisonId', comparisonId, '@param', 'string');
  soy.assertParamType(typeof contributor === 'string', 'contributor', contributor, '@param', 'string');
  soy.assertParamType(project == null || typeof project === 'string', 'project', project, '@param', 'null|string|undefined');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'admin', 'instance-compare', undefined, undefined, undefined, {subView: 'comparison/contributor', snapshotId: snapshotId, comparisonId: comparisonId, project: project, contributor: contributor});
};
export { $showInstanceComparisonContribution as showInstanceComparisonContribution };
export { $showInstanceComparisonContribution$ as showInstanceComparisonContribution$ };
/**
 * @typedef {{
 *  snapshotId: string,
 *  comparisonId: string,
 *  contributor: string,
 *  project?: (null|string|undefined),
 * }}
 */
$showInstanceComparisonContribution.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showInstanceComparisonContribution.soyTemplateName = 'ts.commons.LinkTemplate.showInstanceComparisonContribution';
}


/**
 * @param {!$showInstanceComparisonContributionElementDetails.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $showInstanceComparisonContributionElementDetails = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $showInstanceComparisonContributionElementDetails$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.snapshotId, opt_data.comparisonId, opt_data.contributor, opt_data.diffEntryName, opt_data.remoteDetails, opt_data.project, opt_data.title);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} snapshotId
 * @param {string} comparisonId
 * @param {string} contributor
 * @param {string} diffEntryName
 * @param {boolean} remoteDetails
 * @param {null|string|undefined=} project
 * @param {null|string|undefined=} title
 * @return {string}
 * @suppress {checkTypes}
 */
const $showInstanceComparisonContributionElementDetails$ = function($$areYouAnInternalCaller, $ijData, snapshotId, comparisonId, contributor, diffEntryName, remoteDetails, project, title) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showInstanceComparisonContributionElementDetails']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showInstanceComparisonContributionElementDetails']({snapshotId: snapshotId, comparisonId: comparisonId, contributor: contributor, diffEntryName: diffEntryName, remoteDetails: remoteDetails, project: project, title: title}, $ijData);
  }
  soy.assertParamType(typeof snapshotId === 'string', 'snapshotId', snapshotId, '@param', 'string');
  soy.assertParamType(typeof comparisonId === 'string', 'comparisonId', comparisonId, '@param', 'string');
  soy.assertParamType(typeof contributor === 'string', 'contributor', contributor, '@param', 'string');
  soy.assertParamType(typeof diffEntryName === 'string', 'diffEntryName', diffEntryName, '@param', 'string');
  soy.assertParamType(typeof remoteDetails === 'boolean', 'remoteDetails', remoteDetails, '@param', 'boolean');
  soy.assertParamType(project == null || typeof project === 'string', 'project', project, '@param', 'null|string|undefined');
  soy.assertParamType(title == null || typeof title === 'string', 'title', title, '@param', 'null|string|undefined');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'admin', 'instance-compare', undefined, undefined, undefined, {subView: 'comparison/contributor/details', snapshotId: snapshotId, comparisonId: comparisonId, project: project, contributor: contributor, diffEntryName: diffEntryName, remoteDetails: remoteDetails, title: title});
};
export { $showInstanceComparisonContributionElementDetails as showInstanceComparisonContributionElementDetails };
export { $showInstanceComparisonContributionElementDetails$ as showInstanceComparisonContributionElementDetails$ };
/**
 * @typedef {{
 *  snapshotId: string,
 *  comparisonId: string,
 *  contributor: string,
 *  diffEntryName: string,
 *  remoteDetails: boolean,
 *  project?: (null|string|undefined),
 *  title?: (null|string|undefined),
 * }}
 */
$showInstanceComparisonContributionElementDetails.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showInstanceComparisonContributionElementDetails.soyTemplateName = 'ts.commons.LinkTemplate.showInstanceComparisonContributionElementDetails';
}


/**
 * @param {!$editExternalStorageBackendRolesLink.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $editExternalStorageBackendRolesLink = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $editExternalStorageBackendRolesLink$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.externalStorageBackendName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} externalStorageBackendName
 * @return {string}
 * @suppress {checkTypes}
 */
const $editExternalStorageBackendRolesLink$ = function($$areYouAnInternalCaller, $ijData, externalStorageBackendName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.editExternalStorageBackendRolesLink']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.editExternalStorageBackendRolesLink']({externalStorageBackendName: externalStorageBackendName}, $ijData);
  }
  soy.assertParamType(typeof externalStorageBackendName === 'string', 'externalStorageBackendName', externalStorageBackendName, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'admin', 'external-storage-backends', undefined, undefined, undefined, {name: externalStorageBackendName, action: 'roles'});
};
export { $editExternalStorageBackendRolesLink as editExternalStorageBackendRolesLink };
export { $editExternalStorageBackendRolesLink$ as editExternalStorageBackendRolesLink$ };
/**
 * @typedef {{
 *  externalStorageBackendName: string,
 * }}
 */
$editExternalStorageBackendRolesLink.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editExternalStorageBackendRolesLink.soyTemplateName = 'ts.commons.LinkTemplate.editExternalStorageBackendRolesLink';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $editExternalAccountsLink = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.editExternalAccountsLink']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.editExternalAccountsLink'](opt_data, $ijData);
  }
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'admin', 'external-accounts');
};
export { $editExternalAccountsLink as editExternalAccountsLink };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editExternalAccountsLink.soyTemplateName = 'ts.commons.LinkTemplate.editExternalAccountsLink';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $addProject = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.addProject']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.addProject'](opt_data, $ijData);
  }
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'project', undefined, undefined, undefined, {name: ':new'});
};
export { $addProject as addProject };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $addProject.soyTemplateName = 'ts.commons.LinkTemplate.addProject';
}


/**
 * @param {!$issue.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $issue = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $issue$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.id, opt_data.selectedTestGapBranch);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {?} id
 * @param {?=} selectedTestGapBranch
 * @return {string}
 * @suppress {checkTypes}
 */
const $issue$ = function($$areYouAnInternalCaller, $ijData, project, id, selectedTestGapBranch) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.issue']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.issue']({project: project, id: id, selectedTestGapBranch: selectedTestGapBranch}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  let $output = '';
  const issueId__soy28619 = id.internalId ? id.internalId : id;
  $output += $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'activity', 'issues', project, undefined, id.internalId, selectedTestGapBranch ? {issueBranch: selectedTestGapBranch, action: 'view', id: issueId__soy28619} : {action: 'view', id: issueId__soy28619});
  return $output;
};
export { $issue as issue };
export { $issue$ as issue$ };
/**
 * @typedef {{
 *  project: string,
 *  id: ?,
 *  selectedTestGapBranch?: (?|undefined),
 * }}
 */
$issue.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $issue.soyTemplateName = 'ts.commons.LinkTemplate.issue';
}


/**
 * @param {!$specItem.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $specItem = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $specItem$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.id, opt_data.tracingTab, opt_data.commit);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {?} id
 * @param {null|number|undefined=} tracingTab
 * @param {?=} commit
 * @return {string}
 * @suppress {checkTypes}
 */
const $specItem$ = function($$areYouAnInternalCaller, $ijData, project, id, tracingTab, commit) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.specItem']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.specItem']({project: project, id: id, tracingTab: tracingTab, commit: commit}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(tracingTab == null || typeof tracingTab === 'number', 'tracingTab', tracingTab, '@param', 'null|number|undefined');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'requirements-tracing', 'details', project, commit, undefined, {id: id.internalId ? id.internalId : id, tracingTab: tracingTab});
};
export { $specItem as specItem };
export { $specItem$ as specItem$ };
/**
 * @typedef {{
 *  project: string,
 *  id: ?,
 *  tracingTab?: (null|number|undefined),
 *  commit?: (?|undefined),
 * }}
 */
$specItem.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $specItem.soyTemplateName = 'ts.commons.LinkTemplate.specItem';
}


/**
 * @param {!$mergeRequestDetails.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $mergeRequestDetails = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $mergeRequestDetails$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.id);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {string} id
 * @return {string}
 * @suppress {checkTypes}
 */
const $mergeRequestDetails$ = function($$areYouAnInternalCaller, $ijData, project, id) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.mergeRequestDetails']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.mergeRequestDetails']({project: project, id: id}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(typeof id === 'string', 'id', id, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'activity', 'merge-requests', project, undefined, id);
};
export { $mergeRequestDetails as mergeRequestDetails };
export { $mergeRequestDetails$ as mergeRequestDetails$ };
/**
 * @typedef {{
 *  project: string,
 *  id: string,
 * }}
 */
$mergeRequestDetails.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $mergeRequestDetails.soyTemplateName = 'ts.commons.LinkTemplate.mergeRequestDetails';
}


/**
 * @param {!$issueQuery.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $issueQuery = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $issueQuery$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.query, opt_data.queryName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {string} query
 * @param {null|string|undefined=} queryName
 * @return {string}
 * @suppress {checkTypes}
 */
const $issueQuery$ = function($$areYouAnInternalCaller, $ijData, project, query, queryName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.issueQuery']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.issueQuery']({project: project, query: query, queryName: queryName}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(typeof query === 'string', 'query', query, '@param', 'string');
  soy.assertParamType(queryName == null || typeof queryName === 'string', 'queryName', queryName, '@param', 'null|string|undefined');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'activity', 'issues', project, undefined, undefined, {q: query, queryname: queryName != null ? queryName : ''});
};
export { $issueQuery as issueQuery };
export { $issueQuery$ as issueQuery$ };
/**
 * @typedef {{
 *  project: string,
 *  query: string,
 *  queryName?: (null|string|undefined),
 * }}
 */
$issueQuery.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $issueQuery.soyTemplateName = 'ts.commons.LinkTemplate.issueQuery';
}


/**
 * @param {!$specItemQuery.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $specItemQuery = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $specItemQuery$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.query, opt_data.queryName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {string} query
 * @param {null|string|undefined=} queryName
 * @return {string}
 * @suppress {checkTypes}
 */
const $specItemQuery$ = function($$areYouAnInternalCaller, $ijData, project, query, queryName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.specItemQuery']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.specItemQuery']({project: project, query: query, queryName: queryName}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(typeof query === 'string', 'query', query, '@param', 'string');
  soy.assertParamType(queryName == null || typeof queryName === 'string', 'queryName', queryName, '@param', 'null|string|undefined');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'requirements-tracing', '', project, undefined, undefined, {q: query, queryname: queryName != null ? queryName : ''});
};
export { $specItemQuery as specItemQuery };
export { $specItemQuery$ as specItemQuery$ };
/**
 * @typedef {{
 *  project: string,
 *  query: string,
 *  queryName?: (null|string|undefined),
 * }}
 */
$specItemQuery.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $specItemQuery.soyTemplateName = 'ts.commons.LinkTemplate.specItemQuery';
}


/**
 * @param {!$testQuery.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $testQuery = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $testQuery$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.query, opt_data.queryName, opt_data.commit);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {string} query
 * @param {null|string|undefined=} queryName
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @return {string}
 * @suppress {checkTypes}
 */
const $testQuery$ = function($$areYouAnInternalCaller, $ijData, project, query, queryName, commit) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.testQuery']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.testQuery']({project: project, query: query, queryName: queryName, commit: commit}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(typeof query === 'string', 'query', query, '@param', 'string');
  soy.assertParamType(queryName == null || typeof queryName === 'string', 'queryName', queryName, '@param', 'null|string|undefined');
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'metrics', 'tests', project, commit, undefined, {action: 'filter', q: query, queryname: queryName != null ? queryName : ''});
};
export { $testQuery as testQuery };
export { $testQuery$ as testQuery$ };
/**
 * @typedef {{
 *  project: string,
 *  query: string,
 *  queryName?: (null|string|undefined),
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 * }}
 */
$testQuery.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $testQuery.soyTemplateName = 'ts.commons.LinkTemplate.testQuery';
}


/**
 * @param {!$arrayQueryParameter.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $arrayQueryParameter = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $arrayQueryParameter$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.parameter, opt_data.values);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} parameter
 * @param {!Array<string>} values
 * @return {string}
 * @suppress {checkTypes}
 */
const $arrayQueryParameter$ = function($$areYouAnInternalCaller, $ijData, parameter, values) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.arrayQueryParameter']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.arrayQueryParameter']({parameter: parameter, values: values}, $ijData);
  }
  soy.assertParamType(typeof parameter === 'string', 'parameter', parameter, '@param', 'string');
  soy.assertParamType(Array.isArray(values), 'values', values, '@param', '!Array<string>');
  let $output = '';
  const text28660List = values;
  const text28660ListLen = text28660List.length;
  for (let text28660Index = 0; text28660Index < text28660ListLen; text28660Index++) {
    const text28660Data = text28660List[text28660Index];
    $output += $query$(soy.$$internalCallMarkerDoNotUse, $ijData, parameter, text28660Data, false);
  }
  return $output;
};
export { $arrayQueryParameter as arrayQueryParameter };
export { $arrayQueryParameter$ as arrayQueryParameter$ };
/**
 * @typedef {{
 *  parameter: string,
 *  values: !Array<string>,
 * }}
 */
$arrayQueryParameter.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $arrayQueryParameter.soyTemplateName = 'ts.commons.LinkTemplate.arrayQueryParameter';
}


/**
 * @param {!$taskDetails.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $taskDetails = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.taskDetails']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.taskDetails'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {number} */
  const id = soy.assertParamType(typeof opt_data.id === 'number', 'id', opt_data.id, '@param', 'number');
  /** @type {null|undefined|{statuses?: (!Array<string>|null|undefined), assignees: !Array<string>, authors: !Array<string>, tags?: (!Array<string>|null|undefined),}} */
  const taskFilters = soy.assertParamType(opt_data.taskFilters == null || google.isObject(opt_data.taskFilters), 'taskFilters', opt_data.taskFilters, '@param', 'null|undefined|{statuses?: (!Array<string>|null|undefined), assignees: !Array<string>, authors: !Array<string>, tags?: (!Array<string>|null|undefined),}');
  return $taskViewLink(soy.$$assignDefaults({action: 'details'}, opt_data), $ijData);
};
export { $taskDetails as taskDetails };
/**
 * @typedef {{
 *  project: string,
 *  id: number,
 *  taskFilters?: (null|undefined|{statuses?: (!Array<string>|null|undefined), assignees: !Array<string>, authors: !Array<string>, tags?: (!Array<string>|null|undefined),}),
 * }}
 */
$taskDetails.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $taskDetails.soyTemplateName = 'ts.commons.LinkTemplate.taskDetails';
}


/**
 * @param {!$taskList.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $taskList = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $taskList$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @return {string}
 * @suppress {checkTypes}
 */
const $taskList$ = function($$areYouAnInternalCaller, $ijData, project) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.taskList']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.taskList']({project: project}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'qualitycontrol', 'tasks', project, undefined, undefined, {action: ''});
};
export { $taskList as taskList };
export { $taskList$ as taskList$ };
/**
 * @typedef {{
 *  project: string,
 * }}
 */
$taskList.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $taskList.soyTemplateName = 'ts.commons.LinkTemplate.taskList';
}


/**
 * @param {!$editTask.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $editTask = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.editTask']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.editTask'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {number} */
  const id = soy.assertParamType(typeof opt_data.id === 'number', 'id', opt_data.id, '@param', 'number');
  /** @type {string} */
  const action = soy.assertParamType(typeof opt_data.action === 'string', 'action', opt_data.action, '@param', 'string');
  /** @type {null|undefined|{statuses?: (!Array<string>|null|undefined), assignees: !Array<string>, authors: !Array<string>, tags?: (!Array<string>|null|undefined),}} */
  const taskFilters = soy.assertParamType(opt_data.taskFilters == null || google.isObject(opt_data.taskFilters), 'taskFilters', opt_data.taskFilters, '@param', 'null|undefined|{statuses?: (!Array<string>|null|undefined), assignees: !Array<string>, authors: !Array<string>, tags?: (!Array<string>|null|undefined),}');
  return $taskViewLink(soy.$$assignDefaults({action: action}, opt_data), $ijData);
};
export { $editTask as editTask };
/**
 * @typedef {{
 *  project: string,
 *  id: number,
 *  action: string,
 *  taskFilters?: (null|undefined|{statuses?: (!Array<string>|null|undefined), assignees: !Array<string>, authors: !Array<string>, tags?: (!Array<string>|null|undefined),}),
 * }}
 */
$editTask.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editTask.soyTemplateName = 'ts.commons.LinkTemplate.editTask';
}


/**
 * @param {!$taskViewLink.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $taskViewLink = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $taskViewLink$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.id, opt_data.action, opt_data.taskFilters);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {number} id
 * @param {string} action
 * @param {null|undefined|{statuses?: (!Array<string>|null|undefined), assignees: !Array<string>, authors: !Array<string>, tags?: (!Array<string>|null|undefined),}=} taskFilters
 * @return {string}
 * @suppress {checkTypes}
 */
const $taskViewLink$ = function($$areYouAnInternalCaller, $ijData, project, id, action, taskFilters) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.taskViewLink']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.taskViewLink']({project: project, id: id, action: action, taskFilters: taskFilters}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(typeof id === 'number', 'id', id, '@param', 'number');
  soy.assertParamType(typeof action === 'string', 'action', action, '@param', 'string');
  soy.assertParamType(taskFilters == null || google.isObject(taskFilters), 'taskFilters', taskFilters, '@param', 'null|undefined|{statuses?: (!Array<string>|null|undefined), assignees: !Array<string>, authors: !Array<string>, tags?: (!Array<string>|null|undefined),}');
  return taskFilters != null ? $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'qualitycontrol', 'tasks', project, undefined, undefined, {id: id, action: action}) + $arrayQueryParameter$(soy.$$internalCallMarkerDoNotUse, $ijData, 'author', taskFilters.authors) + $arrayQueryParameter$(soy.$$internalCallMarkerDoNotUse, $ijData, 'assignee', taskFilters.assignees) + (taskFilters.statuses != null ? $arrayQueryParameter$(soy.$$internalCallMarkerDoNotUse, $ijData, 'statuses', taskFilters.statuses) : '') + (taskFilters.tags != null ? $arrayQueryParameter$(soy.$$internalCallMarkerDoNotUse, $ijData, 'tags', taskFilters.tags) : '') : $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'qualitycontrol', 'tasks', project, undefined, undefined, {id: id, action: action});
};
export { $taskViewLink as taskViewLink };
export { $taskViewLink$ as taskViewLink$ };
/**
 * @typedef {{
 *  project: string,
 *  id: number,
 *  action: string,
 *  taskFilters?: (null|undefined|{statuses?: (!Array<string>|null|undefined), assignees: !Array<string>, authors: !Array<string>, tags?: (!Array<string>|null|undefined),}),
 * }}
 */
$taskViewLink.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $taskViewLink.soyTemplateName = 'ts.commons.LinkTemplate.taskViewLink';
}


/**
 * @param {!$audit.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $audit = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.audit']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.audit'](opt_data, $ijData);
  }
  /** @type {string} */
  const viewName = soy.assertParamType(typeof opt_data.viewName === 'string', 'viewName', opt_data.viewName, '@param', 'string');
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {null|string|undefined} */
  const uniformPath = soy.assertParamType(opt_data.uniformPath == null || typeof opt_data.uniformPath === 'string', 'uniformPath', opt_data.uniformPath, '@param', 'null|string|undefined');
  return $uniformLink(soy.$$assignDefaults({perspective: 'audit', viewName: viewName}, opt_data), $ijData);
};
export { $audit as audit };
/**
 * @typedef {{
 *  viewName: string,
 *  project: string,
 *  uniformPath?: (null|string|undefined),
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  parameters?: (?|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$audit.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $audit.soyTemplateName = 'ts.commons.LinkTemplate.audit';
}


/**
 * @param {!$parseLog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $parseLog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.parseLog']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.parseLog'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  return $uniformLink(soy.$$assignDefaults({perspective: 'system', viewName: 'parse-log', parameters: {project: project}}, opt_data), $ijData);
};
export { $parseLog as parseLog };
/**
 * @typedef {{
 *  project: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  uniformPath?: (null|string|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$parseLog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $parseLog.soyTemplateName = 'ts.commons.LinkTemplate.parseLog';
}


/**
 * @param {!$uniformLink.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $uniformLink = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.perspective, opt_data.viewName, opt_data.project, opt_data.commit, opt_data.uniformPath, opt_data.parameters, opt_data.partitions);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} perspective
 * @param {null|string|undefined=} viewName
 * @param {null|string|undefined=} project
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @param {null|string|undefined=} uniformPath
 * @param {?=} parameters
 * @param {!Array<string>|null|undefined=} partitions
 * @return {string}
 * @suppress {checkTypes}
 */
const $uniformLink$ = function($$areYouAnInternalCaller, $ijData, perspective, viewName, project, commit, uniformPath, parameters, partitions) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.uniformLink']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.uniformLink']({perspective: perspective, viewName: viewName, project: project, commit: commit, uniformPath: uniformPath, parameters: parameters, partitions: partitions}, $ijData);
  }
  soy.assertParamType(typeof perspective === 'string', 'perspective', perspective, '@param', 'string');
  soy.assertParamType(viewName == null || typeof viewName === 'string', 'viewName', viewName, '@param', 'null|string|undefined');
  soy.assertParamType(project == null || typeof project === 'string', 'project', project, '@param', 'null|string|undefined');
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  soy.assertParamType(uniformPath == null || typeof uniformPath === 'string', 'uniformPath', uniformPath, '@param', 'null|string|undefined');
  soy.assertParamType(partitions == null || Array.isArray(partitions), 'partitions', partitions, '@param', '!Array<string>|null|undefined');
  let $output = soy.$$escapeUri(perspective) + '.html#' + (viewName ? soy.$$escapeUri(viewName) : '') + '/' + (project ? soy.$$escapeUri(project) : '') + '/' + (uniformPath ? soy.$$escapeUri(uniformPath) : '');
  if (parameters) {
    const key28737List = soy.$$getMapKeys(parameters);
    const key28737ListLen = key28737List.length;
    for (let key28737Index = 0; key28737Index < key28737ListLen; key28737Index++) {
      const key28737Data = key28737List[key28737Index];
      $output += $query$(soy.$$internalCallMarkerDoNotUse, $ijData, key28737Data, parameters[/** @type {?} */ (key28737Data)], key28737Index == 0);
    }
  }
  $output += commit != null ? $queryWithCommit$(soy.$$internalCallMarkerDoNotUse, $ijData, parameters, commit) : '';
  if (partitions) {
    const partition28750List = partitions;
    const partition28750ListLen = partition28750List.length;
    if (partition28750ListLen > 0) {
      for (let partition28750Index = 0; partition28750Index < partition28750ListLen; partition28750Index++) {
        const partition28750Data = partition28750List[partition28750Index];
        $output += $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'partition', partition28750Data, (!parameters || parameters.length == 0) && commit == null && partition28750Index == 0);
      }
    } else {
      $output += $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 'partition-empty', 'true', (!parameters || parameters.length == 0) && commit == null);
    }
  }
  return $output;
};
export { $uniformLink as uniformLink };
export { $uniformLink$ as uniformLink$ };
/**
 * @typedef {{
 *  perspective: string,
 *  viewName?: (null|string|undefined),
 *  project?: (null|string|undefined),
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  uniformPath?: (null|string|undefined),
 *  parameters?: (?|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$uniformLink.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $uniformLink.soyTemplateName = 'ts.commons.LinkTemplate.uniformLink';
}


/**
 * @param {?$commitForQueryValue.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $commitForQueryValue = function(opt_data, opt_ijData) {
  opt_data = opt_data || {};
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $commitForQueryValue$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.commit);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @return {string}
 * @suppress {checkTypes}
 */
const $commitForQueryValue$ = function($$areYouAnInternalCaller, $ijData, commit) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.commitForQueryValue']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.commitForQueryValue']({commit: commit}, $ijData);
  }
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  let $output = '';
  if (commit != null) {
    const branchName__soy28764 = commit.branchName;
    const timestamp__soy28765 = commit.timestamp;
    const previous__soy28766 = commit.previous;
    $output += (branchName__soy28764 != null ? '' + branchName__soy28764 + ':' : '') + (timestamp__soy28765 != null ? '' + timestamp__soy28765 : 'HEAD') + (previous__soy28766 != null && previous__soy28766 > 0 ? 'p' + '' + previous__soy28766 : '');
  } else {
    $output += 'HEAD';
  }
  return $output;
};
export { $commitForQueryValue as commitForQueryValue };
export { $commitForQueryValue$ as commitForQueryValue$ };
/**
 * @typedef {{
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 * }}
 */
$commitForQueryValue.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $commitForQueryValue.soyTemplateName = 'ts.commons.LinkTemplate.commitForQueryValue';
}


/**
 * @param {!$queryWithCommit.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $queryWithCommit = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $queryWithCommit$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.parameters, opt_data.commit);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} parameters
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @return {string}
 * @suppress {checkTypes}
 */
const $queryWithCommit$ = function($$areYouAnInternalCaller, $ijData, parameters, commit) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.queryWithCommit']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.queryWithCommit']({commit: commit, parameters: parameters}, $ijData);
  }
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  let $output = '';
  let formattedCommit__soy28783 = '' + $commitForQueryValue$(soy.$$internalCallMarkerDoNotUse, $ijData, commit);
  $output += $query$(soy.$$internalCallMarkerDoNotUse, $ijData, 't', formattedCommit__soy28783, !parameters || parameters.length == 0);
  return $output;
};
export { $queryWithCommit as queryWithCommit };
export { $queryWithCommit$ as queryWithCommit$ };
/**
 * @typedef {{
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  parameters: ?,
 * }}
 */
$queryWithCommit.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $queryWithCommit.soyTemplateName = 'ts.commons.LinkTemplate.queryWithCommit';
}


/**
 * @param {!$query.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @private
 */
const $query = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $query$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.parameter, opt_data.value, opt_data.isFirst);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} parameter
 * @param {boolean|null|number|string|undefined=} value
 * @param {boolean|null|undefined=} isFirst
 * @return {string}
 * @private
 * @suppress {checkTypes}
 */
const $query$ = function($$areYouAnInternalCaller, $ijData, parameter, value, isFirst) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.query']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.query']({parameter: parameter, value: value, isFirst: isFirst}, $ijData);
  }
  soy.assertParamType(typeof parameter === 'string', 'parameter', parameter, '@param', 'string');
  soy.assertParamType(value == null || typeof value === 'boolean' || typeof value === 'number' || typeof value === 'string', 'value', value, '@param', 'boolean|null|number|string|undefined');
  soy.assertParamType(isFirst == null || typeof isFirst === 'boolean', 'isFirst', isFirst, '@param', 'boolean|null|undefined');
  let $tmp;
  if (isFirst) {
    $tmp = '?';
  } else if (value != null) {
    $tmp = '&';
  } else {
    $tmp = '';
  }
  let $output = '' + $tmp;
  let queryString__soy28796 = '' + (value != null ? soy.$$escapeUri(parameter) + '=' + soy.$$escapeUri(value) : '');
  $output += '' + queryString__soy28796;
  return $output;
};
/**
 * @typedef {{
 *  parameter: string,
 *  value?: (boolean|null|number|string|undefined),
 *  isFirst?: (boolean|null|undefined),
 * }}
 */
$query.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $query.soyTemplateName = 'ts.commons.LinkTemplate.query';
}


/**
 * @param {!$architectureEditor.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $architectureEditor = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $architectureEditor$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.assessmentIdentifier, opt_data.commit, opt_data.component);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {string} assessmentIdentifier
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @param {?=} component
 * @return {string}
 * @suppress {checkTypes}
 */
const $architectureEditor$ = function($$areYouAnInternalCaller, $ijData, project, assessmentIdentifier, commit, component) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.architectureEditor']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.architectureEditor']({project: project, assessmentIdentifier: assessmentIdentifier, commit: commit, component: component}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(typeof assessmentIdentifier === 'string', 'assessmentIdentifier', assessmentIdentifier, '@param', 'string');
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'architecture', 'editor', project, commit, assessmentIdentifier, component ? {component: component} : []);
};
export { $architectureEditor as architectureEditor };
export { $architectureEditor$ as architectureEditor$ };
/**
 * @typedef {{
 *  project: string,
 *  assessmentIdentifier: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  component?: (?|undefined),
 * }}
 */
$architectureEditor.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $architectureEditor.soyTemplateName = 'ts.commons.LinkTemplate.architectureEditor';
}


/**
 * @param {!$file.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $file = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.file']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.file'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {string} */
  const uniformPath = soy.assertParamType(typeof opt_data.uniformPath === 'string', 'uniformPath', opt_data.uniformPath, '@param', 'string');
  /** @type {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} */
  const commit = soy.assertParamType(opt_data.commit == null || google.isObject(opt_data.commit), 'commit', opt_data.commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  /** @type {?} */
  const visibleMetric = opt_data.visibleMetric;
  /** @type {null|string|undefined} */
  const targetPerspectiveName = soy.assertParamType(opt_data.targetPerspectiveName == null || typeof opt_data.targetPerspectiveName === 'string', 'targetPerspectiveName', opt_data.targetPerspectiveName, '@param', 'null|string|undefined');
  /** @type {null|string|undefined} */
  const targetViewName = soy.assertParamType(opt_data.targetViewName == null || typeof opt_data.targetViewName === 'string', 'targetViewName', opt_data.targetViewName, '@param', 'null|string|undefined');
  return soy.$$checkNotNull(uniformPath).endsWith('.architecture') ? $architectureEditor$(soy.$$internalCallMarkerDoNotUse, $ijData, project, uniformPath, commit) : $code(/** @type {?} */ (opt_data), $ijData);
};
export { $file as file };
/**
 * @typedef {{
 *  project: string,
 *  uniformPath: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  visibleMetric?: (?|undefined),
 *  targetPerspectiveName?: (null|string|undefined),
 *  targetViewName?: (null|string|undefined),
 *  findingFilter?: (!Array<string>|null|undefined),
 *  selection?: (?|undefined),
 *  identifier?: (null|string|undefined),
 *  highlight?: (null|string|undefined),
 *  thresholdProfile?: (null|string|undefined),
 *  highlightMetric?: (boolean|null|undefined),
 *  showCoverage?: (boolean|null|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 * }}
 */
$file.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $file.soyTemplateName = 'ts.commons.LinkTemplate.file';
}


/**
 * @param {!$showExternalAnalysisUploads.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $showExternalAnalysisUploads = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $showExternalAnalysisUploads$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @return {string}
 * @suppress {checkTypes}
 */
const $showExternalAnalysisUploads$ = function($$areYouAnInternalCaller, $ijData, project) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.showExternalAnalysisUploads']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.showExternalAnalysisUploads']({project: project}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', undefined, undefined, undefined, undefined, {name: project, action: 'showExternalAnalysisUploads'});
};
export { $showExternalAnalysisUploads as showExternalAnalysisUploads };
export { $showExternalAnalysisUploads$ as showExternalAnalysisUploads$ };
/**
 * @typedef {{
 *  project: string,
 * }}
 */
$showExternalAnalysisUploads.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $showExternalAnalysisUploads.soyTemplateName = 'ts.commons.LinkTemplate.showExternalAnalysisUploads';
}


/**
 * @param {!$metrics.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $metrics = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.metrics']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.metrics'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {string} */
  const uniformPath = soy.assertParamType(typeof opt_data.uniformPath === 'string', 'uniformPath', opt_data.uniformPath, '@param', 'string');
  /** @type {null|string|undefined} */
  const thresholdProfile = soy.assertParamType(opt_data.thresholdProfile == null || typeof opt_data.thresholdProfile === 'string', 'thresholdProfile', opt_data.thresholdProfile, '@param', 'null|string|undefined');
  /** @type {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} */
  const commit = soy.assertParamType(opt_data.commit == null || google.isObject(opt_data.commit), 'commit', opt_data.commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  let $tmp;
  if (soy.$$strContains('' + uniformPath, '-architectures-')) {
    $tmp = $architectureMetrics(/** @type {?} */ (opt_data), $ijData);
  } else if (soy.$$checkNotNull(uniformPath).startsWith('-test-query-')) {
    $tmp = $testQuery$(soy.$$internalCallMarkerDoNotUse, $ijData, project, '', uniformPath, commit);
  } else {
    $tmp = $code(/** @type {?} */ (opt_data), $ijData);
  }
  return $tmp;
};
export { $metrics as metrics };
/**
 * @typedef {{
 *  project: string,
 *  uniformPath: string,
 *  thresholdProfile?: (null|string|undefined),
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  visibleMetric?: (null|string|undefined),
 *  targetPerspectiveName?: (null|string|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 *  findingFilter?: (!Array<string>|null|undefined),
 *  selection?: (?|undefined),
 *  identifier?: (null|string|undefined),
 *  highlight?: (null|string|undefined),
 *  highlightMetric?: (boolean|null|undefined),
 *  showCoverage?: (boolean|null|undefined),
 *  targetViewName?: (null|string|undefined),
 * }}
 */
$metrics.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $metrics.soyTemplateName = 'ts.commons.LinkTemplate.metrics';
}


/**
 * @param {!$testExecution.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $testExecution = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $testExecution$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.uniformPath, opt_data.commit, opt_data.partition);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {string} uniformPath
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @param {null|string|undefined=} partition
 * @return {string}
 * @suppress {checkTypes}
 */
const $testExecution$ = function($$areYouAnInternalCaller, $ijData, project, uniformPath, commit, partition) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.testExecution']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.testExecution']({project: project, uniformPath: uniformPath, commit: commit, partition: partition}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(typeof uniformPath === 'string', 'uniformPath', uniformPath, '@param', 'string');
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  soy.assertParamType(partition == null || typeof partition === 'string', 'partition', partition, '@param', 'null|string|undefined');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'metrics', 'tests', project, commit, uniformPath, partition ? {partition: partition} : null);
};
export { $testExecution as testExecution };
export { $testExecution$ as testExecution$ };
/**
 * @typedef {{
 *  project: string,
 *  uniformPath: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  partition?: (null|string|undefined),
 * }}
 */
$testExecution.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $testExecution.soyTemplateName = 'ts.commons.LinkTemplate.testExecution';
}


/**
 * @param {!$metricsShowingPerspective.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @suppress {checkTypes}
 */
const $metricsShowingPerspective = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.metricsShowingPerspective']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.metricsShowingPerspective'](opt_data, $ijData);
  }
  /** @type {string} */
  const uniformPath = soy.assertParamType(typeof opt_data.uniformPath === 'string', 'uniformPath', opt_data.uniformPath, '@param', 'string');
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} */
  const commit = soy.assertParamType(opt_data.commit == null || google.isObject(opt_data.commit), 'commit', opt_data.commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  /** @type {null|string|undefined} */
  const resourceType = soy.assertParamType(opt_data.resourceType == null || typeof opt_data.resourceType === 'string', 'resourceType', opt_data.resourceType, '@param', 'null|string|undefined');
  /** @type {null|string|undefined} */
  const visibleMetric = soy.assertParamType(opt_data.visibleMetric == null || typeof opt_data.visibleMetric === 'string', 'visibleMetric', opt_data.visibleMetric, '@param', 'null|string|undefined');
  /** @type {null|string|undefined} */
  const targetPerspectiveName = soy.assertParamType(opt_data.targetPerspectiveName == null || typeof opt_data.targetPerspectiveName === 'string', 'targetPerspectiveName', opt_data.targetPerspectiveName, '@param', 'null|string|undefined');
  /** @type {!Array<string>|null|undefined} */
  const partitions = soy.assertParamType(opt_data.partitions == null || Array.isArray(opt_data.partitions), 'partitions', opt_data.partitions, '@param', '!Array<string>|null|undefined');
  let $tmp;
  if (soy.$$strContains('' + uniformPath, '.architecture') && !soy.$$equals('FILE', resourceType)) {
    $tmp = $architectureMetrics(/** @type {?} */ (opt_data), $ijData);
  } else if (soy.$$checkNotNull(uniformPath).startsWith('-issues-/') && soy.$$equals('FILE', resourceType)) {
    $tmp = $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'activity', 'issues', project, undefined, undefined, {queryname: uniformPath});
  } else if (soy.$$checkNotNull(uniformPath).startsWith('-spec-items-') && soy.$$equals('FILE', resourceType)) {
    $tmp = $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'requirements-tracing', '', project, undefined, undefined, {queryname: uniformPath, specItemTab: 0});
  } else if (soy.$$checkNotNull(uniformPath).startsWith('-test-implementation-/') || soy.$$checkNotNull(uniformPath).startsWith('-test-execution-/')) {
    $tmp = soy.$$equals('FILE', resourceType) ? $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'metrics', 'tests', project, undefined, uniformPath, {details: true}) : $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'metrics', 'tests', project, undefined, uniformPath, undefined, partitions);
  } else if (soy.$$checkNotNull(uniformPath).startsWith('-test-query-')) {
    $tmp = $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'metrics', 'tests', project, undefined, undefined, {action: 'filter', queryname: ('' + uniformPath).substring(13)}, partitions);
  } else {
    $tmp = visibleMetric ? $file(soy.$$assignDefaults({highlightMetric: true}, opt_data), $ijData) : $file(/** @type {?} */ (opt_data), $ijData);
  }
  return $tmp;
};
export { $metricsShowingPerspective as metricsShowingPerspective };
/**
 * @typedef {{
 *  uniformPath: string,
 *  project: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  resourceType?: (null|string|undefined),
 *  visibleMetric?: (null|string|undefined),
 *  targetPerspectiveName?: (null|string|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 *  thresholdProfile?: (null|string|undefined),
 *  targetViewName?: (null|string|undefined),
 *  findingFilter?: (!Array<string>|null|undefined),
 *  selection?: (?|undefined),
 *  identifier?: (null|string|undefined),
 *  highlight?: (null|string|undefined),
 *  showCoverage?: (boolean|null|undefined),
 *  highlightMetric?: (boolean|null|undefined),
 * }}
 */
$metricsShowingPerspective.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $metricsShowingPerspective.soyTemplateName = 'ts.commons.LinkTemplate.metricsShowingPerspective';
}


/**
 * @param {!$presentReport.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $presentReport = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $presentReport$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.id, opt_data.startIndex);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {number|string} id
 * @param {null|number|undefined=} startIndex
 * @return {string}
 * @suppress {checkTypes}
 */
const $presentReport$ = function($$areYouAnInternalCaller, $ijData, id, startIndex) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.LinkTemplate.presentReport']) {
    return soy.$$stubsMap['ts.commons.LinkTemplate.presentReport']({id: id, startIndex: startIndex}, $ijData);
  }
  soy.assertParamType(typeof id === 'number' || typeof id === 'string', 'id', id, '@param', 'number|string');
  soy.assertParamType(startIndex == null || typeof startIndex === 'number', 'startIndex', startIndex, '@param', 'null|number|undefined');
  return $uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'qualitycontrol', 'reports', undefined, undefined, undefined, {id: id, startIndex: startIndex != null ? startIndex : 0, action: 'present'});
};
export { $presentReport as presentReport };
export { $presentReport$ as presentReport$ };
/**
 * @typedef {{
 *  id: (number|string),
 *  startIndex?: (null|number|undefined),
 * }}
 */
$presentReport.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $presentReport.soyTemplateName = 'ts.commons.LinkTemplate.presentReport';
}
