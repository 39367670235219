import { useQuery } from '@tanstack/react-query';
import type { JSX } from 'react';
import ReactDateTimePicker from 'react-datetime-picker';
import * as asserts from 'ts-closure-library/lib/asserts/asserts';
import type { Callback } from 'ts/base/Callback';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { useTimePickerContext } from 'ts/commons/time/components/TimePickerContext';
import { useInputWithDefault } from 'ts/commons/time/components/TimePickerUtils';
import useDefaultCalendarProps from 'ts/commons/time/DefaultCalendarPropsHook';
import { EPointInTimeType } from 'ts/commons/time/EPointInTimeType';
import { TimeUtils } from 'ts/commons/time/TimeUtils';
import type { TypedPointInTime } from 'ts/commons/time/TypedPointInTime';

function validateAndExtractPointInTime(
	timestamp: number | null,
	setTypedPointInTime: Callback<TypedPointInTime>
): Promise<string | undefined> {
	if (timestamp == null) {
		return Promise.resolve('No date was selected.');
	}
	setTypedPointInTime(TimeUtils.timestamp(timestamp));
	return Promise.resolve(undefined);
}

function useSetInitialValueWithClient() {
	const client = useTeamscaleServiceClient();
	const { defaultValue } = useTimePickerContext();
	const { data: selectedTimestampFromDefinedPointInTime } = useQuery(
		['selectedTimestampFromDefinedPointInTime', defaultValue],
		() => {
			asserts.assert(defaultValue != null, 'Default value was not expected to be undefined');
			if (defaultValue.type === EPointInTimeType.BASELINE) {
				return client
					.getBaselineInfo(defaultValue.value.project, defaultValue.value.name)
					.then(baselineInfo => baselineInfo.timestamp);
			}
			if (defaultValue.type === EPointInTimeType.SYSTEM_VERSION) {
				return client
					.getSystemVersionInfo(defaultValue.value.project, defaultValue.value.name)
					.then(versionInfo => versionInfo.commit.timestamp);
			}
			asserts.fail('Default value was expected to have "baseline" or "system version" type');
		},
		{
			enabled:
				defaultValue?.type === EPointInTimeType.BASELINE ||
				defaultValue?.type === EPointInTimeType.SYSTEM_VERSION,
			useErrorBoundary: false // If the query failed (e.g. the baseline does not exist), defaults to current date
		}
	);
	return (defaultValue: TypedPointInTime | null) =>
		setInitialValue(defaultValue, selectedTimestampFromDefinedPointInTime);
}

function setInitialValue(
	defaultValue: TypedPointInTime | null,
	defaultTimestampFromDefinedPointInTime?: number
): number {
	if (defaultTimestampFromDefinedPointInTime != null) {
		return defaultTimestampFromDefinedPointInTime;
	}
	if (defaultValue != null) {
		if (defaultValue.type === EPointInTimeType.TIMESTAMP || defaultValue.type === EPointInTimeType.REVISION) {
			return defaultValue.value.timestamp;
		}
	}
	return Date.now();
}

/** Props for DateTimePicker. */
type DateTimePickerProps = {
	dateOnly: boolean;
};

/** A component for picking a date and optional time of the day. */
export default function DateTimePicker({ dateOnly }: DateTimePickerProps): JSX.Element | null {
	const setInitialValue = useSetInitialValueWithClient();
	const [selectedValueAsTimestamp, setSelectedValueAsTimestamp] = useInputWithDefault(
		'date',
		validateAndExtractPointInTime,
		setInitialValue
	);
	const selectedValueAsDate = new Date(selectedValueAsTimestamp ?? Date.now());
	const calendarProps = useDefaultCalendarProps(dateOnly);
	return (
		<ReactDateTimePicker
			value={selectedValueAsDate}
			onChange={date => {
				if (date != null) {
					setSelectedValueAsTimestamp(date.getTime());
				}
			}}
			{...calendarProps}
		/>
	);
}
