import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import * as LinkTemplate from 'soy/commons/LinkTemplate.soy.generated';
import { urlDecode } from 'ts-closure-library/lib/string/string';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

export default class Redirects {
	private static readonly PREFIX_REDIRECTS: Array<[RegExp, string]> = [
		[
			/\/issues\.html#?\/([^/]+)\/\?metric=([^?]*)/,
			`/${ETeamscalePerspective.ACTIVITY.page}#issues/$1/?action=list&metric=$2`
		],
		[
			/\/issues\.html#?\/([^/]+)\/([^?]*)\??/,
			`/${ETeamscalePerspective.ACTIVITY.page}#issues/$1/?action=view&id=$2&`
		],
		[/\/issues\.html/, `/${ETeamscalePerspective.ACTIVITY.page}#issues`],
		[/\/tasks\.html#?/, `/${ETeamscalePerspective.QUALITY_CONTROL.page}#tasks`],
		[/\/reports\.html/, `/${ETeamscalePerspective.QUALITY_CONTROL.page}#reports`],
		[/\/findings\.html#baselines/, `/${ETeamscalePerspective.QUALITY_CONTROL.page}#baselines`],
		[/\/tests\.html/, `/${ETeamscalePerspective.TEST_GAPS.page}`],
		[/\/metrics\.html#test-executions/, `/${ETeamscalePerspective.METRICS.page}#tests`],
		[/\/dashboard\.html#show/, `/${ETeamscalePerspective.DASHBOARD.page}#`],
		[/\/dashboard\.html#kiosk(.*)/, `/${ETeamscalePerspective.DASHBOARD.page}#$1&kioskViewMode=true`]
	];

	/**
	 * <p>Creates a relative redirect url based on the given window location. Returns <code>null</code> if no redirect
	 * is necessary. Non-null results of this method should e.g. be applied using {@link window.location#replaceState}
	 * or {@link document#location#replace}</p> <p>Example: <ul> <li>Input with <code>window.location.href ===
	 * 'http://foo.com:8080/teamscale/issues.html'</code></li> <li>Result:
	 * <code>'/activity.html#issues?foo=bar'</code></li> </ul> </p>
	 */
	public static createRedirectOrNull(location: Location): string | null {
		return Redirects.createRedirectOrNullInternal(location.pathname + location.hash);
	}

	/**
	 * This internal method uses an url string to make it unit-testable (window.location is not usable in tests). Note
	 * that if the Teamscale instance is configured with a url prefix, the urlPathAndHash contains it.
	 */
	public static createRedirectOrNullInternal(urlPathAndHash: string): string | null {
		for (const patternAndReplacement of Redirects.PREFIX_REDIRECTS) {
			if (urlPathAndHash.match(patternAndReplacement[0]!)) {
				return Redirects.rewritePreTeamscaleV60TaskDetailLink(
					urlPathAndHash.replace(patternAndReplacement[0]!, patternAndReplacement[1]!)
				);
			}
		}
		return Redirects.rewriteCompareLink(urlPathAndHash);
	}

	/**
	 * Rewrites the task details URL for Teamscale versions before v6.0 (format
	 * <code>http://teamscale-base-url/tasks.html#details/project-id/query-string</code>) into the new format
	 * (<code>http://teamscale-base-url/qualitycontrol.html#tasks/project-id/query-string&action=details</code>)
	 * supported from v6.0 and above so that task details, stored for instance in reports, can be viewed.
	 */
	private static rewritePreTeamscaleV60TaskDetailLink(urlPathAndHash: string): string {
		const taskDetailUrlHeader = '/' + ETeamscalePerspective.QUALITY_CONTROL.page + '#tasks';
		if (urlPathAndHash.startsWith(taskDetailUrlHeader)) {
			const urlWithProjectAndQueryString = urlPathAndHash.substring(taskDetailUrlHeader.length);
			const regex = /(details|edit)\/([^/]+)\/([^/]+)/;
			if (urlWithProjectAndQueryString.match(regex)) {
				return taskDetailUrlHeader + urlWithProjectAndQueryString.replace(regex, '/$2/$3&action=$1');
			}
		}

		return urlPathAndHash;
	}

	/**
	 * Rewrites the compare link to the new format from Teamscale versions v8.6.17 (new format was introduced in
	 * TS-33428).
	 */
	private static rewriteCompareLink(urlPathAndHash: string) {
		if (
			!(
				urlPathAndHash.includes('/compare.html#/') &&
				urlPathAndHash.includes('#@#') &&
				urlPathAndHash.includes('#&#')
			)
		) {
			return null;
		}
		const [urlPrefix, hash] = urlPathAndHash.split('/compare.html#/');
		const parts = urlDecode(hash!).split(/#&#/, 4);

		// Full path might contain line information for scrolling
		const leftFullPath = parts[0]!;
		const rightFullPath = parts[1]!;

		const isInconsistentClone = parts.length > 3 && parts[3] === 'isInconsistentClone';
		let leftStartLine, leftEndLine, rightStartLine, rightEndLine;
		if (parts.length > 2) {
			[, leftStartLine, leftEndLine, rightStartLine, rightEndLine] = parts[2]!.match(/(\d+)-(\d+):(\d+)-(\d+)/)!;
		}

		//The first capturing group (.+?) matches the project name or alias. The second capturing group (.+?) after the
		// slash matches the file's uniform path. It is then followed by the last optional capturing group matching the
		// '#@#' followed by the timestamp information.
		const matcher = /^(.+?)\/(.+?)(#@#.+)?$/;
		const leftProjectAndPathMatch = leftFullPath.match(matcher)!;
		const leftProject = leftProjectAndPathMatch[1]!;
		const leftUniformPath = leftProjectAndPathMatch[2]!;
		const leftAdditionInfo = Redirects.parseAdditionalPathInfo(leftProjectAndPathMatch[3]);
		const rightProjectAndPathMatch = rightFullPath.match(matcher)!;
		const rightProject = rightProjectAndPathMatch[1]!;
		const rightUniformPath = rightProjectAndPathMatch[2]!;
		const rightAdditionInfo = Redirects.parseAdditionalPathInfo(rightProjectAndPathMatch[3]);
		return (
			urlPrefix +
			'/' +
			LinkTemplate.comparePerspective({
				leftProject,
				leftUniformPath,
				leftCommit: leftAdditionInfo.commit,
				leftStartLine: leftStartLine ? Number(leftStartLine) : leftAdditionInfo.initialLine,
				leftEndLine: Number(leftEndLine),
				rightProject,
				rightUniformPath,
				rightCommit: rightAdditionInfo.commit,
				rightStartLine: rightStartLine ? Number(rightStartLine) : rightAdditionInfo.initialLine,
				rightEndLine: Number(rightEndLine),
				isInconsistentClone
			})
		);
	}

	/**
	 * If possible, parses the branch, timestamp and initial line number from the given commit string. If the string is
	 * null, undefined or doesn't match any of the possible commit info patterns, nothing happens.
	 */
	private static parseAdditionalPathInfo(commitInfo: string | undefined): {
		commit?: UnresolvedCommitDescriptor;
		initialLine?: number;
	} {
		if (commitInfo == null) {
			return {};
		}
		const separator = '(#@#)';
		const timestampPattern = '(?<timestamp>(\\d{9,13}|HEAD)(p\\d+)?)';
		const branchAndTimestampPattern = '(?<branch>[^:]*)(?<colon>:?)' + timestampPattern;
		const lineNumberPattern = '(:)(\\d+)';

		// Check for the three cases:
		// (1) (opt_branch :) timestamp : line number
		const branchWithTimestampAndLine = commitInfo.match(
			new RegExp(separator + branchAndTimestampPattern + lineNumberPattern + '$')
		);
		if (branchWithTimestampAndLine != null) {
			return {
				commit: this.getCommit(branchWithTimestampAndLine),
				initialLine: parseInt(branchWithTimestampAndLine[8]!, 10)
			};
		}

		// (2) (opt_branch :) timestamp
		const branchWithTimestamp = commitInfo.match(new RegExp(separator + branchAndTimestampPattern + '$'));
		if (branchWithTimestamp != null) {
			return {
				commit: this.getCommit(branchWithTimestamp)
			};
		}

		// (3) (opt_branch :) line number
		const branchWithLine = commitInfo.match(new RegExp(separator + '((?<branch>[^:]+):)?(\\d+)$'));
		if (branchWithLine != null) {
			return {
				commit: this.getCommit(branchWithLine),
				initialLine: parseInt(branchWithLine[4]!, 10)
			};
		}

		return {};
	}

	/** Extracts the commit from the matched regex array. */
	private static getCommit(match: RegExpMatchArray) {
		const branchAndTimestamp =
			(match.groups!.branch ?? '') + (match.groups!.colon ?? '') + (match.groups!.timestamp ?? '');
		return UnresolvedCommitDescriptor.fromString(branchAndTimestamp) ?? undefined;
	}
}
