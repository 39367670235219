import type { JSX } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import type { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import * as PerspectiveBaseTemplate from 'soy/base/scaffolding/PerspectiveBaseTemplate.soy.generated';
import * as LinkTemplate from 'soy/commons/LinkTemplate.soy.generated';
import { useUserPermissionInfo } from 'ts/base/hooks/PermissionInfoHook';
import { useTeamscaleInfo } from 'ts/base/hooks/TeamscaleInfoHook';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { TeamscaleLink } from 'ts/base/routing/TeamscaleLink';
import { KeyboardShortcutRegistry } from 'ts/base/scaffolding/KeyboardShortcutRegistry';
import { TeamscaleSupportDialog } from 'ts/base/scaffolding/TeamscaleSupportDialog';
import {
	DECLARATION_OF_ACCESSIBILITY_DISPLAY_NAME,
	DECLARATION_OF_ACCESSIBILITY_LINK,
	useDeclarationOfAccessibilityExistence
} from 'ts/commons/accessibility/DeclarationOfAccessibility';
import { UIUtils } from 'ts/commons/UIUtils';
import { EGlobalPermission } from 'typedefs/EGlobalPermission';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';
import styles from './PerspectiveSettingsBar.module.less';

/** Shows the question mark dropdown in the top right with the help/support menu popup. */
export function HelpMenu(): JSX.Element {
	const client = useTeamscaleServiceClient();
	const teamscaleInfo = useTeamscaleInfo();
	const options: DropdownItemProps[] = [];
	options.push({
		key: 'docs',
		icon: <Icon color="grey" name="book" />,
		text: 'Documentation',
		className: 'left',
		as: 'a',
		href: 'documentation/',
		target: '_blank'
	});
	options.push({
		key: 'rules',
		icon: <Icon color="grey" name="world" />,
		text: 'Check Explorer',
		className: 'left',
		as: TeamscaleLink,
		to: LinkTemplate.showCheckExplorer()
	});

	options.push({
		key: 'snippet_analysis',
		icon: <Icon color="grey" name="window maximize outline" />,
		text: 'Snippet Analysis',
		className: 'left',
		as: TeamscaleLink,
		to: ETeamscalePerspective.SNIPPET_ANALYSIS.page
	});

	const permissionInfo = useUserPermissionInfo();
	if (permissionInfo.hasGlobalPermission(EGlobalPermission.CREATE_SUPPORT_REQUEST)) {
		options.push({
			key: 'create-support-request',
			icon: <Icon color="grey" name="file alternate outline" />,
			text: 'Create Support Request',
			className: 'left',
			onClick: () => new TeamscaleSupportDialog(client).show()
		});
	}
	options.push({
		key: 'shortcuts',
		icon: <Icon color="grey" name="lightbulb outline" />,
		text: 'Keyboard shortcuts',
		className: 'left',
		onClick: () => KeyboardShortcutRegistry.INSTANCE.showHelp()
	});
	options.push({
		key: 'api',
		icon: <Icon color="grey" name="code" />,
		text: 'API Reference',
		className: 'left',
		as: TeamscaleLink,
		to: LinkTemplate.showApiDocumentation()
	});
	options.push({
		key: 'freshdesk-support',
		icon: <Icon color="grey" name="mail outline" />,
		text: 'Get Support',
		className: 'left',
		as: 'a',
		href: 'https://cqse.freshdesk.com/support/tickets/new',
		target: '_blank',
		rel: 'noopener noreferrer'
	});
	options.push({
		key: 'about',
		icon: <Icon color="grey" name="info circle" />,
		text: 'About Teamscale',
		className: 'left',
		onClick: () =>
			UIUtils.showHelpPopup('About Teamscale', PerspectiveBaseTemplate.aboutDialog, {
				versionDetails: teamscaleInfo.versionDetails
			})
	});
	const declarationExists = useDeclarationOfAccessibilityExistence();
	if (declarationExists) {
		options.push({
			key: 'accessibility',
			icon: <Icon color="grey" name="universal access" />,
			text: DECLARATION_OF_ACCESSIBILITY_DISPLAY_NAME,
			className: 'left',
			as: 'a',
			href: DECLARATION_OF_ACCESSIBILITY_LINK,
			target: '_blank',
			rel: 'noopener noreferrer'
		});
	}
	return (
		<Dropdown
			closeOnChange
			direction="left"
			openOnFocus={false}
			trigger={<Icon color="grey" size="large" className="very" name="question circle" />}
			icon={false}
			id="help-button"
			className={styles.helpDropdown}
			options={options}
			value={false}
		/>
	);
}
