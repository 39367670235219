/** Utility methods for object handling. */
import { equals } from 'ts-closure-library/lib/array/array';

export class ObjectUtils {
	/**
	 * Converts an object to a map.
	 *
	 * @param object Array of objects from which some objects should be removed.
	 */
	public static toMap<V>(object: Record<string, V> | null): Map<string, V> {
		if (object === null) {
			return new Map<string, V>();
		}
		const keys = Object.keys(object);
		return new Map<string, V>(keys.map(key => [key, object[key]!]));
	}

	/** Applies the mapping to the values of the record. */
	public static mapValues<V, R>(obj: Record<string, V>, mapping: (value: V, key: string) => R): Record<string, R> {
		return Object.keys(obj).reduce(
			(result, key) => {
				result[key] = mapping(obj[key]!, key);
				return result;
			},
			{} as Record<string, R>
		);
	}

	/**
	 * Performs a deep equals comparison amongst two objects recursively comparing each field's value for equality.
	 *
	 * @param x First element to compare
	 * @param y Second element to compare
	 * @param nonStrict Defaults to false, only if set to true, the comparison is performed with ==
	 * @returns True if equal
	 */
	public static deepEqual(x: unknown, y: unknown, nonStrict = false): boolean {
		if (x && y && Array.isArray(x) && Array.isArray(y)) {
			return equals(x, y, (ele1, ele2) => ObjectUtils.deepEqual(ele1, ele2, nonStrict));
		} else if (x && y && typeof x === 'object' && typeof y === 'object') {
			return (
				Object.keys(x).length === Object.keys(y).length &&
				Object.keys(x).every(key =>
					ObjectUtils.deepEqual(
						(x as Record<string, unknown>)[key]!,
						(y as Record<string, unknown>)[key]!,
						nonStrict
					)
				)
			);
		} else {
			// Only if explicitly given as parameter, strict equality is softened to ==
			// eslint-disable-next-line eqeqeq
			return x === y || (nonStrict && x == y);
		}
	}

	/** Returns the keys of the given record. */
	public static numberKeys(record: Record<number, unknown>): number[] {
		return Object.keys(record).map(key => Number(key));
	}

	/** Returns a deep copy of an object */
	public static deepClone(object: object) {
		return JSON.parse(JSON.stringify(object));
	}
}
