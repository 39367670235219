// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy

/**
 * @fileoverview Templates in namespace ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import * as google from 'ts-closure-library/lib/google';
import * as goog_soy_data from 'soyutils/soyutils_usegoog';
import * as soy from 'soyutils/soyutils_usegoog';
import * as $soy$ts$commons$LinkTemplate from './../../commons/LinkTemplate.soy.generated.js';
import * as $soy$ts$commons$UIUtilsTemplate from './../../commons/UIUtilsTemplate.soy.generated.js';
import * as $soy$ts$perspectives$activity$commons$ActivityCommonsTemplate from './../activity/commons/ActivityCommonsTemplate.soy.generated.js';
import * as $soy$ts$perspectives$dashboard$widgets$parameters$WidgetParameterTemplate from './widgets/parameters/WidgetParameterTemplate.soy.generated.js';
import * as $soy$ts$perspectives$user$TeamscaleUserPerspectiveTemplate from './../user/TeamscaleUserPerspectiveTemplate.soy.generated.js';


/**
 * @param {!$dashboardUpload.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $dashboardUpload = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardUpload']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardUpload'](opt_data, $ijData);
  }
  /** @type {?} */
  const csrfToken = opt_data.csrfToken;
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<form id="dashboard-upload-form" enctype="multipart/form-data"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardUpload ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:23"' : '') + '>' + $soy$ts$commons$UIUtilsTemplate.csrfTokenInput(/** @type {?} */ (opt_data), $ijData) + $soy$ts$commons$UIUtilsTemplate.fileUpload$(soy.$$internalCallMarkerDoNotUse, $ijData, 'dashboardDescriptor', 'Select Files', undefined, undefined, '.tsdashboard, .tstemplate', true) + '</form>');
};
export { $dashboardUpload as dashboardUpload };
/**
 * @typedef {{
 *  csrfToken: ?,
 * }}
 */
$dashboardUpload.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $dashboardUpload.soyTemplateName = 'ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardUpload';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $noTemplatesAvailableMessage = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.noTemplatesAvailableMessage']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.noTemplatesAvailableMessage'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<h3' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.noTemplatesAvailableMessage ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:40"' : '') + '>No templates available</h3><p' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.noTemplatesAvailableMessage ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:41"' : '') + '>Use an existing dashboard\'s menu to save it as a template.</p>');
};
export { $noTemplatesAvailableMessage as noTemplatesAvailableMessage };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $noTemplatesAvailableMessage.soyTemplateName = 'ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.noTemplatesAvailableMessage';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $toggleIcons = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.toggleIcons']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.toggleIcons'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div id="toggle-icons" class="greyLBBorder hoverDim" style="position: absolute; top: -3px; right: 0; background: white; padding-left: 2px; padding-top: 2px;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.toggleIcons ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:49"' : '') + '><button id="exit-kiosk-button" class="ts-dropdown"' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'Exit fullscreen mode')) + '><i class="compress icon"></i></button></div>');
};
export { $toggleIcons as toggleIcons };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $toggleIcons.soyTemplateName = 'ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.toggleIcons';
}


/**
 * @param {!$dashboardNotFound.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $dashboardNotFound = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $dashboardNotFound$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.dashboardName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} dashboardName
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $dashboardNotFound$ = function($$areYouAnInternalCaller, $ijData, dashboardName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardNotFound']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardNotFound']({dashboardName: dashboardName}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui error message error-container"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardNotFound ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:66"' : '') + '><p><b>Dashboard <i>' + soy.$$escapeHtml(dashboardName) + '</i> was not found.</b></p><p>See the top bar for available dashboards.</p></div>');
};
export { $dashboardNotFound as dashboardNotFound };
export { $dashboardNotFound$ as dashboardNotFound$ };
/**
 * @typedef {{
 *  dashboardName: ?,
 * }}
 */
$dashboardNotFound.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $dashboardNotFound.soyTemplateName = 'ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardNotFound';
}


/**
 * @param {!$dashboardNotShared.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $dashboardNotShared = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $dashboardNotShared$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.dashboardName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} dashboardName
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $dashboardNotShared$ = function($$areYouAnInternalCaller, $ijData, dashboardName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardNotShared']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardNotShared']({dashboardName: dashboardName}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui info message"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardNotShared ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:79"' : '') + '><p><b>Sorry, you are not able to access Dashboard <i>' + soy.$$escapeHtml(dashboardName) + '</i>!</b></p><p>The owner of the requested dashboard or another user with sufficient rights(<i>READ_WRITE</i>) has to share it with you.</p><p><b>Instructions:</b><br>An authorized user has to open the dropdown menu for <i>' + soy.$$escapeHtml(dashboardName) + '</i> in the sidebar. After selecting the menu item <i>"Change permissions"</i> the authorized user can grant the required access rights(<i>READ</i>) to watch Dashboard <i>' + soy.$$escapeHtml(dashboardName) + '</i> to your account or a whole group of users.</p></div>');
};
export { $dashboardNotShared as dashboardNotShared };
export { $dashboardNotShared$ as dashboardNotShared$ };
/**
 * @typedef {{
 *  dashboardName: ?,
 * }}
 */
$dashboardNotShared.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $dashboardNotShared.soyTemplateName = 'ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardNotShared';
}
