import $ from 'jquery';
import * as TeamscaleChartTemplate from 'soy/commons/charts/TeamscaleChartsTemplate.soy.generated';
import * as asserts from 'ts-closure-library/lib/asserts/asserts';
import * as dom from 'ts-closure-library/lib/dom/dom';
import * as object from 'ts-closure-library/lib/object/object';
import { url } from 'ts/base/client/URLBuilder';
import * as soy from 'ts/base/soy/SoyRenderer';
import type { ExtendedFlotOptions } from 'ts/commons/charts/data_types/ExtendedFlotOptions';
import type { FlotSeriesObject } from 'ts/commons/charts/data_types/FlotSeriesObject';
import { DateUtils } from 'ts/commons/DateUtils';
import { SemanticUIUtils } from 'ts/commons/SemanticUIUtils';
import { tsdom } from 'ts/commons/tsdom';
import type { BaselineInfo } from 'typedefs/BaselineInfo';
import type { MetricThresholdTimePoint } from 'typedefs/MetricThresholdTimePoint';
import { FlotUtils } from './FlotUtils';
import type { MetricChartOptions } from './MetricChartOptions';
import { calculateMarginTimestamps, updateDeltaPerspectiveLink } from './TrendChartUtils';

/** Basic DOM scaffolding for the chart. */
type BasicChartScaffolding = { all: Element; main: HTMLElement; zoom: Element | null };

/** Formatter which formats a value on the plot to a readable string. */
export type ValueFormatter = (value: number) => string;

/** Base class for all trend charts rendered with Flot. */
export abstract class TrendChartBase {
	/** The default color used for charts. */
	protected static DEFAULT_COLOR = '#639CCE';

	/**
	 * The color of baselines, which are rendered as vertical lines in the background of the chart. Note that yellow and
	 * red are already in use if thresholds are displayed.
	 */
	protected static BASELINE_COLOR = '#B0C4DE';

	/** The width of the area on either side of a baseline in pixels, within which the tooltip for it is shown. */
	private static readonly BASELINE_HOVER_RADIUS = 3;

	/** The options passed to Flot. */
	protected flotOptions: ExtendedFlotOptions = {};

	/** The DOM scaffolding of the chart. */
	protected scaffold: BasicChartScaffolding;

	/** The link element of the action menu to navigate to the delta perspective. */
	protected deltaPerspectiveLink: HTMLAnchorElement | null = null;

	/**
	 * @param options The basic chart options.
	 * @param enableColorBlindMode Whether to enable the color-blind mode inside the trend chart.
	 */
	protected constructor(
		protected options: MetricChartOptions, // Protected formatters: ((value: number) => string)[] = []
		protected readonly enableColorBlindMode: boolean
	) {
		this.scaffold = this.createBasicScaffolding();
	}

	/** @returns A DOM element containing the rendered chart. */
	public render(): Element {
		this.flotOptions = this.createBasicFlotOptions();

		if (this.options.minTimestamp != null) {
			this.flotOptions.xaxis!.min = this.options.minTimestamp;
		}
		if (this.options.maxTimestamp != null) {
			this.flotOptions.xaxis!.max = this.options.maxTimestamp;
		}
		this.modifyRenderingData();
		const plotData = this.getData();
		(async () => {
			const plot = await FlotUtils.renderFlotChart(
				this.scaffold.main,
				plotData,
				asserts.assertObject(this.flotOptions)
			);
			this.installCurrentValue(plot);
			if (this.options.showZoom) {
				this.installZoom(plot, plotData);
			}
			if (this.options.showActionMenu) {
				this.installActions(plot);
			} else {
				tsdom.removeNode(this.getActionButton());
			}
			this.installBaselineHover(plot);
		})();
		return this.scaffold.all;
	}

	/**
	 * Returns the action button of this trend chart
	 *
	 * @returns The action button element
	 */
	public getActionButton(): Element | null {
		return dom.getElementByClass('ts-dropdown', dom.getAncestorByClass(this.scaffold.all, 'widget-frame'));
	}

	/** Creates the basic options for Flot. */
	private createBasicFlotOptions(): ExtendedFlotOptions {
		const flotOptions: ExtendedFlotOptions = {
			xaxis: { mode: 'time', timezone: 'browser', tickLength: 5 },
			yaxis: { tickFormatter: this.getFormatters()[0]!, show: !this.options.hideYAxes },
			grid: {
				hoverable: true,
				autoHighlight: false,
				borderWidth: 0,
				borderColor: '#CCC',
				markings: () => this.createBaselineMarkings()
			},
			series: {},
			legend: { show: true, position: 'nw', backgroundOpacity: 0.5, labelBoxBorderColor: '#FFF0' },
			crosshair: { mode: 'x' }
		};

		if (this.options.showZoom) {
			flotOptions.selection = { mode: 'x' };
		}

		return flotOptions;
	}

	/**
	 * Installs actions for the plot.
	 *
	 * @param plot The Flot plot.
	 */
	protected installActions(plot: jquery.flot.plot): void {
		const actionButton = this.findActionButton();
		if (actionButton === null) {
			return;
		}

		SemanticUIUtils.addMenuToDropdownElement(actionButton);
		this.addActionPopupItems(actionButton, plot);
		SemanticUIUtils.activateDropdown(actionButton);
		tsdom.setElementShown(actionButton, true);
	}

	private findActionButton(): Element | null {
		let actionButton = null;
		let dialogElement: Element | null = this.scaffold.all.parentElement;

		// Ugly hack but needs to work both in a dialog and in a widget
		while (actionButton === null && dialogElement !== null) {
			actionButton = dialogElement.querySelector('.ts-dropdown');
			dialogElement = dialogElement.parentElement;
		}

		return actionButton;
	}

	/** Attaches the menu items to the popup action menu of the given plot. */
	protected addActionPopupItems(dropdownElement: Element, plot: jquery.flot.plot): void {
		SemanticUIUtils.addDropdownAction(
			dropdownElement,
			'download-trend-data',
			'Download trend data as CSV',
			false,
			() => this.downloadTrendData()
		);
		this.deltaPerspectiveLink = SemanticUIUtils.addDropdownAction(
			dropdownElement,
			'delta-perspective-link',
			'View selected range in delta perspective',
			true
		) as HTMLAnchorElement;
		updateDeltaPerspectiveLink(
			plot,
			calculateMarginTimestamps(plot, this.flotOptions),
			this.deltaPerspectiveLink,
			this.options
		);
	}

	/** Downloads the trend data as CSV */
	protected downloadTrendData(): void {
		const requestUrl = url`api/projects/${this.options.project!}/metrics/history/csv`;
		requestUrl.append('uniform-path', this.options.path);
		requestUrl.append('metric-indexes', this.options.metricIndices.join(','));
		window.location.href = requestUrl.getURL();
	}

	/** Installs zoom support for the plot. */
	private installZoom(plot: jquery.flot.plot, plotData: FlotSeriesObject[]): void {
		const overviewOptions: jquery.flot.plotOptions = object.unsafeClone(
			this.flotOptions
		) as jquery.flot.plotOptions;
		overviewOptions.grid!.borderWidth = 1;
		overviewOptions.grid!.markings = () => this.createBaselineMarkings();
		overviewOptions.xaxis!.ticks = [];
		let yaxes: jquery.flot.axisOptions[] | undefined;
		if (overviewOptions.yaxis) {
			// What the hack??
			yaxes = [overviewOptions.yaxis];
		} else {
			yaxes = overviewOptions.yaxes;
		}
		for (const item of yaxes!) {
			item.ticks = [];
			item.autoscaleMargin = 0.1;
		}
		overviewOptions.series!.shadowSize = 0;
		overviewOptions.series!.lines = { show: true, lineWidth: 1 };
		overviewOptions.legend!.show = false;
		const zoomElement = this.scaffold.zoom! as HTMLElement;
		FlotUtils.renderFlotChart(zoomElement, plotData, overviewOptions).then(overview => {
			this.addListenerForZooming(plot, plotData, overview, zoomElement);
		});
	}

	private addListenerForZooming(
		plot: jquery.flot.plot,
		plotData: FlotSeriesObject[],
		overview: jquery.flot.plot,
		zoomElement: HTMLElement
	) {
		const plotSelected = async (
			event: JQuery.TriggeredEvent<HTMLElement, undefined, HTMLElement, HTMLElement>,
			ranges: { xaxis: { from: number | undefined; to: number | undefined } }
		) => {
			// Do the zooming
			// @ts-ignore
			this.flotOptions.xaxis.min = ranges.xaxis.from;
			// @ts-ignore
			this.flotOptions.xaxis.max = ranges.xaxis.to;
			plot = await FlotUtils.renderFlotChart(this.scaffold.main, plotData, this.flotOptions);

			// Don't fire event on the overview to prevent eternal loop
			// @ts-ignore
			overview.setSelection(ranges, true);
			updateDeltaPerspectiveLink(
				plot,
				calculateMarginTimestamps(plot, this.flotOptions),
				this.deltaPerspectiveLink,
				this.options
			);
		};
		$(this.scaffold.main).on('plotselected', (event, ranges) => void plotSelected(event, ranges));
		$(zoomElement).on('plotselected', (event, ranges) => {
			// @ts-ignore
			plot.setSelection(ranges);
		});
	}

	/**
	 * Installs support for displaying the current value.
	 *
	 * @param plot The Flot plot.
	 */
	private installCurrentValue(plot: jquery.flot.plot): void {
		let latestPosition: { x: number; y: number } = { x: 0, y: 0 };
		let updateLegendTimeout: number | null = null;
		const updateLegend = () => {
			updateLegendTimeout = null;
			const pos = latestPosition;
			const axes = plot.getAxes();
			// @ts-ignore
			if (pos.x < axes.xaxis.min || pos.x > axes.xaxis.max) {
				return;
			}
			this.insertDateLabel(pos.x);
			const dataset = plot.getData();
			const legends = $(this.scaffold.main).find('.legendLabel');
			for (let datasetIndex = 0, legendIndex = 0; datasetIndex < dataset.length; datasetIndex++) {
				const series = dataset[datasetIndex]!;
				if (series.label == null) {
					// No legend for series without label
					continue;
				}
				let formatter = this.getFormatters()[datasetIndex];
				if (formatter == null) {
					formatter = TrendChartBase.toStringFormatter;
				}

				// Find the nearest point, x-wise
				const value = this.findNearestValueToDisplay(dataset, datasetIndex, series, pos.x);
				const formattedValue = formatter(value);

				// Replace the legend
				legends.eq(legendIndex).html(series.label + ': ' + formattedValue);
				legendIndex++;
			}
		};
		$(this.scaffold.main).on('plothover', (event, pos) => {
			latestPosition = pos;
			if (updateLegendTimeout == null) {
				updateLegendTimeout = setTimeout(updateLegend, 50);
			}
		});
	}

	/** Find the nearest value point, x-wise. */
	protected findNearestValueToDisplay<T extends { data: number[][] }>(
		dataset: T[],
		currentSeriesIndex: number,
		currentSeries: T,
		xPos: number
	): number {
		let value = 0;
		for (const item of currentSeries.data) {
			if (item[0]! > xPos) {
				break;
			}
			value = item[1]!;
		}
		return value;
	}

	/**
	 * Updates the label for the date in the legend of the chart. If the label is not yet present, the label will be
	 * created.
	 *
	 * @param posx The x position (time) delivered by the hover event.
	 */
	protected insertDateLabel(posx: number): void {
		let dateLabel = this.scaffold.main.querySelector('.dateLabel');
		const legendTableBody = this.scaffold.main.querySelector('.legendLabel')?.parentElement?.parentElement as
			| HTMLTableElement
			| undefined;
		if (legendTableBody == null) {
			return;
		}
		if (dateLabel == null) {
			const dateRow = legendTableBody.insertRow(-1);
			const colorboxCell = dateRow.insertCell(0);
			colorboxCell.innerHTML = '<i class="small wait icon"></i>';
			colorboxCell.style.textAlign = 'center';
			colorboxCell.style.fontSize = 'medium';
			dateLabel = dateRow.insertCell(1);
			dateLabel.className = 'dateLabel';
		}
		dateLabel.innerHTML = 'Date: ' + DateUtils.formatTimestamp(posx);

		// Layout the background to match the content
		const backgroundElement = dom.getFirstElementChild(
			dom.getElementByClass('legend', this.scaffold.main)
		) as HTMLElement;
		backgroundElement.style.height = legendTableBody.clientHeight + 'px';
		backgroundElement.style.width = legendTableBody.clientWidth + 5 + 'px';
	}

	/**
	 * Installs support for displaying the name and date of a baseline on hover.
	 *
	 * @param plot The Flot plot.
	 */
	private installBaselineHover(plot: jquery.flot.plot): void {
		const axes = plot.getAxes();
		const xAxis = axes.xaxis;
		const plotWidth = plot.width();
		const minDistance = ((xAxis.max! - xAxis.min!) / plotWidth) * TrendChartBase.BASELINE_HOVER_RADIUS;
		$(this.scaffold.main).on('plothover', (event, pos) => {
			for (const item of this.options.baselines) {
				const baseline = item;
				if (Math.abs(baseline.timestamp - pos.x) < minDistance) {
					this.showBaselineInfo(baseline);
					return;
				}
			}
			this.showBaselineInfo(null);
		});
	}

	/**
	 * Shows a tooltip for the given baseline.
	 *
	 * @param baseline The baseline to show the tooltip for. If <code>null</code>, the tooltip is hidden.
	 */
	private showBaselineInfo(baseline: BaselineInfo | null): void {
		this.setUpBaselineTooltip();
		const tooltip = tsdom.getElementByClass('widget-baseline-tooltip', this.scaffold.all);
		if (baseline !== null) {
			dom.setTextContent(tooltip, DateUtils.formatTimestamp(baseline.timestamp) + ': Baseline ' + baseline.name);
		}
		tsdom.setElementShown(tooltip, baseline !== null);
	}

	/** Sets up the Baseline tooltip and renders the tooltip element if it's still not created. */
	private setUpBaselineTooltip(): void {
		let tooltip = dom.getElementByClass('widget-baseline-tooltip', this.scaffold.all);
		if (tooltip == null) {
			tooltip = soy.renderAsElement(TeamscaleChartTemplate.baselineTooltip, {});
			const flotElement = dom.getElementByClass('flot-text', this.scaffold.all)!;
			flotElement.appendChild(tooltip);
		}
	}

	/** Creates the basic DOM scaffold for the chart. */
	private createBasicScaffolding(): BasicChartScaffolding {
		const allElement = soy.renderAsElement(TeamscaleChartTemplate.basicScaffolding, {
			width: this.options.width,
			height: this.options.height,
			showZoom: this.options.showZoom
		});

		const result: BasicChartScaffolding = {
			all: allElement,
			main: dom.getFirstElementChild(allElement) as HTMLElement,
			zoom: null
		};

		if (this.options.showZoom) {
			result.zoom = dom.getNextElementSibling(result.main);
		}

		return result;
	}

	/**
	 * Returns the Flot markings for the baselines.
	 *
	 * @returns The markings for the baselines that are rendered as the chart background.
	 */
	private createBaselineMarkings(): Array<{ xaxis: { from: number; to: number }; color: string }> {
		const markings = [];
		for (const item of this.options.baselines) {
			const baseline = item;
			const timestamp = baseline.timestamp;
			markings.push({ xaxis: { from: timestamp, to: timestamp }, color: TrendChartBase.BASELINE_COLOR });
		}
		return markings;
	}

	/** Create the threshold course. */
	protected createThresholdCourse(
		thresholdPoints: MetricThresholdTimePoint[],
		color: string,
		label?: string
	): FlotSeriesObject {
		const thresholdData: Array<[number, number]> = [];
		for (const item of thresholdPoints) {
			let value = item.value;
			if (value == null) {
				value = 0;
			}
			thresholdData.push([item.timestamp, value]);
		}
		return {
			data: thresholdData,
			lines: { show: false, fill: false, steps: false },
			dashes: { show: true, lineWidth: 1 },
			color,
			label,
			shadowSize: 0,
			isThreshold: true
		};
	}

	/**
	 * Template method that provides the actual data to render.
	 *
	 * @returns The data that will be passed to Flot for rendering.
	 */
	protected abstract getData(): FlotSeriesObject[];

	/**
	 * Template method that provides the formatters for the data to render.
	 *
	 * @returns The formatters that will be passed to Flot for rendering.
	 */
	protected abstract getFormatters(): ValueFormatter[];

	/** Template method that allows subclasses to modify the rendering data, e.g. create markings or add additional axes. */
	protected modifyRenderingData(): void {
		// By default there is nothing to do
	}

	/**
	 * A flot formatter that simply uses value.toString().
	 *
	 * @param value The value to format.
	 * @returns The formatted value.
	 */
	private static toStringFormatter(value: unknown): string {
		// @ts-ignore
		return value.toString();
	}
}
