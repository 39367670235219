import type { JSX } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useUserPermissionInfo } from 'ts/base/hooks/PermissionInfoHook';
import { useTeamscaleInfo } from 'ts/base/hooks/TeamscaleInfoHook';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { GlobalWarning } from 'ts/base/perspective/topbar/warnings/GlobalWarning';
import { EGlobalPermission } from 'typedefs/EGlobalPermission';
import styles from './SystemHealthWarning.module.less';

/** Checks the health state after 30secs and emits a warning if the system state is critical. */
export function useSystemHealthWarning(): JSX.Element | null {
	const permissionInfo = useUserPermissionInfo();
	const teamscaleInfo = useTeamscaleInfo();

	const warning = useHealthStateWithoutPermissionCheck();

	if (
		permissionInfo.hasGlobalPermission(EGlobalPermission.ACCESS_ADMINISTRATIVE_SERVICES) &&
		teamscaleInfo.licenseInfo.license
	) {
		return warning;
	}
	return null;
}

/** The actual warning about the health state of Teamscale, which refreshes every 30 seconds. */
function useHealthStateWithoutPermissionCheck() {
	const client = useTeamscaleServiceClient();
	const {
		isSuccess,
		data: systemHealthState,
		isError,
		error
	} = useQuery(['health-check'], () => client.getSystemHealthState(), {
		refetchInterval: 30000,
		suspense: false
	});
	if (isSuccess && !systemHealthState.healthy) {
		return (
			<GlobalWarning
				// The status message may contain HTML
				message={
					<span
						className={styles.healthCheck}
						dangerouslySetInnerHTML={{ __html: systemHealthState.statusMessage }}
					/>
				}
				severity="error"
				id="system-health-warning"
				tooltip="Teamscale appears to be in an error state."
			/>
		);
	} else if (isError) {
		return <GlobalWarning message={(error as Error).message} severity="error" />;
	}
	return null;
}
