import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useMutation } from '@tanstack/react-query';
import { QUERY } from 'api/Query';
import { type JSX } from 'react';
import { FormProvider, useController, useForm, useFormContext } from 'react-hook-form';
import { Button, Form, Message, Search } from 'semantic-ui-react';
import * as LinkTemplate from 'soy/commons/LinkTemplate.soy.generated';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { useKeyboardShortcut } from 'ts/base/hooks/UseKeyboardShortcut';
import { useProjectIdOrEmpty } from 'ts/base/hooks/UseProject';
import { KeyboardShortcutRegistry } from 'ts/base/scaffolding/KeyboardShortcutRegistry';
import { FormProjectSelector } from 'ts/commons/forms/FormProjectSelector';
import { ModalActionButtons } from 'ts/commons/modal/ModalActionButtons';
import { NavigationUtils } from 'ts/commons/NavigationUtils';

/** Dialog for navigating directly to a specific issue number. */
export function NavigateToIssueDialog() {
	const [opened, { open, close }] = useDisclosure(false);
	useKeyboardShortcut(KeyboardShortcutRegistry.OPEN_ISSUE_PERSPECTIVE_SHORTCUT, 'Open issue dialog', open);
	return (
		<Modal opened={opened} onClose={close} title="Navigate to Issue">
			<NavigateToIssueDialogContent onClose={close} />
		</Modal>
	);
}

type IssueDialogValues = {
	issue: string;
	project: string;
};

function NavigateToIssueDialogContent({ onClose }: { onClose: () => void }) {
	const client = useTeamscaleServiceClient();
	const formContext = useForm<IssueDialogValues>({
		defaultValues: {
			issue: '',
			project: useProjectIdOrEmpty()
		}
	});
	const { mutate, error } = useMutation(
		(values: IssueDialogValues) => client.getIssue(values.project, values.issue),
		{
			onSuccess: (resolvedIssue, values) => {
				onClose();
				NavigationUtils.updateLocation(
					LinkTemplate.issue({
						project: values.project,
						id: resolvedIssue.issue.id.internalId
					})
				);
			}
		}
	);
	const errors = [...Object.values(formContext.formState.errors), ...(error ? [error as Error] : [])];

	return (
		<FormProvider {...formContext}>
			<Form
				error={errors.length > 0}
				onSubmit={formContext.handleSubmit(values => mutate(values))}
				id="navigate-to-issue-form"
			>
				<Message error content={errors.map(error => error.message).join('\n')} />
				<IssueSearchInput />
				<Form.Field inline error={formContext.formState.errors.project}>
					<label>Project</label>
					<FormProjectSelector<IssueDialogValues>
						name="project"
						rules={{ required: 'Project must not be empty.' }}
					/>
				</Form.Field>
				<input type="submit" hidden />
			</Form>
			<ModalActionButtons>
				<Button type="submit" primary content="Go to issue" form="navigate-to-issue-form" />
				<Button type="button" content="Cancel" onClick={onClose} />
			</ModalActionButtons>
		</FormProvider>
	);
}

/** Provides an issue search field with issue ID autocompletion and integrates it with react-hook-form. */
function IssueSearchInput(): JSX.Element {
	const activeProjectId = useFormContext<IssueDialogValues>().getValues('project');
	const controller = useController<IssueDialogValues>({
		name: 'issue',
		rules: { required: 'Issue ID must not be empty.' }
	});
	const projectSelected = activeProjectId !== '';
	const queryResult = QUERY.autocompleteIssueId(activeProjectId, { search: controller.field.value }).useQuery({
		enabled: projectSelected
	});

	const { data: issueDetails } = QUERY.getIssuesDetails(activeProjectId, { 'issue-ids': queryResult.data }).useQuery({
		enabled: queryResult.data != null
	});

	return (
		<Form.Field error={Boolean(controller.fieldState.error)}>
			<label>Issue ID</label>
			<Search
				showNoResults={projectSelected}
				loading={queryResult.isFetching}
				id="navigate-to-issue-dialog-input"
				icon="search"
				autoComplete="off"
				placeholder="Issue ID"
				input={{ fluid: true, autoFocus: true, 'data-autofocus': true }}
				className="prompt"
				onResultSelect={(result, data) => controller.field.onChange(data.result.title)}
				onSearchChange={(e, data) => controller.field.onChange(data.value!)}
				results={queryResult.data?.map((issue, index) => ({
					title: issue,
					description: issueDetails?.[index]?.issue.subject
				}))}
				value={controller.field.value}
			/>
		</Form.Field>
	);
}
