import type { ColumnDef, RowData, SortingState, Table, TableOptions } from '@tanstack/react-table';
import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { type JSX, useMemo } from 'react';
import type { TableBaseProps } from 'ts/base/table/Table';
import { Table as TableComponent } from 'ts/base/table/Table';
import { ESortOrder } from 'typedefs/ESortOrder';

export type SortableTableOptions<T extends RowData, TValue = unknown> = {
	columnDefinitions: Array<ColumnDef<T, TValue>>;
	data: T[];
	initialSortOptions?: SortOptions;
	tableOptions?: Partial<TableOptions<T>>;
};

/** Props for SortableTable. */
export type SortableTableProps<T extends RowData, TValue = unknown> = Omit<TableBaseProps<T>, 'sortable'> &
	SortableTableOptions<T, TValue>;

/** Props for the sortOrder state. */
export type SortOptions = {
	sortByField: string;
	sortOrder: ESortOrder;
};

/** Converts our own sort options into the format React Table expects to receive as initialState. */
export function convertToReactTableSortBy(id: string | undefined, sortOrder: ESortOrder | undefined): SortingState {
	if (id === undefined || sortOrder === undefined) {
		return [];
	}
	const desc = sortOrder === ESortOrder.DESCENDING;
	return [{ id, desc }];
}

/**
 * Provides a table instance that is configured to sort its content. In contrast to the SortableTable component the hook
 * allows you to customize the returned table as needed before passing it to the Table component for rendering.
 */
export function useSortableTable<T extends RowData, TValue = unknown>({
	columnDefinitions,
	data,
	initialSortOptions,
	tableOptions = {}
}: SortableTableOptions<T, TValue>): Table<T> {
	const sorting = useMemo(
		() => convertToReactTableSortBy(initialSortOptions?.sortByField, initialSortOptions?.sortOrder),
		[initialSortOptions?.sortByField, initialSortOptions?.sortOrder]
	);

	return useReactTable({
		data,
		columns: columnDefinitions,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		enableSorting: true,
		enableSortingRemoval: true,
		...tableOptions,
		initialState: {
			sorting,
			...tableOptions.initialState
		}
	});
}

/** A table that is automatically sortable. All data must be available on the client side. */
export function SortableTable<T extends RowData, TValue = unknown>({
	columnDefinitions,
	data,
	initialSortOptions,
	tableOptions,
	...tableProps
}: SortableTableProps<T, TValue>): JSX.Element {
	const table = useSortableTable({
		initialSortOptions,
		columnDefinitions,
		data,
		tableOptions
	});
	return <TableComponent sortable table={table} {...tableProps} />;
}
