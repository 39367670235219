import { QUERY } from 'api/Query';
import { ServiceClientImplementation } from 'api/ServiceClientImplementation';
import type { Dispatch, SetStateAction } from 'react';
import { useState, type JSX } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import * as LinkTemplate from 'soy/commons/LinkTemplate.soy.generated';
import * as TeamscaleDashboardPerspectiveTemplate from 'soy/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy.generated';
import * as array from 'ts-closure-library/lib/array/array';
import * as asserts from 'ts-closure-library/lib/asserts/asserts';
import type { BrowserEvent } from 'ts-closure-library/lib/events/browserevent';
import * as events from 'ts-closure-library/lib/events/eventhandler';
import { ButtonSet, DefaultButtons, Dialog, EventType as Dialog_EventType } from 'ts-closure-library/lib/ui/dialog';
import type { TeamscaleServiceClient } from 'ts/base/client/TeamscaleServiceClient';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { useCommit } from 'ts/base/hooks/UseCommit';
import { useKeyboardShortcut } from 'ts/base/hooks/UseKeyboardShortcut';
import { useProjectIfExists } from 'ts/base/hooks/UseProject';
import { ReactUtils } from 'ts/base/ReactUtils';
import { TeamscaleLink } from 'ts/base/routing/TeamscaleLink';
import * as soy from 'ts/base/soy/SoyRenderer';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import type { DropdownItemOptions } from 'ts/commons/InMenuSearchableDropdown';
import { convertToDropdownItemProps } from 'ts/commons/InMenuSearchableDropdown';
import { NavigationUtils } from 'ts/commons/NavigationUtils';
import { StringUtils } from 'ts/commons/StringUtils';
import { tsdom } from 'ts/commons/tsdom';
import { UIUtils } from 'ts/commons/UIUtils';
import { CreateFromTemplateModal } from 'ts/perspectives/dashboard/templates/CreateFromTemplateModal';

/**
 * The dropdown which is shown in the dashboard perspective that allows adding new/importing dashboards and managing
 * existing templates.
 */
export function DashboardAddDropdown(): JSX.Element {
	const [isCreateFromTemplateModalVisible, setIsCreateFromTemplateModalVisible] = useState(false);
	const itemOptions: DropdownItemOptions[] = [];
	const projectId = useProjectIfExists()?.primaryId;
	const commitDescriptor = useCommit();
	const client = useTeamscaleServiceClient();
	const onCreateFromTemplate = () => void showImportTemplateDialog(client, setIsCreateFromTemplateModalVisible);
	const onImportDashboards = () => showImportDialog();
	const addLink = LinkTemplate.newDashboard({
		commit: commitDescriptor,
		project: projectId
	});
	useKeyboardShortcut('N', 'New Dashboard', () => NavigationUtils.updateLocation(addLink));
	itemOptions.push({
		description: 'N',
		text: 'Add a new Dashboard',
		icon: <Icon name="add" color="grey" />,
		id: 'add-a-new-dashboard',
		as: TeamscaleLink,
		to: addLink
	});
	useKeyboardShortcut('T', 'Create from template', onCreateFromTemplate);
	itemOptions.push({
		description: 'T',
		text: 'Create from template',
		icon: <Icon name="file outline" color="grey" />,
		id: 'create-with-template',
		onClick: onCreateFromTemplate
	});
	itemOptions.push({
		kind: 'divider',
		key: 'divider'
	});

	itemOptions.push({
		text: 'Import dashboards/templates',
		icon: <Icon name="upload" color="grey" />,
		id: 'import-dashboard',
		onClick: onImportDashboards
	});
	itemOptions.push({
		text: 'Manage templates',
		icon: <Icon name="cog" color="grey" />,
		id: 'edit-templates',
		as: TeamscaleLink,
		to: LinkTemplate.dashboardTemplates()
	});
	return (
		<>
			<Dropdown
				button
				icon={false}
				value=""
				id="add-dashboard-dropdown"
				className="icon"
				trigger={<Icon name="add" />}
				options={itemOptions.map(convertToDropdownItemProps)}
			/>
			{isCreateFromTemplateModalVisible ? (
				<CreateFromTemplateModal setIsModalVisible={setIsCreateFromTemplateModalVisible} />
			) : null}
		</>
	);
}

/** Show a dialog for importing a dashboard from a template */
async function showImportTemplateDialog(
	client: TeamscaleServiceClient,
	setIsModalVisible: Dispatch<SetStateAction<boolean>>
): Promise<void> {
	const dialog = new Dialog();
	dialog.setTitle('Create from Template');
	const buttonSet = ButtonSet.createOkCancel();
	dialog.setButtonSet(buttonSet);
	dialog.setDisposeOnHide(true);
	const templates = await client.listDashboardTemplates();
	if (array.isEmpty(templates)) {
		dialog.setButtonSet(ButtonSet.createOk());
		const errorMessage = soy.renderAsElement(TeamscaleDashboardPerspectiveTemplate.noTemplatesAvailableMessage);
		dialog.getContentElement()!.appendChild(errorMessage);
		dialog.setVisible(true);
	} else {
		setIsModalVisible(true);
	}
}

/** Show a dialog for importing a dashboard. */
function showImportDialog(): void {
	const dialog = new Dialog();
	dialog.setTitle('Import Dashboards/Templates');
	const buttons = ButtonSet.createOkCancel();
	dialog.setButtonSet(buttons);
	const dialogContent = soy.renderAsElement(TeamscaleDashboardPerspectiveTemplate.dashboardUpload, {
		csrfToken: ServiceClientImplementation.getCsrfToken()
	});
	dialog.getContentElement()!.appendChild(dialogContent);
	UIUtils.setupFileUploads(dialogContent);
	const fileInput = tsdom.getElementByClass('fileinput', dialogContent) as HTMLInputElement;
	const submitButton = asserts.assertElement(buttons.getButton(buttons.getDefault()!));
	UIUtils.hookDisableUploadSubmissionListener(submitButton, fileInput);
	dialog.setDisposeOnHide(true);
	dialog.setVisible(true);
	events.listen(dialog, Dialog_EventType.SELECT, e => uploadDashboardsAndTemplates(e));
}

/** Callback for dashboard upload dialog. */
async function uploadDashboardsAndTemplates(e: BrowserEvent): Promise<void> {
	if (e.key === DefaultButtons.OK.key) {
		const dashboardFiles = tsdom.getHtmlInputElementById('fileInput').files!;
		const filesByExtension = ArrayUtils.groupBy(dashboardFiles, file => StringUtils.getLastPart(file.name, '.'));
		const dashboards = filesByExtension.get('tsdashboard');
		const dashboardTemplates = filesByExtension.get('tstemplate');
		let redirectUrl = LinkTemplate.dashboardTemplates();
		if (dashboards !== undefined) {
			const dashboardIds = await QUERY.importDashboard({ dashboard: dashboards }).fetch();
			redirectUrl = LinkTemplate.dashboard({ name: dashboardIds[0]! });
		}
		if (dashboardTemplates !== undefined) {
			await QUERY.importDashboardTemplate({ 'dashboard-template': dashboardTemplates }).fetch();
		}
		await ReactUtils.queryClient.invalidateQueries();
		NavigationUtils.updateLocation(redirectUrl);
	}
}
