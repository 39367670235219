import { usePerspectiveContext } from 'ts/base/hooks/PerspectiveContextHook';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import type { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';

/** Returns the sub-views of a perspective that the user has access to. */
export function useAccessibleViews(perspectiveDescriptor: PerspectiveViewDescriptorBase): ViewDescriptor[] {
	const hash = useNavigationHash();
	const context = usePerspectiveContext();
	return perspectiveDescriptor.getAccessibleViewDescriptors(context, hash);
}

/** Returns the sub-views that should be shown in the sidebar. */
export function filterVisibleInSidebar(accessibleViewDescriptors: ViewDescriptor[]) {
	return accessibleViewDescriptors.filter(viewDescriptor => viewDescriptor.visibleInSidebar);
}
