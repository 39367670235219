/** Generated from EFeatureToggle.java. Do not modify this file! */


export type EFeatureToggleEntry = 'NONE' | 'CODE_USAGE_WIDGET' | 'BMW_COVERABLE_VOLUME_FILTER' | 'ENABLE_DEMO_LANDING_PAGE' | 'DISABLE_MEMORY_CHECK' | 'DISABLE_COMMIT_CHART_WIDGET' | 'USE_COVERABLE_LINES_FROM_COVERAGE_REPORTS' | 'SKIP_CLASS_ATTRIBUTES_FOR_COVERAGE' | 'ENABLE_CONQAT_INFILE_RATING' | 'ENABLE_MONITORING_UPLOAD' | 'ENABLE_RAEX' | 'ENABLE_DEV_MODE' | 'DISABLE_UI_DEV_SERVER_MODE' | 'TEAMSCALE_IO_MODE' | 'DISABLE_SIMULINK_DEPENDENCY_EXTRACTION' | 'INSTANCE_LABEL_OPTION_VISIBLE' | 'DISABLE_DEFAULT_ANALYSIS_PROFILES_CREATION' | 'NO_WARN_ON_UNSAFE_ADMIN_USER' | 'ENABLE_NORDEX_MODEL_ADVISOR_REPORT_ITEM' | 'ENABLE_LOG_PERFORMANCE_DETAILS' | 'ENABLE_LOG_SCHEDULER_PERFORMANCE_DETAILS' | 'ENABLE_GIT_AUTO_GC' | 'ENABLE_APPLE_SPECIFIC_FEATURES' | 'ENABLE_TEST_MODE' | 'CLEANUP_ORPHANED_DATABASE_ENTIRES' | 'DISABLE_PERSISTENCE_MAINTENANCE_TRIGGER' | 'GERRIT_PATCHSET_MAX_FILES_CHANGED_FILTER_COUNT' | 'DISABLE_DEAD_CONNECTOR_CHECK' | 'ENABLE_PREEMPTIVE_GIT_BASIC_AUTH' | 'DISABLE_TEST_CASE_EXTRACTION' | 'ENABLE_LDAP_GROUP_AUTO_IMPORT' | 'ENABLE_SCHEDULER_STORE_OVERLAP_DEBUG' | 'DISABLE_WORKER_LOG_TRUNCATION' | 'FORCE_FULL_EXTERNAL_UPLOAD_DELTA' | 'DISABLE_VERSION_DISPLAY' | 'DISABLE_FILE_SYSTEM_CONNECTOR' | 'SHOW_HIDDEN_LANGUAGES' | 'CPP_DISABLE_REPARSE_ON_HEADER_CHANGE' | 'PRECOMMIT3_DISABLED' | 'DISABLE_AUTO_REPAIR_INCONSISTENT_COMMITS_ON_BACKUP_IMPORT' | 'DISABLE_COVERAGE_MERGING' | 'PREFIX_CUSTOM_FINDING_DESCRIPTION' | 'ENABLE_INDEPENDENT_SOLAR_LINT_ANALYSIS' | 'DISABLE_BULLSEYE_CONTROL_FLOW_PARSING' | 'DISABLE_GRAVATAR' | 'AUDIT_FEATURES' | 'SVN_THOROUGH_AUTH_CHECK_SUPPORT';

export class EFeatureToggle {
	static readonly NONE = new EFeatureToggle(0, 'NONE', "");
	static readonly CODE_USAGE_WIDGET = new EFeatureToggle(1, 'CODE_USAGE_WIDGET', "com.teamscale.feature-toggle.code-usage-widget");
	static readonly BMW_COVERABLE_VOLUME_FILTER = new EFeatureToggle(2, 'BMW_COVERABLE_VOLUME_FILTER', "bmw.teamscale.feature-toggle.coverable-volume-filter");
	static readonly ENABLE_DEMO_LANDING_PAGE = new EFeatureToggle(3, 'ENABLE_DEMO_LANDING_PAGE', "com.teamscale.landing.enable");
	static readonly DISABLE_MEMORY_CHECK = new EFeatureToggle(4, 'DISABLE_MEMORY_CHECK', "com.teamscale.disable-memory-check");
	static readonly DISABLE_COMMIT_CHART_WIDGET = new EFeatureToggle(5, 'DISABLE_COMMIT_CHART_WIDGET', "com.teamscale.feature-toggle.disable-commit-chart-widget");
	static readonly USE_COVERABLE_LINES_FROM_COVERAGE_REPORTS = new EFeatureToggle(6, 'USE_COVERABLE_LINES_FROM_COVERAGE_REPORTS', "com.teamscale.feature-toggle.use-coverable-lines-from-coverage-reports");
	static readonly SKIP_CLASS_ATTRIBUTES_FOR_COVERAGE = new EFeatureToggle(7, 'SKIP_CLASS_ATTRIBUTES_FOR_COVERAGE', "com.teamscale.feature-toggle.skip-class-attributes-for-coverage");
	static readonly ENABLE_CONQAT_INFILE_RATING = new EFeatureToggle(8, 'ENABLE_CONQAT_INFILE_RATING', "com.teamscale.feature-toggle.conqat-infile-rating");
	static readonly ENABLE_MONITORING_UPLOAD = new EFeatureToggle(9, 'ENABLE_MONITORING_UPLOAD', "com.teamscale.enable-monitoring-uploads");
	static readonly ENABLE_RAEX = new EFeatureToggle(10, 'ENABLE_RAEX', "com.teamscale.feature-toggle.raex");
	static readonly ENABLE_DEV_MODE = new EFeatureToggle(11, 'ENABLE_DEV_MODE', "com.teamscale.dev-mode");
	static readonly DISABLE_UI_DEV_SERVER_MODE = new EFeatureToggle(12, 'DISABLE_UI_DEV_SERVER_MODE', "com.teamscale.ui-dev-server-mode");
	static readonly TEAMSCALE_IO_MODE = new EFeatureToggle(13, 'TEAMSCALE_IO_MODE', "teamscale.io-mode");
	static readonly DISABLE_SIMULINK_DEPENDENCY_EXTRACTION = new EFeatureToggle(14, 'DISABLE_SIMULINK_DEPENDENCY_EXTRACTION', "com.teamscale.simulink.dependency-extracion.disable");
	static readonly INSTANCE_LABEL_OPTION_VISIBLE = new EFeatureToggle(15, 'INSTANCE_LABEL_OPTION_VISIBLE', "com.teamscale.instance-label-option.visible");
	static readonly DISABLE_DEFAULT_ANALYSIS_PROFILES_CREATION = new EFeatureToggle(16, 'DISABLE_DEFAULT_ANALYSIS_PROFILES_CREATION', "com.teamscale.feature-toggle.disable-default-analysis-profiles-creation");
	static readonly NO_WARN_ON_UNSAFE_ADMIN_USER = new EFeatureToggle(17, 'NO_WARN_ON_UNSAFE_ADMIN_USER', "com.teamscale.feature-toggle.no-warn-on-unsafe-admin-user");
	static readonly ENABLE_NORDEX_MODEL_ADVISOR_REPORT_ITEM = new EFeatureToggle(18, 'ENABLE_NORDEX_MODEL_ADVISOR_REPORT_ITEM', "com.teamscale.feature-toggle.enable-nordex-model-advisor-report-item");
	static readonly ENABLE_LOG_PERFORMANCE_DETAILS = new EFeatureToggle(19, 'ENABLE_LOG_PERFORMANCE_DETAILS', "com.teamscale.log_performance_details");
	static readonly ENABLE_LOG_SCHEDULER_PERFORMANCE_DETAILS = new EFeatureToggle(20, 'ENABLE_LOG_SCHEDULER_PERFORMANCE_DETAILS', "com.teamscale.log_scheduler_performance_details");
	static readonly ENABLE_GIT_AUTO_GC = new EFeatureToggle(21, 'ENABLE_GIT_AUTO_GC', "com.teamscale.experimental.git-auto-gc");
	static readonly ENABLE_APPLE_SPECIFIC_FEATURES = new EFeatureToggle(22, 'ENABLE_APPLE_SPECIFIC_FEATURES', "com.teamscale.customers.enable-apple-features");
	static readonly ENABLE_TEST_MODE = new EFeatureToggle(23, 'ENABLE_TEST_MODE', "com.teamscale.test-mode");
	static readonly CLEANUP_ORPHANED_DATABASE_ENTIRES = new EFeatureToggle(24, 'CLEANUP_ORPHANED_DATABASE_ENTIRES', "com.teamscale.cleanup-database");
	static readonly DISABLE_PERSISTENCE_MAINTENANCE_TRIGGER = new EFeatureToggle(25, 'DISABLE_PERSISTENCE_MAINTENANCE_TRIGGER', "com.teamscale.disable-persistence-maintenance");
	static readonly GERRIT_PATCHSET_MAX_FILES_CHANGED_FILTER_COUNT = new EFeatureToggle(26, 'GERRIT_PATCHSET_MAX_FILES_CHANGED_FILTER_COUNT', "com.teamscale.gerrit.patchset-max-files");
	static readonly DISABLE_DEAD_CONNECTOR_CHECK = new EFeatureToggle(27, 'DISABLE_DEAD_CONNECTOR_CHECK', "com.teamscale.disable-dead-connector-check");
	static readonly ENABLE_PREEMPTIVE_GIT_BASIC_AUTH = new EFeatureToggle(28, 'ENABLE_PREEMPTIVE_GIT_BASIC_AUTH', "com.teamscale.enable-preemptive-git-basic-auth");
	static readonly DISABLE_TEST_CASE_EXTRACTION = new EFeatureToggle(29, 'DISABLE_TEST_CASE_EXTRACTION', "com.teamscale.disable-test-case-extraction");
	static readonly ENABLE_LDAP_GROUP_AUTO_IMPORT = new EFeatureToggle(30, 'ENABLE_LDAP_GROUP_AUTO_IMPORT', "com.teamscale.enable-ldap-group-auto-import");
	static readonly ENABLE_SCHEDULER_STORE_OVERLAP_DEBUG = new EFeatureToggle(31, 'ENABLE_SCHEDULER_STORE_OVERLAP_DEBUG', "com.teamscale.scheduler-store-overlap-debug");
	static readonly DISABLE_WORKER_LOG_TRUNCATION = new EFeatureToggle(32, 'DISABLE_WORKER_LOG_TRUNCATION', "com.teamscale.disable-worker-log-truncation");
	static readonly FORCE_FULL_EXTERNAL_UPLOAD_DELTA = new EFeatureToggle(33, 'FORCE_FULL_EXTERNAL_UPLOAD_DELTA', "com.teamscale.external-uploads.enable-full-delta");
	static readonly DISABLE_VERSION_DISPLAY = new EFeatureToggle(34, 'DISABLE_VERSION_DISPLAY', "com.teamscale.feature-toggle.disable-version-display");
	static readonly DISABLE_FILE_SYSTEM_CONNECTOR = new EFeatureToggle(35, 'DISABLE_FILE_SYSTEM_CONNECTOR', "com.teamscale.feature-toggle.disable-file-system-connector");
	static readonly SHOW_HIDDEN_LANGUAGES = new EFeatureToggle(36, 'SHOW_HIDDEN_LANGUAGES', "com.teamscale.feature-toggle.show-hidden-languages");
	static readonly CPP_DISABLE_REPARSE_ON_HEADER_CHANGE = new EFeatureToggle(37, 'CPP_DISABLE_REPARSE_ON_HEADER_CHANGE', "com.teamscale.experimental.cpp-disable-reparse-on-header-change");
	static readonly PRECOMMIT3_DISABLED = new EFeatureToggle(38, 'PRECOMMIT3_DISABLED', "com.teamscale.diable_automatic_precommit_analysis");
	static readonly DISABLE_AUTO_REPAIR_INCONSISTENT_COMMITS_ON_BACKUP_IMPORT = new EFeatureToggle(39, 'DISABLE_AUTO_REPAIR_INCONSISTENT_COMMITS_ON_BACKUP_IMPORT', "com.teamscale.index.backup.disable-auto-repair-backup-on-import");
	static readonly DISABLE_COVERAGE_MERGING = new EFeatureToggle(40, 'DISABLE_COVERAGE_MERGING', "com.teamscale.disable-coverage-merging");
	static readonly PREFIX_CUSTOM_FINDING_DESCRIPTION = new EFeatureToggle(41, 'PREFIX_CUSTOM_FINDING_DESCRIPTION', "com.teamscale.feature-toggle.prefix-custom-finding-description");
	static readonly ENABLE_INDEPENDENT_SOLAR_LINT_ANALYSIS = new EFeatureToggle(42, 'ENABLE_INDEPENDENT_SOLAR_LINT_ANALYSIS', "com.teamscale.enable-independent-sonar-lint-analysis");
	static readonly DISABLE_BULLSEYE_CONTROL_FLOW_PARSING = new EFeatureToggle(43, 'DISABLE_BULLSEYE_CONTROL_FLOW_PARSING', "com.teamscale.disable-bullseye-control-flow-parsing");
	static readonly DISABLE_GRAVATAR = new EFeatureToggle(44, 'DISABLE_GRAVATAR', "com.teamscale.disable-gravatar");
	static readonly AUDIT_FEATURES = new EFeatureToggle(45, 'AUDIT_FEATURES', "com.teamscale.audit-features");
	static readonly SVN_THOROUGH_AUTH_CHECK_SUPPORT = new EFeatureToggle(46, 'SVN_THOROUGH_AUTH_CHECK_SUPPORT', "com.teamscale.enable-svn-thorough-auth-check-support");
	static readonly values = [
		EFeatureToggle.NONE,
		EFeatureToggle.CODE_USAGE_WIDGET,
		EFeatureToggle.BMW_COVERABLE_VOLUME_FILTER,
		EFeatureToggle.ENABLE_DEMO_LANDING_PAGE,
		EFeatureToggle.DISABLE_MEMORY_CHECK,
		EFeatureToggle.DISABLE_COMMIT_CHART_WIDGET,
		EFeatureToggle.USE_COVERABLE_LINES_FROM_COVERAGE_REPORTS,
		EFeatureToggle.SKIP_CLASS_ATTRIBUTES_FOR_COVERAGE,
		EFeatureToggle.ENABLE_CONQAT_INFILE_RATING,
		EFeatureToggle.ENABLE_MONITORING_UPLOAD,
		EFeatureToggle.ENABLE_RAEX,
		EFeatureToggle.ENABLE_DEV_MODE,
		EFeatureToggle.DISABLE_UI_DEV_SERVER_MODE,
		EFeatureToggle.TEAMSCALE_IO_MODE,
		EFeatureToggle.DISABLE_SIMULINK_DEPENDENCY_EXTRACTION,
		EFeatureToggle.INSTANCE_LABEL_OPTION_VISIBLE,
		EFeatureToggle.DISABLE_DEFAULT_ANALYSIS_PROFILES_CREATION,
		EFeatureToggle.NO_WARN_ON_UNSAFE_ADMIN_USER,
		EFeatureToggle.ENABLE_NORDEX_MODEL_ADVISOR_REPORT_ITEM,
		EFeatureToggle.ENABLE_LOG_PERFORMANCE_DETAILS,
		EFeatureToggle.ENABLE_LOG_SCHEDULER_PERFORMANCE_DETAILS,
		EFeatureToggle.ENABLE_GIT_AUTO_GC,
		EFeatureToggle.ENABLE_APPLE_SPECIFIC_FEATURES,
		EFeatureToggle.ENABLE_TEST_MODE,
		EFeatureToggle.CLEANUP_ORPHANED_DATABASE_ENTIRES,
		EFeatureToggle.DISABLE_PERSISTENCE_MAINTENANCE_TRIGGER,
		EFeatureToggle.GERRIT_PATCHSET_MAX_FILES_CHANGED_FILTER_COUNT,
		EFeatureToggle.DISABLE_DEAD_CONNECTOR_CHECK,
		EFeatureToggle.ENABLE_PREEMPTIVE_GIT_BASIC_AUTH,
		EFeatureToggle.DISABLE_TEST_CASE_EXTRACTION,
		EFeatureToggle.ENABLE_LDAP_GROUP_AUTO_IMPORT,
		EFeatureToggle.ENABLE_SCHEDULER_STORE_OVERLAP_DEBUG,
		EFeatureToggle.DISABLE_WORKER_LOG_TRUNCATION,
		EFeatureToggle.FORCE_FULL_EXTERNAL_UPLOAD_DELTA,
		EFeatureToggle.DISABLE_VERSION_DISPLAY,
		EFeatureToggle.DISABLE_FILE_SYSTEM_CONNECTOR,
		EFeatureToggle.SHOW_HIDDEN_LANGUAGES,
		EFeatureToggle.CPP_DISABLE_REPARSE_ON_HEADER_CHANGE,
		EFeatureToggle.PRECOMMIT3_DISABLED,
		EFeatureToggle.DISABLE_AUTO_REPAIR_INCONSISTENT_COMMITS_ON_BACKUP_IMPORT,
		EFeatureToggle.DISABLE_COVERAGE_MERGING,
		EFeatureToggle.PREFIX_CUSTOM_FINDING_DESCRIPTION,
		EFeatureToggle.ENABLE_INDEPENDENT_SOLAR_LINT_ANALYSIS,
		EFeatureToggle.DISABLE_BULLSEYE_CONTROL_FLOW_PARSING,
		EFeatureToggle.DISABLE_GRAVATAR,
		EFeatureToggle.AUDIT_FEATURES,
		EFeatureToggle.SVN_THOROUGH_AUTH_CHECK_SUPPORT
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: EFeatureToggleEntry,
		public readonly id: string
	){
	}

	toString() {
		return this.name;
	}
}

