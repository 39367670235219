import type { JSX } from 'react';
import { QUERY } from 'api/Query';
import * as LinkTemplate from 'soy/commons/LinkTemplate.soy.generated';
import { GlobalWarning } from 'ts/base/perspective/topbar/warnings/GlobalWarning';
import { DEFAULT_STALE_TIME } from 'ts/base/perspective/topbar/warnings/GlobalWarnings';
import { TeamscaleLink } from 'ts/base/routing/TeamscaleLink';

/** Informs the user that shadow mod is enabled. */
export function useShadowModeWarning(): JSX.Element | null {
	const { data: shadowMode } = QUERY.showShadowModeWarning().useQuery({
		staleTime: DEFAULT_STALE_TIME,
		refetchOnReconnect: 'always'
	});

	if (!shadowMode) {
		return null;
	}
	return (
		<GlobalWarning
			message={
				<>
					Shadow Mode (
					<TeamscaleLink
						to={LinkTemplate.uniformLink({
							viewName: 'options',
							perspective: 'admin'
						})}
					>
						change
					</TeamscaleLink>
					)
				</>
			}
			severity="warning"
			tooltip="Shadow mode disables all external notifications, including emails, pull request annotations, and SAP downloads."
		/>
	);
}
