import type { UseQueryResult } from '@tanstack/react-query';
import { QUERY } from 'api/Query';
import { useState } from 'react';
import type { DropdownItemProps } from 'semantic-ui-react';
import { Header, Message } from 'semantic-ui-react';
import type { Callback } from 'ts/base/Callback';
import { DefinedPointInTimeDropdown } from 'ts/commons/time/components/TimePickerUtils';
import type { GitTag } from 'typedefs/GitTag';

type GitTagDropdownProps = {
	project: string | null;
	repository: string | null;
	selectedGitTag: string | undefined;
	setSelectedGitTag: Callback<string>;
};

/** Dropdown for selecting a git tag. */
export function GitTagDropdown({ project, repository, selectedGitTag, setSelectedGitTag }: GitTagDropdownProps) {
	const [tagsFilter, setTagsFilter] = useState('');
	const gitTags = useAvailableGitTags(project, repository);
	const filteredTags = getTopNMatches(gitTags.data ?? [], tagsFilter, 10);
	return (
		<>
			<Header size="tiny" content="Git Tag:" />
			{!gitTags.isFetching && gitTags.isSuccess && gitTags.data.length === 0 ? (
				<Message>No Git tags available in this repository.</Message>
			) : (
				<DefinedPointInTimeDropdown<GitTag>
					testId="git-tag-select"
					placeholder={gitTags.isFetching ? 'Loading Git tags...' : 'Please select a tag'}
					loading={gitTags.isFetching}
					disabled={gitTags.isFetching}
					selectedValue={selectedGitTag}
					setSelectedValue={setSelectedGitTag}
					displayObjects={filteredTags}
					optionMapper={convertTagToDropdownOption}
					onSearchChange={(event, data) => setTagsFilter(data.searchQuery)}
				/>
			)}
			{gitTags.isError ? <Message error>{(gitTags.error as Error).message}</Message> : null}
		</>
	);
}

function useAvailableGitTags(project: string | null, repository: string | null): UseQueryResult<GitTag[]> {
	return QUERY.getAllGitTags(project ?? '', { repository: repository ?? '' }).useQuery({
		enabled: !!project && !!repository
	});
}

function getTopNMatches(gitTags: GitTag[], query: string, n: number) {
	return gitTags.filter(item => item.displayName.includes(query)).slice(0, n);
}

function convertTagToDropdownOption(item: GitTag): DropdownItemProps {
	return {
		value: JSON.stringify(item),
		text: item.displayName
	};
}
