import type { MutableRefObject } from 'react';
import { useEffect, useRef, type JSX } from 'react';
import type { SemanticICONS } from 'semantic-ui-react';
import { Icon } from 'semantic-ui-react';
import { filterVisibleInSidebar, useAccessibleViews } from 'ts/base/hooks/UseAccessibleViews';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { SidebarTooltip } from 'ts/base/perspective/sidebar/left/SidebarTooltip';
import { getLinkToPerspective, SubViewMenu } from 'ts/base/perspective/sidebar/left/SubViewMenu';
import { TeamscaleLink } from 'ts/base/routing/TeamscaleLink';
import type { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import type { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Props for LeftSidebarEntry. */
type LeftSidebarEntryProps = {
	perspective: ETeamscalePerspective;
	iconOnlySidebar: boolean;
	perspectiveDescriptor: PerspectiveViewDescriptorBase;
};

/** Represents a single perspective entry in the left sidebar. Takes care of attaching the floating submenus. */
export function LeftSidebarEntry({
	perspective,
	iconOnlySidebar,
	perspectiveDescriptor
}: LeftSidebarEntryProps): JSX.Element {
	const hash = useNavigationHash();
	const activePerspective = hash.getPerspective();
	const isActive = perspective.name === activePerspective.name;

	const accessibleViewDescriptors = useAccessibleViews(perspectiveDescriptor);

	// We use the first view here because the perspective link will lead to the first view (may not necessarily be visible in the sidebar)
	const mainPerspectiveLink = getLinkToPerspective(perspective, accessibleViewDescriptors[0], hash);

	const accessibleSubviewsInSidebar = filterVisibleInSidebar(accessibleViewDescriptors);
	const linkRef = useFloatingPerspectiveViewPopup(
		perspective,
		iconOnlySidebar,
		accessibleSubviewsInSidebar,
		mainPerspectiveLink
	);
	return (
		<>
			<TeamscaleLink
				id={'link-' + perspective.simpleName}
				className={'perspective-link item ' + (isActive ? ' active' : '')}
				to={mainPerspectiveLink}
				data-index={perspective.ordinal}
				ref={linkRef}
			>
				<div className="item-wrapper">
					{perspective.iconClass ? <Icon name={perspective.iconClass as SemanticICONS} /> : null}
					<span className="item__text">{perspective.displayName}</span>
				</div>
			</TeamscaleLink>
			{isActive ? <SubViewMenu views={accessibleSubviewsInSidebar} /> : null}
		</>
	);
}

// We cache the height of a link in order to not do it again for every single sidebar entry, as this will
// lead to multiple forced redraws and cost us >50ms
let cachedLinkHeight: number | undefined = undefined;

/** Attaches the floating perspective menu to the perspective link that is shown on hover. */
function useFloatingPerspectiveViewPopup(
	perspective: ETeamscalePerspective,
	iconOnlySidebar: boolean,
	accessibleViewDescriptors: ViewDescriptor[],
	mainPerspectiveLink: string
): MutableRefObject<HTMLAnchorElement | null> {
	const hash = useNavigationHash();
	const activePerspective = hash.getPerspective();
	const viewName = hash.getViewName();
	const linkRef = useRef<HTMLAnchorElement | null>(null);

	useEffect(() => {
		const perspectiveLink = linkRef.current!;
		cachedLinkHeight = cachedLinkHeight ?? perspectiveLink.offsetHeight;
		const tooltip = new SidebarTooltip(
			perspectiveLink,
			activePerspective,
			perspective,
			iconOnlySidebar,
			cachedLinkHeight,
			accessibleViewDescriptors,
			mainPerspectiveLink,
			viewName
		);
		return () => {
			tooltip.detach();
			// @ts-ignore Method is protected, but we need to call it will crash otherwise when the
			// MOUSEOUT event handler fires after the tooltip got destroyed
			tooltip.clearHideTimer();
			tooltip.dispose();
		};
	}, [accessibleViewDescriptors, activePerspective, iconOnlySidebar, perspective, viewName, mainPerspectiveLink]);
	return linkRef;
}
