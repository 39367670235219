import type { Cell, ColumnMeta, Row, RowData, Table as TableInstance } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import clsx from 'clsx';
import type { JSX, ReactNode } from 'react';
import type { StrictTableProps, TableCellProps } from 'semantic-ui-react';
import { Table as SemanticUiTable } from 'semantic-ui-react';
import styles from './Table.module.less';

/** Extended table row props. */
export type ExtendedTableRowProps = JSX.IntrinsicElements['tr'] & Record<`data-${string}`, string>;

/** Partial representation of cell props. */
export type PartialTableCellProps = Partial<TableCellProps & JSX.IntrinsicElements['td']>;

/**
 * We need to split the TableProps up like this to be able to use basically Omit<TableProps<D>, 'table'> for the
 * SortableTable, which does not produce the expected outcome due to
 * https://github.com/Microsoft/TypeScript/issues/28884 As an effect the table prop would still be required even when
 * omitting it.
 */
export type TableBaseProps<T extends RowData> = JSX.IntrinsicElements['table'] &
	Omit<StrictTableProps, 'footerRow'> & {
		footerRow?: ReactNode;
		getRowProps?: (row: Row<T>) => ExtendedTableRowProps;
		getCellProps?: (cell: Cell<T, unknown>) => PartialTableCellProps;
		resizable?: boolean;
		headless?: boolean;
		withColumnSummary?: number;
	};

/** Props for Table. */
type TableProps<T extends RowData> = TableBaseProps<T> & {
	table: TableInstance<T>;
};

/** A table that is automatically sortable. All data must be available on the client side. */
export function Table<T extends RowData>({
	table,
	footerRow,
	getRowProps = () => ({}),
	sortable,
	resizable,
	headless,
	getCellProps,
	withColumnSummary,
	...tableProps
}: TableProps<T>): JSX.Element {
	return (
		<SemanticUiTable sortable={sortable} {...tableProps}>
			{headless ? null : (
				<SemanticUiTable.Header>
					{table.getHeaderGroups().map(headerGroup => (
						<SemanticUiTable.Row key={headerGroup.id}>
							{headerGroup.headers.map(header => (
								<SemanticUiTable.HeaderCell
									key={header.id}
									style={resizable ? { width: header.getSize() } : null}
									colSpan={header.colSpan}
									onMouseDown={(e: MouseEvent) => {
										return e.button === 0 ? header.column.getToggleSortingHandler()?.(e) : null;
									}}
									title="Click to sort/change the sort order"
									sorted={
										(
											{
												asc: 'ascending',
												desc: 'descending'
											} as const
										)[header.column.getIsSorted() as string] ?? undefined
									}
									{...getCustomColumnProps(header.column.columnDef.meta)}
								>
									{header.isPlaceholder
										? null
										: flexRender(header.column.columnDef.header, header.getContext())}
									{resizable && header.column.getCanResize() ? (
										<div
											title="resizer"
											onMouseDown={event => {
												event.stopPropagation();
												header.getResizeHandler()(event);
											}}
											onTouchStart={header.getResizeHandler()}
											className={
												header.column.getIsResizing() ? styles.isResizing : styles.resizer
											}
										/>
									) : null}
								</SemanticUiTable.HeaderCell>
							))}
						</SemanticUiTable.Row>
					))}
				</SemanticUiTable.Header>
			)}
			<SemanticUiTable.Body>
				{withColumnSummary != null
					? Array.from({ length: withColumnSummary }).map((_, rowIndex) => (
							<SemanticUiTable.Row key={'summary-row-' + rowIndex}>
								{table.getVisibleLeafColumns().map(column => (
									<SemanticUiTable.Cell
										key={column.id}
										{...getCustomSummaryCellProps(rowIndex, column.columnDef.meta)}
									>
										{(function () {
											const ColumnSummary = column.columnDef.meta!.ColumnSummaries![rowIndex]!;
											return <ColumnSummary />;
										})()}
									</SemanticUiTable.Cell>
								))}
							</SemanticUiTable.Row>
					  ))
					: null}
				{table.getRowModel().rows.map(row => {
					return (
						<SemanticUiTable.Row key={row.id} {...getRowProps(row)}>
							{row.getVisibleCells().map(cell => (
								<SemanticUiTable.Cell
									key={cell.id}
									{...getCustomCellProps(cell.column.columnDef.meta, cell, getCellProps?.(cell))}
								>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</SemanticUiTable.Cell>
							))}
						</SemanticUiTable.Row>
					);
				})}
			</SemanticUiTable.Body>
			{footerRow != null ? <SemanticUiTable.Footer>{footerRow}</SemanticUiTable.Footer> : null}
		</SemanticUiTable>
	);
}

function mergeProps<D extends TableCellProps>(props1: Partial<D>, props2: Partial<D>) {
	Object.assign(props1, {
		...props2,
		className: clsx(props1.className, props2.className)
	});
}

function getCustomColumnProps<TData extends RowData, TValue>(
	meta: ColumnMeta<TData, TValue> | undefined,
	props: PartialTableCellProps = {}
) {
	if (meta && 'className' in meta) {
		mergeProps(props, { className: meta.className });
	}
	if (meta && 'collapsing' in meta) {
		Object.assign(props, {
			collapsing: meta.collapsing
		});
	}
	return props;
}

function getCustomSummaryCellProps<TData extends RowData, TValue>(
	rowIndex: number,
	meta: ColumnMeta<TData, TValue> | undefined,
	props: PartialTableCellProps = {}
) {
	if (meta && 'getColumnSummaryCellProps' in meta) {
		mergeProps(props, meta.getColumnSummaryCellProps!(rowIndex));
	}
	return getCustomColumnProps(meta, props);
}

function getCustomCellProps<TData extends RowData, TValue>(
	meta: ColumnMeta<TData, TValue> | undefined,
	cell: Cell<TData, TValue>,
	props: PartialTableCellProps = {}
) {
	if (meta && 'getCellProps' in meta) {
		mergeProps(props, meta.getCellProps!(cell));
	}
	return getCustomColumnProps(meta, props);
}
