// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy

/**
 * @fileoverview Templates in namespace ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import * as google from 'ts-closure-library/lib/google';
import * as goog_soy_data from 'soyutils/soyutils_usegoog';
import * as soy from 'soyutils/soyutils_usegoog';
import * as $soy$ts$commons$LinkTemplate from './../../../commons/LinkTemplate.soy.generated.js';
import * as $soy$ts$commons$MetricsUtilsTemplate from './../../../commons/MetricsUtilsTemplate.soy.generated.js';
import * as $soy$ts$commons$UIUtilsTemplate from './../../../commons/UIUtilsTemplate.soy.generated.js';
import * as $soy$ts$perspectives$findings$FindingsPerspectiveTemplate from './../../findings/FindingsPerspectiveTemplate.soy.generated.js';


/**
 * @param {?$codeContainer.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $codeContainer = function(opt_data, opt_ijData) {
  opt_data = opt_data || {};
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $codeContainer$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.heightLimit, opt_data.minHeight);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {null|number|undefined=} heightLimit
 * @param {null|string|undefined=} minHeight
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $codeContainer$ = function($$areYouAnInternalCaller, $ijData, heightLimit, minHeight) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.codeContainer']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.codeContainer']({heightLimit: heightLimit, minHeight: minHeight}, $ijData);
  }
  soy.assertParamType(heightLimit == null || typeof heightLimit === 'number', 'heightLimit', heightLimit, '@param', 'null|number|undefined');
  soy.assertParamType(minHeight == null || typeof minHeight === 'string', 'minHeight', minHeight, '@param', 'null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ts-flex-code-container"' + (heightLimit != null ? ' style="max-height: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(heightLimit)) + 'px;"' : '') + (minHeight != null ? ' style="min-height: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(minHeight)) + ';"' : '') + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.codeContainer ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:29"' : '') + '><div class="ts-code-scroll-container"></div><canvas width="16" style="display: none;position: absolute;"></canvas></div>');
};
export { $codeContainer as codeContainer };
export { $codeContainer$ as codeContainer$ };
/**
 * @typedef {{
 *  heightLimit?: (null|number|undefined),
 *  minHeight?: (null|string|undefined),
 * }}
 */
$codeContainer.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $codeContainer.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.codeContainer';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $noData = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.noData']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.noData'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui message" style="max-width: 600px"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.noData ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:46"' : '') + '><i class="file icon"></i> No metrics available</div>');
};
export { $noData as noData };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $noData.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.noData';
}


/**
 * @param {!$metricAssessmentTable.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $metricAssessmentTable = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.metricAssessmentTable']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.metricAssessmentTable'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {?} */
  const commit = opt_data.commit;
  /** @type {!Array<?>} */
  const metricTableEntries = soy.assertParamType(Array.isArray(opt_data.metricTableEntries), 'metricTableEntries', opt_data.metricTableEntries, '@param', '!Array<?>');
  /** @type {string} */
  const basePath = soy.assertParamType(typeof opt_data.basePath === 'string', 'basePath', opt_data.basePath, '@param', 'string');
  /** @type {boolean} */
  const centerNumbers = soy.assertParamType(typeof opt_data.centerNumbers === 'boolean', 'centerNumbers', opt_data.centerNumbers, '@param', 'boolean');
  /** @type {string} */
  const tableId = soy.assertParamType(typeof opt_data.tableId === 'string', 'tableId', opt_data.tableId, '@param', 'string');
  /** @type {null|string|undefined} */
  const visibleMetric = soy.assertParamType(opt_data.visibleMetric == null || typeof opt_data.visibleMetric === 'string', 'visibleMetric', opt_data.visibleMetric, '@param', 'null|string|undefined');
  /** @type {null|string|undefined} */
  const targetPerspectiveName = soy.assertParamType(opt_data.targetPerspectiveName == null || typeof opt_data.targetPerspectiveName === 'string', 'targetPerspectiveName', opt_data.targetPerspectiveName, '@param', 'null|string|undefined');
  /** @type {null|string|undefined} */
  const targetViewName = soy.assertParamType(opt_data.targetViewName == null || typeof opt_data.targetViewName === 'string', 'targetViewName', opt_data.targetViewName, '@param', 'null|string|undefined');
  /** @type {!Array<string>|null|undefined} */
  const partitions = soy.assertParamType(opt_data.partitions == null || Array.isArray(opt_data.partitions), 'partitions', opt_data.partitions, '@param', '!Array<string>|null|undefined');
  let $output = '<table id="' + soy.$$escapeHtmlAttribute(tableId) + '" class="ui large collapsing unstackable selectable borderless striped framed table body-code-table child-list"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.metricAssessmentTable ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:71"' : '') + '><thead><tr><th><h4>Path</h4></th>';
  if (soy.$$coerceToBoolean(metricTableEntries) && soy.$$coerceToBoolean(metricTableEntries[/** @type {?} */ (0)])) {
    const metric4295List = metricTableEntries[/** @type {?} */ (0)][/** @type {?} */ ('metrics')];
    const metric4295ListLen = metric4295List.length;
    for (let metric4295Index = 0; metric4295Index < metric4295ListLen; metric4295Index++) {
      const metric4295Data = metric4295List[metric4295Index];
      $output += !metric4295Data.hidden ? '<th class="center aligned ' + (metric4295Data[/** @type {?} */ ('highlight')] ? ' flash' : '') + '" id="metric-childTable-tooltip-hook" data-metric-descriptions-index="' + soy.$$escapeHtmlAttribute(metric4295Index) + '" style="min-width: 100px;position: relative">' + (!soy.$$equals(metric4295Data.valueType, 'dummy') ? '<h4>' + soy.$$escapeHtml(metric4295Data.name) + '</h4>' : '<div class="ui placeholder"><div class="paragraph"><div class="line"></div></div></div>') + '</th>' : '';
    }
  }
  $output += '</tr></thead>' + $metricAssessmentTableBody(/** @type {?} */ (opt_data), $ijData) + '</table>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $metricAssessmentTable as metricAssessmentTable };
/**
 * @typedef {{
 *  project: string,
 *  commit: ?,
 *  metricTableEntries: !Array<?>,
 *  basePath: string,
 *  centerNumbers: boolean,
 *  tableId: string,
 *  visibleMetric?: (null|string|undefined),
 *  targetPerspectiveName?: (null|string|undefined),
 *  targetViewName?: (null|string|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 *  thresholdProfile?: (null|string|undefined),
 *  findingFilter?: (!Array<string>|null|undefined),
 *  selection?: (?|undefined),
 *  identifier?: (null|string|undefined),
 *  highlight?: (null|string|undefined),
 *  showCoverage?: (boolean|null|undefined),
 *  highlightMetric?: (boolean|null|undefined),
 * }}
 */
$metricAssessmentTable.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $metricAssessmentTable.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.metricAssessmentTable';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $sidebarItem = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.sidebarItem']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.sidebarItem'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="item"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.sidebarItem ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:107"' : '') + '></div>');
};
export { $sidebarItem as sidebarItem };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $sidebarItem.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.sidebarItem';
}


/**
 * @param {!$metricAssessmentTableBody.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $metricAssessmentTableBody = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.metricAssessmentTableBody']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.metricAssessmentTableBody'](opt_data, $ijData);
  }
  /** @type {!Array<?>} */
  const metricTableEntries = soy.assertParamType(Array.isArray(opt_data.metricTableEntries), 'metricTableEntries', opt_data.metricTableEntries, '@param', '!Array<?>');
  /** @type {string} */
  const basePath = soy.assertParamType(typeof opt_data.basePath === 'string', 'basePath', opt_data.basePath, '@param', 'string');
  /** @type {?} */
  const project = opt_data.project;
  /** @type {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} */
  const commit = soy.assertParamType(opt_data.commit == null || google.isObject(opt_data.commit), 'commit', opt_data.commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  /** @type {?} */
  const centerNumbers = opt_data.centerNumbers;
  /** @type {?} */
  const visibleMetric = opt_data.visibleMetric;
  /** @type {null|string|undefined} */
  const targetPerspectiveName = soy.assertParamType(opt_data.targetPerspectiveName == null || typeof opt_data.targetPerspectiveName === 'string', 'targetPerspectiveName', opt_data.targetPerspectiveName, '@param', 'null|string|undefined');
  /** @type {null|string|undefined} */
  const targetViewName = soy.assertParamType(opt_data.targetViewName == null || typeof opt_data.targetViewName === 'string', 'targetViewName', opt_data.targetViewName, '@param', 'null|string|undefined');
  /** @type {!Array<string>|null|undefined} */
  const partitions = soy.assertParamType(opt_data.partitions == null || Array.isArray(opt_data.partitions), 'partitions', opt_data.partitions, '@param', '!Array<string>|null|undefined');
  let $output = '<tbody' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.metricAssessmentTableBody ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:134"' : '') + '>';
  const entry4373List = metricTableEntries;
  const entry4373ListLen = entry4373List.length;
  for (let entry4373Index = 0; entry4373Index < entry4373ListLen; entry4373Index++) {
    const entry4373Data = entry4373List[entry4373Index];
    const isContentExcluded__soy4379 = entry4373Data.isContentExcluded;
    const isSummary__soy4381 = entry4373Index == 0;
    const isArchitectureSummary__soy4382 = soy.$$checkNotNull('' + entry4373Data.relativePath).startsWith('-architecture-files-');
    if (!(basePath == '-architectures-/' || basePath == '-spec-items-' || soy.$$checkNotNull(basePath).startsWith('-non-code-') || basePath == '-issues-' || metricTableEntries.length == 2) || !isSummary__soy4381) {
      $output += '<tr class="' + (isSummary__soy4381 ? 'first-row' : '') + ' ' + (isArchitectureSummary__soy4382 ? 'arch-summary-row' : '') + '">' + $firstMetricTableCell(soy.$$assignDefaults({isSummary: isSummary__soy4381, isArchitectureSummary: isArchitectureSummary__soy4382, entry: entry4373Data, targetPerspectiveName: targetPerspectiveName, targetViewName: targetViewName, partitions: partitions}, opt_data), $ijData);
      const metric4404List = entry4373Data.metrics;
      const metric4404ListLen = metric4404List.length;
      for (let metric4404Index = 0; metric4404Index < metric4404ListLen; metric4404Index++) {
        const metric4404Data = metric4404List[metric4404Index];
        if (!metric4404Data.hidden) {
          let classes__soy4410 = 'class="' + (metric4404Data.isIssueMetricAndUnAvailable ? 'unavailable' : 'metric-data') + (!centerNumbers && soy.$$equals(metric4404Data.valueType, 'NUMERIC') ? ' right' : '') + (isSummary__soy4381 || isArchitectureSummary__soy4382 ? ' summary' : '') + (isArchitectureSummary__soy4382 ? ' disabled' : '') + (metric4404Data.highlight ? ' flash' : '') + (soy.$$equals(metric4404Data.name, 'Language') ? ' language-metric' : '') + ' metrics-table-cell"';
          const classes__wrapped4410 = soy.VERY_UNSAFE.$$ordainSanitizedAttributesForInternalBlocks(classes__soy4410);
          const $tmp = entry4373Data.relativePath;
          let $tmp$$1;
          if (soy.$$equals(metric4404Data.valueType, 'dummy')) {
            $tmp$$1 = '<div class="ui placeholder"><div class="paragraph"><div class="line"></div></div></div>';
          } else if (soy.$$coerceToBoolean(isContentExcluded__soy4379) && !soy.$$equals(metric4404Data.name, 'Files')) {
            $tmp$$1 = $soy$ts$commons$MetricsUtilsTemplate.missingData(null, $ijData);
          } else {
            $tmp$$1 = soy.$$escapeHtml(metric4404Data.formattedValue);
          }
          $output += '<td' + soy.$$whitespaceHtmlAttributes(soy.$$filterHtmlAttributes(classes__wrapped4410)) + ' data-group="' + soy.$$escapeHtmlAttribute(entry4373Index) + '" data-metricindex="' + soy.$$escapeHtmlAttribute(metric4404Index) + '" data-relative-path="' + soy.$$escapeHtmlAttribute($tmp != null ? $tmp : '') + '">' + $tmp$$1 + '</td>';
        }
      }
      $output += '</tr>';
    }
  }
  $output += '</tbody>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $metricAssessmentTableBody as metricAssessmentTableBody };
/**
 * @typedef {{
 *  metricTableEntries: !Array<?>,
 *  basePath: string,
 *  project: ?,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  centerNumbers: ?,
 *  visibleMetric?: (?|undefined),
 *  targetPerspectiveName?: (null|string|undefined),
 *  targetViewName?: (null|string|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 *  thresholdProfile?: (null|string|undefined),
 *  findingFilter?: (!Array<string>|null|undefined),
 *  selection?: (?|undefined),
 *  identifier?: (null|string|undefined),
 *  highlight?: (null|string|undefined),
 *  showCoverage?: (boolean|null|undefined),
 *  highlightMetric?: (boolean|null|undefined),
 * }}
 */
$metricAssessmentTableBody.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $metricAssessmentTableBody.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.metricAssessmentTableBody';
}


/**
 * @param {!$firstMetricTableCell.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $firstMetricTableCell = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.firstMetricTableCell']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.firstMetricTableCell'](opt_data, $ijData);
  }
  /** @type {string} */
  const basePath = soy.assertParamType(typeof opt_data.basePath === 'string', 'basePath', opt_data.basePath, '@param', 'string');
  /** @type {?} */
  const entry = opt_data.entry;
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {boolean} */
  const isSummary = soy.assertParamType(typeof opt_data.isSummary === 'boolean', 'isSummary', opt_data.isSummary, '@param', 'boolean');
  /** @type {boolean} */
  const isArchitectureSummary = soy.assertParamType(typeof opt_data.isArchitectureSummary === 'boolean', 'isArchitectureSummary', opt_data.isArchitectureSummary, '@param', 'boolean');
  /** @type {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} */
  const commit = soy.assertParamType(opt_data.commit == null || google.isObject(opt_data.commit), 'commit', opt_data.commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  /** @type {null|string|undefined} */
  const visibleMetric = soy.assertParamType(opt_data.visibleMetric == null || typeof opt_data.visibleMetric === 'string', 'visibleMetric', opt_data.visibleMetric, '@param', 'null|string|undefined');
  /** @type {null|string|undefined} */
  const targetPerspectiveName = soy.assertParamType(opt_data.targetPerspectiveName == null || typeof opt_data.targetPerspectiveName === 'string', 'targetPerspectiveName', opt_data.targetPerspectiveName, '@param', 'null|string|undefined');
  /** @type {null|string|undefined} */
  const targetViewName = soy.assertParamType(opt_data.targetViewName == null || typeof opt_data.targetViewName === 'string', 'targetViewName', opt_data.targetViewName, '@param', 'null|string|undefined');
  /** @type {!Array<string>|null|undefined} */
  const partitions = soy.assertParamType(opt_data.partitions == null || Array.isArray(opt_data.partitions), 'partitions', opt_data.partitions, '@param', '!Array<string>|null|undefined');
  let $tmp;
  if (isSummary) {
    $tmp = '<span class="metrics-table-summary-cell">Summary</span>';
  } else if (isArchitectureSummary) {
    $tmp = '<div style="white-space: nowrap; display: inline-flex;"><a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.architectureMetrics(soy.$$assignDefaults({uniformPath: '-architectures-'}, opt_data), $ijData))) + '">Architectures</a></div>';
  } else {
    $tmp = '<div style="white-space: nowrap; display: inline-flex;"><div><a' + (entry.isExcluded ? soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'Excluded from analysis due to file content')) : '') + ' href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.metricsShowingPerspective(soy.$$assignDefaults({uniformPath: basePath + entry.relativePath, targetPerspectiveName: soy.$$equals(entry.resourceType, 'CONTAINER') ? targetPerspectiveName : null, resourceType: entry.resourceType}, opt_data), $ijData))) + '"><i class="' + (entry.isTest ? 'green icon' : 'link-colored-icon') + ' ' + soy.$$escapeHtmlAttribute($iconForResource$(soy.$$internalCallMarkerDoNotUse, $ijData, entry.resourceType)) + '"> </i>' + soy.$$escapeHtml(entry.shortenedPath) + (soy.$$equals(entry.resourceType, 'CONTAINER') ? '<span style="visibility: hidden;">/</span>' : '') + '</a></div>' + (soy.$$checkNotNull('' + entry.relativePath).endsWith('.architecture') ? '<div class="arch-file-button-placeholder" data-uniform-path="' + soy.$$escapeHtmlAttribute(basePath + entry.relativePath) + '"></div>' : '') + '</div>';
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<td class="' + (isSummary || isArchitectureSummary ? 'summary' : '') + ' ' + (entry.isContentExcluded ? 'excluded' : '') + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.firstMetricTableCell ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:204"' : '') + '>' + $tmp + '</td>');
};
export { $firstMetricTableCell as firstMetricTableCell };
/**
 * @typedef {{
 *  basePath: string,
 *  entry: ?,
 *  project: string,
 *  isSummary: boolean,
 *  isArchitectureSummary: boolean,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  visibleMetric?: (null|string|undefined),
 *  targetPerspectiveName?: (null|string|undefined),
 *  targetViewName?: (null|string|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 *  thresholdProfile?: (null|string|undefined),
 *  findingFilter?: (!Array<string>|null|undefined),
 *  selection?: (?|undefined),
 *  identifier?: (null|string|undefined),
 *  highlight?: (null|string|undefined),
 *  showCoverage?: (boolean|null|undefined),
 *  highlightMetric?: (boolean|null|undefined),
 * }}
 */
$firstMetricTableCell.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $firstMetricTableCell.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.firstMetricTableCell';
}


/**
 * @param {?$iconForResource.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $iconForResource = function(opt_data, opt_ijData) {
  opt_data = opt_data || {};
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $iconForResource$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.resourceType);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {null|string|undefined=} resourceType
 * @return {string}
 * @suppress {checkTypes}
 */
const $iconForResource$ = function($$areYouAnInternalCaller, $ijData, resourceType) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.iconForResource']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.iconForResource']({resourceType: resourceType}, $ijData);
  }
  soy.assertParamType(resourceType == null || typeof resourceType === 'string', 'resourceType', resourceType, '@param', 'null|string|undefined');
  let $tmp;
  if (soy.$$equals(resourceType, 'FILE')) {
    $tmp = 'file outline icon';
  } else if (soy.$$equals(resourceType, 'CONTAINER')) {
    $tmp = 'folder icon';
  } else {
    $tmp = '';
  }
  return $tmp;
};
export { $iconForResource as iconForResource };
export { $iconForResource$ as iconForResource$ };
/**
 * @typedef {{
 *  resourceType?: (null|string|undefined),
 * }}
 */
$iconForResource.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $iconForResource.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.iconForResource';
}


/**
 * @param {!$architectureButtonOrBadge.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $architectureButtonOrBadge = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $architectureButtonOrBadge$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.uploadType, opt_data.uniformPath);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} uploadType
 * @param {?} uniformPath
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $architectureButtonOrBadge$ = function($$areYouAnInternalCaller, $ijData, uploadType, uniformPath) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureButtonOrBadge']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureButtonOrBadge']({uploadType: uploadType, uniformPath: uniformPath}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml(uploadType ? '<i class="icon spinner icon-spin fast"' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'Analysis in progress...')) + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureButtonOrBadge ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:263"' : '') + '></i>' : '<button class="ui button mini basic arch-file-button"' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'Open this architecure in the editor')) + ' data-uniform-path="' + soy.$$escapeHtmlAttribute(uniformPath) + '" data-testid="editor-button-' + soy.$$escapeHtmlAttribute(uniformPath) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureButtonOrBadge ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:267"' : '') + '>Editor</button>');
};
export { $architectureButtonOrBadge as architectureButtonOrBadge };
export { $architectureButtonOrBadge$ as architectureButtonOrBadge$ };
/**
 * @typedef {{
 *  uploadType: ?,
 *  uniformPath: ?,
 * }}
 */
$architectureButtonOrBadge.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $architectureButtonOrBadge.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureButtonOrBadge';
}


/**
 * @param {!$metricsLink.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $metricsLink = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $metricsLink$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.uniformPath, opt_data.commit, opt_data.prefixText, opt_data.title);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {string} uniformPath
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @param {?=} prefixText
 * @param {null|string|undefined=} title
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $metricsLink$ = function($$areYouAnInternalCaller, $ijData, project, uniformPath, commit, prefixText, title) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.metricsLink']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.metricsLink']({project: project, uniformPath: uniformPath, commit: commit, prefixText: prefixText, title: title}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(typeof uniformPath === 'string', 'uniformPath', uniformPath, '@param', 'string');
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  soy.assertParamType(title == null || typeof title === 'string', 'title', title, '@param', 'null|string|undefined');
  let $output = '';
  let text__soy4648 = '' + '' + project + (uniformPath ? '/' + '' + uniformPath : '');
  $output += (prefixText ? soy.$$escapeHtml(prefixText) + ' ' : '') + '<a href="' + (soy.$$checkNotNull(uniformPath).startsWith('-issues-/') ? soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.metricsShowingPerspective(/** @type {?} */ ({project: project, uniformPath: uniformPath, commit: commit, resourceType: 'FILE'}), $ijData))) : soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.metricsShowingPerspective(/** @type {?} */ ({project: project, uniformPath: uniformPath, commit: commit}), $ijData)))) + '"' + (title ? soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, '' + title)) : '') + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.metricsLink ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:289"' : '') + '>' + soy.$$escapeHtml(text__soy4648) + '</a>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $metricsLink as metricsLink };
export { $metricsLink$ as metricsLink$ };
/**
 * @typedef {{
 *  project: string,
 *  uniformPath: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  prefixText?: (?|undefined),
 *  title?: (null|string|undefined),
 * }}
 */
$metricsLink.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $metricsLink.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.metricsLink';
}


/**
 * @param {!$coverageSetting.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $coverageSetting = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $coverageSetting$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.partitionsWithoutCoverage, opt_data.lineCoveragePartitions);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} partitionsWithoutCoverage
 * @param {?=} lineCoveragePartitions
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $coverageSetting$ = function($$areYouAnInternalCaller, $ijData, partitionsWithoutCoverage, lineCoveragePartitions) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.coverageSetting']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.coverageSetting']({lineCoveragePartitions: lineCoveragePartitions, partitionsWithoutCoverage: partitionsWithoutCoverage}, $ijData);
  }
  let $output = '<div class="item"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.coverageSetting ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:317"' : '') + '><div class="ui header" style="font-size: 1em; margin-bottom: .5em; margin-top: 1em"><label for="show-testcoverage">Annotate test coverage</label><div class="ui checkbox pull-right show-coverage-checkbox"><input type="checkbox" id="show-testcoverage"/><label></label></div><i class="coverage-loading-spinner blue spinner loading icon pull-right"></i></div></div>' + (lineCoveragePartitions.length > 0 ? '<div id="partition-selector-container"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.coverageSetting ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:328"' : '') + '></div>' : '') + '<table class="ui very basic compact table"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.coverageSetting ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:330"' : '') + '>';
  if (soy.$$equals(lineCoveragePartitions.length, 0)) {
    $output += '<tbody><tr><td style="font-weight: normal;" colspan="2">No coverage uploaded, showing only coverable lines</td></tr></tbody>';
  } else {
    $output += '<thead><thead><tr><th>Coverage source</th><th>Latest Upload</th></tr></thead><tbody>';
    const partition4806List = lineCoveragePartitions;
    const partition4806ListLen = partition4806List.length;
    for (let partition4806Index = 0; partition4806Index < partition4806ListLen; partition4806Index++) {
      const partition4806Data = partition4806List[partition4806Index];
      $output += '<tr><td>' + soy.$$escapeHtml(partition4806Data.name) + '</td><td>' + soy.$$escapeHtml(partition4806Data.formattedTimestamp) + '</td></tr>';
    }
    if (partitionsWithoutCoverage.length > 0) {
      $output += '<tr><td colspan="2"><i>';
      if (partitionsWithoutCoverage.length > 5) {
        $output += 'No coverage in other coverage sources';
      } else {
        $output += 'No coverage in coverage sources:&nbsp;';
        const partitionWithoutCoverage4839List = partitionsWithoutCoverage;
        const partitionWithoutCoverage4839ListLen = partitionWithoutCoverage4839List.length;
        for (let partitionWithoutCoverage4839Index = 0; partitionWithoutCoverage4839Index < partitionWithoutCoverage4839ListLen; partitionWithoutCoverage4839Index++) {
          const partitionWithoutCoverage4839Data = partitionWithoutCoverage4839List[partitionWithoutCoverage4839Index];
          $output += (partitionWithoutCoverage4839Index > 0 ? ', ' : '') + soy.$$escapeHtml(partitionWithoutCoverage4839Data);
        }
      }
      $output += '</i></td></tr>';
    }
    $output += '</tbody>';
  }
  $output += '</table>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $coverageSetting as coverageSetting };
export { $coverageSetting$ as coverageSetting$ };
/**
 * @typedef {{
 *  lineCoveragePartitions?: (?|undefined),
 *  partitionsWithoutCoverage: ?,
 * }}
 */
$coverageSetting.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $coverageSetting.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.coverageSetting';
}


/**
 * @param {!$dependencyDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $dependencyDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $dependencyDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.pathDependencies, opt_data.inversePathDependencies, opt_data.maxDialogContentHeight, opt_data.maxDialogContentWidth, opt_data.commit);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {!Array<?>} pathDependencies
 * @param {!Array<?>} inversePathDependencies
 * @param {?} maxDialogContentHeight
 * @param {?} maxDialogContentWidth
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $dependencyDialog$ = function($$areYouAnInternalCaller, $ijData, project, pathDependencies, inversePathDependencies, maxDialogContentHeight, maxDialogContentWidth, commit) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyDialog']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyDialog']({project: project, pathDependencies: pathDependencies, inversePathDependencies: inversePathDependencies, maxDialogContentHeight: maxDialogContentHeight, maxDialogContentWidth: maxDialogContentWidth, commit: commit}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(Array.isArray(pathDependencies), 'pathDependencies', pathDependencies, '@param', '!Array<?>');
  soy.assertParamType(Array.isArray(inversePathDependencies), 'inversePathDependencies', inversePathDependencies, '@param', '!Array<?>');
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  return soy.VERY_UNSAFE.ordainSanitizedHtml(soy.$$coerceToBoolean(pathDependencies) || soy.$$coerceToBoolean(inversePathDependencies) ? '<div class="dependency-dialog-content" style="max-height: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(maxDialogContentHeight)) + 'px; max-width: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(maxDialogContentWidth)) + 'px; overflow: auto;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyDialog ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:390"' : '') + '><table class="ui very basic compact table">' + $dependencyTableHead$(soy.$$internalCallMarkerDoNotUse, $ijData, 'long arrow right icon', 'Outgoing', pathDependencies.length) + $dependencyTableBody$(soy.$$internalCallMarkerDoNotUse, $ijData, project, pathDependencies, true, commit) + '</table><table class="ui very basic compact table">' + $dependencyTableHead$(soy.$$internalCallMarkerDoNotUse, $ijData, 'long arrow left icon', 'Incoming', inversePathDependencies.length) + $dependencyTableBody$(soy.$$internalCallMarkerDoNotUse, $ijData, project, inversePathDependencies, false, commit) + '</table></div>' : '<div class="dependency-dialog-content-missing"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyDialog ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:422"' : '') + '><p>There is no dependency information available for this file.</p><p>Make sure architecture analysis is enabled.</p></div>');
};
export { $dependencyDialog as dependencyDialog };
export { $dependencyDialog$ as dependencyDialog$ };
/**
 * @typedef {{
 *  project: string,
 *  pathDependencies: !Array<?>,
 *  inversePathDependencies: !Array<?>,
 *  maxDialogContentHeight: ?,
 *  maxDialogContentWidth: ?,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 * }}
 */
$dependencyDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $dependencyDialog.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyDialog';
}


/**
 * @param {!$dependencyTableHead.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $dependencyTableHead = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $dependencyTableHead$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.arrowDirection, opt_data.message, opt_data.dependencyCount);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} arrowDirection
 * @param {?} message
 * @param {number} dependencyCount
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $dependencyTableHead$ = function($$areYouAnInternalCaller, $ijData, arrowDirection, message, dependencyCount) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyTableHead']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyTableHead']({arrowDirection: arrowDirection, message: message, dependencyCount: dependencyCount}, $ijData);
  }
  soy.assertParamType(typeof dependencyCount === 'number', 'dependencyCount', dependencyCount, '@param', 'number');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<thead' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyTableHead ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:441"' : '') + '><th><span class="file text icon"></span><span class="' + soy.$$escapeHtmlAttribute(arrowDirection) + '" style="margin-left: 4px; margin-right: 4px"></span><span class="file text outline icon"></span><span style="margin-left: 4px">' + soy.$$escapeHtml(message) + ' (' + soy.$$escapeHtml(dependencyCount) + ')</span></th><th></th><thead>');
};
export { $dependencyTableHead as dependencyTableHead };
export { $dependencyTableHead$ as dependencyTableHead$ };
/**
 * @typedef {{
 *  arrowDirection: ?,
 *  message: ?,
 *  dependencyCount: number,
 * }}
 */
$dependencyTableHead.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $dependencyTableHead.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyTableHead';
}


/**
 * @param {!$dependencyTableBody.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $dependencyTableBody = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $dependencyTableBody$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.dependencies, opt_data.isOutgoing, opt_data.commit);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {!Array<?>} dependencies
 * @param {boolean} isOutgoing
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $dependencyTableBody$ = function($$areYouAnInternalCaller, $ijData, project, dependencies, isOutgoing, commit) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyTableBody']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyTableBody']({project: project, dependencies: dependencies, isOutgoing: isOutgoing, commit: commit}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(Array.isArray(dependencies), 'dependencies', dependencies, '@param', '!Array<?>');
  soy.assertParamType(typeof isOutgoing === 'boolean', 'isOutgoing', isOutgoing, '@param', 'boolean');
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  let $output = '<tbody' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyTableBody ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:469"' : '') + '>';
  if (soy.$$coerceToBoolean(dependencies) && dependencies.length > 0) {
    const dependency4985List = dependencies;
    const dependency4985ListLen = dependency4985List.length;
    for (let dependency4985Index = 0; dependency4985Index < dependency4985ListLen; dependency4985Index++) {
      const dependency4985Data = dependency4985List[dependency4985Index];
      $output += '<tr><td width="70%"><a class="dependency-link" href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.code(/** @type {?} */ ({project: project, uniformPath: dependency4985Data.dependencyUniformPath, commit: commit, selection: isOutgoing ? null : {startLine: dependency4985Data.dependencyLocations[/** @type {?} */ (0)], endLine: dependency4985Data.dependencyLocations[/** @type {?} */ ('0')]}}), $ijData))) + '">' + soy.$$escapeHtml(dependency4985Data.dependencyUniformPath) + '</a></td><td width="30%">';
      const i5021ListLen = Math.max(0, Math.ceil((10 - 0) / 1));
      for (let i5021Index = 0; i5021Index < i5021ListLen; i5021Index++) {
        const i5021Data = 0 + i5021Index * 1;
        $output += dependency4985Data.dependencyLocations[/** @type {?} */ (i5021Data)] ? (i5021Data > 0 ? ', ' : '') + '<a class="dependency-link" href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.code(/** @type {?} */ ({project: project, uniformPath: dependency4985Data.dependencyLocationUniformPath, commit: commit, selection: {startLine: dependency4985Data.dependencyLocations[/** @type {?} */ (i5021Data)], endLine: dependency4985Data.dependencyLocations[/** @type {?} */ (i5021Data)]}}), $ijData))) + '">' + soy.$$escapeHtml(dependency4985Data.dependencyLocations[/** @type {?} */ (i5021Data)]) + '</a>' : '';
      }
      $output += (dependency4985Data.dependencyLocations.length > 10 ? ', ...' : '') + '</td></tr>';
    }
  } else {
    $output += '<tr><td>No results found.</td></tr>';
  }
  $output += '</tbody>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $dependencyTableBody as dependencyTableBody };
export { $dependencyTableBody$ as dependencyTableBody$ };
/**
 * @typedef {{
 *  project: string,
 *  dependencies: !Array<?>,
 *  isOutgoing: boolean,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 * }}
 */
$dependencyTableBody.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $dependencyTableBody.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyTableBody';
}


/**
 * @param {!$architectureComponentAssignmentsDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $architectureComponentAssignmentsDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $architectureComponentAssignmentsDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.componentAssignments, opt_data.maxDialogContentHeight, opt_data.maxDialogContentWidth, opt_data.commit);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {?} componentAssignments
 * @param {number} maxDialogContentHeight
 * @param {number} maxDialogContentWidth
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $architectureComponentAssignmentsDialog$ = function($$areYouAnInternalCaller, $ijData, project, componentAssignments, maxDialogContentHeight, maxDialogContentWidth, commit) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureComponentAssignmentsDialog']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureComponentAssignmentsDialog']({project: project, commit: commit, componentAssignments: componentAssignments, maxDialogContentHeight: maxDialogContentHeight, maxDialogContentWidth: maxDialogContentWidth}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  soy.assertParamType(typeof maxDialogContentHeight === 'number', 'maxDialogContentHeight', maxDialogContentHeight, '@param', 'number');
  soy.assertParamType(typeof maxDialogContentWidth === 'number', 'maxDialogContentWidth', maxDialogContentWidth, '@param', 'number');
  return soy.VERY_UNSAFE.ordainSanitizedHtml(soy.$$coerceToBoolean(componentAssignments) && componentAssignments.length > 0 ? '<div class="dependency-dialog-content" style="max-height: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(maxDialogContentHeight)) + 'px; max-width: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(maxDialogContentWidth)) + 'px;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureComponentAssignmentsDialog ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:528"' : '') + '><table class="ui very basic compact table">' + $architectureTableBody(/** @type {?} */ ({project: project, commit: commit, componentAssignments: componentAssignments}), $ijData) + '</table></div>' : '<div class="dependency-dialog-content-missing"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureComponentAssignmentsDialog ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:540"' : '') + '><p>There are no architectures available for this project.</p></div>');
};
export { $architectureComponentAssignmentsDialog as architectureComponentAssignmentsDialog };
export { $architectureComponentAssignmentsDialog$ as architectureComponentAssignmentsDialog$ };
/**
 * @typedef {{
 *  project: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  componentAssignments: ?,
 *  maxDialogContentHeight: number,
 *  maxDialogContentWidth: number,
 * }}
 */
$architectureComponentAssignmentsDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $architectureComponentAssignmentsDialog.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureComponentAssignmentsDialog';
}


/**
 * @param {!$architectureTableBody.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $architectureTableBody = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureTableBody']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureTableBody'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {?} */
  const commit = opt_data.commit;
  /** @type {?} */
  const componentAssignments = opt_data.componentAssignments;
  let $output = '<thead' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureTableBody ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:557"' : '') + '><th><span style="margin-left: 4px">Architecture</span></th><th><span style="margin-left: 4px">Component</span></th><thead><tbody>';
  const assignment5142List = componentAssignments;
  const assignment5142ListLen = assignment5142List.length;
  for (let assignment5142Index = 0; assignment5142Index < assignment5142ListLen; assignment5142Index++) {
    const assignment5142Data = assignment5142List[assignment5142Index];
    $output += '<tr><td width="50%"><a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.architectureEditor(soy.$$assignDefaults({assessmentIdentifier: assignment5142Data.architecturePath, project: project, component: assignment5142Data.componentName}, opt_data), $ijData))) + '">' + soy.$$escapeHtml(assignment5142Data.architecturePath) + '</a></td><td width="50%">';
    const $tmp = assignment5142Data.sortKey;
    switch (google.isObject($tmp) ? $tmp.toString() : $tmp) {
      case 0:
        $output += '<div class="ui  blue label"><i class="clone icon"></i>/' + soy.$$escapeHtml(assignment5142Data.componentName) + '</div>';
        break;
      case 1:
        $output += '<div class="ui red label"><i class="exclamation triangle icon"></i>' + soy.$$escapeHtml(assignment5142Data.componentName) + '</div>';
        break;
      case 2:
        $output += '<div class="ui grey label"><i class="minus icon"></i>' + soy.$$escapeHtml(assignment5142Data.componentName) + '</div>';
        break;
    }
    $output += '</td></tr>';
  }
  $output += '</tbody>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $architectureTableBody as architectureTableBody };
/**
 * @typedef {{
 *  project: string,
 *  commit: ?,
 *  componentAssignments: ?,
 * }}
 */
$architectureTableBody.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $architectureTableBody.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureTableBody';
}


/**
 * @param {!$outdatedCoverageInfo.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $outdatedCoverageInfo = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $outdatedCoverageInfo$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.codeDate);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} codeDate
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $outdatedCoverageInfo$ = function($$areYouAnInternalCaller, $ijData, codeDate) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.outdatedCoverageInfo']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.outdatedCoverageInfo']({codeDate: codeDate}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div id="test-coverage-date-warning" class="ui warning message"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.outdatedCoverageInfo ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:606"' : '') + '>Test coverage information in one or more partition (see right sidebar) may be outdated as code was changed on ' + soy.$$escapeHtml(codeDate) + '.</div>');
};
export { $outdatedCoverageInfo as outdatedCoverageInfo };
export { $outdatedCoverageInfo$ as outdatedCoverageInfo$ };
/**
 * @typedef {{
 *  codeDate: ?,
 * }}
 */
$outdatedCoverageInfo.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $outdatedCoverageInfo.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.outdatedCoverageInfo';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $mcdcCoverageInfo = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.mcdcCoverageInfo']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.mcdcCoverageInfo'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div id="mcdc-coverage-info" class="ui info message"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.mcdcCoverageInfo ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:618"' : '') + '>MC/DC coverage annotation active. Please hover over the numbers to get an explanation of the values.<span class="pull-right"><i id="dismiss-mcdc-info" class="close icon info-message-color"></i></span></div>');
};
export { $mcdcCoverageInfo as mcdcCoverageInfo };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $mcdcCoverageInfo.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.mcdcCoverageInfo';
}


/**
 * @param {!$simulinkContainer.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $simulinkContainer = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $simulinkContainer$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.useContainerStyles);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} useContainerStyles
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $simulinkContainer$ = function($$areYouAnInternalCaller, $ijData, useContainerStyles) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkContainer']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkContainer']({useContainerStyles: useContainerStyles}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div id="simulink-container"' + (useContainerStyles ? ' style="height: 100%; border: 1px solid whiteSmoke; border-radius: 4px; overflow: auto; max-height: 100vh;"' : '') + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkContainer ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:631"' : '') + '></div>');
};
export { $simulinkContainer as simulinkContainer };
export { $simulinkContainer$ as simulinkContainer$ };
/**
 * @typedef {{
 *  useContainerStyles: ?,
 * }}
 */
$simulinkContainer.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $simulinkContainer.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkContainer';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $simulinkBreadcrumbs = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkBreadcrumbs']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkBreadcrumbs'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div id="simulink-breadcrumbs" class="ui large padded bold breadcrumb"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkBreadcrumbs ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:641"' : '') + '><div class="section">Project</div></div>');
};
export { $simulinkBreadcrumbs as simulinkBreadcrumbs };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $simulinkBreadcrumbs.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkBreadcrumbs';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $simulinkNoDrawableModel = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkNoDrawableModel']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkNoDrawableModel'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkNoDrawableModel ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:650"' : '') + '>Simulink model cannot be drawn. See the logs for possible reasons</div>');
};
export { $simulinkNoDrawableModel as simulinkNoDrawableModel };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $simulinkNoDrawableModel.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkNoDrawableModel';
}


/**
 * @param {!$simulinkBreadcrumbsEntry.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $simulinkBreadcrumbsEntry = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $simulinkBreadcrumbsEntry$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.name, opt_data.link);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} name
 * @param {?} link
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $simulinkBreadcrumbsEntry$ = function($$areYouAnInternalCaller, $ijData, name, link) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkBreadcrumbsEntry']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkBreadcrumbsEntry']({name: name, link: link}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<a class="section" href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri(link)) + '" style="cursor: pointer;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkBreadcrumbsEntry ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:663"' : '') + '>' + soy.$$escapeHtml(name) + '</a>');
};
export { $simulinkBreadcrumbsEntry as simulinkBreadcrumbsEntry };
export { $simulinkBreadcrumbsEntry$ as simulinkBreadcrumbsEntry$ };
/**
 * @typedef {{
 *  name: ?,
 *  link: ?,
 * }}
 */
$simulinkBreadcrumbsEntry.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $simulinkBreadcrumbsEntry.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkBreadcrumbsEntry';
}


/**
 * @param {!$simulinkFindingsMarkerTooltip.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $simulinkFindingsMarkerTooltip = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $simulinkFindingsMarkerTooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.findings, opt_data.colors);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} project
 * @param {?} findings
 * @param {?} colors
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $simulinkFindingsMarkerTooltip$ = function($$areYouAnInternalCaller, $ijData, project, findings, colors) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkFindingsMarkerTooltip']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkFindingsMarkerTooltip']({project: project, findings: findings, colors: colors}, $ijData);
  }
  let $output = '<div class="tableWithClickableRows"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkFindingsMarkerTooltip ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:677"' : '') + '><row><p>Assessment</p><p>Qualified Name</p><p>Category</p><p>Message</p></row>';
  const finding5376List = findings;
  const finding5376ListLen = finding5376List.length;
  for (let finding5376Index = 0; finding5376Index < finding5376ListLen; finding5376Index++) {
    const finding5376Data = finding5376List[finding5376Index];
    let findingDetailsLink__soy5378 = '' + $soy$ts$commons$LinkTemplate.findingDetails(/** @type {?} */ ({project: project, id: finding5376Data[/** @type {?} */ ('id')]}), $ijData);
    $output += '<row data-href="' + soy.$$escapeHtmlAttribute(findingDetailsLink__soy5378) + '" style="cursor: pointer"><a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri(findingDetailsLink__soy5378)) + '"><div style="width: 20px; background-color:' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(colors[/** @type {?} */ (finding5376Data.assessment)].hexValue)) + '">&nbsp;</div></a><a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri(findingDetailsLink__soy5378)) + '">' + (soy.$$coerceToBoolean(finding5376Data.location.qualifiedName) && !soy.$$equals(finding5376Data.location.qualifiedName, '$bdroot') ? soy.$$escapeHtml(finding5376Data.location.qualifiedName) : soy.$$escapeHtml(finding5376Data.location.uniformPath)) + '</a><a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri(findingDetailsLink__soy5378)) + '">' + soy.$$escapeHtml(finding5376Data.categoryName) + '</a><a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri(findingDetailsLink__soy5378)) + '">' + $soy$ts$commons$UIUtilsTemplate.findingMessage$(soy.$$internalCallMarkerDoNotUse, $ijData, finding5376Data) + '</a></row>';
  }
  $output += '</div>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $simulinkFindingsMarkerTooltip as simulinkFindingsMarkerTooltip };
export { $simulinkFindingsMarkerTooltip$ as simulinkFindingsMarkerTooltip$ };
/**
 * @typedef {{
 *  project: ?,
 *  findings: ?,
 *  colors: ?,
 * }}
 */
$simulinkFindingsMarkerTooltip.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $simulinkFindingsMarkerTooltip.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkFindingsMarkerTooltip';
}


/**
 * @param {!$simulinkCoverageTooltipContent.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $simulinkCoverageTooltipContent = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $simulinkCoverageTooltipContent$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.coverageInfo, opt_data.coveragePercentage, opt_data.blockDefaultText, opt_data.showCoverage);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {{coveredLines: number, coverableLines: number, mappedLocations: (!Array<{uniformPath: string, rawStartLine: number, rawEndLine: number,}>|null|undefined),}} coverageInfo
 * @param {string} coveragePercentage
 * @param {string} blockDefaultText
 * @param {boolean} showCoverage
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $simulinkCoverageTooltipContent$ = function($$areYouAnInternalCaller, $ijData, project, coverageInfo, coveragePercentage, blockDefaultText, showCoverage) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkCoverageTooltipContent']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkCoverageTooltipContent']({project: project, coverageInfo: coverageInfo, coveragePercentage: coveragePercentage, blockDefaultText: blockDefaultText, showCoverage: showCoverage}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(google.isObject(coverageInfo), 'coverageInfo', coverageInfo, '@param', '{coveredLines: number, coverableLines: number, mappedLocations: (!Array<{uniformPath: string, rawStartLine: number, rawEndLine: number,}>|null|undefined),}');
  soy.assertParamType(typeof coveragePercentage === 'string', 'coveragePercentage', coveragePercentage, '@param', 'string');
  soy.assertParamType(typeof blockDefaultText === 'string', 'blockDefaultText', blockDefaultText, '@param', 'string');
  soy.assertParamType(typeof showCoverage === 'boolean', 'showCoverage', showCoverage, '@param', 'boolean');
  let $output = '' + soy.$$escapeHtml(blockDefaultText);
  if (showCoverage) {
    $output += '<hr' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkCoverageTooltipContent ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:727"' : '') + '>';
    if (coverageInfo.coverableLines == 0) {
      $output += '<span style="font-weight:bold"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkCoverageTooltipContent ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:729"' : '') + '>No mapped code</span>';
    } else {
      $output += '<span style="font-weight:bold"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkCoverageTooltipContent ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:731"' : '') + '>Test Coverage:</span><ul' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkCoverageTooltipContent ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:732"' : '') + '><li>Coverage:  ' + soy.$$escapeHtml(coveragePercentage) + '%, (covered ' + soy.$$escapeHtml(coverageInfo.coveredLines) + ' lines out of ' + soy.$$escapeHtml(coverageInfo.coverableLines) + ')</li>';
      if (soy.$$coerceToBoolean(coverageInfo.mappedLocations) && coverageInfo.mappedLocations.length > 0) {
        $output += '<li>Mapped Code Lines:</li><ul>';
        const coveredCode5499List = coverageInfo.mappedLocations;
        const coveredCode5499ListLen = coveredCode5499List.length;
        for (let coveredCode5499Index = 0; coveredCode5499Index < coveredCode5499ListLen; coveredCode5499Index++) {
          const coveredCode5499Data = coveredCode5499List[coveredCode5499Index];
          $output += '<li><a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.code(/** @type {?} */ ({project: project, uniformPath: coveredCode5499Data.uniformPath, selection: {startLine: coveredCode5499Data.rawStartLine, endLine: coveredCode5499Data.rawEndLine}}), $ijData))) + '"' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, '' + coveredCode5499Data.uniformPath)) + '>' + soy.$$escapeHtml(coveredCode5499Data.uniformPath) + ':' + (coveredCode5499Data.rawStartLine ? ' ' + soy.$$escapeHtml(coveredCode5499Data.rawStartLine) + ' ' : '') + '-' + (coveredCode5499Data.rawEndLine ? ' ' + soy.$$escapeHtml(coveredCode5499Data.rawEndLine) + ' ' : '') + '</a></li>';
        }
        $output += '</ul>';
      }
      $output += '</ul>';
    }
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $simulinkCoverageTooltipContent as simulinkCoverageTooltipContent };
export { $simulinkCoverageTooltipContent$ as simulinkCoverageTooltipContent$ };
/**
 * @typedef {{
 *  project: string,
 *  coverageInfo: {coveredLines: number, coverableLines: number, mappedLocations: (!Array<{uniformPath: string, rawStartLine: number, rawEndLine: number,}>|null|undefined),},
 *  coveragePercentage: string,
 *  blockDefaultText: string,
 *  showCoverage: boolean,
 * }}
 */
$simulinkCoverageTooltipContent.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $simulinkCoverageTooltipContent.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkCoverageTooltipContent';
}


/**
 * @param {!$codeRegionActionBar.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $codeRegionActionBar = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $codeRegionActionBar$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.url);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} url
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $codeRegionActionBar$ = function($$areYouAnInternalCaller, $ijData, url) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.codeRegionActionBar']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.codeRegionActionBar']({url: url}, $ijData);
  }
  soy.assertParamType(typeof url === 'string', 'url', url, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="code-region-action-menu ui compact icon top left pointing dropdown button"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.codeRegionActionBar ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:771"' : '') + '><i class="icon ellipsis vertical"></i><div class="menu"><div data-clipboard-text="' + soy.$$escapeHtmlAttribute(url) + '" class="item code-region-copy-link">Copy Link</div><div class="item code-region-remove-selection">Deselect</div><div class="item code-region-add-code-snippet-to-task">Add code snippet to task</div></div></div>');
};
export { $codeRegionActionBar as codeRegionActionBar };
export { $codeRegionActionBar$ as codeRegionActionBar$ };
/**
 * @typedef {{
 *  url: string,
 * }}
 */
$codeRegionActionBar.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $codeRegionActionBar.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.codeRegionActionBar';
}


/**
 * @param {!$reviewCommentDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $reviewCommentDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $reviewCommentDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.text);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} text
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $reviewCommentDialog$ = function($$areYouAnInternalCaller, $ijData, text) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.reviewCommentDialog']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.reviewCommentDialog']({text: text}, $ijData);
  }
  soy.assertParamType(typeof text === 'string', 'text', text, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui form"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.reviewCommentDialog ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:793"' : '') + '><div class="field"><span class="muted">Please enter a descriptive message for your review comment:</span><textarea id="review-comment-text" rows="3" cols="80">' + soy.$$escapeHtmlRcdata(text) + '</textarea></div></div>');
};
export { $reviewCommentDialog as reviewCommentDialog };
export { $reviewCommentDialog$ as reviewCommentDialog$ };
/**
 * @typedef {{
 *  text: string,
 * }}
 */
$reviewCommentDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $reviewCommentDialog.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.reviewCommentDialog';
}


/**
 * @param {!$abapFileMetadataDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $abapFileMetadataDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $abapFileMetadataDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.metadata, opt_data.creationDateAsString, opt_data.lastUpdateDateTimeAsString, opt_data.programTypeSynced, opt_data.authGroupSynced, opt_data.fileIsFunctionModule, opt_data.fileIsAbapDictionary);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {{originatingSystem: string, programType: string, authorizationGroup: string, isGeneratedCode: boolean, isRfcEnabled: boolean,}} metadata
 * @param {string} creationDateAsString
 * @param {string} lastUpdateDateTimeAsString
 * @param {boolean} programTypeSynced
 * @param {boolean} authGroupSynced
 * @param {boolean} fileIsFunctionModule
 * @param {boolean} fileIsAbapDictionary
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $abapFileMetadataDialog$ = function($$areYouAnInternalCaller, $ijData, metadata, creationDateAsString, lastUpdateDateTimeAsString, programTypeSynced, authGroupSynced, fileIsFunctionModule, fileIsAbapDictionary) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.abapFileMetadataDialog']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.abapFileMetadataDialog']({metadata: metadata, creationDateAsString: creationDateAsString, lastUpdateDateTimeAsString: lastUpdateDateTimeAsString, programTypeSynced: programTypeSynced, authGroupSynced: authGroupSynced, fileIsFunctionModule: fileIsFunctionModule, fileIsAbapDictionary: fileIsAbapDictionary}, $ijData);
  }
  soy.assertParamType(google.isObject(metadata), 'metadata', metadata, '@param', '{originatingSystem: string, programType: string, authorizationGroup: string, isGeneratedCode: boolean, isRfcEnabled: boolean,}');
  soy.assertParamType(typeof creationDateAsString === 'string', 'creationDateAsString', creationDateAsString, '@param', 'string');
  soy.assertParamType(typeof lastUpdateDateTimeAsString === 'string', 'lastUpdateDateTimeAsString', lastUpdateDateTimeAsString, '@param', 'string');
  soy.assertParamType(typeof programTypeSynced === 'boolean', 'programTypeSynced', programTypeSynced, '@param', 'boolean');
  soy.assertParamType(typeof authGroupSynced === 'boolean', 'authGroupSynced', authGroupSynced, '@param', 'boolean');
  soy.assertParamType(typeof fileIsFunctionModule === 'boolean', 'fileIsFunctionModule', fileIsFunctionModule, '@param', 'boolean');
  soy.assertParamType(typeof fileIsAbapDictionary === 'boolean', 'fileIsAbapDictionary', fileIsAbapDictionary, '@param', 'boolean');
  let $tmp$$2;
  if (!fileIsAbapDictionary) {
    let $tmp$$1;
    if (programTypeSynced) {
      let $tmp;
      if (metadata.programType == 'UNKNOWN_TYPE') {
        $tmp = '[Unknown program type] <i class="circle icon info"' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'This object has a type unknown to Teamscale. Contact Teamscale administrator. Recent sync logs of the SAP system will give more information.')) + '></i>';
      } else if (metadata.programType == 'NOT_ASSIGNED') {
        $tmp = '[Not assigned] <i class="circle icon info"' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'This object has no program type assigned (i.e. value is empty)')) + '></i>';
      } else {
        $tmp = soy.$$escapeHtml(metadata.programType);
      }
      $tmp$$1 = $tmp;
    } else {
      $tmp$$1 = $renderNotSyncedWarning(null, $ijData);
    }
    $tmp$$2 = '<tr><td><strong>Program Type</strong></td><td>' + $tmp$$1 + '</td></tr><tr><td><strong>Authorization Group</strong></td><td>' + (authGroupSynced ? ('' + soy.$$checkNotNull(soy.$$checkNotNull(metadata.authorizationGroup).trim())).length > 0 ? soy.$$escapeHtml(metadata.authorizationGroup) : '[Not assigned] <i class="circle icon info"' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'This object has no authorization group assigned (i.e. value is empty)')) + '></i>' : $renderNotSyncedWarning(null, $ijData)) + '</td></tr>';
  } else {
    $tmp$$2 = '';
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.abapFileMetadataDialog ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:813"' : '') + '><table class="ui very basic compact table"><thead></thead><tbody>' + (creationDateAsString ? '<tr><td><strong>Creation Date</strong></td><td>' + soy.$$escapeHtml(creationDateAsString) + '</td></tr>' : '') + '<tr><td><strong>Last Update Date/Time</strong></td><td>' + soy.$$escapeHtml(lastUpdateDateTimeAsString) + '</td></tr><tr><td><strong>Originating System</strong></td><td>' + (metadata.originatingSystem ? soy.$$escapeHtml(metadata.originatingSystem) : $renderNotSyncedWarning(null, $ijData)) + '</td></tr>' + $tmp$$2 + (fileIsFunctionModule ? '<tr><td><strong>RFC Enabled</strong></td><td>' + soy.$$escapeHtml(metadata.isRfcEnabled) + '</td></tr>' : '') + '<tr><td><strong>Generated Code</strong></td><td>' + soy.$$escapeHtml(metadata.isGeneratedCode) + '</td></tr></tbody></table></div>');
};
export { $abapFileMetadataDialog as abapFileMetadataDialog };
export { $abapFileMetadataDialog$ as abapFileMetadataDialog$ };
/**
 * @typedef {{
 *  metadata: {originatingSystem: string, programType: string, authorizationGroup: string, isGeneratedCode: boolean, isRfcEnabled: boolean,},
 *  creationDateAsString: string,
 *  lastUpdateDateTimeAsString: string,
 *  programTypeSynced: boolean,
 *  authGroupSynced: boolean,
 *  fileIsFunctionModule: boolean,
 *  fileIsAbapDictionary: boolean,
 * }}
 */
$abapFileMetadataDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $abapFileMetadataDialog.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.abapFileMetadataDialog';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $renderNotSyncedWarning = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.renderNotSyncedWarning']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.renderNotSyncedWarning'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('[Not synced to Teamscale] <i class="circle icon info"' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'Contact Teamscale administrator. This datum requires at least v2211 (Teamscale 8.5) of Teamscale SAP connector to be installed on the SAP system.')) + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.renderNotSyncedWarning ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:902"' : '') + '></i>');
};
export { $renderNotSyncedWarning as renderNotSyncedWarning };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $renderNotSyncedWarning.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.renderNotSyncedWarning';
}


/**
 * @param {!$abapFileMetadataErrorDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $abapFileMetadataErrorDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $abapFileMetadataErrorDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.error);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} error
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $abapFileMetadataErrorDialog$ = function($$areYouAnInternalCaller, $ijData, error) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.abapFileMetadataErrorDialog']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.abapFileMetadataErrorDialog']({error: error}, $ijData);
  }
  soy.assertParamType(typeof error === 'string', 'error', error, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div style="max-width: 400px; padding: 5px;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.abapFileMetadataErrorDialog ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:914"' : '') + '>' + soy.$$escapeHtml(error) + '</div>');
};
export { $abapFileMetadataErrorDialog as abapFileMetadataErrorDialog };
export { $abapFileMetadataErrorDialog$ as abapFileMetadataErrorDialog$ };
/**
 * @typedef {{
 *  error: string,
 * }}
 */
$abapFileMetadataErrorDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $abapFileMetadataErrorDialog.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.abapFileMetadataErrorDialog';
}
