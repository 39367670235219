import { ScrollArea } from '@mantine/core';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { Button, Form, Input } from 'semantic-ui-react';
import { ModalActionButtons } from 'ts/commons/modal/ModalActionButtons';
import { openModal } from 'ts/commons/modal/ModalUtils';
import { ToastNotification } from 'ts/commons/ToastNotification';

type ConfirmModalOptions = {
	/** The title to be shown in the modal. */
	title: string;
	/**
	 * The content of the modal. This will be a plain string in most cases, but can also be arbitrary JSX.Elements e.g.
	 * to style the text. If you want to have interactive elements in there e.g. inputs use openModal instead with a
	 * proper form in it.
	 */
	content: ReactNode;
	/**
	 * The text to be shown on the confirm action button. Should ideally be concise and specific i.e. `Delete` instead
	 * of `OK`.
	 */
	confirmText: string;
	/** Whether the action is in some way destructive. This will let the confirm button appear in red color. */
	destructive?: boolean;
	/** The label of the Cancel button. Defaults to `Cancel` */
	cancelText?: string;
	/**
	 * Callback when the confirm button is clicked. If the callback returns a Promise the modal takes care of showing a
	 * toast message in case it resolves to a service call error.
	 */
	onConfirm: () => void | Promise<unknown>;
	/** Callback when the cancel button is clicked. Default does nothing but closing the modal. */
	onCancel?: () => void;
	/**
	 * Requires the user to type the given text out before the dialog can be confirmed. This should be used very
	 * sparsely.
	 */
	retypeText?: string;
};

/**
 * Shows a Modal with the given options and takes care of removing it from the DOM when it is closed, canceled or
 * confirmed.
 */
export function openConfirmModal({ title, retypeText, ...props }: ConfirmModalOptions) {
	if (retypeText) {
		openModal({
			title,
			scrollAreaComponent: ScrollArea.Autosize,
			contentRenderer: close => <StrictConfirmModalContent retypeText={retypeText} close={close} {...props} />
		});
	} else {
		openModal({
			title,
			scrollAreaComponent: ScrollArea.Autosize,
			contentRenderer: close => <ConfirmModalContent close={close} {...props} />
		});
	}
}

type ConfirmModalContentProps = Omit<ConfirmModalOptions, 'title' | 'retypeText'> & { close: () => void };

function ConfirmModalContent({
	content,
	destructive,
	confirmText,
	onConfirm,
	cancelText,
	onCancel,
	close
}: ConfirmModalContentProps) {
	return (
		<>
			{content}
			<ModalActionButtons>
				<Button
					data-testid="confirm-button"
					primary={!destructive}
					content={confirmText}
					color={destructive ? 'red' : undefined}
					onClick={() => {
						close();
						Promise.resolve(onConfirm()).catch(ToastNotification.showIfServiceError);
					}}
				/>
				<Button
					data-testid="cancel-button"
					content={cancelText ?? 'Cancel'}
					onClick={() => {
						close();
						onCancel?.();
					}}
				/>
			</ModalActionButtons>
		</>
	);
}

type StrictConfirmModalContentProps = ConfirmModalContentProps & {
	retypeText: string;
};

function StrictConfirmModalContent({
	content,
	destructive,
	confirmText,
	onConfirm,
	cancelText,
	onCancel,
	close,
	retypeText
}: StrictConfirmModalContentProps) {
	const [input, setInput] = useState('');
	return (
		<Form
			error
			onSubmit={() => {
				close();
				onConfirm();
			}}
		>
			{content}
			<div className="text-center mt-1">
				<p>
					Type <strong>&quot;{retypeText}&quot;</strong> to confirm:
				</p>
				<Input
					autoFocus
					data-autofocus="true"
					data-testid="input-confirmation"
					value={input}
					onChange={e => setInput(e.target.value)}
				/>
			</div>
			<ModalActionButtons>
				<Button
					type="submit"
					data-testid="confirm-button"
					primary={!destructive}
					content={confirmText}
					disabled={input !== retypeText}
					color={destructive ? 'red' : undefined}
				/>
				<Button
					data-testid="cancel-button"
					type="button"
					content={cancelText ?? 'Cancel'}
					onClick={() => {
						close();
						onCancel?.();
					}}
				/>
			</ModalActionButtons>
		</Form>
	);
}
