/** Generated from ETrafficLightColor.java. Do not modify this file! */


export type ETrafficLightColorEntry = 'RED' | 'ORANGE' | 'YELLOW' | 'GREEN' | 'BASELINE' | 'UNKNOWN';

export class ETrafficLightColor {
	static readonly RED = new ETrafficLightColor(0, 'RED', "Red", "R", "#dd513a");
	static readonly ORANGE = new ETrafficLightColor(1, 'ORANGE', "Orange", "O", "#ff9966");
	static readonly YELLOW = new ETrafficLightColor(2, 'YELLOW', "Yellow", "Y", "#fde725");
	static readonly GREEN = new ETrafficLightColor(3, 'GREEN', "Green", "G", "#44bf70");
	static readonly BASELINE = new ETrafficLightColor(4, 'BASELINE', "Baseline", "B", "#dfeaf4");
	static readonly UNKNOWN = new ETrafficLightColor(5, 'UNKNOWN', "Unknown", "U", "#000000");
	static readonly values = [
		ETrafficLightColor.RED,
		ETrafficLightColor.ORANGE,
		ETrafficLightColor.YELLOW,
		ETrafficLightColor.GREEN,
		ETrafficLightColor.BASELINE,
		ETrafficLightColor.UNKNOWN
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: ETrafficLightColorEntry,
		public readonly displayText: string,
		public readonly shortDisplayText: string,
		public readonly hexValue: string
	){
	}

	toString() {
		return this.name;
	}
}

