import type { RaphaelAttributes, RaphaelPaper } from 'raphael';
import * as TeamscaleCodePerspectiveTemplate from 'soy/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy.generated';
import { SafeHtml } from 'ts-closure-library/lib/html/safehtml';
import { Builder, HtmlSanitizer } from 'ts-closure-library/lib/html/sanitizer/htmlsanitizer';
import { Tooltip } from 'ts-closure-library/lib/ui/tooltip';
import { StringUtils } from 'ts/commons/StringUtils';
import { UIUtils } from 'ts/commons/UIUtils';
import { renderMarkdownInFindingMessages } from 'ts/data/ExtendedTrackedFinding';
import type { ElementLocation } from 'typedefs/ElementLocation';
import { ETrafficLightColor } from 'typedefs/ETrafficLightColor';
import type { QualifiedNameLocation } from 'typedefs/QualifiedNameLocation';
import type { TextRegionLocation } from 'typedefs/TextRegionLocation';
import type { TrackedFinding } from 'typedefs/TrackedFinding';

/** Utility methods for Simulink. */
export class SimulinkUtils {
	/**
	 * The delay until the tooltip, which contains the findings table, will be hidden. Used to give the user enough time
	 * to hover over the tooltip, so that he is actually able to click one of the table rows.
	 */
	private static readonly TOOLTIP_HIDE_DELAY_MS = 300;

	/** Renders a marker for the given findings at x/y location. */
	public static renderFindingsMarker(
		project: string,
		findings: TrackedFinding[],
		x: number,
		y: number,
		paper: RaphaelPaper
	): void {
		const marker = paper.image('images/architecture/invalid_dependency.svg', x, y, 15, 15);
		const tooltipContent = UIUtils.renderAsSafeHtml(
			TeamscaleCodePerspectiveTemplate.simulinkFindingsMarkerTooltip,
			{ project, findings: renderMarkdownInFindingMessages(findings), colors: ETrafficLightColor }
		);
		const tooltip = new Tooltip(marker.node);
		tooltip.setSafeHtml(tooltipContent);
		tooltip.setHideDelayMs(SimulinkUtils.TOOLTIP_HIDE_DELAY_MS);
	}

	/**
	 * Returns whether the location belongs to a finding for the model- or file-level of a Simulink model and not a
	 * block or some element inside the model.
	 */
	public static isLocationForRootFinding(
		location: ElementLocation | TextRegionLocation | QualifiedNameLocation
	): boolean {
		return (
			'qualifiedName' in location && StringUtils.equalsOneOf(location.qualifiedName, '$bdroot', '$dbroot/', '')
		);
	}

	/** Sanitizes the given HTML and returns its text. */
	public static getSanitizedText(html: string): string {
		const safeHtml = HtmlSanitizer.sanitize(html);
		return (
			SafeHtml.unwrap(safeHtml)
				// Remove HTML tags
				.replace(/<[^>]*>/g, '')
				.trim()
		);
	}

	/**
	 * Sets or overrides raphael SVG attributes according to the HTML inline style
	 *
	 * @param html The HTML text which contains inline style that should be used to update the attributes
	 * @param attributes Raphael SVG attributes to be updated according to the inline style in the given HTML string.
	 * @returns Updated Raphael SVG attributes.
	 */
	public static updateAttributes(html: string, attributes: Partial<RaphaelAttributes>): Partial<RaphaelAttributes> {
		const builder = new Builder();
		const safeHtml = builder.allowCssStyles().build().sanitize(html);
		// Extract style attributes string with regex
		const styleAttributesMatch = SafeHtml.unwrap(safeHtml).match(/<[^>]* style="([^"]*)"/);
		if (!styleAttributesMatch || styleAttributesMatch.length < 2) {
			return attributes;
		}

		const styleAttributes = styleAttributesMatch[1]!;
		for (const styleAttribute of StringUtils.splitWithWhitespaceTrim(styleAttributes, ';')) {
			const attribute = StringUtils.splitWithWhitespaceTrim(styleAttribute, ':');
			switch (attribute[0]) {
				case 'font-family':
					attributes['font-family'] = attribute[1]!;
					break;
				case 'font-size':
					attributes['font-size'] = attribute[1]!;
					break;
				case 'font-weight':
					attributes['font-weight'] = attribute[1]!;
					break;
				case 'color':
					attributes.fill = attribute[1]!;
					break;
				default:
					break;
			}
		}
		return attributes;
	}
}
