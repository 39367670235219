import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { PermissionUtils } from 'ts/commons/permission/PermissionUtils';
import { ReportWarning } from 'ts/perspectives/quality_control/reports/views/ReportWarning';
import { EConfigurationFeature } from 'typedefs/EConfigurationFeature';
import { EProjectPermission } from 'typedefs/EProjectPermission';
import type { PerspectiveContext } from 'typedefs/PerspectiveContext';

function canConfigureQualityReports(context: PerspectiveContext): boolean {
	return PermissionUtils.mayAccessFeature(context, EConfigurationFeature.CONFIGURE_QUALITY_REPORTS);
}

function canEditRetrospectives(context: PerspectiveContext): boolean {
	return context.projectsInfo.projects.some(project =>
		PermissionUtils.hasProjectPermission(context, project, EProjectPermission.EDIT_RETROSPECTIVES)
	);
}

export const EQualityPerspectiveView = {
	REPORTS: {
		name: 'Reports',
		anchor: 'reports',
		requiresProject: false,
		visibleInSidebar: true,
		viewCreator: () => import('./reports/views/ReportListView'),
		canBeAccessed: canConfigureQualityReports
	} as ViewDescriptor,
	REPORT_SHOW: {
		name: 'Reports',
		anchor: 'reports',
		action: 'view',
		requiresProject: false,
		viewCreator: () => import('./reports/views/ReportShowView'),
		additionalPerspectiveSettingsComponent: <ReportWarning />,
		canBeAccessed: canConfigureQualityReports
	} as ViewDescriptor,
	REPORT_EDIT: {
		name: 'Reports',
		anchor: 'reports',
		action: 'edit',
		requiresProject: false,
		viewCreator: () => import('./reports/views/ReportEditorView'),
		additionalPerspectiveSettingsComponent: <ReportWarning addSlideInaccessiblePlaceholder />,
		canBeAccessed: canConfigureQualityReports
	} as ViewDescriptor,
	REPORT_PRINT: {
		name: 'Reports',
		anchor: 'reports',
		action: 'print',
		requiresProject: false,
		viewCreator: () => import('./reports/views/PrintReportView'),
		canBeAccessed: canConfigureQualityReports
	} as ViewDescriptor,
	REPORT_PRESENT: {
		name: 'Reports',
		anchor: 'reports',
		action: 'present',
		requiresProject: false,
		viewCreator: () => import('./reports/views/PresentReportView'),
		canBeAccessed: canConfigureQualityReports
	} as ViewDescriptor,
	REPORT_ROLES: {
		name: 'Reports',
		anchor: 'reports',
		action: 'report-roles',
		requiresProject: false,
		view: () => import('./reports/views/ReportRolesView'),
		canBeAccessed: canConfigureQualityReports
	} as ViewDescriptor,
	BASELINES: {
		name: 'Baselines',
		anchor: 'baselines',
		requiresProject: true,
		visibleInSidebar: true,
		view: () => import('../findings/baselines/BaselineView')
	} as ViewDescriptor,
	TASKS: {
		name: 'Tasks',
		anchor: 'tasks',
		requiresProject: true,
		visibleInSidebar: true,
		timeTravel: {
			contentName: 'Tasks',
			hideTimeTravelButton: true
		},
		viewCreator: () => import('./tasks/list/TaskListView')
	} as ViewDescriptor,
	TASK_NEW: {
		name: 'Tasks',
		anchor: 'tasks',
		action: 'new',
		requiresProject: true,
		projectIsFixed: true,
		viewCreator: () => import('./tasks/detail/TaskEditView')
	} as ViewDescriptor,
	TASK_DETAIL: {
		name: 'Tasks',
		anchor: 'tasks',
		action: 'details',
		requiresProject: true,
		projectIsFixed: true,
		timeTravel: {
			contentName: 'Tasks',
			hideTimeTravelButton: true
		},
		view: () => import('./tasks/detail/TaskDetailsView')
	} as ViewDescriptor,
	TASK_EDIT: {
		name: 'Tasks',
		anchor: 'tasks',
		action: 'edit',
		requiresProject: true,
		projectIsFixed: true,
		viewCreator: () => import('./tasks/detail/TaskEditView')
	} as ViewDescriptor,
	RETROSPECTIVES: {
		name: 'Retrospectives',
		anchor: 'retrospectives',
		requiresProject: true,
		visibleInSidebar: false, // Hidden, until stable enough
		timeTravel: {
			contentName: 'Retrospectives',
			hideTimeTravelButton: true
		},
		viewCreator: () => import('./retrospectives/views/RetrospectiveListView')
	} as ViewDescriptor,
	RETROSPECTIVE_EDIT: {
		name: 'Retrospectives',
		anchor: 'retrospectives',
		action: 'edit',
		requiresProject: true,
		viewCreator: () => import('./retrospectives/views/RetrospectiveEditView'),
		canBeAccessed: canEditRetrospectives
	} as ViewDescriptor
} as const;
