import type { LicenseInfo } from 'typedefs/LicenseInfo';
import type { PerspectiveContext } from 'typedefs/PerspectiveContext';

/** Helper class for dealing with the Teamscale license */
export class LicenseUtils {
	/** Determines whether any requirements management tool is enabled in the license. */
	public static hasRequirementsManagementToolsEnabled(context: PerspectiveContext): boolean {
		return (context.teamscaleInfo.licenseInfo.license?.requirementsManagementTools.features.length ?? 0) > 0;
	}
	/** Checks if there is a license present and if it is valid. */
	public static isMissingOrInvalid(license: LicenseInfo) {
		return license.license == null || license.license.expired;
	}
}
