import { Group } from '@mantine/core';
import type { GroupProps } from '@mantine/core/lib/Group/Group';

/**
 * Tiny wrapper around Group that should be used to group the action buttons of a modal. The buttons within the
 * component should be ordered from primary action to secondary action left-to-right.
 */
export function ModalActionButtons(props: GroupProps) {
	return <Group position="right" {...props} />;
}
