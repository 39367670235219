import type { UserOptions } from 'custom-types/UserOptions';
import type { EventAnnouncement } from 'typedefs/EventAnnouncement';
import type { PermissionSummary } from 'typedefs/PermissionSummary';
import type { PerspectiveContextUserInfo } from 'typedefs/PerspectiveContextUserInfo';
import type { User } from 'typedefs/User';

/** Client-specific extension of the PerspectiveContextUserInfo class. */
export class ExtendedPerspectiveContextUserInfo {
	/** See {@link PerspectiveContextUserInfo#currentUser} */
	public readonly currentUser: User;

	/** See {@link PerspectiveContextUserInfo#canChangePassword} */
	public readonly canChangePassword: boolean;

	/** See {@link PerspectiveContextUserInfo#isAdmin}. */
	public readonly isAdmin: boolean;

	/** See {@link PerspectiveContextUserInfo#userOptions}. */
	public readonly userOptions: UserOptions;

	/** See {@link PerspectiveContextUserInfo#permissionSummary}. */
	public readonly permissionSummary: PermissionSummary;

	/** See {@link PerspectiveContextUserInfo#allowedPerspectivePageNames}. */
	public readonly allowedPerspectivePageNames: string[];

	/** See {@link PerspectiveContextUserInfo#eventAnnouncement}. */
	public readonly eventAnnouncement?: EventAnnouncement;

	public constructor(userInfo: PerspectiveContextUserInfo) {
		this.currentUser = userInfo.currentUser;
		this.canChangePassword = userInfo.canChangePassword;
		this.isAdmin = userInfo.isAdmin;
		this.userOptions = userInfo.userOptions;
		this.permissionSummary = userInfo.permissionSummary;
		this.allowedPerspectivePageNames = userInfo.allowedPerspectivePageNames;
		this.eventAnnouncement = userInfo.eventAnnouncement;
	}

	public isColorBlindModeEnabled(): boolean {
		return this.userOptions['accessibility.colorblind'].colorBlindModeEnabled;
	}
}
