import { MUTATION } from 'api/Mutation';
import { isSameDay, isValid } from 'date-fns';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useState, type JSX } from 'react';
import { Button, Header, Icon, Message } from 'semantic-ui-react';
import { ReactUtils } from 'ts/base/ReactUtils';
import { ToastNotification } from 'ts/commons/ToastNotification';
import type { EventAnnouncement } from 'typedefs/EventAnnouncement';

type EventAnnouncementBannerProps = {
	event: EventAnnouncement | undefined;
};

/** A banner announcing an event to the user. */
export function EventAnnouncementBanner({ event }: EventAnnouncementBannerProps): JSX.Element | null {
	const { mutate: hideEventAnnouncement } = MUTATION.hideEventAnnouncement.useMutation({
		onError: ToastNotification.error,
		onSuccess: () => {
			ReactUtils.queryClient.invalidateQueries(['perspective-context']);
			setHidden(true);
		}
	});
	const [hidden, setHidden] = useState(false);
	if (event === undefined || hidden) {
		return null;
	}
	// Converts the event's start and end time to the client's timezone
	// We do this by first normalizing the date to UTC and then converting it to the target timezone, because there is
	// no function to directly convert it
	const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const startTime = utcToZonedTime(zonedTimeToUtc(event.startIsoLocalDateTime, event.timezone), clientTimezone);
	const endTime = utcToZonedTime(zonedTimeToUtc(event.endIsoLocalDateTime, event.timezone), clientTimezone);
	return (
		<Message info id={event.eventId === 'preview' ? undefined : 'event-announcement-message'}>
			<Message.Content className="flex items-center gap-4">
				<div className="self-start">
					<Header as="h3">{event.headline}</Header>
					{formatTimeRange(startTime, endTime)} - {event.description}
				</div>
				<a href={event.learnMoreUrl} className="ml-auto" target="_blank" rel="noopener noreferrer">
					<Icon name="info circle" />
					<strong>Learn more</strong>
				</a>
				<Button
					id={event.eventId === 'preview' ? undefined : 'hide-forever'}
					className="!text-red-500 !bg-white hover:!bg-red-100"
					icon={<Icon name="close" />}
					content="Hide forever"
					onClick={() => {
						if (event.eventId === 'preview') {
							return;
						}
						hideEventAnnouncement({ body: event.eventId });
					}}
				/>
			</Message.Content>
		</Message>
	);
}

function formatTimeRange(startTime: Date, endTime: Date): string {
	const sameDay = isSameDay(startTime, endTime);
	if (isValid(startTime) && isValid(endTime)) {
		if (sameDay) {
			return format(startTime, 'MMM d R, H:mm - ') + format(endTime, 'H:mm z');
		} else {
			return format(startTime, 'MMM d R, H:mm - ') + format(endTime, 'MMM d R, H:mm z');
		}
	} else if (isValid(startTime)) {
		return format(startTime, 'MMM d R, H:mm - ') + 'invalid date ' + format(startTime, 'z');
	} else if (isValid(endTime)) {
		return 'invalid date - ' + format(endTime, 'MMM d R, H:mm z');
	} else {
		return 'invalid dates';
	}
}
