import { marked } from 'marked';
import { StringUtils } from 'ts/commons/StringUtils';

/** Marked renderer that reduces the heading levels of all emitted markdown by two levels. */
export class HeaderReducingRenderer extends marked.Renderer {
	public override heading(text: string, level: 1 | 2 | 3 | 4 | 5 | 6) {
		if (level <= 4) {
			return `<h${level + 2}>${text}</h${level + 2}>\n`;
		} else {
			return `<strong>${text}</strong>\n`;
		}
	}

	public override html(html: string, block: boolean): string {
		return HeaderReducingRenderer.reduceHeaderSizes(super.html(html, block));
	}

	/** Reduces the headline sizes in the rule description by setting <h3> as the maximum headline. */
	private static reduceHeaderSizes(description: string): string {
		return StringUtils.replaceAllUsingPairs(description, [
			...HeaderReducingRenderer.getTagReplacements('h6', 'strong'),
			...HeaderReducingRenderer.getTagReplacements('h5', 'strong'),
			...HeaderReducingRenderer.getTagReplacements('h4', 'h6'),
			...HeaderReducingRenderer.getTagReplacements('h3', 'h5'),
			...HeaderReducingRenderer.getTagReplacements('h2', 'h4'),
			...HeaderReducingRenderer.getTagReplacements('h1', 'h3')
		])!;
	}

	private static getTagReplacements(tagName: string, replacementTagName: string): Array<[string, string]> {
		return [
			[`<${tagName}>`, `<${replacementTagName}>`],
			[`</${tagName}>`, `</${replacementTagName}>`]
		];
	}
}
