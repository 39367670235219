import type { ReactNode } from 'react';
import { useEffect, type JSX } from 'react';
import { Menu } from 'semantic-ui-react';
import { useProjectInfos } from 'ts/base/hooks/ProjectsInfosHook';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { AccountMenu } from 'ts/base/perspective/topbar/AccountMenu';
import { GlobalSearchBar } from 'ts/base/perspective/topbar/GlobalSearchBar';
import { HelpMenu } from 'ts/base/perspective/topbar/HelpMenu';
import type { ProjectIds } from 'ts/base/perspective/topbar/UseProjectIds';
import { GlobalWarnings } from 'ts/base/perspective/topbar/warnings/GlobalWarnings';
import { GlobalProjectSelector } from 'ts/base/scaffolding/GlobalProjectSelector';
import { SuspendingErrorBoundary } from 'ts/base/SuspendingErrorBoundary';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { GlobalBranchSelectorWrapper, TimeTravelButtonWrapper, TimeTravelState } from 'ts/commons/TimeTravelState';

/** Props for PerspectiveSettingsBar. */
type PerspectiveSettingsBarProps = {
	viewDescriptor: ViewDescriptor;
	projectIds: ProjectIds;
};

/**
 * The perspective settings bar (AKA top bar) for all perspectives. Provides links to the User perspective (via the 'My
 * account' dropdown), a logout button as well as search and help functionality. Optionally, project, branch selection
 * and time travel can be enabled.
 */
export function PerspectiveSettingsBar({ viewDescriptor, projectIds }: PerspectiveSettingsBarProps): JSX.Element {
	const hash = useNavigationHash();
	useEffect(() => {
		// Reset the time travel commit to what is represented in the URL
		TimeTravelState.INSTANCE.reset(hash.getCommit());
	}, [hash]);

	const projectSelector = useProjectSelector(viewDescriptor);
	const additionComponent = viewDescriptor.additionalPerspectiveSettingsComponent ?? null;
	let globalBranchSelector: JSX.Element | null = null;
	let timeTravelElement: JSX.Element | null = null;

	if (projectIds.isLoaded && viewDescriptor.timeTravel && projectIds.existingProjectIds.length > 0) {
		globalBranchSelector = <GlobalBranchSelectorWrapper projectIds={projectIds.existingProjectIds} />;
		if (!viewDescriptor.timeTravel.hideTimeTravelButton) {
			timeTravelElement = (
				<TimeTravelButtonWrapper
					projectIds={projectIds.existingProjectIds}
					timeTravelOptions={viewDescriptor.timeTravel}
				/>
			);
		}
	}

	// 'segment' is needed for the progress bar to work
	return (
		<PerspectiveSettingsBarSkeleton>
			<SuspendingErrorBoundary errorFallback={() => null} suspenseFallback={null}>
				<SuspendingErrorBoundary errorFallback={() => null} suspenseFallback={null}>
					<GlobalWarnings />
				</SuspendingErrorBoundary>
				<div className="menu">
					<Menu.Item fitted id="selectors">
						{projectSelector}
						{globalBranchSelector}
						{additionComponent ? (
							<SuspendingErrorBoundary errorFallback={() => null} suspenseFallback={null}>
								<div className="fitted item side-fitting">{additionComponent}</div>
							</SuspendingErrorBoundary>
						) : null}
					</Menu.Item>
				</div>
				<RightPerspectiveBarSection timeTravelElement={timeTravelElement} />
			</SuspendingErrorBoundary>
		</PerspectiveSettingsBarSkeleton>
	);
}

type PerspectiveSettingsBarSkeletonProps = { children?: ReactNode };

/** The skeloton for the perspective settings bar. */
export function PerspectiveSettingsBarSkeleton({ children }: PerspectiveSettingsBarSkeletonProps) {
	return (
		<Menu secondary className="perspective-settings-bar segment">
			{children}
		</Menu>
	);
}

/** Renders the search and user section of the navigation bar. */
function RightPerspectiveBarSection({ timeTravelElement }: { timeTravelElement: JSX.Element | null }): JSX.Element {
	const projectInfos = useProjectInfos();
	return (
		<>
			<div className="ml-auto" />
			{timeTravelElement}
			{projectInfos.getAllProjects().length === 0 ? null : <GlobalSearchBar />}
			<AccountMenu />
			<HelpMenu />
		</>
	);
}

/**
 * Returns the global project selector that should be shown in the perspective settings bar or null if no project
 * selector should be shown.
 */
function useProjectSelector(viewDescriptor: ViewDescriptor): JSX.Element | null {
	const showProjectSelector =
		useProjectInfos().projectsInfo.length > 0 &&
		viewDescriptor.requiresProject &&
		!viewDescriptor.hideProjectSelector;
	if (!showProjectSelector) {
		return null;
	}
	return (
		<GlobalProjectSelector
			projectIsFixed={Boolean(viewDescriptor.projectIsFixed)}
			showAllProjects={Boolean(viewDescriptor.showAllProjects)}
		/>
	);
}
