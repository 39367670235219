import { useQuery } from '@tanstack/react-query';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import type { ProjectSpecificBaselineInfo } from 'ts/perspectives/findings/baselines/ProjectSpecificBaselineInfo';

/** Provides a list of all baselines of the specified projects. */
export function useBaselines(projects: string[] | null): {
	baselines: ProjectSpecificBaselineInfo[] | undefined;
} {
	const client = useTeamscaleServiceClient();
	const { data } = useQuery(
		['baselines', projects],
		() =>
			client.getAllBaselinesByProjects(projects!).then(baselinesByProjects => {
				return Object.keys(baselinesByProjects).flatMap(project => {
					return baselinesByProjects[project]!.map(baselineInfo => {
						return {
							...baselineInfo,
							project
						};
					});
				});
			}),
		{ enabled: !ArrayUtils.isEmptyOrUndefined(projects) }
	);
	return { baselines: data };
}
