import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { ReactNode } from 'react';
import { type JSX, useMemo } from 'react';
import type { Root } from 'react-dom/client';
import { createRoot } from 'react-dom/client';
import { TeamscaleServiceClient } from 'ts/base/client/TeamscaleServiceClient';
import {
	EHashReloadBehavior,
	NavigationHashReloadBehaviorContext
} from 'ts/base/context/NavigationHashReloadBehaviorContext';
import { PerspectiveContextContext } from 'ts/base/context/PerspectiveContextContext';
import { ProjectInfosContext } from 'ts/base/context/ProjectInfosContext';
import { TeamscaleInfoContext } from 'ts/base/context/TeamscaleInfoContext';
import { TeamscaleServiceClientContext } from 'ts/base/context/TeamscaleServiceClientContext';
import { UserInfoContext } from 'ts/base/context/UserInfoContext';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { SuspendingErrorBoundary } from 'ts/base/SuspendingErrorBoundary';
import { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';
import type { PerspectiveContext } from 'typedefs/PerspectiveContext';
import { ProjectProvider } from './context/ProjectInfoContext';

/** The allowed extra properties to be set on the wrapper element into which the React component is embedded. */
export type WrapperStyles = { className?: string; style?: Partial<CSSStyleDeclaration> };

/** Utilities for integrating React into Teamscale views. */
export class ReactUtils {
	/** The query client used */
	public static queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				suspense: true,
				retry: false,
				refetchOnWindowFocus: false,
				// Site should also work when not connected to the Internet (i.e. Demo Setup)
				networkMode: 'offlineFirst'
			},
			mutations: {
				// Site should also work when not connected to the Internet (i.e. Demo Setup)
				networkMode: 'offlineFirst'
			}
		},
		// Disable additional warnings in the log from @tanstack/react-query
		logger: {
			log: () => undefined,
			warn: () => undefined,
			error: () => undefined
		}
	});

	/**
	 * Renders the given react component into the container element. You must also make sure to call ReactUtils.unmount
	 * before destroying the DOM Node. In a typical view you would do this in the dispose() lifecycle method. The
	 * perspective context is made available inside the component via the following hooks:
	 *
	 * - UsePerspectiveContext
	 * - UseUserInfo
	 * - UseUserPermissionInfo
	 * - UseProjectInfos
	 * - UseTeamscaleInfo
	 */
	public static render(component: ReactNode, container: Element, perspectiveContext: PerspectiveContext): Root {
		const extendedPerspectiveContext = new ExtendedPerspectiveContext(perspectiveContext);
		const root = createRoot(container);
		root.render(
			<BaseProviders hashReloadBehavior={EHashReloadBehavior.RELOAD_ONLY_SILENTLY_APPLIED_CHANGES}>
				<PerspectiveContextProviders perspectiveContext={extendedPerspectiveContext}>
					{component}
				</PerspectiveContextProviders>
			</BaseProviders>
		);
		return root;
	}

	/**
	 * Unmounts the React component from the given React root. This needs to be called to free the resources allocated
	 * for rendering the component. In a typical view you would do this in the dispose() lifecycle method.
	 */
	public static unmount(root: Root | undefined | null): void {
		setTimeout(() => root?.unmount());
	}

	/** Replaces the currently rendered content with the new component. */
	public static replace(component: ReactNode, root: Root, perspectiveContext: PerspectiveContext): void {
		const extendedPerspectiveContext = new ExtendedPerspectiveContext(perspectiveContext);
		root.render(
			<BaseProviders hashReloadBehavior={EHashReloadBehavior.RELOAD_ONLY_SILENTLY_APPLIED_CHANGES}>
				<PerspectiveContextProviders perspectiveContext={extendedPerspectiveContext}>
					{component}
				</PerspectiveContextProviders>
			</BaseProviders>
		);
	}

	/** Replaces the currently rendered content with the new component. */
	public static replaceStatic(component: ReactNode, root: Root): void {
		root.render(
			<BaseProviders hashReloadBehavior={EHashReloadBehavior.RELOAD_ONLY_SILENTLY_APPLIED_CHANGES}>
				{component}
			</BaseProviders>
		);
	}

	/**
	 * Convenience method that creates a separate child in the given container into which the given component is
	 * rendered.
	 *
	 * @returns The auto-created wrapper element
	 */
	public static append(
		component: ReactNode,
		container: Element | DocumentFragment,
		perspectiveContext: PerspectiveContext,
		wrapperStyles?: WrapperStyles
	): Root {
		const root = ReactUtils.appendRoot(container, wrapperStyles);
		ReactUtils.replace(component, root, perspectiveContext);
		return root;
	}

	/**
	 * Convenience method that creates a separate child in the given container as first child into which the given
	 * component is rendered.
	 *
	 * @returns The auto-created wrapper element
	 */
	public static prepend(
		component: ReactNode,
		container: Element | DocumentFragment,
		perspectiveContext: PerspectiveContext,
		wrapperStyles?: WrapperStyles
	): Root {
		const wrapper = ReactUtils.createWrapperElement(wrapperStyles);
		container.prepend(wrapper);
		return ReactUtils.render(component, wrapper, perspectiveContext);
	}

	/**
	 * Creates a separate child in the given container into which the given static (i.e., non-interactive) component is
	 * rendered. Note that you should use {@link append} when your component needs service calls.
	 *
	 * @returns The auto-created wrapper element
	 */
	public static appendStatic(
		component: ReactNode,
		container: Element | DocumentFragment,
		wrapperStyles?: WrapperStyles
	): Root {
		const root = ReactUtils.appendRoot(container, wrapperStyles);
		ReactUtils.replaceStatic(component, root);
		return root;
	}

	/**
	 * Renders the given static (i.e., non-interactive) component into the container element. Note that you should use
	 * {@link render} when your component needs service calls or access to the perspective context.
	 */
	public static renderStatic(component: ReactNode, container: Element): Root {
		const root = createRoot(container);
		root.render(
			<BaseProviders hashReloadBehavior={EHashReloadBehavior.RELOAD_ONLY_SILENTLY_APPLIED_CHANGES}>
				{component}
			</BaseProviders>
		);
		return root;
	}

	/**
	 * Appends a new DOM element that will serve as a React root element and can be filled with #replace or
	 * #replaceStatic later on.
	 */
	public static appendRoot(container: Element | DocumentFragment, wrapperStyles?: WrapperStyles): Root {
		const wrapper = ReactUtils.createWrapperElement(wrapperStyles);
		container.appendChild(wrapper);
		return createRoot(wrapper);
	}

	private static createWrapperElement(wrapperStyles?: WrapperStyles) {
		const wrapper: HTMLDivElement = document.createElement('div');
		wrapper.style.display = 'contents';
		if (wrapperStyles?.className) {
			wrapper.className = wrapperStyles.className;
		}
		if (wrapperStyles?.style) {
			Object.assign(wrapper.style, wrapperStyles.style);
		}
		return wrapper;
	}
}

/** Props for BaseContextProviders. */
type BaseContextProvidersProps = { perspectiveContext: ExtendedPerspectiveContext; children: ReactNode };

/** Provides the basic perspective context for React components in Teamscale. */
export function PerspectiveContextProviders({ perspectiveContext, children }: BaseContextProvidersProps): JSX.Element {
	const hash = useNavigationHash();
	return (
		<PerspectiveContextContext.Provider value={perspectiveContext}>
			<UserInfoContext.Provider value={perspectiveContext.userInfo}>
				<ProjectInfosContext.Provider value={perspectiveContext.projectsInfo}>
					<TeamscaleInfoContext.Provider value={perspectiveContext.teamscaleInfo}>
						<ProjectProvider projectId={hash.getProject()}>{children}</ProjectProvider>
					</TeamscaleInfoContext.Provider>
				</ProjectInfosContext.Provider>
			</UserInfoContext.Provider>
		</PerspectiveContextContext.Provider>
	);
}

/** Props for BaseProviders. */
type BaseProvidersProps = { children: ReactNode; hashReloadBehavior: EHashReloadBehavior; client?: QueryClient };

/** Provides suspense handling, client and error boundary for React components in Teamscale. */
export function BaseProviders({ children, hashReloadBehavior, client }: BaseProvidersProps): JSX.Element {
	const teamscaleServiceClient = useMemo(
		() =>
			new TeamscaleServiceClient(error => {
				throw error;
			}),
		[]
	);
	return (
		<NavigationHashReloadBehaviorContext.Provider value={hashReloadBehavior}>
			<QueryClientProvider client={client ?? ReactUtils.queryClient}>
				<TeamscaleServiceClientContext.Provider value={teamscaleServiceClient}>
					<SuspendingErrorBoundary>{children}</SuspendingErrorBoundary>
				</TeamscaleServiceClientContext.Provider>
			</QueryClientProvider>
		</NavigationHashReloadBehaviorContext.Provider>
	);
}
